import { Reducer } from 'redux'
import { DEPOSIT_ACTIONS } from './const'
import { getBlockchainTransferInfoKey, getBankTransferInfoKey } from './helpers'
import { ActionTypes } from './types'
import { DepositBankTransferInfo, DepositBlockchainTransferInfo } from '~/types/deposit'

type State = {
  bankTransferInfoList: Record<string, DepositBankTransferInfo> // (currency | currency__transferInfoIdentifier) => DepositBankTransferInfo
  blockchainTransferInfoList: Record<string, DepositBlockchainTransferInfo> // currency__network => DepositBlockchainTransferInfo
}

const initialState: State = {
  bankTransferInfoList: {},
  blockchainTransferInfoList: {},
}

export const deposit: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_ACTIONS.SET_BANK_TRANSFER_INFO: {
      const storeKey = getBankTransferInfoKey(action.currency, action.transferInfoIdentifier)
      const newBankTransferInfo = {
        ...state.bankTransferInfoList,
        [storeKey]: action.transferInfo,
      }
      return {
        ...state,
        bankTransferInfoList: newBankTransferInfo,
      }
    }

    case DEPOSIT_ACTIONS.SET_BLOCKCHAIN_TRANSFER_INFO: {
      const storeKey = getBlockchainTransferInfoKey(action.currency, action.blockchainNetwork)
      const newBlockchainTransferInfo = {
        ...state.blockchainTransferInfoList,
        [storeKey]: action.blockchainAddress,
      }
      return {
        ...state,
        blockchainTransferInfoList: newBlockchainTransferInfo,
      }
    }

    default:
      return state
  }
}

// withPermission HOC- mostly related to url accessible permission
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useSelector } from 'react-redux'
import { ConfigManager, SITE_TYPE } from '~/managers/ConfigManager'
import { PERMISSIONS } from '~/types'
import { hasPermission } from '~/permission'
import { selectFeatureMatrixFactors as bizFeatureMatrix } from '~/biz/store/route/selectors'
import { selectFeatureMatrixFactors as xidrBizFeatureMatrix } from '~/xidrBiz/store/route/selectors'

const withPermission = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  BaseComponent: React.ComponentType<any>,
  permission: PERMISSIONS,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DeniedComponent?: React.ComponentType<any>
) => ({ ...props }) => {
  let featureMatrix
  switch (ConfigManager.getSiteType()) {
    case SITE_TYPE.BIZ:
      featureMatrix = useSelector(bizFeatureMatrix)
      break
    case SITE_TYPE.XIDR_BIZ:
      featureMatrix = useSelector(xidrBizFeatureMatrix)
      break
    default:
      featureMatrix = useSelector(xidrBizFeatureMatrix)
  }

  const userPermission = hasPermission(permission, featureMatrix)

  if (userPermission) {
    return <BaseComponent {...props} />
  } else if (DeniedComponent) {
    return <DeniedComponent {...props} />
  }

  return null
}

export default withPermission

import { Reducer } from 'redux'
import {
  ActionTypes,
  AccountOptions,
  AccountStatementFilterValues,
  ACCOUNT_STATEMENT_MODE,
  DownloadUrls,
} from './types'
import { ACCOUNT_STATEMENT_ACTIONS } from './const'

type State = {
  isAccountStatementUIEnabled: boolean

  accountOptions: AccountOptions | null
  downloadUrls: DownloadUrls
  accountStatementFilterValues: AccountStatementFilterValues
}

const initialState: State = {
  isAccountStatementUIEnabled: false,

  accountOptions: null,

  downloadUrls: {},

  accountStatementFilterValues: {
    mode: ACCOUNT_STATEMENT_MODE.DOWNLOAD_URLS,
    userEmail: null,
    accountIds: undefined,
    startDate: '',
    endDate: '',
  },
}

export const straitsXAccountStatement: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ACCOUNT_STATEMENT_ACTIONS.SET_IS_ACCOUNT_STATEMENT_UI_ENABLED: {
      return {
        ...state,
        isAccountStatementUIEnabled: action.isAccountStatementUIEnabled,
      }
    }

    case ACCOUNT_STATEMENT_ACTIONS.GET_ACCOUNT_OPTIONS: {
      return {
        ...state,
        accountOptions: action.accountOptions,
      }
    }

    case ACCOUNT_STATEMENT_ACTIONS.SET_ACCOUNT_STATEMENT_FILTER_VALUES: {
      return {
        ...state,
        accountStatementFilterValues: {
          ...state.accountStatementFilterValues,
          ...action.accountStatementFilterObject,
        },
      }
    }

    case ACCOUNT_STATEMENT_ACTIONS.GET_DOWNLOAD_URLS: {
      return {
        ...state,
        downloadUrls: { ...state.downloadUrls, ...action.downloadUrls },
      }
    }

    default:
      return state
  }
}

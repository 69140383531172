import { Reducer } from 'redux'
import {
  FilterOptions,
  FilterValues,
  ActionTypes,
  XIDR_TRANSACTIONS_ACTIONS,
  CSV_FORMAT_VERSION,
  TRANSACTIONS_TAB_KEY,
  Transaction,
} from './types'

const initialState = {
  filterOptions: null,
  latestPersonalTransactions: null,
  filterValues: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: {
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      blockedReasons: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
    },
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: {
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      blockedReasons: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
    },
  },
  csvFormatVersion: null,
  transactionsTabKey: TRANSACTIONS_TAB_KEY.XFERS_WALLET, // should be different in biz site
  transactionTabOptions: [],
  viewType: '',
}

type State = {
  filterOptions: null | {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterOptions
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterOptions
  }
  filterValues: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterValues
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterValues
  }
  csvFormatVersion: null | CSV_FORMAT_VERSION
  transactionsTabKey: null | TRANSACTIONS_TAB_KEY
  transactionTabOptions: TRANSACTIONS_TAB_KEY[]
  viewType: string
  latestPersonalTransactions: Transaction[] | null
}

export const xidrTransactions: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            isTxnLoading: action.isTxnLoading,
          },
        },
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING: {
      return {
        ...state,
        isFilterLoading: action.isFilterLoading,
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING: {
      return {
        ...state,
        isPaginationLoading: action.isPaginationLoading,
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            transactions: action.transactions,
            contractsExist: action.contractsExist,
            isTxnLoading: false,
          },
        },
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            totalCount: action.totalCount,
            isPaginationLoading: false,
          },
        },
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: action.filterOptions,
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.SET_FILTERS: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues[transactionsTabKey],
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        const values = filterValuesObject[key]
        newTabFilterValues[key] = values
      })

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            ...newTabFilterValues,
          },
        },
      } as State
    }

    case XIDR_TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            currentPage: 1,
          },
        },
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION: {
      return {
        ...state,
        csvFormatVersion: action.csvFormatVersion,
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB: {
      return {
        ...state,
        transactionsTabKey: action.transactionsTabKey,
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS: {
      return {
        ...state,
        transactionTabOptions: action.transactionTabOptions,
      }
    }

    case XIDR_TRANSACTIONS_ACTIONS.SET_LATEST_PERSONAL_TRANSACTIONS: {
      return {
        ...state,
        latestPersonalTransactions: action.latestPersonalTransactions,
      }
    }

    default:
      return state
  }
}

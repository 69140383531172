import { ENDPOINTS, get } from '~/api'
import { ConfigManager } from '~/managers/ConfigManager'

class FeatureGatingManager {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public isEnabled(featureKey: string[] | string, flipperId?: string): Promise<any> {
    this.isEnabled = this.isEnabled.bind(this)
    return new Promise((resolve, reject) => {
      if (typeof featureKey !== 'string' && !Array.isArray(featureKey)) {
        reject('flipper must be a string or an array of string.')
      }

      const queryString = FeatureGatingManager.buildFeatureURL(featureKey, flipperId)
      get(
        ConfigManager.isXIDRSite()
          ? ENDPOINTS.API_V3_STABLECOIN_CHECK_FEATURE
          : ENDPOINTS.API_XFERS_CHECK_FEATURE,
        queryString
      )
        .then(resp => {
          if (typeof featureKey === 'string') {
            resolve(resp.data[featureKey])
          } else if (Array.isArray(featureKey)) {
            resolve(resp.data)
          }
        })
        .catch(err => {
          console.error(err) // eslint-disable-line
          resolve(false)
        })
    })
  }

  public isSomeEnabled(featureArray: { featureKey: string; flipperId?: string }[]) {
    return Promise.all(
      featureArray.map(feature => this.isEnabled(feature.featureKey, feature.flipperId))
    ).then(respArray => {
      return respArray.some(resp => resp === true)
    })
  }

  public isAllEnabled(featureArray: { featureKey: string; flipperId?: string }[]) {
    return Promise.all(
      featureArray.map(feature => this.isEnabled(feature.featureKey, feature.flipperId))
    ).then(respArray => {
      return respArray.every(resp => resp === true)
    })
  }

  static buildFeatureURL(featureKey: string[] | string, flipperId?: string): string {
    let params = '?features='
    params += JSON.stringify(typeof featureKey === 'string' ? [featureKey] : featureKey.sort())
    if (flipperId) {
      params = params + '&flipper_id=' + encodeURIComponent(flipperId)
    }
    return params
  }
}

export default new FeatureGatingManager()

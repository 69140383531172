export enum DEPOSIT_INSTRUCTIONS_ACTIONS {
  SET_IS_COMMENT_FULLNAME_ENABLED = 'DEPOSIT_INSTRUCTIONS.SET_IS_COMMENT_FULLNAME_ENABLED',
  SET_IS_FAST_LIVE_BANNER_ENABLED = 'DEPOSIT_INSTRUCTIONS.SET_IS_FAST_LIVE_BANNER_ENABLED ',
}

type SetIsFastLiveBannerEnabled = {
  type: typeof DEPOSIT_INSTRUCTIONS_ACTIONS.SET_IS_FAST_LIVE_BANNER_ENABLED
  isFastLiveBannerEnabled: boolean
}

export type ActionTypes = SetIsFastLiveBannerEnabled

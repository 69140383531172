import { Reducer } from 'redux'
import { ActionTypes, AccountDetail, Account } from './types'
import { XIDR_ACCOUNT_ACTIONS } from './const'

type State = {
  accountList: Account[]
  isAccountListInitiated: boolean
  accountDetailList: AccountDetail[]
  isAccountDetailListInitiated: boolean
}

const initialState: State = {
  accountDetailList: [],
  isAccountDetailListInitiated: false,
  accountList: [],
  isAccountListInitiated: false,
}

export const xidrAccount: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_ACCOUNT_ACTIONS.SET_ACCOUNT_DETAIL_LIST: {
      return {
        ...state,
        accountDetailList: action.accountDetailList,
        isAccountDetailListInitiated: true,
      }
    }

    case XIDR_ACCOUNT_ACTIONS.SET_ACCOUNT_LIST: {
      return {
        ...state,
        accountList: action.accountList,
        isAccountListInitiated: true,
      }
    }

    default:
      return state
  }
}

import article1 from './article1.json'
import article2 from './article2.json'
import article3 from './article3.json'
import article4 from './article4.json'
import article5 from './article5.json'
import article6 from './article6.json'
import article7 from './article7.json'
import article8 from './article8.json'
import article9 from './article9.json'
import article10 from './article10.json'
import notes from './notes.json'

import { Document } from '../../types'

export const StraitsXEarn: Document = {
  notes,
  "sections": [
    article1,
    article2,
    article3,
    article4,
    article5,
    article6,
    article7,
    article8,
    article9,
    article10
  ]
}

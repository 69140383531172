import moment from 'moment'
import { Moment } from '~/types'
import { ACCOUNT_STATEMENT_ACTIONS } from './const'
import { ActionTypes, AccountStatementFilterObject, ACCOUNT_STATEMENT_MODE } from './types'
import { Dispatch } from '~/biz/store/types'
import { ENDPOINTS, get, post } from '~/api'
import { keysToCamel } from '~/helpers/helpers'

export const getAccountOptions = (onSuccess: () => void) => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_ACCOUNT_STATEMENTS_RETRIEVE_ACCOUNT_OPTIONS).then(resp => {
    dispatch({
      type: ACCOUNT_STATEMENT_ACTIONS.GET_ACCOUNT_OPTIONS,
      accountOptions: {
        accounts: keysToCamel(resp.accounts),
        earliestInvalidDate: resp.earliest_invalid_date,
      },
    })
    onSuccess()
  })
}

export const setIsAccountStatementUIEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ACCOUNT_STATEMENT_ACTIONS.SET_IS_ACCOUNT_STATEMENT_UI_ENABLED,
    isAccountStatementUIEnabled: enabled,
  })
}

export const setAccountStatementFilter = (
  accountStatementFilterObject: AccountStatementFilterObject
) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ACCOUNT_STATEMENT_ACTIONS.SET_ACCOUNT_STATEMENT_FILTER_VALUES,
    accountStatementFilterObject,
  })
}

export const getDownloadUrls = (
  {
    mode,
    email,
    accounts,
  }: {
    mode: ACCOUNT_STATEMENT_MODE | null
    email: string | null
    accounts: Array<{ account_ids: string[]; date: string | Moment }>
  },
  onSuccess: (downloadUrl: string) => void
) => (dispatch: Dispatch<ActionTypes>) => {
  const requestBody = {
    mode,
    email,
    accounts,
  }

  post(ENDPOINTS.API_V3_STABLECOIN_ACCOUNT_STATEMENTS, requestBody).then(resp => {
    const { date, downloadUrl } = keysToCamel(resp.download_urls_info[0]) // only get 1 downloadUrl per request

    const timestamp = moment(date).format('MMM YYYY')

    dispatch({
      type: ACCOUNT_STATEMENT_ACTIONS.GET_DOWNLOAD_URLS,
      downloadUrls: { [timestamp]: downloadUrl },
    })
    onSuccess(downloadUrl)
  })
}

import { Moment } from '~/types'

export type EarnAccount = {
  email: string
  name: string
  platform_account_identifier: string
  uen: number
} | null

export type InterestInfo = {
  apy: string
  apyRate: string | Array<{ lower_bound: string; upper_bound: string; apy: string; rate: string }>
  payoutFrequency: string
  pendingInterestPayoutAmt: string
  reward_rate_type: string
  totalInterestEarned: string
} | null

export type PlatformInfo = {
  name: string
  baseInterestRate: string
  baseInterestRatePercent: string
} | null

export type EarnBalance = {
  currentBalance: number
  holdingLimitAmount: number
  availableBalance: number
  balanceLimit: number
} | null

export type EarnCurrencyBalance = {
  rewardRateType: string
  apy: string
  apyRate: string | Array<{ lower_bound: string; upper_bound: string; apy: string; rate: string }>
  currencyCode: string
  currentBalance: number
  holdingLimitAmount: number
  availableBalance: number
  balanceLimit: number
  pendingInterestPayout: number
  totalInterestEarned: number
} | null

export enum EarnTransferRequestType {
  DEPOSIT,
  WITHDRAWAL,
}

export enum STRAITSX_EARN_TRANSACTIONS_ACTIONS {
  SET_IS_TRANSACTION_LOADING = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_LOADING',
  SET_EARN_ACCOUNT = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.CREATE_EARN_ACCOUNT',
  SET_IS_LOADING = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_IS_LOADING',
  SET_EARN_BALANCE = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_BALANCE',
  SET_EARN_USDC_BALANCE = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_USDC_BALANCE',
  SET_EARN_XSGD_BALANCE = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_XSGD_BALANCE',
  SET_LATEST_TRANSFER_REQUEST = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_LATEST_TRANSFER_REQUEST',
  SET_EARN_TRANSACTIONS = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS',
  SET_EARN_TRANSACTIONS_FILTER_CURRENCY = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY',
  SET_EARN_TRANSACTIONS_FILTER_DATE = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE',
  SET_EARN_TRANSACTIONS_FILTER_STATUS = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS',
  SET_EARN_TRANSACTION_TYPE = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_TYPE',
  SET_EARN_TRANSACTION_CURRENT_PAGE = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_CURRENT_PAGE',
  SET_EARN_TRANSACTION_SORT_ORDER = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_SORT_ORDER',
  API_V3_STABLECOIN_EARN_INTEREST = 'api/v3/stablecoin/earn/interests',
  SET_INTEREST_INFO = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_INTEREST_INFO',
  SET_PLATFORM_INFO = 'STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_PLATFORM_INFO',
}

type SetIsTransactionLoading = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_LOADING
  isTransactionLoading: boolean
}

type SetEarnAccount = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_ACCOUNT
  earnAccount: EarnAccount
}
type SetInterestInfo = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_INTEREST_INFO
  interestInfo: InterestInfo
}
type SetPlatformInterestInfo = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_PLATFORM_INFO
  platformInfo: PlatformInfo
}

type SetIsLoading = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}
type SetEarnBalance = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_BALANCE
  earnBalance: EarnBalance
}
type SetEarnUSDCBalance = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_USDC_BALANCE
  earnUSDCBalance: EarnCurrencyBalance
}
type SetEarnXSGDBalance = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_XSGD_BALANCE
  earnXSGDBalance: EarnCurrencyBalance
}
type SetEarnTransactedAmount = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_LATEST_TRANSFER_REQUEST
  amount: number
  currencyCode: string
  requestType: EarnTransferRequestType
}

export type EarnTransaction = {
  amount: string
  apy: string
  balance: string
  createdAt: string
  currency: string
  exactAmount: string
  exactBalance: string
  exactStatus: string
  exactTransactionTypeTotal: string
  id: string
  platformAccountIdentifier: string
  referenceId: string
  status: string
  transactionType: string
  transactionTypeTotal: string
  updatedAt: string
}

type SetEarnTransaction = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS
  transactions: EarnTransaction[]
  totalCount: number
  totalPages: number
}

type SetEarnTransactionCurrentPage = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_CURRENT_PAGE
  currentPage: number
}

export enum EARN_TRANSACTION_TYPE {
  INTEREST = 'interest',
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum EARN_CURRENCY {
  XSGD = 'XSGD',
  USDC = 'USDC',
}

export enum EARN_TXN_STATUS {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

type SetEarnTransactionsFilterCurrency = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY
  currency: EARN_CURRENCY | null
}

type SetEarnTransactionsFilterDate = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE
  startDate: string | null
  endDate: string | null
}

type SetEarnTransactionsFilterStatus = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS
  status: EARN_TXN_STATUS | null
}

type SetEarnTransactionType = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_TYPE
  transactionType: EARN_TRANSACTION_TYPE
}

export enum EARN_TRANSACTION_SORT_ORDER {
  NEWEST = 'desc',
  OLDEST = 'asc',
}

type SetEarnTransactionSortOrder = {
  type: typeof STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_SORT_ORDER
  sortOrder: EARN_TRANSACTION_SORT_ORDER
}

export type ActionTypes =
  | SetIsTransactionLoading
  | SetEarnAccount
  | SetIsLoading
  | SetEarnBalance
  | SetEarnUSDCBalance
  | SetEarnXSGDBalance
  | SetEarnTransactedAmount
  | SetEarnTransaction
  | SetEarnTransactionsFilterCurrency
  | SetEarnTransactionsFilterDate
  | SetEarnTransactionsFilterStatus
  | SetEarnTransactionType
  | SetEarnTransactionCurrentPage
  | SetEarnTransactionSortOrder
  | SetInterestInfo
  | SetPlatformInterestInfo

export type FilterValuesObject = {
  [filterKey: string]: Moment | string[] | number[] | string | number | boolean | undefined
}

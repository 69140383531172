import { EARN_TRANSACTION_TYPE } from '~/biz/store/straitsXEarn/types'

export type TransactionDetails = {
  id: string
  referenceId: string
  transactionType: EARN_TRANSACTION_TYPE | null
  amount: { prefix: string; postfix: string }
  createdDate: string
  completedDate: string
  currencyCode: string
  status: string
  details: string
}

export enum STRAITSX_EARN_TRANSACTION_DETAILS_ACTIONS {
  SET_TRANSACTION_DETAILS = 'STRAITSX_EARN_TRANSACTION_DETAILS_ACTIONS.SET_TRANSACTION_DETAILS',
}

type SetTransactionDetails = {
  type: typeof STRAITSX_EARN_TRANSACTION_DETAILS_ACTIONS.SET_TRANSACTION_DETAILS
  transactionDetails: TransactionDetails
}

export type ActionTypes = SetTransactionDetails

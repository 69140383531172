/* eslint-disable max-lines */
import { lazy } from 'react'
import { ConfigManager } from '~/managers/ConfigManager'
import { PATHS } from './paths'
import { Route, PERMISSIONS } from '~/types'
import withStraitsXBreadcrumbBar from '~/common/withStraitsXBreadcrumbBar'
import withStraitsXBreadcrumbBarAndLink from '~/common/withStraitsXBreadcrumbBarAndLink'

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '~/biz/pages/NotFound'))

const Error = lazy(() => import(/* webpackChunkName: "Error" */ '~/sharedModules/Error'))

const StraitsXDeveloperTools = lazy(() =>
  import(/* webpackChunkName: "StraitsXDeveloperTools" */ '~/biz/pages/StraitsXDeveloperTools')
)

const InternalTools = lazy(() =>
  import(/* webpackChunkName: "InternalTools" */ '~/biz/pages/InternalTools')
)

const StraitsXDeposit = lazy(() =>
  import(/* webpackChunkName: "StraitsXDeposit" */ '~/biz/pages/StraitsXDeposit')
)

const StraitsXLandingPage = lazy(() =>
  import(/* webpackChunkName: "StraitsXLandingPage" */ '~/biz/pages/StraitsXLandingPage')
)

const StraitsXMyAccount = lazy(() =>
  import(/* webpackChunkName: "StraitsXMyAccount" */ '~/biz/pages/StraitsXMyAccount')
)
const StraitsXTransactions = lazy(() =>
  import(/* webpackChunkName: "StraitsXTransactions" */ '~/biz/pages/StraitsXTransactions')
)
const StraitsXTransactionDetails = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXTransactionDetails" */ '~/biz/pages/StraitsXTransactionDetails'
  )
)
const StraitsXAccountStatement = lazy(() =>
  import(/* webpackChunkName: "StraitsXAccountStatement" */ '~/biz/pages/StraitsXAccountStatement')
)
const StraitsXWithdraw = lazy(() =>
  import(/* webpackChunkName: "StraitsXWithdraw" */ '~/biz/pages/StraitsXWithdraw')
)
const GetStarted = lazy(() => import(/* webpackChunkName: "GetStarted" */ '~/biz/pages/GetStarted'))
const StraitsXTeam = lazy(() => import(/* webpackChunkName: "Team" */ '~/biz/pages/StraitsXTeam'))

const StraitsXVerification = lazy(() =>
  import(/* webpackChunkName: "StraitsXVerification" */ '~/biz/pages/StraitsXVerification')
)

const StraitsXVerificationClaimKyc = lazy(() =>
  import(/* webpackChunkName: "VerificationClaimKyc" */ '~/biz/pages/StraitsXVerification/ClaimKyc')
)

const StraitsXVerificationMyInfoCallback = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXVerification" */ '~/biz/pages/StraitsXVerification/MyInfoDetails'
  )
)

const StraitsXBizKycInformation = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycInformation" */ '~/biz/pages/StraitsXBusinessVerification/BusinessInformations'
  )
)
const StraitsXBizKycIntendedRelations = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycIntendedRelations" */ '~/biz/pages/StraitsXBusinessVerification/IntendedRelations'
  )
)
const StraitsXBizKycBusinessActivities = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycBusinessActivities" */ '~/biz/pages/StraitsXBusinessVerification/BusinessActivities'
  )
)
const StraitsXBizKycTaxResidency = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycTaxResidency" */ '~/biz/pages/StraitsXBusinessVerification/TaxResidency'
  )
)
const StraitsXBizKycUploadDocuments = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycUploadDocuments" */ '~/biz/pages/StraitsXBusinessVerification/UploadDocuments'
  )
)
const StraitsXBizKycAccountManager = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycAccountManager" */ '~/biz/pages/StraitsXBusinessVerification/AccountManager'
  )
)
const StraitsXBizKycDirectorsAndBo = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycDirectorsAndBo" */ '~/biz/pages/StraitsXBusinessVerification/DirectorsAndBo'
  )
)
const StraitsXBizKycReview = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycReview" */ '~/biz/pages/StraitsXBusinessVerification/Review'
  )
)
const StraitsXBizKycComplete = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXBizKycComplete" */ '~/biz/pages/StraitsXBusinessVerification/Complete'
  )
)

const StraitsXSimulateDeposit = lazy(() =>
  import(/* webpackChunkName: "StraitsXSimulateDeposit" */ '~/biz/pages/StraitsXSimulateDeposit')
)

const StraitsXEarn = lazy(() =>
  import(/* webpackChunkName: "StraitsXEarn" */ '~/biz/pages/StraitsXEarn')
)

const StraitsXEarnFaqsLink = lazy(() =>
  import(/* webpackChunkName: "StraitsXEarn" */ '~/biz/pages/StraitsXEarn/FaqsLink')
)

const StraitsXEarnInterestSubmitted = lazy(() =>
  import(/* webpackChunkName: "StraitsXEarn" */ '~/biz/pages/StraitsXEarn/InterestSubmitted')
)

const StraitsXEarnTransferRequestSubmission = lazy(() =>
  import(/* webpackChunkName: "StraitsXEarn" */ '~/biz/pages/StraitsXEarnTransferRequestSubmission')
)

const TwoFaSetting = lazy(() =>
  import(/* webpackChunkName: "TwoFaSetting" */ '~/biz/pages/TwoFaSetting')
)

const StraitsXEarnTransactionDetails = lazy(() =>
  import(
    /* webpackChunkName: "StraitsXEarnTransactionDetails" */ '~/biz/pages/StraitsXEarnTransactionDetails'
  )
)

export const isStagingOrLocal =
  ConfigManager.isIdBizSite() &&
  (window.location.hostname === 'id-biz-staging.internal.xfers.com' ||
    window.location.hostname === 'localhost')

export const straitsXRoutes: Route[] = [
  {
    path: PATHS.GET_STARTED,
    component: GetStarted,
    exact: true,
    sideBar: 'Get Started',
    documentTitle: { name: 'Get Started' },
  },
  {
    path: PATHS.LANDING_PAGE,
    component: StraitsXLandingPage,
    exact: true,
    sideBar: 'Home',
    breadcrumbName: 'Home',
    documentTitle: {
      name: 'Dashboard',
      withSiteTypePrefix: true,
      withSiteTypeSuffix: false,
    },
  },
  {
    path: PATHS.EARN,
    component: withStraitsXBreadcrumbBarAndLink(StraitsXEarn, StraitsXEarnFaqsLink),
    exact: true,
    sideBar: 'Earn',
    breadcrumbName: 'Earn',
    documentTitle: { name: 'Earn' },
  },
  {
    path: PATHS.EARN_INTEREST_SUBMITTED,
    component: StraitsXEarnInterestSubmitted,
    exact: true,
    documentTitle: { name: 'Earn Interest Submitted' },
  },
  {
    path: PATHS.EARN_TRANSACTION_DETAILS,
    component: StraitsXEarnTransactionDetails,
    exact: true,
    documentTitle: { name: 'Earn Transactions' },
  },
  {
    path: PATHS.EARN_TRANSFER_REQUEST_SUBMITTED,
    component: withStraitsXBreadcrumbBar(StraitsXEarnTransferRequestSubmission),
    exact: true,
    documentTitle: { name: 'Earn Transfer Submitted' },
  },
  {
    path: PATHS.TRANSACTIONS,
    component: withStraitsXBreadcrumbBar(StraitsXTransactions),
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    sideBar: 'Transaction History',
    breadcrumbName: 'Transaction History',
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.TRANSACTION_DETAILS,
    component: StraitsXTransactionDetails,
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.ACCOUNT_STATEMENT,
    component: withStraitsXBreadcrumbBar(StraitsXAccountStatement),
    permission: PERMISSIONS.ACCOUNT_STATEMENT,
    exact: true,
    sideBar: 'Account Statement',
    breadcrumbName: 'Account Statement',
    documentTitle: { name: 'Account Statement' },
  },
  {
    path: PATHS.TWO_FA_SETTING,
    component: withStraitsXBreadcrumbBar(TwoFaSetting),
    exact: true,
    breadcrumbName: '2FA setting',
    documentTitle: { name: 'Mobile Authenticator Setup' },
  },
  {
    path: PATHS.TRANSFER_IN,
    component: withStraitsXBreadcrumbBar(StraitsXDeposit),
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Transfer In',
    documentTitle: { name: 'Transfer In' },
  },
  {
    path: PATHS.SIMULATE_DEPOSIT,
    component: StraitsXSimulateDeposit,
    exact: true,
    documentTitle: { name: 'Simulate Transfer In' },
  },
  {
    path: PATHS.TRANSFER_OUT,
    component: withStraitsXBreadcrumbBar(StraitsXWithdraw),
    permission: PERMISSIONS.WITHDRAW,
    exact: true,
    breadcrumbName: 'Transfer Out',
    documentTitle: { name: 'Transfer Out' },
  },
  {
    path: PATHS.MANAGE_ACCOUNT,
    component: withStraitsXBreadcrumbBar(StraitsXMyAccount),
    exact: true,
    breadcrumbName: 'My Account',
    documentTitle: { name: 'Manage Account' },
  },
  {
    path: PATHS.DEVELOPER_TOOLS,
    component: withStraitsXBreadcrumbBar(StraitsXDeveloperTools),
    permission: PERMISSIONS.DEVELOPER_TOOLS,
    sideBar: 'Developer Tools',
    breadcrumbName: 'Developer Tools',
    documentTitle: { name: 'Developer Tools' },
  },
  {
    path: PATHS.TEAM,
    component: withStraitsXBreadcrumbBar(StraitsXTeam),
    permission: PERMISSIONS.TEAM,
    sideBar: 'Team',
    breadcrumbName: 'Team',
    documentTitle: { name: 'Team Management' },
  },
  {
    path: PATHS.VERIFICATION,
    component: withStraitsXBreadcrumbBar(StraitsXVerification),
    exact: true,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Manager Verification',
    documentTitle: { name: 'Complete Verification' },
  },
  {
    path: PATHS.CLAIM_KYC,
    component: withStraitsXBreadcrumbBar(StraitsXVerificationClaimKyc),
    exact: true,
    breadcrumbName: 'Verification',
    pageTitle: 'Account Manager Verification',
    // documentTitle: { name: 'Verification' },
  },
  {
    path: PATHS.BIZ_KYC_INFORMATION,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycInformation),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification Information' },
  },
  {
    path: PATHS.BIZ_KYC_INTENDED_RELATIONS,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycIntendedRelations),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification - Intended Relations' },
  },
  {
    path: PATHS.BIZ_KYC_BUSINESS_ACTIVITIES,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycBusinessActivities),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification - Business Activities' },
  },
  {
    path: PATHS.BIZ_KYC_TAX_RESIDENCY,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycTaxResidency),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification - Tax Residency' },
  },
  {
    path: PATHS.BIZ_KYC_UPLOAD_DOCUMENTS,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycUploadDocuments),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification - Upload Documents' },
  },
  {
    path: PATHS.BIZ_KYC_ACCOUNT_MANAGER,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycAccountManager),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Account Manager' },
  },
  {
    path: PATHS.BIZ_KYC_DIRECTORS_AND_BO,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycDirectorsAndBo),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification - Director List' },
  },
  {
    path: PATHS.BIZ_KYC_REVIEW,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycReview),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification Review' },
  },
  {
    path: PATHS.BIZ_KYC_COMPLETE,
    component: withStraitsXBreadcrumbBar(StraitsXBizKycComplete),
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Business Verification',
    documentTitle: { name: 'Verification Complete' },
  },
  {
    path: PATHS.VERIFICATION_MY_INFO_CALLBACK,
    component: withStraitsXBreadcrumbBar(StraitsXVerificationMyInfoCallback),
    exact: true,
    documentTitle: { name: 'Singpass Callback' },
  },
  {
    path: PATHS.INTERNAL_TOOLS,
    component: withStraitsXBreadcrumbBar(InternalTools),
    breadcrumbName: 'Internal Tools',
    documentTitle: { name: 'Internal Tools' },
  },
  {
    path: PATHS.ERROR,
    component: Error,
    documentTitle: { name: 'Error' },
  },
  {
    component: NotFound,
    documentTitle: { name: 'Error' },
  },
]

function getRoutes() {
  return straitsXRoutes
}

const routes = getRoutes()

export default routes

import React from 'react'
import StraitsXBreadcrumbBar from '~/components/StraitsXBreadcrumbBar'

function withStraitsXBreadcrumbBar(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WrappedComponent: React.ComponentType<any>
) {
  return class extends React.Component {
    render() {
      return (
        <>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <StraitsXBreadcrumbBar {...this.props} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...this.props} />
        </>
      )
    }
  }
}

export default withStraitsXBreadcrumbBar

import { Dispatch } from '~/xidrApp/store/types'
import { get } from '~/api'
import ENDPOINTS from '~/api/paths'
import { FLIPPER_ACTIONS } from './const'
import { ActionTypes } from './types'
import { ConfigManager } from '~/managers/ConfigManager'

const requestFlipperItem = (featureKey: string | string[], flipperId?: string) => (
  dispatch: Dispatch<ActionTypes>
) => {
  if (typeof featureKey === 'string') {
    dispatch({
      type: FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_REQUESTED,
      featureKey,
      flipperId,
    })
  } else if (Array.isArray(featureKey)) {
    featureKey.forEach(key => {
      dispatch({
        type: FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_REQUESTED,
        featureKey: key,
        flipperId,
      })
    })
  }
}

const fetchFlipperItemSucceeded = (
  featureKey: string,
  flipperId: string | undefined,
  enabled: boolean
) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_SUCCEEDED,
    featureKey,
    flipperId,
    enabled,
  })
}

const fetchFlipperItemFailed = (featureKey: string, flipperId?: string) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_FAILED,
    featureKey,
    flipperId,
  })
}

const buildFeatureURL = (featureKey: string[] | string, flipperId?: string): string => {
  let params = '?features='
  params += JSON.stringify(typeof featureKey === 'string' ? [featureKey] : featureKey.sort())
  if (flipperId) {
    params = params + '&flipper_id=' + encodeURIComponent(flipperId)
  }
  return params
}

export const checkFlipperIsEnabled = (featureKey: string[] | string, flipperId?: string) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(requestFlipperItem(featureKey, flipperId))

  const queryString = buildFeatureURL(featureKey, flipperId)
  get(
    ConfigManager.isXIDRSite()
      ? ENDPOINTS.API_V3_STABLECOIN_CHECK_FEATURE
      : ENDPOINTS.API_XFERS_CHECK_FEATURE,
    queryString
  )
    .then(resp => {
      if (typeof featureKey === 'string') {
        dispatch(fetchFlipperItemSucceeded(featureKey, flipperId, resp.data[featureKey]))
      } else if (Array.isArray(featureKey)) {
        featureKey.forEach(key => {
          dispatch(fetchFlipperItemSucceeded(key, flipperId, resp.data[key]))
        })
      }
    })
    .catch(() => {
      if (typeof featureKey === 'string') {
        dispatch(fetchFlipperItemFailed(featureKey, flipperId))
      } else if (Array.isArray(featureKey)) {
        featureKey.forEach(key => {
          dispatch(fetchFlipperItemFailed(key, flipperId))
        })
      }
    })
}

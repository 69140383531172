import { Reducer } from 'redux'
import { ActionTypes, Bank } from './types'
import { BANK_ACCOUNT_ACTIONS } from './const'
import { CURRENCY, STABLECOIN, BankAccount } from '~/types'

type BankAccountStore = {
  isBankAccountListInitiated: boolean
  bankAccountList: BankAccount[]
  isBankListInitiated?: boolean
  bankList?: Bank[]
}

type State = {
  [key in STABLECOIN]: BankAccountStore
}

const initialState: State = {
  [CURRENCY.XSGD]: {
    isBankAccountListInitiated: false,
    isBankListInitiated: false,
    bankAccountList: [],
    bankList: [],
  },
  [CURRENCY.USDC]: {
    isBankAccountListInitiated: false,
    bankAccountList: [],
  },
  [CURRENCY.USDT]: {
    isBankAccountListInitiated: false,
    bankAccountList: [],
  },
}

export const bankAccounts: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case BANK_ACCOUNT_ACTIONS.SET_BANK_ACCOUNT_LIST: {
      const parsedBankAccountList = action.bankAccountList.map((bank: BankAccount) => ({
        ...bank,
        // use `stablecoin` or `bankId` to mark the currency of a bank account
        isSGDBank: action.stablecoin === CURRENCY.XSGD || !!bank.bankId,
        isUSDBank: action.stablecoin === CURRENCY.USDC || !!bank.swiftCode,
      }))
      return {
        ...state,
        [action.stablecoin]: {
          ...state[action.stablecoin],
          bankAccountList: parsedBankAccountList,
          isBankAccountListInitiated: true,
        },
      }
    }

    case BANK_ACCOUNT_ACTIONS.SET_BANK_LIST: {
      return {
        ...state,
        [action.stablecoin]: {
          ...state[action.stablecoin],
          bankList: action.bankList,
          isBankListInitiated: true,
        },
      }
    }

    case BANK_ACCOUNT_ACTIONS.DELETE_BANK_ACCOUNTS: {
      const stablecoins = Object.keys(initialState)
      const copiedState = JSON.parse(JSON.stringify(state))

      for (let i = 0; i < stablecoins.length; i += 1) {
        const remainingAccounts = copiedState[stablecoins[i]].bankAccountList.filter(
          (account: BankAccount) => account.id !== action.bankAccountId
        )
        copiedState[stablecoins[i]].bankAccountList = remainingAccounts
      }

      return copiedState
    }

    default:
      return state
  }
}

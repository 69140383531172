export enum PERMISSIONS {
  TRANSACTION = 'transaction:view',

  DISBURSEMENTS = 'disbursements:view',
  DEPOSIT = 'deposit:view',
  WITHDRAW = 'withdraw:view',
  SWAP = 'swap:view',

  DEVELOPER_TOOLS = 'developerTools:view',
  TEAM = 'team:view',

  MERCHANT_USERS = 'merchantUsers:view',

  SANDBOX = 'sandbox:view',

  MANAGE_ACCOUNT_BANK = 'bankAccount:edit',
  MANAGE_ACCOUNT_BLOCKCHAIN_ADDRESS = 'blockChainAddress:edit',

  INVOICING = 'invoicing:edit',

  CREDIT_CARDS = 'creditCards:view',

  ACCOUNT_STATEMENT = 'accountStatement:view',
  XFERS_ACCEPT = 'xfersAccept:view',
  XFERS_SEND = 'xfersSend:view',

  VERIFICATION = 'verification:edit',

  // Regional permissions
  MANAGE_ACCOUNT_BANK_ADD_ACCOUNT = 'addBankAccount:edit',

  DEVELOPER_TOOLS_GENERATE_SECRET_KEY = 'developerToolsGenerateSecretKey:edit',

  INVOICING_CREATE_INVOICE = 'invoicingCreateInvoice:edit',

  DISBURSEMENTS_PROCEED_TRANSFER_DETAILS = 'disbursementsProceedTransferDetails:view',

  TRANSACTION_ACCOUNT_BALANCE = 'transactionAccountBalance:view',

  SETTINGS = 'settings:view',
}

/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'

function SvgHamburger(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 21 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={21} height={3} rx={1.5} fill="#959595" />
      <rect width={21} height={3} rx={1.5} fill="#CACACA" />
      <rect y={5} width={21} height={3} rx={1.5} fill="#959595" />
      <rect y={5} width={21} height={3} rx={1.5} fill="#CACACA" />
      <rect y={10} width={21} height={3} rx={1.5} fill="#959595" />
      <rect y={10} width={21} height={3} rx={1.5} fill="#CACACA" />
    </svg>
  )
}

export default SvgHamburger

import { Reducer } from 'redux'
import { TeamMember, TEAM_ACTIONS, ActionTypes } from './types'

const initialState = {
  team: [],
  isTeamInitiated: false,
}

type State = {
  team: TeamMember[]
  isTeamInitiated: boolean
}

export const xidrTeam: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_ACTIONS.SET_TEAM:
      return {
        ...state,
        team: action.team,
        isTeamInitiated: true,
      }
    default:
      return state
  }
}

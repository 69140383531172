/* eslint-disable max-lines */
import moment, { Moment } from 'moment'
import { Dispatch } from '~/app/store/types'
import {
  ActionTypes,
  EarnTransferRequestType,
  EarnTransaction,
  EARN_CURRENCY,
  EARN_TRANSACTION_STATUS,
  EARN_TRANSACTIONS_SORT_ORDER,
  EARN_TRANSACTION_TYPE,
  EARN_ACTIONS,
} from './types'
import { ENDPOINTS, get, post } from '~/api'
import { keysToCamel } from '~/helpers/helpers'

export const setIsEarnEnabled = (isEnabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: EARN_ACTIONS.SET_IS_EARN_ENABLED,
    isEnabled,
  })
}

export const createEarnAccount = ({ callback }: { callback: () => void }) => (
  dispatch: Dispatch<ActionTypes>
) => {
  const requestBody = {}
  post(ENDPOINTS.API_V3_STABLECOIN_EARN_ACCOUNT, requestBody).then(resp => {
    dispatch({
      type: EARN_ACTIONS.SET_EARN_ACCOUNT,
      earnAccount: {
        email: resp.email,
        name: resp.name,
        platform_account_identifier: resp.platform_account_identifier,
        uen: resp.uen,
      },
    })

    callback()
    dispatch({
      type: EARN_ACTIONS.SET_IS_LOADING,
      isLoading: false,
    })
  })
}

export const getEarnAccount = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: EARN_ACTIONS.SET_IS_LOADING,
    isLoading: true,
  })

  const subRoute = `/get_account`

  get(ENDPOINTS.API_V3_STABLECOIN_EARN_ACCOUNT, subRoute)
    .then(resp => {
      dispatch({
        type: EARN_ACTIONS.SET_EARN_ACCOUNT,
        earnAccount: {
          email: resp.email || '',
          name: resp.name || '',
          platform_account_identifier: resp.platform_account_identifier || '',
          uen: resp.uen || 0,
        },
      })
      dispatch({
        type: EARN_ACTIONS.SET_IS_LOADING,
        isLoading: false,
      })
    })
    .catch(err => {
      const message = err.response.data.error
      if (message === "User doesn't have an earn account yet") {
        dispatch({
          type: EARN_ACTIONS.SET_EARN_ACCOUNT,
          earnAccount: {
            email: '',
            name: '',
            platform_account_identifier: '',
            uen: 0,
          },
        })
        dispatch({
          type: EARN_ACTIONS.SET_IS_LOADING,
          isLoading: false,
        })
      }
    })
}

export const getPlatformInfo = (onSuccess: () => void) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: EARN_ACTIONS.SET_IS_LOADING,
    isLoading: true,
  })

  get(ENDPOINTS.API_V3_STABLECOIN_EARN_PLATFORM).then(resp => {
    dispatch({
      type: EARN_ACTIONS.SET_PLATFORM_INFO,
      platformInfo: {
        name: resp.name || '',
        baseInterestRate: resp.base_interest_rate || '',
        baseInterestRatePercent: resp.base_interest_rate_percent || '',
      },
    })
    onSuccess()
  })
}

export const getCurrencyBalance = (currency: EARN_CURRENCY, accountId: number | undefined) => (
  dispatch: Dispatch<ActionTypes>
) => {
  const subRoute = `/currency_balance/${currency}?account_id=${accountId}`

  get(ENDPOINTS.API_V3_STABLECOIN_EARN_ACCOUNT, subRoute).then(resp => {
    const convertToNumber = (value: string | number) => {
      if (typeof value === 'string') {
        return parseFloat(value)
      }
      return value
    }

    const balance = {
      rewardRateType: resp.reward_rate_type || 'apy',
      apy: resp.apy || '',
      apyRate: resp.apy_rate || '',
      currencyCode: resp.currency_code || '',
      currentBalance: convertToNumber(resp.current_balance) || 0,
      holdingLimitAmount: convertToNumber(resp.limit_remaining) || 0,
      availableBalance: convertToNumber(resp.available_balance) || 0,
      balanceLimit: convertToNumber(resp.balance_limit) || 0,
      pendingInterestPayout: convertToNumber(resp.pending_interest_payout_amount) || 0,
      totalInterestEarned: convertToNumber(resp.total_interest_earned) || 0,
    }

    dispatch({
      type: EARN_ACTIONS.SET_EARN_CURRENCY_BALANCE,
      currency: currency.toUpperCase(),
      earnCurrencyBalance: balance,
    })
  })
}

export const setLatestTransferRequest = (
  requestType: EarnTransferRequestType,
  amount: number,
  currencyCode: string
) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: EARN_ACTIONS.SET_LATEST_TRANSFER_REQUEST,
    amount,
    currencyCode,
    requestType,
  })
}

export const proceedEarnDeposit = (
  accountId: number,
  amount: number,
  currencyCode: string,
  onSuccess?: (resp: Record<string, string>) => void,
  onFailure?: (err: Record<string, string>) => void
) => {
  const reqBody = { account_id: accountId, amount, currency_code: currencyCode }
  post(ENDPOINTS.API_V3_STABLECOIN_EARN_DEPOSIT, reqBody)
    .then(resp => {
      if (resp.id && onSuccess) {
        onSuccess(resp)
      } else if (onFailure) {
        // missing contract id returned
        onFailure({ error: 'Something went wrong' })
      }
    })
    .catch(err => {
      if (onFailure) {
        onFailure(err?.response?.data)
      }
    })
}

export const proceedEarnWithdrawal = (
  accountId: number,
  amount: number,
  currencyCode: string,
  onSuccess?: (resp: Record<string, string>) => void,
  onFailure?: (err: Record<string, string>) => void
) => {
  const reqBody = { account_id: accountId, amount, currency_code: currencyCode }
  post(ENDPOINTS.API_V3_STABLECOIN_EARN_WITHDRAWALS, reqBody)
    .then(resp => {
      if (resp.id && onSuccess) {
        onSuccess(resp)
      } else if (onFailure) {
        // missing contract id returned
        onFailure({ error: 'Something went wrong' })
      }
    })
    .catch(err => {
      if (onFailure) {
        onFailure(err?.response?.data)
      }
    })
}

export const fetchEarnTransactions = (
  searchOptions: {
    startDate: string | null
    endDate: string | null
    currency: EARN_CURRENCY | null
    status: EARN_TRANSACTION_STATUS | null
    type: EARN_TRANSACTION_TYPE
    sortField: string | null
    sortOrder: EARN_TRANSACTIONS_SORT_ORDER | null
    currentPage: number
    txPerPage: number
  },
  onSuccess: () => void
) => (dispatch: Dispatch<ActionTypes>) => {
  const params = {
    created_after: searchOptions.startDate,
    created_before: searchOptions.endDate,
    currency: searchOptions.currency,
    status: searchOptions.status,
    transaction_type: searchOptions.type,
    sort_field: searchOptions.sortField,
    sort_order: searchOptions.sortOrder,
    per_page: searchOptions.txPerPage,
    page: searchOptions.currentPage,
  }
  get(ENDPOINTS.API_V3_STABLECOIN_EARN_TRANSACTIONS, '', params).then(resp => {
    dispatch(setEarnTransactions(keysToCamel(resp)))
    onSuccess()
  })
}

const setEarnTransactions = (resp: Record<string, unknown>) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS,
    transactions: resp.transactions as EarnTransaction[],
    totalCount: Number(resp.totalCount),
    totalPages: Number(resp.totalPages),
  })
}

export const setEarnTransactionsCurrentPage = (currentPage: number) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS_CURRENT_PAGE,
    currentPage,
  })
}

export const setEarnTransactionsSortOrder = (sortOrder: EARN_TRANSACTIONS_SORT_ORDER) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS_SORT_ORDER,
    sortOrder,
  })
}

export const setEarnTransactionsType = (txType: EARN_TRANSACTION_TYPE) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS_TYPE,
    transactionType: txType,
  })
}

export const setEarnTransactionsFilterCurrency = (currency: EARN_CURRENCY | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY,
    currency,
  })
}

export const setEarnTransactionsFilterDate = (startDate: Moment | null, endDate: Moment | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  const isoStartDate = startDate
    ? moment(startDate)
        .startOf('day')
        .toISOString()
    : null
  const isoEndDate = endDate
    ? moment(endDate)
        .endOf('day')
        .toISOString()
    : null
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE,
    startDate: isoStartDate,
    endDate: isoEndDate,
  })
}

export const setEarnTransactionsFilterStatus = (status: EARN_TRANSACTION_STATUS | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS,
    status,
  })
}

export const getTransactionDetails = (transactionReferenceId: string, onSuccess: () => void) => (
  dispatch: Dispatch<ActionTypes>
) => {
  get(ENDPOINTS.API_V3_STABLECOIN_EARN_TRANSACTIONS, `/${transactionReferenceId}`).then(resp => {
    dispatch({
      type: EARN_ACTIONS.SET_TRANSACTION_DETAILS,
      transactionDetails: formatTransactionDetails(resp),
    })

    onSuccess()
  })
}

const formatTransactionDetails = (transactionDetails: Record<string, string>) => {
  const isDeposit = transactionDetails.transaction_type === EARN_TRANSACTION_TYPE.DEPOSIT
  const amountPrefix = isDeposit ? '+' : '-'

  const isTxnCompleted = transactionDetails.status === 'completed'
  const completedDate = isTxnCompleted ? transactionDetails.updated_at : ''

  const details = getDetails(transactionDetails.transaction_type as EARN_TRANSACTION_TYPE)

  return {
    id: transactionDetails.id,
    referenceId: transactionDetails.reference_id,
    transactionType: transactionDetails.transaction_type as EARN_TRANSACTION_TYPE,
    amount: {
      prefix: amountPrefix,
      postfix: transactionDetails.amount,
    },
    createdDate: transactionDetails.created_at,
    completedDate,
    currencyCode: transactionDetails.currency,
    status: transactionDetails.status,
    details,
  }
}

const getDetails = (txnType: EARN_TRANSACTION_TYPE) => {
  let details = ''

  switch (txnType) {
    case EARN_TRANSACTION_TYPE.DEPOSIT:
      details = 'It will take up to 15 minutes for your funds to be added to your Earn account.'
      break
    case EARN_TRANSACTION_TYPE.WITHDRAWAL:
      details =
        'Your funds will be removed from your Earn Account by the end of the next business day.'
      break
  }
  return details
}

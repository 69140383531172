/* eslint-disable max-lines */
import moment from 'moment'
import { STABLECOIN_ASSET } from '~/managers/ConfigManager'
import { Dispatch, GetState } from '~/xidrBiz/store/types'
import {
  ActionTypes,
  XIDR_LANDING_PAGE_ACTIONS,
  TransferMethod,
  BankTransferNetwork,
  Blockchain,
  BlockchainAddress,
  StablecoinFee,
  Transaction,
  WithdrawWalletIdType,
  RetailStore,
} from './types'
import { get, post, ENDPOINTS } from '~/api'
import { WALLET_TYPES, ACCOUNT_TYPES } from '~/types'
import { setGaAttribute } from '~/helpers/gaHelper'
import { toLocaleBalance } from '~/helpers/localeHelper'
import { BankAccount } from '~/xidrBiz/store/userInfo/types'
import { formatComma, keysToCamel } from '~/helpers/helpers'

type Account = {
  attributes: {
    type: ACCOUNT_TYPES
    wallet_id: WALLET_TYPES
    deleted_at: null | string
  }
}

export const getUserInfo = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_USERS).then(resp => {
    setGaAttribute({ personalVerificationStatus: resp.verification_status }) // send personal verification status to GA when user lands on landing page

    dispatch({
      type: XIDR_LANDING_PAGE_ACTIONS.SET_USER_INFO,
      name: resp.business_display_name || resp.business_name || resp.business_legal_name || '',
      kycVerified: resp.kyc_verified,
      kycVerifiedDate: resp.kyc_verified_date && moment(resp.kyc_verified_date),
      hasSuccessfulFiatDeposit: resp.has_successful_fiat_deposit,
    })

    dispatch({
      type: XIDR_LANDING_PAGE_ACTIONS.SET_VERIFICATION_STATUSES,
      personalVerificationStatus: resp.personal_verification_status,
      businessVerificationStatus: resp.business_verification_status,
      has2FaEnabled: resp.gauth_enabled,
    })

    const hasBankAccounts = resp.bank_accounts && resp.bank_accounts.length > 0
    let bankAccounts = []

    if (!hasBankAccounts) {
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.SET_BANK_ACCOUNTS,
        bankAccounts: [],
      })
    } else {
      const validBankAccounts = resp.bank_accounts.filter(
        (account: { disabled: string }) => account.disabled === 'false'
      )
      if (validBankAccounts.length > 0) {
        bankAccounts = (validBankAccounts as []).map(item => keysToCamel(item))
      }

      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.SET_BANK_ACCOUNTS,
        bankAccounts,
      })
    }
  })
}

export const getBusinessAccountInfo = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_ACCOUNTS_INFO_FOR_SDK).then(resp => {
    const accountss = resp.data.filter(
      (account: Account) =>
        account.attributes.type === ACCOUNT_TYPES.BUSINESS ||
        account.attributes.type === ACCOUNT_TYPES.CORPORATE
    )

    if (accountss.length > 0) {
      const personalGeneralWallet = accountss.find(
        (account: Account) => account.attributes.wallet_id === WALLET_TYPES.GENERAL
      )

      const personalDigitalWallet = accountss.find(
        (account: Account) => account.attributes.wallet_id === WALLET_TYPES.DIGITAL_GOODS
      )

      const generalWallet =
        personalGeneralWallet === undefined
          ? null
          : {
              availableBalance: personalGeneralWallet.attributes.balance,
            }

      let digitalGoodsWallet = null
      let limitationIncreaseAmount = null
      let limitationIncreaseDate = null
      let annualSpendingLimit = null
      let remainingAnnualSpending = null
      let withdrawDailyRemaining = null

      if (personalDigitalWallet !== undefined) {
        digitalGoodsWallet = { availableBalance: personalDigitalWallet.attributes.balance }
        limitationIncreaseAmount =
          personalDigitalWallet.attributes.transaction_limit_increment_date?.sum
        limitationIncreaseDate = moment(
          personalDigitalWallet.attributes.transaction_limit_increment_date.date
        )
        annualSpendingLimit =
          personalDigitalWallet.attributes.limitations?.charge_rolling_yearly?.max || null
        remainingAnnualSpending =
          personalDigitalWallet.attributes.limitations?.charge_rolling_yearly?.remaining || null
        withdrawDailyRemaining =
          personalDigitalWallet.attributes.limitations?.withdrawal_daily?.remaining
      }

      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.SET_BUSINESS_ACCOUNT,
        businessAccount: {
          generalWallet,
          digitalGoodsWallet,
          annualSpendingLimit,
          remainingAnnualSpending,
          withdrawDailyRemaining,
          limitationIncreaseAmount,
          limitationIncreaseDate,
        },
      })

      get(ENDPOINTS.API_V3_STABLECOIN_TRANSACTIONS, `?current_page=1&page_limit=5`).then(
        txnResp => {
          const transactionsCopy = JSON.parse(JSON.stringify(txnResp.transactions))
          txnResp.transactions.forEach((transaction: Transaction, i: number) => {
            switch (transaction.description.type) {
              case 'Deposit':
                transactionsCopy[i].description.type = 'Bank Transfer In'
                break
              case 'Withdrawal':
                transactionsCopy[i].description.type = 'Bank Transfer Out'
                break
              case 'Stablecoin Deposit':
                transactionsCopy[i].description.type = 'Blockchain Transfer In'
                break
              case 'Stablecoin Withdrawal':
                transactionsCopy[i].description.type = 'Blockchain Transfer Out'
                break
            }
            // Refer to Api::V3::Xfers::Transactions::StablecoinTransferContractSerializer
            if (transaction.description.data === 'Digital Goods Wallet') {
              transactionsCopy[i].description.data = 'StraitsX Account'
            }
          })
          dispatch({
            type: XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_TRANSACTIONS,
            latestBusinessTransactions: transactionsCopy,
          })
        }
      )
    } else {
      // set empty tx array for unverified users
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_TRANSACTIONS,
        latestBusinessTransactions: [],
      })
    }
  })
}

export const setIsZrc2DepositEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED,
    isZrc2DepositEnabled: enabled,
  })
}

export const setIsErc20DepositEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED,
    isErc20DepositEnabled: enabled,
  })
}

export const setIsMaticEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_ENABLED,
    isMaticEnabled: enabled,
  })
}

export const setIsMaticDepositEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED,
    isMaticDepositEnabled: enabled,
  })
}

export const setIsMaticWithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED,
    isMaticWithdrawalEnabled: enabled,
  })
}

export const setIsZrc2WithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED,
    isZrc2WithdrawalEnabled: enabled,
  })
}

export const setIsErc20WithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED,
    isErc20WithdrawalEnabled: enabled,
  })
}

export const setIsDashboardBannerManagementEnabled = (isEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED,
    isDashboardBannerManagementEnabled: isEnabled,
  })
}

export const setIsPaymentMethodQrisEnabled = (isEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_QRIS_ENABLED,
    isPaymentMethodQrisEnabled: isEnabled,
  })
}

export const setIsVirtualAccountEnabled = (isEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_VIRTUAL_ACCOUNT_ENABLED,
    isVirtualAccountEnabled: isEnabled,
  })
}

export const getDashboardBanners = (site: string) => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_DASHBOARD_BANNERS, `?site=${site}`).then(resp => {
    dispatch({
      type: XIDR_LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS,
      dashboardBanners: resp.reverse(),
    })
  })
}

export const getBankIcons = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_BANKS).then(resp => {
    dispatch({
      type: XIDR_LANDING_PAGE_ACTIONS.GET_BANK_ICONS,
      bankIcons: resp.data,
    })
  })
}

export const setTab = (tab: string) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(setTransferMethod(null))
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_TAB,
    tab,
  })
}

export const setTransferMethod = (transferMethod: TransferMethod | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setBankTransferNetwork(null))
  dispatch(setSelectedBlockchain(null))
  dispatch(setSelectedBankAccount(null))
  dispatch(setSelectedRetailStore(null))
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD,
    transferMethod,
  })
}

export const setBankTransferNetwork = (bankTransferNetwork: BankTransferNetwork | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK,
    bankTransferNetwork,
  })
}

export const setSelectedBlockchain = (blockchain: Blockchain | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setSelectedBlockchainAddress(null))
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN,
    selectedBlockchain: blockchain,
  })
}

export const setSelectedBankAccount = (bankAccount: BankAccount | null) => {
  return (dispatch: Dispatch<ActionTypes>) => {
    dispatch({
      type: XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT,
      selectedBankAccount: bankAccount,
    })
  }
}

export const setSelectedBlockchainAddress = (blockchainAddress: BlockchainAddress | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS,
    selectedBlockchainAddress: blockchainAddress,
  })
}

export const fetchBlockchainAddresses = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_XIDR_ADDRESSES).then(resp => {
    dispatch({
      type: XIDR_LANDING_PAGE_ACTIONS.SET_BLOCKCHAIN_ADDRESSES,
      blockchainAddresses: resp.data.address_list.map(
        (blockchainAddress: {
          address: string
          approved: boolean | null
          blockchain: string
          financial_institution_id: number
          nickname: string
          remaining_of_withdrawal_limit: number
        }) => ({
          address: blockchainAddress.address,
          approved: blockchainAddress.approved,
          blockchain: blockchainAddress.blockchain,
          financialInstitutionId: blockchainAddress.financial_institution_id,
          nickname: blockchainAddress.nickname,
          remainingOfWithdrawalLimit: blockchainAddress.remaining_of_withdrawal_limit,
        })
      ),
      fiList: resp.data.fi_list,
      addressQuota: resp.data.address_quota,
    })
  })
}

export const setWithdrawAmount = (amount: string) => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const { selectedBlockchainAddress, withdrawWalletId } = getState().xidrLandingPage
  const { accountList } = getState().xidrAccount
  const account = accountList.find(acc => acc.id === withdrawWalletId)

  let withdrawAmountError = ''

  const amountFloat = parseFloat(amount)
  const stablecoinWithdrawRemaining = selectedBlockchainAddress?.remainingOfWithdrawalLimit || 0
  const IDR_MINIMUM_WITHDRAWAL_AMOUNT = 10000 // 10K
  const XIDR_MINIMUM_WITHDRAWAL_AMOUNT = 100000 // 100K
  const XIDR_MAXIMUM_AMOUNT = 20000000000 // 20B
  const walletBalanceFloat = account ? parseFloat(account.balance) : 0

  if (selectedBlockchainAddress) {
    if (amountFloat > XIDR_MAXIMUM_AMOUNT) {
      const formattedMaxAmount = Number(XIDR_MAXIMUM_AMOUNT).toLocaleString()
      withdrawAmountError = `You have exceeded the XIDR ${formattedMaxAmount} withdrawal limit per transaction, please process the withdrawal in multiple transactions.`
    } else if (amountFloat > stablecoinWithdrawRemaining) {
      withdrawAmountError = 'Your input amount exceeds your remaining withdraw limit.'
    } else if (amountFloat < XIDR_MINIMUM_WITHDRAWAL_AMOUNT) {
      withdrawAmountError = `You need to withdraw a minimum of XIDR ${XIDR_MINIMUM_WITHDRAWAL_AMOUNT}.`
    } else if (amountFloat > walletBalanceFloat) {
      withdrawAmountError = 'Your input amount exceeds your account balance.'
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (
      amountFloat < IDR_MINIMUM_WITHDRAWAL_AMOUNT &&
      walletBalanceFloat >= IDR_MINIMUM_WITHDRAWAL_AMOUNT
    ) {
      withdrawAmountError = `You need to withdraw a minimum of IDR ${IDR_MINIMUM_WITHDRAWAL_AMOUNT}.`
    } else if (amountFloat > walletBalanceFloat) {
      withdrawAmountError = 'Your input amount exceeds your account balance.'
    } else if (
      walletBalanceFloat < IDR_MINIMUM_WITHDRAWAL_AMOUNT &&
      amountFloat < walletBalanceFloat
    ) {
      withdrawAmountError = `You need to withdraw all your funds if you have less than ${toLocaleBalance(
        { balance: IDR_MINIMUM_WITHDRAWAL_AMOUNT }
      )} in your balance.`
    }
  }

  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT,
    amount: amountFloat.toString(),
    withdrawAmountError,
  })
}

export const setWithdrawWalletId = (withdrawWalletId: WithdrawWalletIdType) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID,
    withdrawWalletId,
  })
}

export const setHasFetchingFeeError = (hasError: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR,
    hasError,
  })
}

export const setIsFetchingFee = (isFetching: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING,
    isStablecoinFeeLoading: isFetching,
  })
}

export const fetchStablecoinFee = ({
  accountId,
  selectedBlockchainAddress,
  withdrawAmount,
}: {
  accountId: number
  selectedBlockchainAddress: BlockchainAddress
  withdrawAmount: string
}) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(setIsFetchingFee(true))
  dispatch(setHasFetchingFeeError(false))

  const subRoute = `?account_id=${accountId}&amount=${withdrawAmount}&blockchain=${selectedBlockchainAddress.blockchain}`
  get(ENDPOINTS.API_V3_STABLECOIN_FEES_XIDR, subRoute)
    .then(resp => {
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE,
        amount: Number(resp.data.amount),
        identifier: resp.data.identifier,
        timestamp: resp.data.timestamp,
      })
    })
    .catch(err => {
      const message: string | undefined = err.response?.data?.error
      if (message && message.includes("can't get estimated fee")) {
        dispatch(setIsFetchingFee(false))
        dispatch(setHasFetchingFeeError(true))
      }
    })
}

const submitStablecoinWithdrawal = (
  requestBody: {
    google_auth_code?: string
    blockchain: string
    amount: number
    address: string
    address_custodial_type: string
    identifier?: string | undefined
  },
  redirectCallback: () => void,
  setLoading?: (loading: boolean) => void
) => (dispatch: Dispatch<ActionTypes>) => {
  post(ENDPOINTS.API_V3_STABLECOIN_WITHDRAWALS_XIDR, requestBody, {})
    .then(() => {
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: '',
      })
      redirectCallback()
    })
    .catch(err => {
      const message = err.response.data.error || 'Error'
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: message,
      })
      // Note: Keep 2FA modal open if 2FA code fails
      if (message.includes('google_auth_code')) {
        dispatch({
          type: XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: true,
        })
      } else {
        dispatch({
          type: XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: false,
        })
        redirectCallback()
      }
      if (setLoading) setLoading(false)
    })
}

export const verifyStablecoin2Fa = ({
  authCode,
  selectedBlockchainAddress,
  withdrawAmount,
  stablecoinFee,
  redirectCallback,
  setLoading,
}: {
  authCode: string
  selectedBlockchainAddress: BlockchainAddress
  withdrawAmount: string
  stablecoinFee: StablecoinFee
  redirectCallback: () => void
  setLoading: (loading: boolean) => void
}) => (dispatch: Dispatch<ActionTypes>) => {
  setLoading(true)
  // the `withdrawAmount` allows 2 digit at most, so we can handle float point issue by rounding to 2 decimal places
  const fixedDigitsAmount = (Number(withdrawAmount) - (stablecoinFee.amount || 0)).toFixed(2)

  const requestBody = {
    google_auth_code: authCode,
    blockchain: selectedBlockchainAddress.blockchain,
    amount: Number(fixedDigitsAmount),
    address: selectedBlockchainAddress.address,
    address_custodial_type: selectedBlockchainAddress.financialInstitutionId
      ? 'custodial'
      : 'non_custodial',
    identifier: stablecoinFee.identifier,
  }
  dispatch(submitStablecoinWithdrawal(requestBody, redirectCallback, setLoading))
}

export const openConfirmModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN,
    open: true,
  })
}

export const closeConfirmModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN,
    open: false,
  })
}

export const proceedBankConfirmation = ({
  has2FaEnabled,
  withdrawAmount,
  currency,
  userBankAccountId,
  redirectCallback,
}: {
  has2FaEnabled: boolean
  withdrawAmount: string
  currency: STABLECOIN_ASSET
  userBankAccountId: number
  redirectCallback: () => void
}) => (dispatch: Dispatch<ActionTypes>) => {
  if (has2FaEnabled) {
    dispatch(closeTempHoldingBalanceModal()) // in case it is open
    dispatch(open2FaModal())
  } else {
    const requestBody = {
      amount: withdrawAmount,
      currency: currency.toLowerCase(),
      user_bank_account_id: userBankAccountId,
    }
    const additionalHeaders = {}

    post(ENDPOINTS.API_V3_STABLECOIN_WITHDRAWALS_IDR, requestBody, additionalHeaders)
      .then(() => {
        dispatch({
          type: XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
          error: '',
        })
        redirectCallback()
      })
      .catch(err => {
        const message = err.response.data.error
        dispatch({
          type: XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
          error: message,
        })
        redirectCallback()
      })
  }
}

export const verify2Fa = ({
  authCode,
  withdrawAmount,
  currency,
  userBankAccountId,
  redirectCallback,
  setLoading,
}: {
  authCode: string
  withdrawAmount: string
  currency: STABLECOIN_ASSET
  userBankAccountId: number
  redirectCallback: () => void
  setLoading: (loading: boolean) => void
}) => (dispatch: Dispatch<ActionTypes>) => {
  setLoading(true)

  const requestBody = {
    amount: withdrawAmount,
    currency: currency.toLowerCase(),
    user_bank_account_id: userBankAccountId,
    google_auth_code: authCode,
  }

  const additionalHeaders = {}

  post(ENDPOINTS.API_V3_STABLECOIN_WITHDRAWALS_IDR, requestBody, additionalHeaders)
    .then(() => {
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: '',
      })
      redirectCallback()
      setLoading(false)
    })
    .catch(err => {
      const message = err.response.data.error || 'Error'
      dispatch({
        type: XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: message,
      })
      // Note: Keep 2FA modal open if 2FA code fails
      if (message.includes('google_auth_code')) {
        dispatch({
          type: XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: true,
        })
      } else {
        dispatch({
          type: XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: false,
        })
        redirectCallback()
      }
      setLoading(false)
    })
}

export const open2FaModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(closeConfirmModal())
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
    open: true,
  })
}

export const close2FaModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
    open: false,
  })
}

export const resetTransferMethod = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(setTab('deposit'))
  dispatch(close2FaModal())
  dispatch(closeConfirmModal())
  dispatch(closeTempHoldingBalanceModal())
}

export const openTempHoldingBalanceModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN,
    open: true,
  })
}

export const closeTempHoldingBalanceModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN,
    open: false,
  })
}

export const flagVisitedGettingStarted = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED,
    value: true,
  })
}

export const setIsXIDRWithdrawalFeeEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_XIDR_WITHDRAWAL_FEE_ENABLED,
    value: enabled,
  })
}

export const setNewlyCreatedBlockchainAddress = (address: BlockchainAddress) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS,
    blockchainAddress: address,
  })
}

export const setIsIndomaretDepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_INDOMARET_DEPOSIT_ENABLED,
    isIndomaretDepositEnabled: enabled,
  })
}

export const setIsAlfamartDepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_ALFAMART_DEPOSIT_ENABLED,
    isAlfamartDepositEnabled: enabled,
  })
}

export const setIsPaymentMethodRetailEnabled = (isEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_RETAIL_ENABLED,
    isPaymentMethodRetailEnabled: isEnabled,
  })
}

export const setSelectedRetailStore = (retailStore: RetailStore | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_STORE,
    selectedRetailStore: retailStore,
  })
}

export const setRetailDepositAmount = (amount: string, maxAmount: number, minAmount: number) => (
  dispatch: Dispatch<ActionTypes>
) => {
  let retailDepositAmountError = ''

  const amountFloat = parseFloat(amount)

  if (amountFloat > maxAmount) {
    retailDepositAmountError = `Please enter a value below maximum transfer limit of ${formatComma(
      maxAmount
    )} XIDR`
  } else if (amountFloat < minAmount) {
    retailDepositAmountError = `You need to transfer a minimum of ${formatComma(minAmount)} XIDR`
  }

  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_DEPOSIT_AMOUNT,
    amount: amountFloat.toString(),
    retailDepositAmountError,
  })
}

export const setIsRenewAddressAcknowledged = (isRenewAddressAcknowledged: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED,
    isRenewAddressAcknowledged,
  })
}

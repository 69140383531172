/* eslint-disable max-lines */
import moment from 'moment'
import axios from 'axios'
import { PERSONAL_VERIFICATION_STATUS } from '~/types'
import { ConfigManager } from '~/managers/ConfigManager'
import { Dispatch, GetState } from '~/app/store/types'
import { PATHS } from '~/app/routes/paths'
import { put, get, post, ENDPOINTS } from '~/api'
import {
  ActionTypes,
  FileData,
  VERIFICATION_ACTIONS,
  SCREENS,
  KYC_TYPE,
  ResidentStatus,
  SgVerificationFlows,
  RetrievedDetails,
  IkycVerificationOptions,
  IkycRetrievedDetails,
} from './types'
import { USER_INFO_ACTIONS } from '~/app/store/userInfo/const'
import { ActionTypes as UserInfoActionTypes, UserInfo } from '~/app/store/userInfo/types'
import { Notification } from '~/components/Notification'
import { VERIFICATION_OPTIONS, I18nVerificationDetails } from './international/types'
import {
  getIsResident,
  getFlow,
  getResidentStatus,
  getPersonalDetailsCompleted,
  getDeclarationsCompleted,
  getProofOfAddressCompleted,
  getIdFrontAndBackCompleted,
  getSelfieCompleted,
  getMyInfoPersonalDetailsCompletedNric,
  getMyInfoPersonalDetailsCompletedFin,
} from './selectors'
import { getNextIncompleteI18nScreen } from './helpers'
import { keysToCamel } from '~/helpers/helpers'
import { LOCAL_STORAGE_VALUES } from '~/common/constants'

export const setResidentStatus = (status: ResidentStatus) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_RESIDENT_STATUS,
    status,
  })
}

export const setSgVerificationFlow = (sgVerificationFlow: SgVerificationFlows) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_FLOW,
    sgVerificationFlow,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setUserDetails = ({ key, value }: { key: string; value: any }) => (
  dispatch: Dispatch<ActionTypes>
) => {
  // Note: Set isResident to false if idNumber does not start with S or T
  // Singapore citizens and permanent residents born before 1 January 2000 are assigned the letter "S".
  // Singapore citizens and permanent residents born on or after 1 January 2000 are assigned the letter "T".
  if (key === 'idNumber' && value) {
    dispatch({
      type: VERIFICATION_ACTIONS.SET_IS_RESIDENT,
      isResident: getIsResident(value),
    })
    dispatch({
      type: VERIFICATION_ACTIONS.SET_USER_DETAILS,
      key: 'expiryDate',
      value: undefined,
    })
  }
  dispatch({
    type: VERIFICATION_ACTIONS.SET_USER_DETAILS,
    key,
    value,
  })
}

export const saveUserDetails = (successCallback: () => void, errorCallback: () => void) => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>,
  getState: GetState
) => {
  const { isResident, userDetails, sgVerificationFlow } = getState().verification
  const { userInfo: oldUserInfo } = getState().userInfo

  const requestBody = {
    verification_documents_shareable: true,
    full_name: userDetails.fullName,
    date_of_birth: userDetails.dateOfBirth ? userDetails.dateOfBirth.format('DD/MM/YYYY') : '',
    country_of_birth: userDetails.countryOfBirth,
    nationality: userDetails.nationality,
    gender: userDetails.gender ? userDetails.gender.toLowerCase() : '',
    address_line_1: userDetails.addressLine1,
    address_line_2: userDetails.addressLine2,
    country: userDetails.country,
    city: userDetails.city || '',
    postal_code: userDetails.postalCode,
    identity_no: userDetails.idNumber,
    nric_issue_date: userDetails.dateOfIssue ? userDetails.dateOfIssue.format('DD/MM/YYYY') : '',
    nric_expiry_date: userDetails.expiryDate ? userDetails.expiryDate.format('DD/MM/YYYY') : '',
    nric_type: isResident ? 'nric' : 'fin',
    is_myinfo_flow: sgVerificationFlow === 'my_info',
    occupation: userDetails.occupation,
    employment_sector: userDetails.employmentSector,
    employer: userDetails.employer,
    annual_income: userDetails.annualIncomeRange,
    expected_transaction_amount: userDetails.expectedTransactionAmount,
    expected_total_transaction: userDetails.expectedTotalTransaction,
    expected_transaction_frequency: userDetails.expectedTransactionFrequency,
    business_industry: userDetails.businessIndustry,
    purpose_of_using: userDetails.purposeOfUsing,
    investment_trading_experience: userDetails.investmentTradingExperience,
    crypto_experience: userDetails.cryptoExperience,
    source_of_wealth: userDetails.sourceOfWealth,
    source_of_funds: userDetails.sourceOfFunds,
  }

  put(ENDPOINTS.API_V3_UPDATE_USER_ENDPOINT, requestBody)
    .then(resp => {
      dispatch({
        type: VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS,
        retrievedDetails: {
          ...convertRetrievedDetails(resp),
        } as RetrievedDetails,
      })

      const newUserInfo: UserInfo = keysToCamel(resp)
      dispatch({
        type: USER_INFO_ACTIONS.SET_USER_INFO,
        userInfo: {
          ...oldUserInfo,
          ...newUserInfo,
        },
      })
      dispatch({
        type: VERIFICATION_ACTIONS.UPDATE_USER,
        error: '',
      })
      successCallback()
    })
    .catch(err => {
      dispatch({
        type: VERIFICATION_ACTIONS.UPDATE_USER,
        error: err.response?.data?.error,
      })
      errorCallback()
    })
}

export const saveUserDocuments = ({
  docTypes,
  documentFiles,
  successCallback,
  errorCallback,
}: {
  docTypes: string[]
  documentFiles: ({
    fileName: string
    fileData: string
  } | null)[]
  successCallback: () => void
  errorCallback: () => void
}) => (dispatch: Dispatch<ActionTypes | UserInfoActionTypes>, getState: GetState) => {
  const { retrievedDetails } = getState().verification
  const { userInfo: oldUserInfo } = getState().userInfo

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestBody: { [key: string]: any } = {
    verification_documents_shareable: true,
    use_raw_data: true,
    is_myinfo_flow: retrievedDetails ? retrievedDetails.isMyInfoFlow : undefined,
  }

  // Note: Using array as idFront and idBack are submitted at the same time
  for (let i = 0; i < docTypes.length; i += 1) {
    const key: string = convertToServerKey(docTypes[i])

    requestBody[key] = documentFiles[i] ? documentFiles[i]?.fileData : undefined
  }

  let updatedRetrievedDetails: RetrievedDetails

  // Note: This is to update the condition checks in
  // dispatch(setNextIncompleteScreen()) to mark that the document has been completed
  // so that we can redirect to the next screen
  if (docTypes.includes('idFront') || docTypes.includes('idBack')) {
    updatedRetrievedDetails = {
      ...retrievedDetails,
      idFrontUrl: 'dummyFile.png',
      idFrontUploaded: true,
      idBackUrl: 'dummyFile.png',
      idBackUploaded: true,
    } as RetrievedDetails
  } else if (docTypes.includes('selfie')) {
    updatedRetrievedDetails = {
      ...retrievedDetails,
      selfieUrl: 'dummyFile.png',
      selfieUploaded: true,
    } as RetrievedDetails
  } else if (docTypes.includes('proofOfAddress')) {
    updatedRetrievedDetails = {
      ...retrievedDetails,
      proofOfAddressUrl: 'dummyFile.png',
      proofOfAddressUploaded: true,
    } as RetrievedDetails
  } else if (docTypes.includes('bankAccountProof')) {
    updatedRetrievedDetails = {
      ...retrievedDetails,
      bankAccountProofUrl: 'dummyFile.png',
      bankAccountProofUploaded: true,
    } as RetrievedDetails
  }

  put(ENDPOINTS.API_V3_UPDATE_USER_ENDPOINT, requestBody)
    .then(resp => {
      updatedRetrievedDetails = {
        ...updatedRetrievedDetails,
        verificationStatus: resp.verification_status,
      } as RetrievedDetails
      dispatch({
        type: VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS,
        retrievedDetails: updatedRetrievedDetails,
      })

      const newUserInfo: UserInfo = keysToCamel(resp)
      dispatch({
        type: USER_INFO_ACTIONS.SET_USER_INFO,
        userInfo: {
          ...oldUserInfo,
          ...newUserInfo,
        },
      })

      dispatch({
        type: VERIFICATION_ACTIONS.UPDATE_USER,
        error: '',
      })
      successCallback()
    })
    .catch(err => {
      dispatch({
        type: VERIFICATION_ACTIONS.UPDATE_USER,
        error: err.response?.data?.error,
      })
      errorCallback()
    })
}

// Note: Used to determine which screen the user should be seeing, as verification
// form dropoffs at different steps should allow user to get back to their in-progress
// (or completed/rejected) step
export const getScreenFromLastSavedStep = () => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>,
  getState: GetState
) => {
  get(ENDPOINTS.API_V3_USER, '?include_options=true&include_documents=true').then(resp => {
    const newUserInfo: UserInfo = keysToCamel(resp)
    dispatch({
      type: USER_INFO_ACTIONS.SET_USER_INFO,
      userInfo: { ...newUserInfo },
    })

    const { countryOfResidence } = getState().verification
    const retrievedDetails = dispatch(setSgVerificationDetails(resp))
    const i18nRetrievedDetails = dispatch(setI18nVerificationDetails(resp))

    if (resp.kyc_type === KYC_TYPE.INTERNATIONAL) {
      // International flow
      if (i18nRetrievedDetails.verificationStatus === 'initial') {
        dispatch(setNextIncompleteI18nScreen(i18nRetrievedDetails))
      } else {
        dispatch({
          type: VERIFICATION_ACTIONS.SET_SCREEN,
          screen: SCREENS.STATUS,
        })
      }
    } else if (resp.kyc_type === KYC_TYPE.SG || countryOfResidence === 'sg') {
      // SG flow
      if (retrievedDetails.verificationStatus === 'initial') {
        dispatch({
          type: VERIFICATION_ACTIONS.SET_SCREEN,
          screen: SCREENS.CHOOSE_VERIFICATION_METHOD,
        })
      } else {
        dispatch({
          type: VERIFICATION_ACTIONS.SET_SCREEN,
          screen: SCREENS.STATUS,
        })
      }
    } else if (countryOfResidence !== 'sg') {
      dispatch({
        type: VERIFICATION_ACTIONS.SET_SCREEN,
        screen: SCREENS.JUMIO,
      })
    } else {
      // Initial flow
      dispatch({
        type: VERIFICATION_ACTIONS.SET_SCREEN,
        screen: SCREENS.CHOOSE_COUNTRY,
      })
    }
  })
}

export const setNextIncompleteScreen = (isInternationalFlow = false) => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const { retrievedDetails } = getState().verification

  if (!retrievedDetails) {
    return
  }

  // TODO: international flow redirection logic will be handled in ticket
  // https://fazzfinancial.atlassian.net/browse/STRAITSX-3249
  if (isInternationalFlow) return

  const { isMyInfoFlow, nricType } = retrievedDetails

  const personalDetailsCompleted = getPersonalDetailsCompleted(retrievedDetails)
  const myInfoPersonalDetailsCompleted =
    nricType === 'nric'
      ? getMyInfoPersonalDetailsCompletedNric(retrievedDetails)
      : getMyInfoPersonalDetailsCompletedFin(retrievedDetails)
  const declarationsCompleted = getDeclarationsCompleted(retrievedDetails)
  const proofOfAddressCompleted = getProofOfAddressCompleted(retrievedDetails)
  const idFrontAndBackCompleted = getIdFrontAndBackCompleted(retrievedDetails)
  const selfieCompleted = getSelfieCompleted(retrievedDetails)

  const redirectToAbout = isMyInfoFlow ? !myInfoPersonalDetailsCompleted : !personalDetailsCompleted
  const redirectToDeclarations = !declarationsCompleted
  // Note: POA is required for all FIN Verification (not resident)
  const redirectToProofOfAddress = nricType === 'fin' && !proofOfAddressCompleted
  // Note: ID Front and Back and Selfie only required for Manual Verification
  const redirectToIdFrontAndBack = !isMyInfoFlow && !idFrontAndBackCompleted
  const redirectToSelfie = !isMyInfoFlow && !selfieCompleted

  if (redirectToAbout) {
    dispatch(setScreen(SCREENS.ABOUT))
  } else if (redirectToDeclarations) {
    dispatch(setScreen(SCREENS.DECLARATIONS))
  } else if (redirectToProofOfAddress) {
    dispatch(setScreen(SCREENS.PROOF_OF_ADDRESS))
  } else if (redirectToIdFrontAndBack) {
    dispatch(setScreen(SCREENS.ID_FRONT_AND_BACK))
  } else if (redirectToSelfie) {
    dispatch(setScreen(SCREENS.SELFIE))
  } else {
    // If none of the above, then user should have completed documents
    dispatch(setScreen(SCREENS.STATUS))
  }
}

export const setScreen = (screen: SCREENS) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_SCREEN,
    screen,
  })
}

export const setVerificationError = (error: string) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_ERROR,
    error,
  })
}

export const initiateMyInfo = (errorCallback: () => void) => (dispatch: Dispatch<ActionTypes>) => {
  const currentUrl = ConfigManager.isDev() ? 'http://localhost:3001' : window.location.origin

  const requestBody = {
    callback_url: `${currentUrl}/callback`,
  }
  post(ENDPOINTS.API_V3_INITIATE_MY_INFO_ENDPOINT, requestBody)
    .then(resp => {
      localStorage.setItem(LOCAL_STORAGE_VALUES.myInfoState, resp.state)
      window.location.href = resp.url
    })
    .catch(err => {
      const message = err.response.data.error
      dispatch({
        type: VERIFICATION_ACTIONS.SET_ERROR,
        error: message,
      })
      errorCallback()
    })
}

export const getMyInfoDetails = (
  params: { myInfoParams: string },
  setLoading: (loading: boolean) => void
) => (dispatch: Dispatch<ActionTypes>) => {
  const queryStringParams = {
    ...JSON.parse(params.myInfoParams),
    state: localStorage.getItem(LOCAL_STORAGE_VALUES.myInfoState),
  }
  get(ENDPOINTS.API_V3_MY_INFO_DETAILS_ENDPOINT, undefined, queryStringParams)
    .then(resp => {
      const personDetail = resp.person_detail
      dispatch({
        type: VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS,
        userDetails: {
          fullName: personDetail.full_name || undefined,
          dateOfBirth: personDetail.date_of_birth ? moment(personDetail.date_of_birth) : undefined,
          countryOfBirth: personDetail.country_of_birth || undefined,
          nationality: personDetail.nationality || undefined,
          gender: personDetail.gender || undefined,
          addressLine1: personDetail.address_line_1 || undefined,
          addressLine2: personDetail.address_line_2 || undefined,
          country: personDetail.country || undefined,
          city: 'Singapore',
          postalCode: personDetail.postal_code || undefined,
          idNumber: resp.uinfin,
          dateOfIssue: undefined,
          expiryDate: personDetail.pass_expiry_date
            ? moment(personDetail.pass_expiry_date)
            : undefined,
          occupation: personDetail.occupation || undefined,
          employmentSector: personDetail.employment_sector || undefined,
          employer: personDetail.employer || undefined,
          passType: personDetail.pass_type || undefined,
          passExpiryDate: personDetail.pass_expiry_date || undefined,
          passStatus: personDetail.pass_status || undefined,
        },
      })
      dispatch({
        type: VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS,
        myInfoOnlyDetails: {
          race: personDetail.race,
          residentialStatus: personDetail.residential_status,
          aliasName: personDetail.alias_name,
          hasValidExpiryDate: !!personDetail.pass_expiry_date,
          hasValidAddress: !!personDetail.address_line_1,
          passStatusLastUpdated: personDetail.pass_status_last_updated
            ? moment(personDetail.pass_status_last_updated)
            : undefined,
          error: undefined,
        },
      })
      dispatch(setSgVerificationFlow('my_info'))
      dispatch({
        type: VERIFICATION_ACTIONS.SET_IS_RESIDENT,
        isResident: getIsResident(resp.uinfin),
      })
      dispatch({
        type: VERIFICATION_ACTIONS.SET_WITH_ADDRESS,
        withAddress: !!personDetail.address_line_1,
      })
      dispatch(setResidentStatus(getIsResident(resp.uinfin) ? 'sg_pr' : 'pass_holder'))
      setLoading(false)
    })
    .catch(err => {
      const message = err.response.data.error ?? 'Error fetching MyInfo details'
      dispatch({
        type: VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS,
        myInfoOnlyDetails: {
          race: undefined,
          residentialStatus: undefined,
          aliasName: undefined,
          error: message,
        },
      })
      setLoading(false)
    })
}

export const sendMobileOtp = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  successCallback: any,
  errorCallback: () => void,
  setError: (error: string) => void
) => () => {
  const requestBody = {
    redirect_url: `${window.location.origin}/verification`,
  }

  post(ENDPOINTS.API_SSO_OTP_LOGIN, requestBody)
    .then(() => {
      successCallback()
    })
    .catch(err => {
      const message = err.response.data.error || 'We have encountered an error. Please try again.'
      setError(message)
      errorCallback()
    })
}

// Note: Converts idFront to id_front, idBack to id_back,
// proofOfAddress to proof_of_address and selfie to selfie_2id
const convertToServerKey = (key: string) => {
  if (key === 'selfie') {
    return 'selfie_2id'
  }
  const result = key.replace(/([A-Z])/g, ' $1')
  return result
    .split(' ')
    .join('_')
    .toLowerCase()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertRetrievedDetails = (resp: any): any => {
  return {
    userId: resp.id,
    verificationStatus: resp.verification_status,
    kycRejectedReason: resp.kyc_rejected_reason,
    isAccountLocked: resp.account_locked,

    isMyInfoFlow: resp.is_myinfo_flow,
    isElderly: resp.is_elderly,

    fullName: resp.full_name || undefined,
    dateOfBirth: resp.date_of_birth ? moment(resp.date_of_birth) : undefined,
    countryOfBirth: resp.country_of_birth || undefined,
    nationality: resp.nationality || undefined,
    gender: resp.gender || undefined,
    addressLine1: resp.address_line_1 || undefined,
    addressLine2: resp.address_line_2 || undefined,
    country: resp.country || undefined,
    city: resp.city || undefined,
    postalCode: resp.postal_code || undefined,
    idNumber: resp.identity_no || undefined,
    nricType: resp.nric_type || undefined,
    dateOfIssue: resp.nric_issue_date ? moment(resp.nric_issue_date) : undefined,
    expiryDate: resp.nric_expiry_date ? moment(resp.nric_expiry_date) : undefined,
    occupation: resp.occupation || undefined,
    businessIndustry: resp.business_industry || undefined,
    employmentSector: resp.employment_sector || undefined,
    employer: resp.employer || undefined,
    annualIncomeRange: resp.annual_income_range || undefined,
    expectedTransactionAmount: resp.expected_transaction_amount || undefined,
    expectedTotalTransaction: resp.expected_total_transaction || undefined,
    expectedTransactionFrequency: resp.expected_transaction_frequency || undefined,
    purposeOfUsing: resp.purpose_of_using || undefined,
    investmentTradingExperience: resp.investment_trading_experience || undefined,
    cryptoExperience: resp.crypto_experience || undefined,
    sourceOfWealth: resp.source_of_wealth || undefined,
    sourceOfFunds: resp.source_of_funds || undefined,

    proofOfAddressUrl: resp.proof_of_address_url,
    proofOfAddressUploaded: resp.proof_of_address_uploaded,

    selfieUrl: resp.selfie_2id_url,
    selfieUploaded: resp.selfie_uploaded,

    idFrontUrl: resp.id_front_url,
    idFrontUploaded: resp.id_front_uploaded,

    idBackUrl: resp.id_back_url,
    idBackUploaded: resp.id_back_uploaded,

    bankAccountProofUrl: resp.bank_account_proof_url,
    bankAccountProofUploaded: resp.bank_account_proof_uploaded,
  }
}

export const getIkycVerificationOptions = (options: VERIFICATION_OPTIONS[]) => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const { ikycRetrievedDetails } = getState().verification
  if (options.length === 0) return

  const queryStringParams: {
    options: VERIFICATION_OPTIONS[]
    callback_path?: string
    straitsx_user_kyc_external_id?: string
  } = { options }

  if (options.includes(VERIFICATION_OPTIONS.JUMIO)) {
    queryStringParams.callback_path = `${window.location.origin}${PATHS.VERIFICATION_INTERNATIONAL}`
  }

  if (
    options.includes(VERIFICATION_OPTIONS.JUMIO) ||
    options.includes(VERIFICATION_OPTIONS.PROOF_OF_ADDRESS)
  ) {
    queryStringParams.straitsx_user_kyc_external_id = ikycRetrievedDetails?.externalId
  }
  get(ENDPOINTS.API_V3_STABLECOIN_IKYC_VERIFICATION_OPTIONS, undefined, queryStringParams)
    .then((res: IkycVerificationOptions) => {
      dispatch(setIkycVerificationOptions(keysToCamel(res)))
    })
    .catch(() => {
      dispatch(setIkycVerificationOptions({ error: true }))
    })
}

export const setIkycVerificationOptions = (ikycVerificationOptions: IkycVerificationOptions) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_IKYC_VERIFICATION_OPTIONS,
    ikycVerificationOptions,
  })
}

export const setKycType = (kycType: KYC_TYPE) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_KYC_TYPE,
    kycType,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setSgVerificationDetails = (resp: any) => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>
) => {
  const retrievedDetails = convertRetrievedDetails(resp)

  const formOptions = {
    countries: resp.countries,
    nationalities: resp.nationalities,
    races: resp.races,
    genders: resp.genders,
    occupations: resp.occupations,
    employmentSectors: resp.employment_sectors,
    annualIncomes: resp.annual_incomes,
    expectedTransactionAmounts: resp.expected_transaction_amounts,
    expectedTotalTransactions: resp.expected_total_transactions,
    expectedTransactionFrequencies: resp.expected_transaction_frequencies,
    businessIndustry: resp.business_industry,
    sourcesOfFunds: resp.sources_of_funds,
  }

  const {
    kycRejectedReason,

    fullName,
    dateOfBirth,
    countryOfBirth,
    nationality,
    gender,
    addressLine1,
    addressLine2,
    country,
    city,
    postalCode,
    idNumber,
    dateOfIssue,
    expiryDate,
    occupation,
    employmentSector,
    employer,
    annualIncomeRange,
    expectedTransactionAmount,
    expectedTotalTransaction,
    expectedTransactionFrequency,
    businessIndustry,
    purposeOfUsing,
    investmentTradingExperience,
    cryptoExperience,
    sourceOfWealth,
    sourceOfFunds,
  } = retrievedDetails

  dispatch({
    type: VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS,
    retrievedDetails,
  })

  dispatch({
    type: VERIFICATION_ACTIONS.SET_FLOW,
    sgVerificationFlow: getFlow(retrievedDetails),
  })
  dispatch({
    type: VERIFICATION_ACTIONS.SET_IS_RESIDENT,
    isResident: getIsResident(idNumber),
  })
  dispatch({
    type: VERIFICATION_ACTIONS.SET_WITH_ADDRESS,
    withAddress: !!addressLine1,
  })
  dispatch({
    type: VERIFICATION_ACTIONS.SET_RESIDENT_STATUS,
    status: getResidentStatus(retrievedDetails),
  })
  dispatch({
    type: VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON,
    kycRejectedReason,
  })
  dispatch({
    type: VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS,
    userDetails: {
      fullName,
      dateOfBirth,
      countryOfBirth,
      nationality,
      gender,
      addressLine1,
      addressLine2,
      country: country && country.toUpperCase(),
      city,
      postalCode,
      idNumber,
      dateOfIssue,
      expiryDate,
      occupation,
      employmentSector,
      employer,
      annualIncomeRange,
      expectedTransactionAmount,
      expectedTotalTransaction,
      expectedTransactionFrequency,
      businessIndustry,
      purposeOfUsing,
      investmentTradingExperience,
      cryptoExperience,
      sourceOfWealth,
      sourceOfFunds,
    },
  })

  dispatch({
    type: VERIFICATION_ACTIONS.SET_FORM_OPTIONS,
    formOptions,
  })

  dispatch({
    type: VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS,
    myInfoOnlyDetails: {
      race: resp.race,
      aliasName: resp.alias_name,
      residentialStatus: resp.residential_status,
    },
  })

  return retrievedDetails
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseIkycRetrievedDetails = (resp: any): IkycRetrievedDetails => {
  const camelCasedResp = keysToCamel(resp)

  return {
    verificationStatus: camelCasedResp.verificationStatus,
    externalId: camelCasedResp.externalId,
    kycExternalId: camelCasedResp.kycExternalId,
    countryOfResidence: camelCasedResp.countryOfResidence,
    jumio: camelCasedResp.jumio,
    declaration: camelCasedResp.declaration,
    proofOfAddress: camelCasedResp.proofOfAddress,
  }
}

export const setNextIncompleteI18nScreen = (retrievedDetails: IkycRetrievedDetails) => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>
) => {
  const screen = getNextIncompleteI18nScreen(retrievedDetails)

  dispatch({
    type: VERIFICATION_ACTIONS.SET_SCREEN,
    screen,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setI18nVerificationDetails = (resp: any) => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>
) => {
  const parsedRetrievedDetails = parseIkycRetrievedDetails(resp)

  dispatch({
    type: VERIFICATION_ACTIONS.SET_IKYC_RETRIEVED_DETAILS,
    ikycRetrievedDetails: parsedRetrievedDetails,
  })

  const {
    verificationStatus,
    countryOfResidence,
    jumio,
    declaration,
    proofOfAddress,
  } = parsedRetrievedDetails

  if (verificationStatus === PERSONAL_VERIFICATION_STATUS.REJECTED) {
    const kycRejectionReason: string[] = []
    if (countryOfResidence?.rejectionReason)
      countryOfResidence.rejectionReason.forEach((reason: string) =>
        kycRejectionReason.push(`Country of Residence: ${reason}`)
      )
    if (jumio?.passportRejectionReason)
      jumio.passportRejectionReason.forEach((reason: string) =>
        kycRejectionReason.push(`Passport: ${reason}`)
      )
    if (jumio?.selfieRejectionReason)
      jumio.selfieRejectionReason.forEach((reason: string) =>
        kycRejectionReason.push(`Selfie: ${reason}`)
      )
    if (declaration?.rejectionReason)
      declaration.rejectionReason.forEach((reason: string) =>
        kycRejectionReason.push(`Declarations: ${reason}`)
      )
    if (proofOfAddress?.rejectionReason)
      proofOfAddress.rejectionReason.forEach((reason: string) =>
        kycRejectionReason.push(`Proof of Address: ${reason}`)
      )

    dispatch({
      type: VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON,
      kycRejectedReason: kycRejectionReason,
    })
  }

  return parsedRetrievedDetails
}

export const saveI18nVerificationDetails = (
  details: I18nVerificationDetails,
  successCallback?: () => void,
  errorCallback?: (err: any) => void // eslint-disable-line @typescript-eslint/no-explicit-any
) => (dispatch: Dispatch<ActionTypes | UserInfoActionTypes>) => {
  const { countryOfResidence, jumioTrxId, declarations, proofOfAddressKey } = details

  const requestBody: {
    external_id?: string
    country_of_residence?: string
    jumio_trx_id?: string
    declarations?: {
      expected_transaction_amount: string
      expected_total_transaction: string
      expected_transaction_frequency: string
      source_of_funds: string
      annual_income: string
      business_industry: string
      occupation: string
    }
    proof_of_address_key?: string
  } = { external_id: details.externalId }

  if (countryOfResidence) requestBody.country_of_residence = countryOfResidence.toUpperCase()
  if (jumioTrxId) requestBody.jumio_trx_id = jumioTrxId
  if (declarations)
    requestBody.declarations = {
      expected_transaction_amount: declarations.expectedTransactionAmount,
      expected_total_transaction: declarations.expectedTotalTransaction,
      expected_transaction_frequency: declarations.expectedTransactionFrequency,
      source_of_funds: declarations.sourceOfFunds,
      annual_income: declarations.annualIncomeRange,
      occupation: declarations.occupation,
      business_industry: declarations.businessIndustry,
    }
  if (proofOfAddressKey) requestBody.proof_of_address_key = proofOfAddressKey

  put(ENDPOINTS.API_V3_STABLECOIN_INTERNATIONAL_USER_KYCS, requestBody)
    .then(resp => {
      const retrievedDetails = dispatch(setI18nVerificationDetails(resp))
      dispatch(setNextIncompleteI18nScreen(retrievedDetails))
      if (successCallback) successCallback()
    })
    .catch(err => {
      if (errorCallback) errorCallback(err)

      Notification.error({
        message: 'Unexpected Condition',
        description:
          'The server encountered an unexpected condition which prevented it from fulfilling the request. Please contact our support team if this problem persists.',
      })
    })
}

export const setCountryOfResidence = (countryOfResidence: string) => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>
) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_COUNTRY_OF_RESIDENCE,
    countryOfResidence,
  })
}

export const setSgVerificationMethod = (sgVerificationMethod: string) => (
  dispatch: Dispatch<ActionTypes | UserInfoActionTypes>
) => {
  dispatch({
    type: VERIFICATION_ACTIONS.SET_SG_VERIFICATION_METHOD,
    sgVerificationMethod,
  })
}

export const uploadFileToS3 = async (
  presignedURL: string,
  uploadingFile: FileData,
  onSuccess?: (resp: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
  onError?: (message: string) => void
) => {
  try {
    const fileResp = await fetch(uploadingFile.fileData)
    const blob = await fileResp.blob()
    const reqFileData = new File([blob], uploadingFile.fileName)

    const uploadResp = await axios({
      url: presignedURL,
      method: 'PUT',
      data: reqFileData,
      headers: { 'Content-Type': blob.type },
    })

    if (onSuccess) onSuccess(uploadResp)
  } catch (error) {
    if (onError) {
      if (error instanceof Error) {
        onError(error.message)
      } else {
        onError(
          'The server encountered an unexpected condition which prevented it from uploading the document'
        )
      }
    }
  }
}

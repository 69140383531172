import React, { useMemo } from 'react'
import moment from 'moment'
import {
  H2,
  Link,
} from '@xfers/design-system'
import {   
  Column,
  ColumnTitle,
  ColumnText,
  CustomCard,
  Divider,
  Row, 
} from '~/styledComponent/styled'
import { BeneficialOwner } from '~/biz/store/merchantInfo/types'
import { parseCountry } from '~/biz/store/businessVerification/helpers'

type Props = {
  beneficialOwner: BeneficialOwner
}

export default function({ beneficialOwner }: Props) {
  const {
    fullName,
    addressLine1,
    addressLine2,
    postalCode,
    city,
    country,
    dateOfBirth,
    nationality,
    countryOfBirth,
    nric,
    nricType,
    nricIssueDate,
    nricExpiryDate,
    businessPosition,
    ownershipPercentage,
    idFront,
    idBack,
    proofOfAddress,
  } = beneficialOwner


  let addressString = ''
  if (addressLine1 !== undefined && addressLine1 !== '') addressString += addressLine1 + ' '
  if (addressLine2 !== undefined && addressLine2 !== '') addressString += addressLine2 + ' '
  if (city !== undefined && city !== '') addressString += city + ' '
  if (country !== undefined && country !== '') addressString += country + ' '
  if (postalCode !== undefined && postalCode !== '') addressString += postalCode

  const getProofOfAddressUrl = useMemo(() => {
    if (proofOfAddress) {
      let url = new URL(proofOfAddress.url);
      return url
    }
    return null
  }, [proofOfAddress]);

  const mapBusinessPosition = (businessPosition: string) => {
    switch (businessPosition) {
      case 'beneficial_owner':
        return 'Beneficial Owner'
      case 'director':
        return 'Director'
      default:
        return '-'
    }
  }

  return (
    <>
      <CustomCard>
        <H2>{mapBusinessPosition(businessPosition)}</H2>
        <Divider />
        <Row>
          <Column>
            <ColumnTitle>Full Name</ColumnTitle>
            <ColumnText>{fullName || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Addresss</ColumnTitle>
            <ColumnText>{addressString || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Date of Birth</ColumnTitle>
            <ColumnText>{dateOfBirth ? moment(dateOfBirth, "DD/MM/YYYY").format('D MMM YYYY') : '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Nationality</ColumnTitle>
            <ColumnText>{nationality || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Country of Birth</ColumnTitle>
            <ColumnText>{parseCountry(countryOfBirth) || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>NRIC</ColumnTitle>
            <ColumnText>{nric || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>NRIC Type</ColumnTitle>
            <ColumnText>{nricType.toUpperCase() || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>NRIC Issue Date</ColumnTitle>
            <ColumnText>{nricIssueDate ? moment(nricIssueDate, "DD/MM/YYYY").format('D MMM YYYY') : '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>NRIC Expiry Date</ColumnTitle>
            <ColumnText>{nricExpiryDate ? moment(nricExpiryDate, "DD/MM/YYYY").format('D MMM YYYY') : '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Business Position</ColumnTitle>
            <ColumnText>{mapBusinessPosition(businessPosition)}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Ownership Percentage</ColumnTitle>
            <ColumnText>{ownershipPercentage || '-'}</ColumnText>
          </Column>
        </Row>
        <Divider />
        <Row>
          <Column>
            <ColumnTitle>ID Front</ColumnTitle>
            {
              (idFront && idFront.url)
              ? (
                <Link
                  href={idFront.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '##00A965' }}
                >
                  {idFront.filename ? idFront.filename : 'document'}
                </Link>
                )
              : <ColumnText>{'-'}</ColumnText>
            }
          </Column>
          <Column>
            <ColumnTitle>ID Back</ColumnTitle>
            {
              (idBack && idBack.url)
              ? (
                <Link
                  href={idBack.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '##00A965' }}
                >
                  {idBack.filename ? idBack.filename : 'document'}
                </Link>
                )
              : <ColumnText>{'-'}</ColumnText>
            }
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Proof of Address</ColumnTitle>
            {
              (proofOfAddress && proofOfAddress.url)
              ? (
                <Link
                  href={getProofOfAddressUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '##00A965' }}
                >
                  document
                </Link>
                )
              : <ColumnText>{'-'}</ColumnText>
            }
          </Column>
        </Row>
      </CustomCard>
    </>
  )
}
import React from 'react'
import styled from '@emotion/styled'
import { useModal, Modal, Button, P1, H1 } from '@xfers/design-system'

type Props = {
  header: string
  message: string
  logo: React.ReactNode
  setModalNumber?: React.Dispatch<React.SetStateAction<number>>
}
export default function({ header, message, logo, setModalNumber }: Props) {
  const { resetModal } = useModal()
  const doneHandler = () => {
    if (setModalNumber) {
      setModalNumber(3)
    }
    resetModal()
  }
  return (
    <Modal icon={false} disabledCloseIcon>
      <Logo>{logo}</Logo>
      <Header>{header}</Header>
      <Message>{message}</Message>
      <ButtonContainer>
        <ModalButton onClick={doneHandler}>Done</ModalButton>
      </ButtonContainer>
    </Modal>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 30px;
`

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = styled(H1)`
  text-align: center;
  margin-top: 20px;
`

const Message = styled(P1)`
  text-align: center;
  margin-top: 10px;
`

const ModalButton = styled(Button)`
  width: 100%;
`

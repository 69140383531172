import { Reducer } from 'redux'
import { ActionTypes, SIMULATE_DEPOSIT_ACTIONS } from './types'

const initialState = {
  isLoading: false,
  depositRequestError: null,
  depositAmount: '',
  accountId: null,
}

type State = {
  isLoading: boolean
  depositRequestError: string | null
  depositAmount: string
  accountId: number | null
}

export const xidrSandbox: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SIMULATE_DEPOSIT_ACTIONS.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case SIMULATE_DEPOSIT_ACTIONS.SET_DEPOSIT_REQUEST_ERROR: {
      return {
        ...state,
        depositRequestError: action.depositRequestError,
        isLoading: false,
      }
    }

    case SIMULATE_DEPOSIT_ACTIONS.SET_DEPOSIT_AMOUNT: {
      return {
        ...state,
        depositAmount: action.depositAmount,
      }
    }

    case SIMULATE_DEPOSIT_ACTIONS.SET_ACCOUNT_ID: {
      return {
        ...state,
        accountId: action.accountId,
      }
    }

    default:
      return state
  }
}

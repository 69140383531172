import { SGDDepositBankTransferInfo } from '~/types/deposit'
import { FinancialInstitutionName, STABLECOIN } from '~/types'
import { BlockchainAddress } from '~/app/store/blockchainAddresses/types'

export type BankAccount = {
  bankName: string
  accountNumber: string
  id: number // this is the user's bank account id, not the bank id
  status: 'unverified' | 'verified' | 'pending' | 'rejected' | null
}

export type MintInfoBlockchainAddress = {
  address: string
  blockchain: string
  nickname: string
  approved: boolean | null
  financialInstitutionId: number | null
  disabled: boolean
  provider: string | null
  blockchainCurrency: STABLECOIN | null
  network?: string
}

export type Mint = {
  verifiedBankAccount: BankAccount | null
  whitelistedAddress: MintInfoBlockchainAddress | null
  userVerified: boolean
  userLocked: boolean
  annualLimit: number
  annualSpending: string
  transferInfo: SGDDepositBankTransferInfo | null
  instructionId: number | null
  has2FaEnabled: boolean
  mintLimitIncreaseStatus: 'unchanged' | 'pending' | 'increased' | 'banned' | null
}

export type MintSetup = {
  bankAccounts: BankAccount[] | null
}

export type FinancialInstitution = {
  id: number
  name: FinancialInstitutionName
  enabled: boolean
}

export type FetchMintSetupProps = {
  mockData?: MintSetup
  accountId?: number
}

export type FetchMintProps = {
  mockData?: Mint
  accountId?: number
}

export enum MINT_ACTIONS {
  SET_MINT = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_MINT',
  SET_MINT_SETUP = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_MINT_SETUP',
  SET_BANKS = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_BANKS',
  SET_BANK_ACCOUNT = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_BANK_ACCOUNT',
  SET_BLOCKCHAIN = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_BLOCKCHAIN',
  SET_BLOCKCHAIN_ADDRESS = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_BLOCKCHAIN_ADDRESS',
  SET_CONFIRM_MODAL_OPEN = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_CONFIRM_MODAL_OPEN',
  SET_ERROR = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_ERROR',
  SET_TRANSFER_INFO = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_TRANSFER_INFO',
  SET_SETUP_MODE = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_SETUP_MODE',
  SET_IS_MINT_LIMIT_INCREASE_ENABLED = 'STRAITSX_DIRECT_MINT_ACTIONS.SET_IS_MINT_LIMIT_INCREASE_ENABLED',
}

type setMint = {
  type: typeof MINT_ACTIONS.SET_MINT
  mint: Mint | null
}

type setMintSetupData = {
  type: typeof MINT_ACTIONS.SET_MINT_SETUP
  data: MintSetup
}

type setSelectedBankAccount = {
  type: typeof MINT_ACTIONS.SET_BANK_ACCOUNT
  bankAccount: BankAccount | null
}

type setBlockchain = {
  type: typeof MINT_ACTIONS.SET_BLOCKCHAIN
  blockchain: string | null
}

type setBlockchainAddress = {
  type: typeof MINT_ACTIONS.SET_BLOCKCHAIN_ADDRESS
  blockchainAddress: BlockchainAddress | null
}

export enum SETUP_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

type setIs2FaOpen = {
  type: typeof MINT_ACTIONS.SET_CONFIRM_MODAL_OPEN
  is2FaOpen: boolean
}

type setError = {
  type: typeof MINT_ACTIONS.SET_ERROR
  error: string
}

type setTransferInfo = {
  type: typeof MINT_ACTIONS.SET_TRANSFER_INFO
  transferInfo: SGDDepositBankTransferInfo
}

type setSetupMode = {
  type: typeof MINT_ACTIONS.SET_SETUP_MODE
  setupMode: SETUP_MODE
}

type SetIsMintLimitIncreaseEnabled = {
  type: typeof MINT_ACTIONS.SET_IS_MINT_LIMIT_INCREASE_ENABLED
  isMintLimitIncreaseEnabled: boolean
}

export type ActionTypes =
  | setMint
  | setMintSetupData
  | setSelectedBankAccount
  | setBlockchain
  | setBlockchainAddress
  | setIs2FaOpen
  | setError
  | setTransferInfo
  | setSetupMode
  | SetIsMintLimitIncreaseEnabled

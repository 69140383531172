import { Moment } from '~/types'
import { PATHS } from '~/biz/routes/paths'
import { ENDPOINTS } from '~/api'
import { TinType } from '~/biz/pages/StraitsXBusinessVerification/TaxResidency'

export enum BUSINESS_VERIFICATION_ACTIONS {
  INIT_BUSINESS_DATA = 'BUSINESS_VERIFICATION_ACTIONS.INIT_BUSINESS_DATA',
  SET_BUSINESS_DATA = 'BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_DATA',
  SET_DIRECT_URL = 'BUSINESS_VERIFICATION_ACTIONS.SET_DIRECT_URL',
  SET_ADD_FLOW_STEP = 'BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_STEP',
  SET_ADD_FLOW_META = 'BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_META',
  SET_CURRENT_PATH = 'BUSINESS_VERIFICATION_ACTIONS.SET_CURRENT_PATH',
}

export type BusinessInformation = {
  businessType: BusinessType
  businessName?: string
  merchantsFormerNames?: Array<string>
  companyDisplayname?: string
  businessRegistrationNumber?: string
  businessContactNo?: number
  businessDateOfIncorporation?: Moment | null
  businessLicenseExpiryDate?: Moment | null
  countryOfIncorporation?: string
  businessAddressLine1?: string
  businessAddressLine2?: string
  businessPostalCode?: number
  city?: string
  country?: string
  tinNpwp?: string
}

export type BusinessType = 'private_company' | 'public_company' | 'sole_proprietorship' | undefined

export type BusinessRelation = {
  methods?: Array<'xfers_accept' | 'xfers_send' | 'xfers_wallet'>
}

export type BusinessActivity = {
  countriesOfActivities?: Array<string>
  businessWebsite?: string
  businessCategory?: string
  businessSubCategory?: string
  businessActivitySummary?: string
  expectedTransactionVolume?: string
  expectedValuePerTransaction?: string
  expectedGtv?: string
  sourceOfWealth?: string
  sourceOfFunds?: string
  sourceOfGoods?: string
}

export type BusinessTaxResidency = {
  taxAmnestyParticipation: boolean | undefined
  merchantTaxResidences: Array<TinType>
}

export type UploadDocument = {
  putIdFrontUrl?: string
  getIdFrontUrl?: string
  putBizfileUrl?: string
  getBizfileUrl?: string
  putMemorandumUrl?: string
  getMemorandumUrl?: string
  putCertificateOfIncorporationUrl?: string
  getCertificateOfIncorporationUrl?: string
  putRegisterOfMembersUrl?: string
  getRegisterOfMembersUrl?: string
  putRegisterOfDirectorsUrl?: string
  getRegisterOfDirectorsUrl?: string
  putMolhrUrl?: string
  getMolhrUrl?: string
  idFrontUrl?: string
  isIdFrontPdf?: boolean
  bizfileUrl?: string
  isBizfilePdf?: boolean
  memorandumUrl?: string
  isMemorandumPdf?: boolean
  certificateOfIncorporationUrl?: string
  isCertificateOfIncorporationPdf?: boolean
  registerOfMembersUrl?: string
  isRegisterOfMembersPdf?: boolean
  registerOfDirectorsUrl?: string
  isRegisterOfDirectorsPdf?: boolean
  molhrUrl?: string
  isMolhrPdf?: boolean
}

export type AccountManager = {
  fullName?: string
  dateOfBirth?: Moment | null
  countryOfBirth?: string
  nationality?: string
  nricType?: string
  NRIC?: string
  nricIssueDate?: Moment | null
  nricExpiryDate?: Moment | null
  addressLine1?: string
  addressLine2?: string
  village?: string
  subDistrict?: string
  country?: string
  city?: string
  postalCode?: string
  rtRw?: string
  ownershipPercentage?: number
  verificationStatus?: 'initial' | 'verified' | 'pending' | 'rejected' | 'permanently rejected'
  idFrontUrl?: string
  isIdFrontPdf?: boolean
  idBackUrl?: string
  isIdBackPdf?: boolean
  proofOfAddressUrl?: string
  isProofOfAddressPdf?: boolean
}

export type Role = 'bo' | 'director'

export type IndividualAddFlowMeta = {
  // individual role_info step
  type: 'individual'
  isDirectorOrBo: boolean
  role?: Role[] | 'account_manager'
  fullName?: string
  dateOfBirth?: Moment | null
  countryOfBirth?: string
  nationality?: string
  nricType?: string
  NRIC?: string
  nricIssueDate?: Moment | null
  nricExpiryDate?: Moment | null
  ownershipPercentage?: '-' | number

  // individual residential_address step
  addressLine1?: string
  addressLine2?: string
  village?: string
  subDistrict?: string
  country?: string
  city?: string
  postalCode?: string
  rtRw?: string

  // upload document step
  putIdFrontUrl?: string
  getIdFrontUrl?: string
  putIdBackUrl?: string
  getIdBackUrl?: string
  putProofOfAddressUrl?: string
  getProofOfAddressUrl?: string

  // fetch from database
  id?: string
  name?: string
  idFrontUrl?: string
  isIdFrontPdf?: boolean
  idBackUrl?: string
  isIdBackPdf?: boolean
  proofOfAddressUrl?: string
  isProofOfAddressPdf?: boolean
  idFrontDisabled: boolean
  idBackDisabled: boolean
  proofOfAddressDisabled: boolean
  deedOfAppointmentUrl?: string
  isDeedOfAppointmentPdf?: boolean
  is_shadow_account_manager?: boolean
}

export type CompanyAddFlowMeta = {
  type: 'company'
  businessType?: 'private_company' | 'public_company' | 'sole_proprietorship'
  businessName?: string
  businessRegistrationNumber?: string
  businessLicenseExpiryDate?: Moment | null
  dateOfIncorporation?: Moment | null
  countryOfIncorporation?: string
  role: ['bo']
  ownershipPercentage?: '-' | number

  // upload document step
  putBizfileUrl?: string
  getBizfileUrl?: string
  putMemorandumUrl?: string
  getMemorandumUrl?: string

  // fetch from database
  id?: string
  name?: string
  bizfileUrl?: string
  isBizfilePdf?: boolean
  memorandumUrl?: string
  isMemorandumPdf?: boolean
  beneficialOwnerDeclarationUrl?: string
  isBeneficialOwnerDeclarationPdf?: boolean
}

export type AddFlowMeta = IndividualAddFlowMeta | CompanyAddFlowMeta

export type State = {
  businessInformation: BusinessInformation
  businessRelation: BusinessRelation
  businessActivity: BusinessActivity
  businessTaxResidency: BusinessTaxResidency
  uploadDocument: UploadDocument
  accountManager: AccountManager
  businessCategoriesList?: { [index: string]: Array<string> }
  highRiskSubcategoriesList?: Array<string>
  addFlowStep: AddFlowSteps
  addFlowMeta: AddFlowMeta
  directorAndBoList: AddFlowMeta[]
  verificationStatus?: 'unverified' | 'rejected' | 'verified' | 'pending'
  currentPath?: PATHS
  review?: undefined
  initialized: boolean
}

export type AddFlowSteps =
  | null
  | 'role_info'
  | 'residential_address'
  | 'choose_role'
  | 'upload_documents'
  | 'upload_proof_of_address'
  | 'upload_biz_document'

export type Pages =
  | 'businessInformation'
  | 'businessRelation'
  | 'businessActivity'
  | 'businessTaxResidency'
  | 'uploadDocument'
  | 'addFlowMeta'
  | 'review'

export type DocumentTypes =
  | 'bizfile'
  | 'memorandum'
  | 'certificateOfIncorporation'
  | 'registerOfMembers'
  | 'registerOfDirectors'
  | 'molhr'
  | 'idFront'
  | 'idBack'
  | 'proofOfAddress'
  | 'beneficialOwnerDeclaration'
  | 'deedOfAppointment'

export type EndpointMap = { [key in Pages]?: ENDPOINTS }

type InitBusinessData = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.INIT_BUSINESS_DATA
  data: object
}

type SetBusinessData = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_DATA
  page: Pages
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type SetDirectUrl = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_DIRECT_URL
  page: Pages
  urls: object
}

type SetAddFlowStep = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_STEP
  step: AddFlowSteps
}

type SetAddFlowMeta = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_META
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: any
}

type SetCurrentPath = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_CURRENT_PATH
  path: PATHS
}

export type ActionTypes =
  | InitBusinessData
  | SetBusinessData
  | SetDirectUrl
  | SetAddFlowStep
  | SetAddFlowMeta
  | SetCurrentPath

import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import {
  H2,
} from '@xfers/design-system'
import {   
  Column,
  ColumnTitle,
  ColumnText,
  CustomCard,
  Divider,
  Row, 
} from '~/styledComponent/styled'
import { selectMerchantDetail } from '~/biz/store/merchantInfo/selectors'
import { MerchantDetail } from '~/biz/store/merchantInfo/types'
import { parseCountry } from '~/biz/store/businessVerification/helpers'

export default function() {
  const { merchantDetail } = useSelector(selectMerchantDetail)

  const {
    businessType,
    businessName,
    companyDisplayname,
    businessRegistrationNumber,
    businessContactNo,
    businessDateOfIncorporation,
    countryOfIncorporation,
    businessAddressLine1,
    businessAddressLine2,
    businessPostalCode,
    city,
    country,
  } = merchantDetail as MerchantDetail

  let addressString = ''
  if (businessAddressLine1 !== undefined && businessAddressLine1 !== '') addressString += businessAddressLine1 + ' '
  if (businessAddressLine2 !== undefined && businessAddressLine2 !== '') addressString += businessAddressLine2 + ' '
  if (city !== undefined && city !== '') addressString += city + ' '
  if (country !== undefined && country !== '') addressString += country + ' '
  if (businessPostalCode !== undefined && businessPostalCode !== '') addressString += businessPostalCode

  const getBizType = useCallback((bizType: string) => {
    switch (bizType) {
      case 'private_company':
        return 'Private company'
      case 'public_company':
        return 'Public company'
      default:
        return '-'
    }
  }, [])

  return (
    <>
      <CustomCard>
        <H2>Business Information</H2>
        <Divider />
        <Row>
          <Column>
            <ColumnTitle>Business Type</ColumnTitle>
            <ColumnText>{getBizType(businessType)}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Business Display Name</ColumnTitle>
            <ColumnText>{companyDisplayname || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Full Legal Business Name</ColumnTitle>
            <ColumnText>{businessName || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Business Registration Number</ColumnTitle>
            <ColumnText>{businessRegistrationNumber || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Business Contact Number</ColumnTitle>
            <ColumnText>{businessContactNo || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Date of Incorporation / Registration</ColumnTitle>
            <ColumnText>{businessDateOfIncorporation ? moment(businessDateOfIncorporation, "DD/MM/YYYY").format('D MMM YYYY') : '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Country of Incorporation / Registration</ColumnTitle>
            <ColumnText>{parseCountry(countryOfIncorporation) || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Registered Business Address</ColumnTitle>
            <ColumnText>{addressString || '-'}</ColumnText>
          </Column>
        </Row>
      </CustomCard>
    </>
  )
}
import { PATHS } from '~/xidrBiz/routes/paths'
import { ENDPOINTS } from '~/api'

export enum BUSINESS_VERIFICATION_ACTIONS {
  INIT_BUSINESS_DATA = 'BUSINESS_VERIFICATION_ACTIONS.INIT_BUSINESS_DATA',
  SET_BUSINESS_DATA = 'BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_DATA',
  SET_DIRECT_URL = 'BUSINESS_VERIFICATION_ACTIONS.SET_DIRECT_URL',
  SET_ADD_FLOW_STEP = 'BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_STEP',
  SET_ADD_FLOW_META = 'BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_META',
  SET_CURRENT_PATH = 'BUSINESS_VERIFICATION_ACTIONS.SET_CURRENT_PATH',
  SET_UPLOADING_DOCUMENT_ERROR = 'BUSINESS_VERIFICATION_ACTIONS.SET_UPLOADING_DOCUMENT_ERROR',
  SET_BUSINESS_CATEGORIES = 'BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_CATEGORIES',
}

export type BusinessType = 'private_company' | 'public_company' | undefined

export type BusinessRelationMethod =
  | 'straitsx_payment_api'
  | 'straitsx_payout_api'
  | 'straitsx_wallet'

export type State = {
  initialized: boolean

  businessType: BusinessType
  businessLegalName?: string
  companyDisplayname?: string
  businessLicenseExpiryDate?: moment.Moment | undefined
  taxNumber?: string
  businessContactNo?: number
  businessRelations: Array<BusinessRelationMethod>
  countryOfActivities?: string
  businessWebsite?: string
  businessCategory?: string
  businessSubCategory?: string
  businessActivitySummary?: string
  expectedTransactionVolume?: string
  expectedGtv?: string

  isDeedOfEstablishmentUploaded: boolean
  deedOfEstablishmentUploadKey?: string
  deedOfEstablishmentUploadUrl?: string
  deedOfEstablishmentUrl?: string
  deedOfEstablishmentStatus?: string
  deedOfEstablishmentRejectionReason?: string[]

  isBusinessLicenseUploaded: boolean
  businessLicenseUploadKey?: string
  businessLicenseUploadUrl?: string
  businessLicenseUrl?: string
  businessLicenseStatus?: string
  businessLicenseRejectionReason?: string[]

  isSkKemenkumhamUploaded: boolean
  skKemenkumhamUploadKey?: string
  skKemenkumhamUploadUrl?: string
  skKemenkumhamUrl?: string
  skKemenkumhamStatus?: string
  skKemenkumhamRejectionReason?: string[]

  personalVerificationStatus?: 'initial' | 'rejected' | 'verified' | 'pending'
  businessVerificationStatus?: 'initial' | 'rejected' | 'verified' | 'pending'

  currentPath?: PATHS

  isBusinessCategoriesInitiated: boolean
  businessCategoriesList?: { [index: string]: Array<string> }
  highRiskSubcategoriesList?: Array<string>

  isUploadURLsInitiated: boolean
  uploadingDocumentError?: string
}

export type Pages =
  | 'businessInformation'
  | 'businessRelation'
  | 'businessActivity'
  | 'uploadDocument'
  | 'review'

export type DocumentTypes = 'deedOfEstablishment' | 'businessLicense' | 'skKemenkumham'

export type EndpointMap = { [key in Pages]?: ENDPOINTS }

type InitBusinessData = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.INIT_BUSINESS_DATA
  data: object
}

type SetBusinessData = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_DATA
  page: Pages
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type SetDirectUrl = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_DIRECT_URL
  page: Pages
  urls: object
}

type SetCurrentPath = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_CURRENT_PATH
  path: PATHS
}

type SetUploadingDocumentError = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_UPLOADING_DOCUMENT_ERROR
  error: string
}

type SetBusinessCategories = {
  type: typeof BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_CATEGORIES
  businessCategoriesList: { [index: string]: Array<string> }
  highRiskSubcategoriesList: Array<string>
}

export type ActionTypes =
  | InitBusinessData
  | SetBusinessData
  | SetDirectUrl
  | SetCurrentPath
  | SetBusinessCategories
  | SetUploadingDocumentError

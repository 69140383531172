import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Button, useModal, H1, P1 } from '@xfers/design-system'
import firstDepositImg from '~/assets/first-deposit.svg'
import { RootState } from '~/xidrApp/store/types'
import {
  setTab,
  setTransferMethod,
  setShouldResetTransferMethodAfterNavigated,
} from '~/xidrApp/store/xidrLandingPage/actions'
import { redirect } from '~/xidrApp/store/route/actions'
import { PATHS } from '~/xidrApp/routes/paths'

export default function() {
  const dispatch = useDispatch()
  const { resetModal } = useModal()

  const { bankAccounts } = useSelector((state: RootState) => state.xidrUserInfo)

  const handleClickNext = () => {
    dispatch(setShouldResetTransferMethodAfterNavigated(false))
    dispatch(setTab('deposit'))
    // have to set tab first then setTransferMethod, coz set tab will reset transferMethod
    if (bankAccounts && bankAccounts.length === 0) {
      dispatch(setTransferMethod('bank'))
    }

    dispatch(redirect(PATHS.LANDING_PAGE))
    resetModal()
  }

  return (
    <Modal disabledCloseIcon>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <div style={{ marginBottom: '30px' }}>
          <img src={firstDepositImg} />
        </div>
        <H1 style={{ marginBottom: '10px' }}>Make Your First Deposit</H1>
        <P1 style={{ marginBottom: '30px' }}>
          Before you can mint your XIDR directly to your blockchain address, you need to make a
          deposit into your StraitsX account first.
        </P1>
        <Button isFullWidth onClick={handleClickNext}>
          Let's go!
        </Button>
      </div>
    </Modal>
  )
}

import { STEPS, State, ActionTypes, ACTIONS } from './types'

export const initialState = {
  isVAEnabled: false,
  bankAccountId: undefined,
  accountNumber: undefined,
  selectedBank: undefined,
  step: STEPS.CHOOSE_BANK_AND_ACCOUNT_NUMBER,
  includeVerification: false,
  successCallback: () => {},
  personalVerificationStatus: null,
  businessVerificationStatus: null,
  onVerificationMethodNextButtonClick: undefined,
}

export default (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case ACTIONS.SET_STEP:
      return {
        ...state,
        step: action.step,
      }
    case ACTIONS.SET_BANK_ACCOUNT_ID:
      return {
        ...state,
        bankAccountId: action.bankAccountId,
      }
    case ACTIONS.SET_ACCOUNT_NUMBER:
      return {
        ...state,
        accountNumber: action.accountNumber,
      }
    case ACTIONS.SET_SELECTED_BANK:
      return {
        ...state,
        selectedBank: action.selectedBank,
      }
    default:
      return state
  }
}

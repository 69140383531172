import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ConfigManager } from '~/managers/ConfigManager'
import { hasPermission } from '~/permission'
import AccessDenied from '~/components/AccessDenied'
import { selectFeatureMatrixFactors as bizFeatureMatrix } from '~/biz/store/route/selectors'
import { selectFeatureMatrixFactors as xidrBizFeatureMatrix } from '~/xidrBiz/store/route/selectors'
import { FeatureMatrixSelectorsType } from '~/types'
import Locked from '~/app/pages/Locked'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute = (route: any) => {
  const {
    documentTitle,
    permission,
    path,
    routes,
    isAccountLocked,
    isLockedFeatureOn,
    isPeriodicReviewRequired,
  } = route

  let siteName = 'StraitsX'
  if (ConfigManager.isXIDRSite()) siteName += ' ID'
  if (ConfigManager.isBizSite()) siteName += ' Business'

  let title: string = ''
  if (documentTitle) {
    const { name, withSiteTypePrefix = false, withSiteTypeSuffix = true } = documentTitle
    title = `
      ${withSiteTypePrefix ? siteName + ' ' : ''}
      ${name}
      ${withSiteTypeSuffix ? ' | ' + siteName : ''}
    `
  } else {
    title = `${siteName}, Internet Banking Payments Made Easy`
  }

  if (
    isAccountLocked &&
    isPeriodicReviewRequired &&
    isLockedFeatureOn &&
    ConfigManager.isXSGDPersonalSite()
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Route component={Locked} path={path} />
      </>
    )
  }

  if (permission) {
    const featureMatrix: FeatureMatrixSelectorsType = ConfigManager.isXSGDSite()
      ? useSelector(bizFeatureMatrix)
      : useSelector(xidrBizFeatureMatrix)

    if (!hasPermission(permission, featureMatrix)) {
      return (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Route component={AccessDenied} path={path} />
        </>
      )
    }
  }

  return (
    <Route
      sensitive
      path={path}
      render={props => (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <route.component {...props} routes={routes} />
        </>
      )}
    />
  )
}

export default PrivateRoute

import { Moment } from '~/types'

export enum CSV_FORMAT_VERSION {
  V3 = 'V3',
  V4 = 'V4',
}

export enum TRANSACTIONS_TAB_KEY {
  XFERS_ACCOUNT = 'xfers_account',
  XFERS_WALLET = 'xfers_wallet',
}

export type Transactions = {
  key: string
  transaction_id: string
  created_at: string
  description: { type: string; direction: string; data: string }
  metadata: { fees: string; payment_method: string; retail_outlet_name: string }
  method: string
  amount: { prefix: string; postfix: string }
  fee: string
  net_amount: { prefix: string; postfix: string }
  transaction_status: string
  tax?: string
  account_holder_name?: string
  transaction_hash?: string | null
  transaction_hash_url?: string | null
  blocked_reasons: string | null
  network: string | null
}

export type FilterOptions = {
  transactionTypes: Array<{ label: string; value: string }>
  transactionStatuses: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
  accountTypes: Array<{ label: string; value: string }> | undefined
}

export type FilterValues = {
  startDate: Moment | string
  endDate: Moment | string
  transactionStatuses: string[]
  transactionTypes: string[]
  sortType: string
  accountIds: number[]
  currentPage: number
  pageLimit: number
  totalCount: number
  searchString: string
  contractsExist: boolean
  transactions: Transactions[] | undefined
  isTxnLoading: boolean
  isPaginationLoading: boolean
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | Transactions[]
    | boolean
    | undefined
}

export enum XIDR_TRANSACTIONS_ACTIONS {
  SET_IS_TXN_LOADING = 'XIDR_TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING',
  SET_IS_FILTER_LOADING = 'XIDR_TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING',
  SET_IS_PAGINATION_LOADING = 'XIDR_TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING',
  SET_CSV_DOWNLOAD_COMPLETE = 'XIDR_TRANSACTIONS_ACTIONS.SET_CSV_DOWNLOAD_COMPLETE',
  GET_TRANSACTIONS = 'XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS',
  GET_TRANSACTIONS_TOTAL_COUNT = 'XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT',
  GET_TRANSACTION_FILTER_OPTIONS = 'XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS',
  SET_FILTERS = 'XIDR_TRANSACTIONS_ACTIONS.SET_FILTERS',
  RESET_CURRENT_PAGE = 'XIDR_TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE',
  SET_CSV_FORMAT_VERSION = 'XIDR_TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION',
  SWITCH_TRANSACTIONS_TAB = 'XIDR_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB',
  GET_TRANSACTION_TAB_OPTIONS = 'XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS',
}

type SetIsTxnLoading = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING
  isTxnLoading: boolean
}

type SetIsFilterLoading = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING
  isFilterLoading: boolean
}

type SetIsPaginationLoading = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING
  isPaginationLoading: boolean
}

type GetTransactions = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS
  transactions: Transactions[]
  contractsExist: boolean
}

type GetTransactionsTotalCount = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT
  totalCount: number
}

type GetTransactionFilterOptions = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS
  viewType: string
  filterOptions: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterOptions
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterOptions
  }
}

type SetFilters = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type ResetCurrentPage = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE
}

type SetCsvFormatVersion = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION
  csvFormatVersion: CSV_FORMAT_VERSION
}

type SwitchTransactionsTab = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB
  transactionsTabKey: TRANSACTIONS_TAB_KEY
}

type GetTransactionTabOptions = {
  type: typeof XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS
  transactionTabOptions: TRANSACTIONS_TAB_KEY[]
}

export type ActionTypes =
  | SetIsTxnLoading
  | SetIsFilterLoading
  | SetIsPaginationLoading
  | GetTransactions
  | GetTransactionsTotalCount
  | GetTransactionFilterOptions
  | SetFilters
  | ResetCurrentPage
  | SetCsvFormatVersion
  | SwitchTransactionsTab
  | GetTransactionTabOptions

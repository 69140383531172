import { Reducer } from 'redux'
import { ActionTypes, TOUR_ACTIONS } from './types'

const initialState = {
  currentStepIndex: 0,
  isTourRunning: false,
}

type State = {
  currentStepIndex: number
  isTourRunning: boolean
}

export const tour: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TOUR_ACTIONS.SET_CURRENT_STEP_INDEX: {
      return {
        ...state,
        currentStepIndex: action.currentStepIndex,
      }
    }

    case TOUR_ACTIONS.SET_IS_TOUR_RUNNING: {
      return {
        ...state,
        isTourRunning: action.isTourRunning,
      }
    }

    default:
      return state
  }
}

import React, { useContext } from 'react'

import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'
import { STEPS, BankAccount } from '~/components/AddUSDCBankFlow/types'
import { setStep } from '~/components/AddUSDCBankFlow/actions'

import AddBankAccount from './AddBankAccount'
import AddBillingAddress from './AddBillingAddress'
import SuccessfullySubmitted from './SuccessfullySubmitted'
import FailedSubmitted from './FailedSubmitted'
import UploadBankStatement from './UploadBankStatement'
import SuccessfullyUploadBankStatement from './SuccessfullyUploadBankStatement'

const AddBankFlowComponent = () => {
  const { state, dispatch } = useContext(AddBankFlowContext)
  const { step: activeStep, successCallback } = state

  const onSubmitted = (success: boolean, bankAccount?: BankAccount) => {
    if (success) {
      dispatch(setStep(STEPS.SUCCESSFULLY_SUBMITTED))

      if (bankAccount) successCallback(bankAccount)
    } else {
      dispatch(setStep(STEPS.FAILED_SUBMITTED))
    }
  }
  const onUploaded = (success: boolean, bankAccount?: BankAccount) => {
    if (success) {
      dispatch(setStep(STEPS.SUCCESSFULLY_UPLOAD_BANK_STATEMENT))

      if (bankAccount) successCallback(bankAccount)
    } else {
      dispatch(setStep(STEPS.FAILED_SUBMITTED))
    }
  }

  return (
    <>
      {activeStep === STEPS.ADD_BANK_ACCOUNT && (
        <AddBankAccount
          goNext={() => {
            dispatch(setStep(STEPS.ADD_BILLING_ADDRESS))
          }}
        />
      )}
      {activeStep === STEPS.ADD_BILLING_ADDRESS && (
        <AddBillingAddress
          goPrev={() => {
            dispatch(setStep(STEPS.ADD_BANK_ACCOUNT))
          }}
          goNext={onSubmitted}
        />
      )}
      {activeStep === STEPS.SUCCESSFULLY_SUBMITTED && <SuccessfullySubmitted />}
      {activeStep === STEPS.FAILED_SUBMITTED && <FailedSubmitted goNext={onSubmitted} />}
      {activeStep === STEPS.VERIFY_WITH_BANK_STATEMENT && (
        <UploadBankStatement goNext={onUploaded} />
      )}

      {activeStep === STEPS.SUCCESSFULLY_UPLOAD_BANK_STATEMENT && (
        <SuccessfullyUploadBankStatement />
      )}
    </>
  )
}

export default AddBankFlowComponent

export enum PATHS {
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/:transactionId',
  DEPOSIT = '/deposit',
  DEPOSIT_REQUESTS = '/deposit/requests',
  SIMULATE_DEPOSIT = '/simulate_deposit',
  TRANSFER_IN = '/transfer_in',
  WITHDRAW = '/withdraw',
  TRANSFER_OUT = '/transfer_out',
  MANAGE_ACCOUNT = '/manage_account',
  VERIFICATION = '/verification/personal',
  CLAIM_KYC = '/claim_kyc',
  VERIFICATION_MY_INFO_CALLBACK = '/callback/:myInfoParams',
  INTERNAL_TOOLS = '/internal_tools',
  GET_STARTED = '/get_started',
  ERROR = '/error/:code',
  ERROR_403 = '/error/403',
  ERROR_404 = '/error/404',
  LANDING_PAGE = '/',
  MINT = '/mint',
  MINT_SETUP = '/mint/setup',
  DEVELOPER_TOOLS = '/developer_tools',
  TEAM = '/team',
  BIZ_KYC_INFORMATION = '/verification/business/information',
  BIZ_KYC_INTENDED_RELATIONS = '/verification/business/intended_relations',
  BIZ_KYC_BUSINESS_ACTIVITIES = '/verification/business/business_activities',
  BIZ_KYC_UPLOAD_DOCUMENTS = '/verification/business/upload_documents',
  BIZ_KYC_REVIEW = '/verification/business/review',
  BIZ_KYC_STATUS = '/verification/business/status',
}

/* eslint-disable max-lines */
import { Dispatch } from '~/xidrBiz/store/types'
import {
  ActionTypes,
  DEVELOPER_TOOLS_ACTIONS,
  CALLBACK_TYPE,
  ApiTokenInfo,
  callbackFunctions,
} from './types'
import { get, put, post, del, ENDPOINTS } from '~/api'

export const fetchMerchantApiToken = (successCallback: () => void) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
    isLoading: true,
  })

  get(ENDPOINTS.API_V3_STABLECOIN_API_TOKEN)
    .then(apiTokenInfo => {
      if (apiTokenInfo.token) {
        dispatch({
          type: DEVELOPER_TOOLS_ACTIONS.SET_API_TOKEN,
          apiTokenInfo: {
            token: apiTokenInfo.token,
            secret: apiTokenInfo.secret,
            lastUsed: apiTokenInfo.last_used || '',
            createdAt: apiTokenInfo.created_at || '',
          },
        })
      }

      successCallback()
    })
    .catch(() => {})
    .finally(() => {
      dispatch({
        type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
        isLoading: false,
      })
    })
}

export const createMerchantApiToken = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
    isLoading: true,
  })

  post(ENDPOINTS.API_V3_STABLECOIN_API_TOKEN, {})
    .then((apiTokenInfo: ApiTokenInfo) => {
      dispatch({
        type: DEVELOPER_TOOLS_ACTIONS.SET_API_TOKEN,
        apiTokenInfo: {
          token: apiTokenInfo.token,
          secret: apiTokenInfo.secret,
          lastUsed: apiTokenInfo.lastUsed || '',
          createdAt: apiTokenInfo.createdAt || '',
        },
      })
    })
    .catch(() => {})
    .finally(() => {
      dispatch({
        type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
        isLoading: false,
      })
    })
}

export const updateMerchantApiToken = (callback: () => void) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
    isLoading: true,
  })

  put(ENDPOINTS.API_V3_STABLECOIN_API_TOKEN, {})
    .then(apiTokenInfo => {
      if (apiTokenInfo.token) {
        dispatch({
          type: DEVELOPER_TOOLS_ACTIONS.SET_API_TOKEN,
          apiTokenInfo: {
            token: apiTokenInfo.token,
            secret: apiTokenInfo.secret,
            lastUsed: apiTokenInfo.last_used || '',
            createdAt: apiTokenInfo.created_at || '',
          },
        })
      }
    })
    .catch(() => {})
    .finally(() => {
      callback()
      dispatch({
        type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
        isLoading: false,
      })
    })
}

export const deleteMerchantApiToken = (callback: () => void) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
    isLoading: true,
  })

  del(ENDPOINTS.API_V3_STABLECOIN_API_TOKEN, {})
    .then(() => {
      dispatch(resetApiToken())
    })
    .catch(() => {})
    .finally(() => {
      callback()
      dispatch({
        type: DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING,
        isLoading: false,
      })
    })
}

export const getCallbackUrls = (successCallback: () => void) => (
  dispatch: Dispatch<ActionTypes>
) => {
  get(ENDPOINTS.API_V3_STABLECOIN_CALLBACK_URLS)
    .then(callbackUrls => {
      dispatch({
        type: DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URLS,
        callbackUrls: {
          payment: callbackUrls.payment,
          payout: callbackUrls.payout,
          retailPayment: callbackUrls.retail_payment,
        },
      })

      successCallback()
    })
    .catch(err => {
      /* eslint-disable-next-line no-console */
      console.log(err)
    })
}

export const updateCallbackUrl = (
  callbackType: CALLBACK_TYPE,
  callbackUrl: string,
  { successCallback, errorCallback }: callbackFunctions
) => () => {
  put(ENDPOINTS.API_V3_STABLECOIN_CALLBACK_URLS, {
    [callbackType.toLowerCase()]: callbackUrl,
  })
    .then(() => {
      if (successCallback) successCallback()
    })
    .catch(() => {
      errorCallback()
    })
}

export const setIsFlipperDeveloperToolEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_DEVELOPER_TOOL_ENABLED,
    enabled,
  })
}

export const setIsFlipperSandboxEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_SANDBOX_ON,
    enabled,
  })
}

export const resetApiToken = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: DEVELOPER_TOOLS_ACTIONS.RESET_API_TOKEN_INFO,
  })
}

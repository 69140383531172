import { WALLET_TYPES } from '~/types'

export type WalletIdType =
  | WALLET_TYPES.DIGITAL_GOODS
  | WALLET_TYPES.GENERAL
  | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE
  | undefined

export type Wallet = {
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export enum MY_ACCOUNT_ACTIONS {
  SET_DIGITAL_GOODS_USER = 'MY_ACCOUNT_ACTIONS.SET_DIGITAL_GOODS_USER',
  GET_WALLETS = 'MY_ACCOUNT_ACTIONS.GET_WALLETS',
}

type GetWallets = {
  type: typeof MY_ACCOUNT_ACTIONS.GET_WALLETS
  wallets: Wallet[]
}

export type ActionTypes = GetWallets

import { Reducer } from 'redux'
import { ActionTypes, BlockchainAddress, FinancialInstitution } from './types'
import { BLOCKCHAIN_ADDRESS_ACTIONS } from './const'

type State = {
  isBlockchainAddressListInitiated: boolean
  blockchainAddressList: BlockchainAddress[]
  fiList: FinancialInstitution[]
  maxAddressAmount: number
  addressQuota: number
  blockchainAddressDeletion: boolean
  isWhitelistingImprovementEnabled: boolean
}

const initialState: State = {
  isBlockchainAddressListInitiated: false,
  blockchainAddressList: [],
  fiList: [],
  maxAddressAmount: 0,
  addressQuota: -1,
  blockchainAddressDeletion: false,
  isWhitelistingImprovementEnabled: false,
}

export const blockchainAddresses: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_BLOCKCHAIN_ADDRESS_LIST: {
      return {
        ...state,
        blockchainAddressList: action.blockchainAddressList,
        isBlockchainAddressListInitiated: true,
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_FI_LIST: {
      return {
        ...state,
        fiList: action.fiList,
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_MAX_ADDRESS_AMOUNT: {
      return {
        ...state,
        maxAddressAmount: action.maxAddressAmount,
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_ADDRESS_QUOTA: {
      return {
        ...state,
        addressQuota: action.addressQuota,
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_BLOCKCHAIN_ADDRESS_DELETION: {
      return {
        ...state,
        blockchainAddressDeletion: action.blockchainAddressDeletion,
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS: {
      let addresses = state.blockchainAddressList
      if (!addresses) {
        addresses = []
      }
      addresses.push(action.blockchainAddress)

      return {
        ...state,
        blockchainAddressList: [...addresses],
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS: {
      let addresses = state.blockchainAddressList
      if (!addresses) {
        addresses = []
      }

      const addressInd = addresses.findIndex(
        item => item.address === action.blockchainAddress.address
      )

      if (addressInd === -1) {
        return { ...state }
      }

      addresses[addressInd] = { ...action.blockchainAddress }

      return {
        ...state,
        blockchainAddressList: [...addresses],
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.UPDATE_BLOCKCHAIN_ADDRESS: {
      const addresses = state.blockchainAddressList

      const addressInd = addresses.findIndex(
        item =>
          item.address === action.blockchainAddress.address &&
          item.blockchain === action.blockchainAddress.blockchain
      )

      if (addressInd !== -1) {
        const newAddressList = [...addresses]
        newAddressList[addressInd] = action.blockchainAddress
        return { ...state, blockchainAddressList: [...newAddressList] }
      }

      return {
        ...state,
        blockchainAddressList: [action.blockchainAddress, ...addresses],
      }
    }

    case BLOCKCHAIN_ADDRESS_ACTIONS.SET_IS_WHITELISTING_IMPROVEMENT_ENABLED: {
      return {
        ...state,
        isWhitelistingImprovementEnabled: action.isWhitelistingImprovementEnabled,
      }
    }

    default:
      return state
  }
}

import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Tick, BankFilled } from '@xfers/design-system/es/icons'
import { useModal, useTheme, Modal, Button, BadgeIcon } from '@xfers/design-system'
import { AddBankFlowContext } from '~/components/AddBankFlow'
import { createGaEvent } from '~/helpers/gaHelper'

export default function() {
  const { resetModal } = useModal()
  const { brand } = useTheme()
  const { state } = useContext(AddBankFlowContext)
  const { accountNumber, selectedBank } = state

  return (
    <Modal
      onClose={() => {
        createGaEvent(
          'My Account',
          'clickCloseAfterAddBankAccount',
          'Clicked Close Button After Adding New Bank Account'
        )
        resetModal()
      }}
      title="Successfully added"
      icon={<BadgeIcon type="success" icon={<Tick />} />}
      footer={
        <Button
          data-cy="button-addAccountSuccess"
          type="secondary"
          onClick={() => {
            createGaEvent(
              'My Account',
              'clickCloseAfterAddBankAccount',
              'Clicked Close Button After Adding New Bank Account'
            )
            resetModal()
          }}
        >
          Close
        </Button>
      }
    >
      <Text>
        Your bank account has been added. You can proceed with your deposit or withdrawal.
      </Text>
      <InfoRow>
        <InfoLeft>Bank Name</InfoLeft>
        <InfoRight data-cy="text-bankNameSuccess">
          <BankFilled
            width={18}
            height={18}
            color={brand.primary.default}
            style={{
              marginRight: '5px',
              verticalAlign: 'middle',
            }}
          />
          {selectedBank}
        </InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Account Number</InfoLeft>
        <InfoRight data-cy="text-accountNumberSuccess">{accountNumber}</InfoRight>
      </InfoRow>
    </Modal>
  )
}

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-top: 20px;
  margin-bottom: 20px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 30px;
  }
`

const InfoLeft = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
`

const InfoRight = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #1b2736;
`

import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { CrossMini, BankFilled } from '@xfers/design-system/es/icons'
import { useModal, useTheme, Modal, BadgeIcon, Button } from '@xfers/design-system'

import { AddBankFlowContext } from '~/components/AddBankFlow'

export default function() {
  const { resetModal } = useModal()
  const { brand } = useTheme()
  const { state } = useContext(AddBankFlowContext)
  const { accountNumber, selectedBank } = state

  return (
    <Modal
      onClose={() => resetModal()}
      title="Error"
      icon={<BadgeIcon type="error" icon={<CrossMini />} />}
      footer={
        <Button type="secondary" onClick={() => resetModal()}>
          Close
        </Button>
      }
    >
      <Text>
        Failed to add your bank account number <b>{accountNumber}</b> as it is invalid. Please try
        again.
      </Text>
      <InfoRow>
        <InfoLeft>Bank Name</InfoLeft>
        <InfoRight>
          <BankFilled
            width={18}
            height={18}
            color={brand.primary.default}
            style={{
              marginRight: '5px',
              verticalAlign: 'middle',
            }}
          />
          {selectedBank}
        </InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Account Number</InfoLeft>
        <InfoRight>{accountNumber}</InfoRight>
      </InfoRow>
    </Modal>
  )
}

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-top: 20px;
  margin-bottom: 20px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 30px;
  }
`

const InfoLeft = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
`

const InfoRight = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #1b2736;
`

import { UploadFile } from 'antd/es/upload/interface'
import { Reducer } from 'redux'
import { XIDR_VERIFICATION_ACTIONS } from './const'
import { ActionTypes, DocumentUploadURLs, FileData, PersonalDetailInfo } from './types'

type State = {
  documentUploadURLs: DocumentUploadURLs | undefined
  uploadedKTPPhotoList: UploadFile[]
  uploadedSelfiePhotoList: UploadFile[] // for upload selfie photo
  liveSelfiePhoto: FileData | null // for live selfie
  personalDetail: PersonalDetailInfo
}

const initialState: State = {
  documentUploadURLs: undefined,
  uploadedKTPPhotoList: [],
  uploadedSelfiePhotoList: [],
  liveSelfiePhoto: null,
  personalDetail: {
    dob: null,
    fullName: '',
    ktpNumber: '',
    pob: '',
    email: '',
    phoneNo: '',
  },
}

export const xidrUserVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_VERIFICATION_ACTIONS.SET_DOCUMENT_UPLOAD_URLS: {
      return {
        ...state,
        documentUploadURLs: action.urls,
      }
    }

    case XIDR_VERIFICATION_ACTIONS.SET_PERSONAL_DETAIL: {
      return {
        ...state,
        personalDetail: { ...action.personalDetail },
      }
    }

    case XIDR_VERIFICATION_ACTIONS.UPDATE_PERSONAL_DETAIL: {
      return {
        ...state,
        personalDetail: { ...state.personalDetail, [action.attr]: action.value },
      }
    }

    case XIDR_VERIFICATION_ACTIONS.SET_UPLOAD_KTP_PHOTO_LIST: {
      return {
        ...state,
        uploadedKTPPhotoList: action.photoList,
      }
    }

    case XIDR_VERIFICATION_ACTIONS.SET_UPLOAD_SELFIE_PHOTO_LIST: {
      return {
        ...state,
        uploadedSelfiePhotoList: action.photoList,
      }
    }

    case XIDR_VERIFICATION_ACTIONS.SET_LIVE_SELFIE_PHOTO: {
      return {
        ...state,
        liveSelfiePhoto: action.photo,
      }
    }

    default:
      return state
  }
}

/* eslint-disable max-lines */
import { lazy } from 'react'
import { PATHS } from './paths'
import { Route } from '~/types'
import withStraitsXBreadcrumbBar from '~/common/withStraitsXBreadcrumbBar'

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '~/xidrApp/pages/NotFound'))

const Error = lazy(() => import(/* webpackChunkName: "Error" */ '~/xidrApp/pages/Error'))

const InternalTools = lazy(() =>
  import(/* webpackChunkName: "InternalTools" */ '~/xidrApp/pages/InternalTools')
)

const XIDRGetStarted = lazy(() =>
  import(/* webpackChunkName: "XIDRGetStarted" */ '~/xidrApp/pages/XIDRGetStarted')
)

const XIDRLandingPage = lazy(() =>
  /* webpackChunkName: "XIDRLandingPage" */
  import('~/xidrApp/pages/XIDRLandingPage')
)

const XIDRDeposit = lazy(() =>
  /* webpackChunkName: "XIDRDeposit" */
  import('~/xidrApp/pages/XIDRDeposit')
)

const XIDRWithdraw = lazy(() =>
  /* webpackChunkName: "XIDRWithdraw" */
  import('~/xidrApp/pages/XIDRWithdraw')
)

const XIDRMint = lazy(() =>
  import(/* webpackChunkName: "Mint" */ '~/xidrApp/pages/XIDRMint/Instructions')
)

const XIDRMintSetup = lazy(() =>
  import(/* webpackChunkName: "MintSetup" */ '~/xidrApp/pages/XIDRMint/Setup')
)

const XIDRVerification = lazy(() =>
  /* webpackChunkName: "XIDRVerification" */
  import('~/xidrApp/pages/XIDRVerification')
)

const XIDRMyAccount = lazy(() =>
  /* webpackChunkName: "XIDRMyAccount" */
  import('~/xidrApp/pages/XIDRMyAccount')
)

const XIDRTransactionDetails = lazy(() =>
  import(/* webpackChunkName: "XIDRTransactionDetails" */ '~/xidrApp/pages/XIDRTransactionDetails')
)

const XIDRTransactions = lazy(() =>
  import(/* webpackChunkName: "XIDRTransactions" */ '~/xidrApp/pages/XIDRTransactions')
)

export const XIDRRoutes: Route[] = [
  {
    path: PATHS.GET_STARTED,
    component: XIDRGetStarted,
    exact: true,
    sideBar: 'Get Started',
    documentTitle: { name: 'Get Started' },
  },
  {
    path: PATHS.LANDING_PAGE,
    component: XIDRLandingPage,
    exact: true,
    sideBar: 'Home',
    breadcrumbName: 'Home',
    documentTitle: {
      name: 'Dashboard',
      withSiteTypePrefix: true,
      withSiteTypeSuffix: false,
    },
  },
  {
    path: PATHS.MINT,
    component: withStraitsXBreadcrumbBar(XIDRMint),
    exact: true,
    sideBar: 'Mint',
    breadcrumbName: 'Mint',
    documentTitle: { name: 'Mint' },
  },
  {
    path: PATHS.MINT_SETUP,
    component: withStraitsXBreadcrumbBar(XIDRMintSetup),
    exact: true,
    breadcrumbName: 'Setup',
    documentTitle: { name: 'Mint Setup' },
  },
  {
    path: PATHS.TRANSACTIONS,
    component: withStraitsXBreadcrumbBar(XIDRTransactions),
    exact: true,
    sideBar: 'Transaction History',
    breadcrumbName: 'Transaction History',
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.TRANSACTION_DETAILS,
    component: XIDRTransactionDetails,
    exact: true,
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.TRANSFER_IN,
    component: withStraitsXBreadcrumbBar(XIDRDeposit),
    exact: true,
    breadcrumbName: 'Transfer In',
    documentTitle: { name: 'Transfer In' },
  },
  {
    path: PATHS.TRANSFER_OUT,
    component: withStraitsXBreadcrumbBar(XIDRWithdraw),
    exact: true,
    breadcrumbName: 'Transfer Out',
    documentTitle: { name: 'Transfer Out' },
  },
  {
    path: PATHS.MANAGE_ACCOUNT,
    component: withStraitsXBreadcrumbBar(XIDRMyAccount),
    exact: true,
    breadcrumbName: 'My Account',
    documentTitle: { name: 'Manage Account' },
  },
  {
    path: PATHS.VERIFICATION,
    component: withStraitsXBreadcrumbBar(XIDRVerification),
    exact: true,
    breadcrumbName: 'Verification',
    documentTitle: { name: 'Complete Verification' },
  },
  {
    path: PATHS.INTERNAL_TOOLS,
    component: withStraitsXBreadcrumbBar(InternalTools),
    breadcrumbName: 'Internal Tools',
    documentTitle: { name: 'Internal Tools' },
  },
  {
    path: PATHS.ERROR,
    component: Error,
    documentTitle: { name: 'Error' },
  },
  {
    component: NotFound,
    documentTitle: { name: 'Error' },
  },
]

/* eslint-disable no-underscore-dangle */
class ModeManagerClass {
  public toggleMode(mode?: MODE) {
    let nextMode

    if (mode) {
      // if mode parameter is passed in, then this will be the next mode set into local storage
      nextMode = mode
    } else {
      // if no mode passed in, the next mode value will be flipped from the previous one
      nextMode = this.isSandboxMode() ? MODE.PRODUCTION : MODE.SANDBOX
    }

    sessionStorage.setItem('mode', nextMode)
  }

  // eslint-disable-next-line class-methods-use-this
  public isSandboxMode() {
    const currentMode = window.Cypress
      ? window.__CYPRESS_OVERWRITE_MODE__
      : sessionStorage.getItem('mode')

    return currentMode === MODE.SANDBOX
  }
}

export enum MODE {
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
}

export const ModeManager = new ModeManagerClass()

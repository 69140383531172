import React from 'react'
import styled from '@emotion/styled'
import { Card, Button, DESKTOP_BREAKPOINT } from '@xfers/design-system'
import { WarningFilled } from '@xfers/design-system/es/icons'
import cookie from 'js-cookie'
import straitsXBackground from '~/assets/straitsXBackground.png'
import SSOManager from '~/managers/SSOManager'
import StraitsXFooter from '~/components/StraitsXFooter'

function Dpt2LockedPage() {
  const signOut = () => {
    cookie.remove('SESSIONID')
    SSOManager.ssoLogout()
  }

  return (
    <Container>
      <Content>
        <CardContainer>
          <LockContainer>
            <WarningFilled style={{ color: '#E5C412' }} width="50" height="50" />
          </LockContainer>
          <Header>Dashboard Access Restricted</Header>
          <Body>
            Your access to the dashboard is currently restricted. Your account manager must accept the new terms and conditions before you can log in.  <br />
            Please reach out to your account manager to proceed. <br />
            Thank you for your understanding.
          </Body>
          <Button type="secondary" style={{ width: '100%' }} onClick={() => signOut()}>
            Log Out
          </Button>
        </CardContainer>
        <StraitsXFooter />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  background-image: url(${straitsXBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`

const CardContainer = styled(Card)`
  max-width: 700px;
  margin-top: 60px;
  padding: 30px;
  radius: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 169, 101, 0.2);
`

const LockContainer = styled.div`
  text-align: center;
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
`

const Body = styled.div`
  width: 100%;
  margin: 30px auto;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
`

export default Dpt2LockedPage

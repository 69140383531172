export enum PATHS {
  TRANSACTIONS = '/transactions',
  TRANSACTION_DETAILS = '/transactions/:transactionId',
  TRANSFER_IN = '/transfer_in',
  TRANSFER_OUT = '/transfer_out',
  MANAGE_ACCOUNT = '/manage_account',
  VERIFICATION = '/verification',
  INTERNAL_TOOLS = '/internal_tools',
  GET_STARTED = '/get_started',
  MINT = '/mint',
  MINT_SETUP = '/mint/setup',
  ERROR = '/error/:code',
  ERROR_403 = '/error/403',
  LANDING_PAGE = '/',
}

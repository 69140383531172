import { Reducer } from 'redux'
import {
  EarnTransaction,
  STRAITSX_EARN_TRANSACTIONS_ACTIONS,
  ActionTypes,
  EarnAccount,
  EarnBalance,
  EarnCurrencyBalance,
  EarnTransferRequestType,
  EARN_CURRENCY,
  EARN_TXN_STATUS,
  EARN_TRANSACTION_TYPE,
  EARN_TRANSACTION_SORT_ORDER,
  InterestInfo,
  PlatformInfo,
} from './types'

const initialState = {
  earnAccount: null,
  isTransactionLoading: false,
  isLoading: false,
  earnBalance: null,
  earnUSDCBalance: null,
  earnXSGDBalance: null,
  latestTransferRequest: undefined,
  earnTransactions: [],
  earnTransactionsFilterCurrency: null,
  earnTransactionsFilterStatus: null,
  earnTransactionsFilterStartDate: null,
  earnTransactionsFilterEndDate: null,
  earnTransactionType: EARN_TRANSACTION_TYPE.INTEREST,
  earnTransactionSortOrder: null,
  earnTransactionSortField: null,
  interestInfo: null,
  platformInfo: null,
  earnTransactionCurrentPage: 1,
  earnTransactionTxPerPage: 10,
  earnTransactionTotalCount: 0,
  earnTransactionTotalPages: 0,
}

type State = {
  earnAccount: EarnAccount
  isTransactionLoading: boolean
  isLoading: boolean
  interestInfo: InterestInfo
  platformInfo: PlatformInfo
  earnBalance: EarnBalance
  earnUSDCBalance: EarnCurrencyBalance
  earnXSGDBalance: EarnCurrencyBalance
  latestTransferRequest:
    | {
        amount: number
        currencyCode: string
        requestType: EarnTransferRequestType
      }
    | undefined

  earnTransactions: EarnTransaction[]
  earnTransactionsFilterCurrency: EARN_CURRENCY | null
  earnTransactionsFilterStatus: EARN_TXN_STATUS | null
  earnTransactionsFilterStartDate: string | null
  earnTransactionsFilterEndDate: string | null
  earnTransactionType: EARN_TRANSACTION_TYPE
  earnTransactionSortOrder: EARN_TRANSACTION_SORT_ORDER | null
  earnTransactionSortField: string | null
  earnTransactionCurrentPage: number
  earnTransactionTxPerPage: number
  earnTransactionTotalCount: number
  earnTransactionTotalPages: number
}

export const straitsXEarn: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_ACCOUNT:
      return {
        ...state,
        earnAccount: action.earnAccount,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_LOADING:
      return {
        ...state,
        isTransactionLoading: action.isTransactionLoading,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_BALANCE:
      return {
        ...state,
        earnBalance: action.earnBalance,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_USDC_BALANCE:
      return {
        ...state,
        earnUSDCBalance: action.earnUSDCBalance,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_XSGD_BALANCE:
      return {
        ...state,
        earnXSGDBalance: action.earnXSGDBalance,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_INTEREST_INFO:
      return {
        ...state,
        interestInfo: action.interestInfo,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_PLATFORM_INFO:
      return {
        ...state,
        platformInfo: action.platformInfo,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_LATEST_TRANSFER_REQUEST:
      return {
        ...state,
        latestTransferRequest: {
          amount: action.amount,
          currencyCode: action.currencyCode,
          requestType: action.requestType,
        },
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS:
      return {
        ...state,
        earnTransactions: action.transactions,
        earnTransactionTotalCount: action.totalCount,
        earnTransactionTotalPages: action.totalPages,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY:
      return {
        ...state,
        earnTransactionsFilterCurrency: action.currency,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE:
      return {
        ...state,
        earnTransactionsFilterStartDate: action.startDate,
        earnTransactionsFilterEndDate: action.endDate,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS:
      return {
        ...state,
        earnTransactionsFilterStatus: action.status,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_TYPE:
      return {
        ...state,
        earnTransactionType: action.transactionType,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_SORT_ORDER:
      return {
        ...state,
        earnTransactionSortOrder: action.sortOrder,
      }
    case STRAITSX_EARN_TRANSACTIONS_ACTIONS.SET_EARN_TRANSACTION_CURRENT_PAGE:
      return {
        ...state,
        earnTransactionCurrentPage: action.currentPage,
      }
    default:
      return state
  }
}

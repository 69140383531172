import { Moment } from '~/types'

export type TransactionDetails = {
  key: string
  transactionId: string
  transactionType: string
  description: { type: string; direction: string; data: string }
  metadata: { fees: string; payment_method: string; retail_outlet_name: string }
  transactionHash?: string | null
  transactionHashUrl?: string | null
  walletType: string
  method: string
  accountHolderName?: string | null
  amount: { prefix: string; postfix: string }
  fee: string
  tax?: string | null
  netAmount: { prefix: string; postfix: string }
  createdDate: string
  completedDate: string
  status: string
  note?: string | null
  sourceDestination?: { source: string; destination: string } | null
  merchantDescription?: string | null
  network?: string | null
  bankAccountNo: string | null
  bankName: string | null
}

export type RetailPaymentDetails = {
  retailOutletName: string
  expiredAt: string | Moment
  amount: string
  fees: string
  paymentCode: string
}

export enum XIDR_TRANSACTION_DETAILS_ACTIONS {
  SET_IS_LOADING = 'XIDR_TRANSACTION_DETAILS_ACTIONS.SET_IS_LOADING',
  GET_XIDR_TRANSACTION_DETAILS = 'XIDR_TRANSACTION_DETAILS_ACTIONS.GET_XIDR_TRANSACTION_DETAILS',
}

type SetIsLoading = {
  type: typeof XIDR_TRANSACTION_DETAILS_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type getTransactionDetails = {
  type: typeof XIDR_TRANSACTION_DETAILS_ACTIONS.GET_XIDR_TRANSACTION_DETAILS
  transactionDetails: TransactionDetails
  retailPaymentDetails: RetailPaymentDetails
  isRetailPending: boolean
}

export type ActionTypes = SetIsLoading | getTransactionDetails

import { Reducer } from 'redux'
import { ActionTypes, DEPOSIT_INSTRUCTIONS_ACTIONS } from './types'

const initialState = {
  isFastLiveBannerEnabled: false,
}

type State = {
  isFastLiveBannerEnabled: boolean
}

export const depositInstructions: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DEPOSIT_INSTRUCTIONS_ACTIONS.SET_IS_FAST_LIVE_BANNER_ENABLED: {
      return {
        ...state,
        isFastLiveBannerEnabled: action.isFastLiveBannerEnabled,
      }
    }

    default:
      return state
  }
}

import { PATHS } from '~/biz/routes/paths'
import { COUNTRY } from '~/managers/ConfigManager'
import {
  USER_ROLES,
  WALLET_TYPES,
  BusinessVerificationStatus,
  PersonalVerificationStatus,
  FundPoolTypes,
  ACCOUNT_TYPES,
} from '~/types'

export type User = {
  kcId: string | undefined
  email: string
  mobileNumber: string
  countryCode: string
  companyId: string
  userRole?: USER_ROLES
  authServerUrl: string
  fullName: string
  twoFa: {
    isOnCoolingDownPeriod: boolean
    twoFaSetupTime: string
    twoFaList: {
      credential: {
        id: string
        type: string
        userLabel: string
        createdDate: number
        credentialData: Record<string, unknown>
      }
    }[]
  }
  isBitgoAddressAcknowledged?: boolean
  isFiatWalletOnly: boolean
}

export type Credential = {
  id: string
  type: string
  createdDate: number
  credentialData: string;
}

export type UserCredentialMetadata = {
  credential: Credential
}

export type Credentials = {
  type: string
  category: string
  displayName: string
  helptext: string
  iconCssClass: string
  updateAction?: string
  createAction?: string
  removeable: boolean
  userCredentialMetadatas: UserCredentialMetadata[]
}

export type Account = {
  id: WALLET_TYPES
  balance: string
  type: ACCOUNT_TYPES.BUSINESS | ACCOUNT_TYPES.PERSONAL | ACCOUNT_TYPES.CORPORATE
  name: 'General Wallet' | 'Digital Goods'
  formattedBalance: string
  settlementBalance?: string
  totalBalance?: string
  formattedAvailableBalance?: string
  formattedSettlementBalance?: string
  formattedTotalBalance?: string
  uniqueId: string
  accountId: number
  fundPoolId: number
}

export type WalletIdType = WALLET_TYPES.DIGITAL_GOODS | WALLET_TYPES.GENERAL | undefined

export type Wallet = {
  accountConfiguration: { minimumBalance: string } | undefined
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export type Notification = {
  id: number
  title: string
  message: string
  created_at: string
  is_read: number
  external_link: string
}

export type FeatureMatrix = {
  userRole: USER_ROLES | null
  country: COUNTRY
  availableWallets: WALLET_TYPES[]
  businessVerificationStatus: BusinessVerificationStatus
  personalVerificationStatus: PersonalVerificationStatus
  availableFundPools: FundPoolTypes[]
  companyTxnVolume: number
  isIdentificationDone: boolean
}

export type isUsingFiatCurrency = boolean

export enum ROUTE_ACTIONS {
  SET_REDIRECT = 'ROUTE_ACTIONS.SET_REDIRECT',
  SET_USER_PROFILE = 'ROUTE_ACTIONS.SET_USER_PROFILE',
  SET_NOTIFICATIONS = 'ROUTE_ACTIONS.SET_NOTIFICATIONS',
  TOGGLE_NOTIFICATIONS_LOADING = 'ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING',
  SET_FEATURE_MATRIX = 'ROUTE_ACTIONS.SET_FEATURE_MATRIX',
  INITIATE_GLOBAL_STORE = 'ROUTE_ACTIONS.INITIATE_GLOBAL_STORE',
  INVALIDATE_GLOBAL_STORE = 'ROUTE_ACTIONS.INVALIDATE_GLOBAL_STORE',
  SET_IS_CHANGE_TO_FIAT_CURRENCY_ENABLED = 'ROUTE_ACTIONS.SET_IS_CHANGE_TO_FIAT_CURRENCY_ENABLED',
  SET_IS_WELCOME_MODAL_OPENED = 'ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED',
  SET_IS_USER_INFO_INITIALISED = 'ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED',
  SET_IS_USER_HAS_PASSWORD = 'ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD',
}

type SetRedirect = {
  type: typeof ROUTE_ACTIONS.SET_REDIRECT
  to: PATHS | null
}

type SetUserProfile = {
  type: typeof ROUTE_ACTIONS.SET_USER_PROFILE
  user: User
}

type SetNotifications = {
  type: typeof ROUTE_ACTIONS.SET_NOTIFICATIONS
  data: Notification[]
  unreadCount: number
  isLoading: boolean
}

type ToggleNotificationsLoading = {
  type: typeof ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING
  isLoading: boolean
}

type SetFeatureMatrix = {
  type: typeof ROUTE_ACTIONS.SET_FEATURE_MATRIX
  featureMatrix: FeatureMatrix
}

type InitiateGlobalStore = {
  type: typeof ROUTE_ACTIONS.INITIATE_GLOBAL_STORE
  isInitiated: boolean
}

type InvalidateGlobalStore = {
  type: typeof ROUTE_ACTIONS.INVALIDATE_GLOBAL_STORE
  isInitiated: boolean
}

type SetChangeToFiatCurrency = {
  type: typeof ROUTE_ACTIONS.SET_IS_CHANGE_TO_FIAT_CURRENCY_ENABLED
  isUsingFiatCurrency: boolean
}

type SetIsWelcomeModalOpened = {
  type: typeof ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED
  enabled: boolean
}

type SetIsUserInfoInitialised = {
  type: typeof ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED
  isUserInfoInitialised: boolean
}

type SetIsUserHasPassword = {
  type: typeof ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD
  isUserHasPassword: boolean
}

export type ActionTypes =
  | SetRedirect
  | SetUserProfile
  | SetNotifications
  | ToggleNotificationsLoading
  | SetFeatureMatrix
  | InitiateGlobalStore
  | InvalidateGlobalStore
  | SetChangeToFiatCurrency
  | SetIsWelcomeModalOpened
  | SetIsUserInfoInitialised
  | SetIsUserHasPassword

import { PersonalVerificationStatus, BusinessVerificationStatus, BankAccount } from '~/types'

export enum STEPS {
  CHOOSE_BANK_AND_ACCOUNT_NUMBER = 1,
  CONFIRM_BANK_AND_ACCOUNT_NUMBER = 2,
  SUCCESSFULLY_ADDED_BANK_ACCOUNT = 3,
  ERROR_ADDING_BANK_ACCOUNT = 4,
  CHOOSE_VERIFY_METHODS = 5,
  VERIFY_WITH_BANK_STATEMENT = 6,
  SUCCESSFULLY_UPLOAD_BANK_STATEMENT = 7,
}

export type AddBankFlowProps = {
  isVAEnabled?: boolean
  bankAccountId?: number | undefined
  accountNumber?: string | undefined
  selectedBank?: string | undefined
  successCallback: (bankAccount: BankAccount) => void
  includeVerification: boolean
  personalVerificationStatus?: PersonalVerificationStatus
  businessVerificationStatus?: BusinessVerificationStatus | null
  onVerificationMethodNextButtonClick?: () => void
}

export type AddBankFlowComponentProps = {
  selectedBankAccount: BankAccount | undefined
}

export type State = {
  step: STEPS
  isVAEnabled: boolean
  bankAccountId: number | undefined
  accountNumber: string | undefined
  selectedBank: string | undefined
  successCallback: (bankAccount: BankAccount) => void
  includeVerification: boolean
  personalVerificationStatus: PersonalVerificationStatus
  businessVerificationStatus: BusinessVerificationStatus | null
  onVerificationMethodNextButtonClick: (() => void) | undefined
  newlyAddedBankAccount?: BankAccount
}

export enum ACTIONS {
  CONFIGURE_FLOW = 'CONFIGURE_FLOW',
  SET_STEP = 'SET_STEP',
  SET_BANK_ACCOUNT_ID = 'SET_BANK_ACCOUNT_ID',
  SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER',
  SET_SELECTED_BANK = 'SET_SELECTED_BANK',
}

export type ActionTypes = {
  type: ACTIONS
  step: STEPS
  selectedBank: string
  accountNumber: string
  bankAccountId: number
}

type ParamType = {
  initialTypes: {
    label: string
    value: string
  }[]
  additionalExcludeList?: string[]
}

const excludeList = [
  'Disbursement',
  'Disbursement Dash',
  'Invoice',
  'Payment Link',
  'Stablecoin Swap', // we separated swap txs by tab, tx type filter
]

// change the display label name on the FE
const labelMapper = {
  Deposit: 'Bank Transfer In',
  Withdrawal: 'Bank Transfer Out',
  'Stablecoin Deposit': 'Blockchain Transfer In',
  'Stablecoin Withdrawal': 'Blockchain Transfer Out',
}

export default function({ initialTypes, additionalExcludeList = [] }: ParamType) {
  const allExcludeList = [...excludeList, ...additionalExcludeList]
  return initialTypes
    .filter(option => !allExcludeList.includes(option.label))
    .map(option => {
      return {
        label: labelMapper[option.label as keyof typeof labelMapper] || option.label,
        value: option.value,
      }
    })
}

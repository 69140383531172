import { Reducer } from 'redux'

import { ActionTypes, TransactionDetails, STRAITSX_EARN_TRANSACTION_DETAILS_ACTIONS } from './types'

const initialState = {
  transactions: {},
}

type State = {
  transactions: Record<TransactionDetails['referenceId'], TransactionDetails>
}

export const straitsXEarnTransactionDetails: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case STRAITSX_EARN_TRANSACTION_DETAILS_ACTIONS.SET_TRANSACTION_DETAILS: {
      const { transactions } = state
      const transactionRefId = action.transactionDetails.referenceId
      transactions[transactionRefId] = action.transactionDetails

      return {
        ...state,
        transactions,
      }
    }

    default:
      return state
  }
}

export enum SIMULATE_DEPOSIT_ACTIONS {
  SET_IS_LOADING = 'SIMULATE_DEPOSIT_ACTIONS.SET_IS_LOADING',
  SET_DEPOSIT_REQUEST_ERROR = 'SIMULATE_DEPOSIT_ACTIONS.SET_DEPOSIT_REQUEST_ERROR',
  SET_DEPOSIT_AMOUNT = 'SIMULATE_DEPOSIT_ACTIONS.SET_DEPOSIT_AMOUNT',
  SET_ACCOUNT_ID = 'SIMULATE_DEPOSIT_ACTIONS.SET_ACCOUNT_ID',
}

type SetIsLoading = {
  type: typeof SIMULATE_DEPOSIT_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type SetDepositRequestError = {
  type: typeof SIMULATE_DEPOSIT_ACTIONS.SET_DEPOSIT_REQUEST_ERROR
  depositRequestError: string | null
}

type SetDepositAmount = {
  type: typeof SIMULATE_DEPOSIT_ACTIONS.SET_DEPOSIT_AMOUNT
  depositAmount: string
}

type SetAccountId = {
  type: typeof SIMULATE_DEPOSIT_ACTIONS.SET_ACCOUNT_ID
  accountId: number | null
}

export type ActionTypes = SetIsLoading | SetDepositRequestError | SetDepositAmount | SetAccountId

// we should deprecate this file once dashboard revamp has been fully launched
import React from 'react'
import { ThemeProvider, ModalProvider, straitsXTheme } from '@xfers/design-system'

export default ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={straitsXTheme}>
      <ModalProvider>{children}</ModalProvider>
    </ThemeProvider>
  )
}

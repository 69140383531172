import React, { useState, useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { BankFilled } from '@xfers/design-system/es/icons'
import { useModal, Modal, Button, Radio, BadgeIcon } from '@xfers/design-system'
import { createGaEvent } from '~/helpers/gaHelper'
import { AddBankFlowContext } from '~/components/AddBankFlow'
import { ConfigManager, COUNTRY, SITE_TYPE } from '~/managers/ConfigManager'

type VerifyMethod = 'deposit' | 'upload_bank_statement'

type Props = {
  goNext: (method: VerifyMethod, bankAccountId: number | undefined) => void
}

export default function({ goNext }: Props) {
  const { resetModal } = useModal()
  const [method, setMethod] = useState<VerifyMethod>()
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false)
  const [isStraitsXBizSite, setIsStraitsXBizSite] = useState<boolean>(false)
  const { state } = useContext(AddBankFlowContext)
  const {
    isVAEnabled,
    personalVerificationStatus,
    businessVerificationStatus,
    bankAccountId,
  } = state

  useEffect(() => {
    if (state.isVAEnabled) setMethod('upload_bank_statement')
    if (ConfigManager.isBizSite() && ConfigManager.getCountry() === COUNTRY.SINGAPORE) {
      setMethod('upload_bank_statement')
      setIsStraitsXBizSite(true)
    }

    const preventStraitsXUserToVerify =
      (ConfigManager.getSiteType() === SITE_TYPE.APP &&
        personalVerificationStatus !== 'verified') ||
      (isStraitsXBizSite && businessVerificationStatus !== 'verified')

    if (method === undefined || preventStraitsXUserToVerify) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
  }, [isVAEnabled, personalVerificationStatus, method])

  return (
    <Modal
      onClose={() => resetModal()}
      title="Add Bank Account"
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <>
          <Button type="secondary" onClick={() => resetModal()}>
            Close
          </Button>
          <Button
            type="primary"
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              if (method !== undefined) {
                createGaEvent(
                  'My Account',
                  `chooseVerificationMethod-<${method}>`,
                  `Choose Verify Method (${method.split('_').join(' ')})`
                )
                goNext(method, bankAccountId)
              }
            }}
            disabled={nextButtonDisabled}
          >
            Next
          </Button>
        </>
      }
    >
      <Container>
        {isVAEnabled || isStraitsXBizSite ? (
          <Text>
            To verify your bank account, please <b>upload your bank statement.</b>
          </Text>
        ) : (
          <Text>
            To verify your bank account, you can <b>make a transfer in</b> from the same account
            added or <b>upload your bank statement.</b>
          </Text>
        )}
        {!isVAEnabled && !isStraitsXBizSite && (
          <Radio.Group
            style={{
              width: '100%',
            }}
            value={method}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setMethod(e.target.value as VerifyMethod)
            }
          >
            <Radio.Button data-cy="radio-deposit" value="deposit" style={{ marginBottom: '10px' }}>
              Transfer In
            </Radio.Button>
            <Radio.Button data-cy="radio-uploadStatement" value="upload_bank_statement">
              Upload bank statement
            </Radio.Button>
          </Radio.Group>
        )}
        {method === 'deposit' && (
          <Alert>
            Please complete the transfer in request flow and make a bank transfer of any amount
            above S$1 to verify your account.
          </Alert>
        )}
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  padding-bottom: 30px;
`

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Alert = styled.div`
  background: #fffbe9;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8d7700;
  margin-top: 20px;
  padding: 15px;

  b {
    font-weight: 600;
  }
`

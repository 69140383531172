import { PERSONAL_VERIFICATION_STATUS } from '~/types'
import {
  JumioRetrievedDetails,
  CountryOfResidenceRetrievedDetails,
  DeclarationRetrievedDetails,
  ProofOfAddressRetrievedDetails,
} from '~/app/store/verification/international/types'

export enum KYC_TYPE {
  INTERNATIONAL = 'international',
  SG = 'singapore',
}

export enum SCREENS {
  // shared screens
  DECLARATIONS = 'DECLARATIONS',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  STATUS = 'STATUS',

  // International verification screens
  CHOOSE_COUNTRY = 'CHOOSE_COUNTRY',
  JUMIO = 'JUMIO',

  // SG verification screens
  CHOOSE_VERIFICATION_METHOD = 'CHOOSE_VERIFICATION_METHOD',
  ABOUT = 'ABOUT',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  ID_FRONT_AND_BACK = 'ID_FRONT_AND_BACK',
  SELFIE = 'SELFIE',
  MY_INFO = 'MY_INFO',
  MY_INFO_DETAILS = 'MY_INFO_DETAILS',
  NRIC_CHECK = 'NRIC_CHECK',
  CLAIM_KYC_ERROR = 'CLAIM_KYC_ERROR',
}

export enum VERIFICATION_ACTIONS {
  // shared actions
  SET_KYC_TYPE = 'VERIFICATION_ACTIONS.SET_KYC_TYPE',
  SET_SCREEN = 'VERIFICATION_ACTIONS.SET_SCREEN',
  SET_FORM_OPTIONS = 'SET_FORM_OPTIONS',
  SET_USER_DETAILS = 'VERIFICATION_ACTIONS.SET_USER_DETAILS',
  UPDATE_USER = 'VERIFICATION_ACTIONS.UPDATE_USER',
  SET_ERROR = 'VERIFICATION_ACTIONS.SET_ERROR',
  SET_KYC_REJECTED_REASON = 'VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON',
  SET_RETRIEVED_DETAILS = 'VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS',
  SET_IKYC_VERIFICATION_OPTIONS = 'VERIFICATION_ACTIONS.SET_IKYC_VERIFICATION_OPTIONS',

  // International verification actions
  SET_IKYC_RETRIEVED_DETAILS = 'VERIFICATION_ACTIONS.SET_IKYC_RETRIEVED_DETAILS',

  // SG verification flow actions
  SET_RESIDENT_STATUS = 'VERIFICATION_ACTIONS.SET_RESIDENT_STATUS',
  SET_FLOW = 'VERIFICATION_ACTIONS.SET_FLOW',
  SET_IS_RESIDENT = 'VERIFICATION_ACTIONS.SET_IS_RESIDENT',
  SET_WITH_ADDRESS = 'VERIFICATION_ACTIONS.SET_WITH_ADDRESS',
  SET_MULTIPLE_USER_DETAILS = 'VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS',
  SET_MY_INFO_ONLY_DETAILS = 'VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS',

  // Beforehand actions
  SET_COUNTRY_OF_RESIDENCE = 'VERIFICATION_ACTIONS.SET_COUNTRY_OF_RESIDENCE',
  SET_SG_VERIFICATION_METHOD = 'VERIFICATION_ACTIONS.SET_SG_VERIFICATION_METHOD',
}

export type RetrievedDetails = {
  userId: string
  verificationStatus: 'permanently rejected' | 'verified' | 'pending' | 'rejected' | 'initial'
  kycRejectedReason: string | { [key: string]: string }
  isAccountLocked: boolean

  isMyInfoFlow: boolean
  isElderly: boolean

  fullName: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfBirth: any
  countryOfBirth: string | undefined
  nationality: string | undefined
  gender: string | undefined
  addressLine1: string | undefined
  addressLine2: string | undefined
  country: string | undefined
  city: string | undefined
  postalCode: string | undefined
  idNumber: string | undefined
  nricType: 'nric' | 'fin' | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfIssue: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiryDate: any

  proofOfAddressUrl: string
  proofOfAddressUploaded: boolean

  selfieUrl: string
  selfieUploaded: boolean

  idFrontUrl: string
  idFrontUploaded: boolean

  idBackUrl: string
  idBackUploaded: boolean

  // additional personal detail items for manual (non-myinfo) verification
  occupation: string
  employmentSector: string
  employer: string
  annualIncomeRange: string

  // declaration
  expectedTransactionAmount: string
  expectedTotalTransaction: string
  expectedTransactionFrequency: string
  // additional declaration items for elderly
  purposeOfUsing: string
  investmentTradingExperience: string
  cryptoExperience: string
  sourceOfWealth: string
  sourceOfFunds: string
  bankAccountProofUrl: string
  bankAccountProofUploaded: boolean
}

export type ResidentStatus = 'sg_pr' | 'pass_holder' | undefined
export type SgVerificationFlows = 'my_info' | 'manual' | undefined
export type UserDetails = {
  fullName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfBirth?: any
  countryOfBirth?: string
  nationality?: string
  gender?: string
  addressLine1?: string
  addressLine2?: string
  country?: string
  city?: string
  postalCode?: string
  idNumber?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOfIssue?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expiryDate?: any
  occupation?: string
  passType?: string
  passStatus?: string
  passExpiryDate?: string
  employmentSector?: string
  employer?: string
  annualIncomeRange?: string
  expectedTransactionAmount?: string
  expectedTotalTransaction?: string
  expectedTransactionFrequency?: string
  businessIndustry: string
  purposeOfUsing?: string
  investmentTradingExperience?: string
  cryptoExperience?: string
  sourceOfWealth?: string
  sourceOfFunds?: string
}

export type MyInfoOnlyDetails = {
  race?: string
  residentialStatus?: string
  aliasName?: string
  hasValidExpiryDate?: boolean
  hasValidAddress?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  passStatusLastUpdated?: any
  error?: string
}

export type FileData = {
  fileData: string
  fileName: string
}

export type FormOptions = {
  countries: Array<{ label: string; value: string }>
  nationalities: Array<string>
  races: Array<{ label: string; value: string }>
  genders: Array<{ label: string; value: string }>
  occupations: Array<{ label: string; value: string }>
  employmentSectors: Array<string>
  annualIncomes: Array<string>
  expectedTransactionAmounts: Array<string>
  expectedTotalTransactions: Array<string>
  expectedTransactionFrequencies: Array<string>
  sourcesOfFunds: Array<string>
}

export type SelectOption = {
  label: string
  value: string
}
export type IkycVerificationOptions = {
  error?: boolean
  countries?: SelectOption[]
  jumioSdk?: {
    transactionId: string
    url: string
  }
  proofOfAddress?: {
    url: string
    key: string
  }
  declarations?: {
    expectedTransactionAmount: string[]
    expectedTotalTransaction: string[]
    expectedTransactionFrequency: string[]
    sourceOfFunds: string[]
    annualIncome: string[]
    occupation: string[]
    businessIndustry: string[]
  }
}

export type IkycRetrievedDetails = {
  verificationStatus: PERSONAL_VERIFICATION_STATUS
  externalId?: string
  kycExternalId?: string
  countryOfResidence?: CountryOfResidenceRetrievedDetails
  jumio?: JumioRetrievedDetails
  declaration?: DeclarationRetrievedDetails
  proofOfAddress?: ProofOfAddressRetrievedDetails
}

type SetResidentStatus = {
  type: typeof VERIFICATION_ACTIONS.SET_RESIDENT_STATUS
  status: ResidentStatus
}

type SetFlow = {
  type: typeof VERIFICATION_ACTIONS.SET_FLOW
  sgVerificationFlow: SgVerificationFlows
}

type SetUserDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_USER_DETAILS
  key: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

type UpdateUser = {
  type: typeof VERIFICATION_ACTIONS.UPDATE_USER
  error: string
}

type SetIsResident = {
  type: typeof VERIFICATION_ACTIONS.SET_IS_RESIDENT
  isResident: boolean
}

type SetWithAddress = {
  type: typeof VERIFICATION_ACTIONS.SET_WITH_ADDRESS
  withAddress: boolean
}

type SetScreen = {
  type: typeof VERIFICATION_ACTIONS.SET_SCREEN
  screen: SCREENS
}

type SetMultipleUserDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS
  userDetails: UserDetails
}

type SetKycRejectedReason = {
  type: typeof VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON
  kycRejectedReason: string | string[]
}

type SetRetrievedDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS
  retrievedDetails: RetrievedDetails
}

type SetError = {
  type: typeof VERIFICATION_ACTIONS.SET_ERROR
  error: string
}

type SetMyInfoOnlyDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS
  myInfoOnlyDetails: MyInfoOnlyDetails
}

type SetFormOptions = {
  type: typeof VERIFICATION_ACTIONS.SET_FORM_OPTIONS
  formOptions: FormOptions
}

type SetIkycVerificationOptions = {
  type: typeof VERIFICATION_ACTIONS.SET_IKYC_VERIFICATION_OPTIONS
  ikycVerificationOptions: IkycVerificationOptions
}

type SetIkycRetrievedDetails = {
  type: typeof VERIFICATION_ACTIONS.SET_IKYC_RETRIEVED_DETAILS
  ikycRetrievedDetails: IkycRetrievedDetails
}

type SetKycType = {
  type: typeof VERIFICATION_ACTIONS.SET_KYC_TYPE
  kycType: KYC_TYPE
}

type SetCountryOfResidence = {
  type: typeof VERIFICATION_ACTIONS.SET_COUNTRY_OF_RESIDENCE
  countryOfResidence: string
}

type SetSgVerificationMethod = {
  type: typeof VERIFICATION_ACTIONS.SET_SG_VERIFICATION_METHOD
  sgVerificationMethod: string
}

export type ActionTypes =
  | SetResidentStatus
  | SetFlow
  | SetUserDetails
  | UpdateUser
  | SetIsResident
  | SetWithAddress
  | SetScreen
  | SetMultipleUserDetails
  | SetKycRejectedReason
  | SetRetrievedDetails
  | SetError
  | SetMyInfoOnlyDetails
  | SetFormOptions
  | SetIkycVerificationOptions
  | SetIkycRetrievedDetails
  | SetKycType
  | SetCountryOfResidence
  | SetSgVerificationMethod

import { Reducer } from 'redux'
import { ActionTypes, BUSINESS_VERIFICATION_ACTIONS, State } from './types'

const initialState: State = {
  initialized: false,
  currentPath: undefined,

  businessType: undefined,
  businessLegalName: undefined,
  companyDisplayname: undefined,
  businessContactNo: undefined,
  businessRelations: [],
  businessWebsite: undefined,
  businessCategory: undefined,
  businessSubCategory: undefined,
  businessActivitySummary: undefined,
  expectedTransactionVolume: undefined,
  expectedGtv: undefined,

  isDeedOfEstablishmentUploaded: false,
  deedOfEstablishmentUrl: undefined,
  deedOfEstablishmentStatus: undefined,
  deedOfEstablishmentRejectionReason: undefined,
  isBusinessLicenseUploaded: false,
  businessLicenseUrl: undefined,
  businessLicenseStatus: undefined,
  businessLicenseRejectionReason: undefined,
  isSkKemenkumhamUploaded: false,
  skKemenkumhamUrl: undefined,
  skKemenkumhamStatus: undefined,
  skKemenkumhamRejectionReason: undefined,

  isBusinessCategoriesInitiated: false,
  businessCategoriesList: undefined,
  highRiskSubcategoriesList: undefined,

  isUploadURLsInitiated: false,
}

export const businessVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_VERIFICATION_ACTIONS.INIT_BUSINESS_DATA: {
      return {
        ...state,
        ...action.data,
        initialized: true,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_DATA: {
      return {
        ...state,
        [action.key]: action.value,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_DIRECT_URL: {
      return {
        ...state,
        ...action.urls,
        isUploadURLsInitiated: true,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_CURRENT_PATH: {
      return {
        ...state,
        currentPath: action.path,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_UPLOADING_DOCUMENT_ERROR: {
      return {
        ...state,
        uploadingDocumentError: action.error,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_CATEGORIES: {
      return {
        ...state,
        businessCategoriesList: action.businessCategoriesList,
        highRiskSubcategoriesList: action.highRiskSubcategoriesList,
        isBusinessCategoriesInitiated: true,
      }
    }

    default:
      return state
  }
}

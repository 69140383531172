import { Reducer } from 'redux'
import {
  FilterOptions,
  FilterValues,
  ActionTypes,
  Transaction,
  TRANSACTIONS_ACTIONS,
  CSV_FORMAT_VERSION,
  TRANSACTIONS_TAB_KEY,
  TRANSACTION_TYPE_TAB,
  FUNDING_TRANSACTION_TYPE_TAB,
} from './types'

const initialState = {
  filterOptions: null,
  filterOptionsInitiated: false,
  filterValues: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: {
      currencySymbol: null,
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      blockedReasons: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
      isTravelRule: false,
    },
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: {
      currencySymbol: null,
      startDate: '',
      endDate: '',
      transactionStatuses: [],
      transactionTypes: [],
      blockedReasons: [],
      sortType: '',
      accountIds: [],
      currentPage: 1,
      pageLimit: 10,
      totalCount: 0,
      searchString: '',
      contractsExist: false,
      transactions: undefined,
      isTxnLoading: false,
      isPaginationLoading: false,
      isTravelRule: false,
    },
  },
  latestPersonalTransactions: null,
  csvFormatVersion: null,
  transactionsTabKey: null,
  transactionTabOptions: [],
  viewType: '',
  transactionTypeTab: TRANSACTION_TYPE_TAB.FUNDING,
  fundingTransactionTypeTab: FUNDING_TRANSACTION_TYPE_TAB.ALL_FUNDING,
  travelRuleComplianceBlockerTotalCount: 0,
  isTransactionTimezoneControlled: false,
}

type State = {
  filterOptions: null | {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterOptions
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterOptions
  }
  filterValues: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterValues
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterValues
  }
  filterOptionsInitiated: boolean
  latestPersonalTransactions: Transaction[] | null
  csvFormatVersion: null | CSV_FORMAT_VERSION
  transactionsTabKey: null | TRANSACTIONS_TAB_KEY
  transactionTabOptions: TRANSACTIONS_TAB_KEY[]
  viewType: string
  transactionTypeTab: TRANSACTION_TYPE_TAB
  fundingTransactionTypeTab: FUNDING_TRANSACTION_TYPE_TAB
  travelRuleComplianceBlockerTotalCount: number | null
  isTransactionTimezoneControlled: boolean
}

export const transactions: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            isTxnLoading: action.isTxnLoading,
          },
        },
      }
    }

    case TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING: {
      return {
        ...state,
        isFilterLoading: action.isFilterLoading,
      }
    }

    case TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING: {
      return {
        ...state,
        isPaginationLoading: action.isPaginationLoading,
      }
    }

    case TRANSACTIONS_ACTIONS.GET_TRANSACTIONS: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            transactions: action.transactions,
            contractsExist: action.contractsExist,
            isTxnLoading: false,
          },
        },
      }
    }

    case TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            totalCount: action.totalCount,
            isPaginationLoading: false,
          },
        },
      }
    }

    case TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS: {
      return {
        ...state,
        filterOptions: action.filterOptions,
        filterOptionsInitiated: true,
      }
    }

    case TRANSACTIONS_ACTIONS.SET_FILTERS: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      const { filterValuesObject } = action

      const newTabFilterValues: FilterValues = {
        ...state.filterValues[transactionsTabKey],
      }

      Object.keys(filterValuesObject).forEach((key: string) => {
        const values = filterValuesObject[key]
        newTabFilterValues[key] = values
      })

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            ...newTabFilterValues,
          },
        },
      } as State
    }

    case TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE: {
      const { transactionsTabKey } = state

      if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')

      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          [transactionsTabKey]: {
            ...state.filterValues[transactionsTabKey],
            currentPage: 1,
          },
        },
      }
    }

    case TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION: {
      return {
        ...state,
        csvFormatVersion: action.csvFormatVersion,
      }
    }

    case TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB: {
      return {
        ...state,
        transactionsTabKey: action.transactionsTabKey,
      }
    }

    case TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS: {
      return {
        ...state,
        transactionTabOptions: action.transactionTabOptions,
      }
    }

    case TRANSACTIONS_ACTIONS.SET_LATEST_PERSONAL_TRANSACTIONS: {
      return {
        ...state,
        latestPersonalTransactions: action.latestPersonalTransactions,
      }
    }

    case TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE_TAB: {
      return {
        ...state,
        transactionTypeTab: action.transactionTypeTab,
      }
    }

    case TRANSACTIONS_ACTIONS.SET_FUNDING_TRANSACTION_TYPE_TAB: {
      return {
        ...state,
        fundingTransactionTypeTab: action.fundingTransactionTypeTab,
      }
    }

    case TRANSACTIONS_ACTIONS.GET_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT: {
      return {
        ...state,
        travelRuleComplianceBlockerTotalCount: action.travelRuleComplianceBlockerTotalCount,
      }
    }

    case TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_TIMEZONE_CONTROLLED: {
      return {
        ...state,
        isTransactionTimezoneControlled: action.isTransactionTimezoneControlled,
      }
    }

    default:
      return state
  }
}

import { createSelector } from 'reselect'
import { RootState } from '~/biz/store/types'
import { BlockchainAddress } from './types'
import { BlockchainNetwork, FINANCIAL_INSTITUTION_NAME, STABLECOIN } from '~/types'
import { EVM_COMPATIBLE_BLOCKCHAIN_NETWORKS } from '~/common/constants'

const selectBlockchainAddressStore = (state: RootState) => state.blockchainAddresses

const selectBlockchainAddressList = createSelector(
  [selectBlockchainAddressStore],
  store => store.blockchainAddressList
)

const selectIsBlockchainAddressesInitiated = createSelector(
  [selectBlockchainAddressStore],
  store => store.isBlockchainAddressListInitiated
)

const selectBlockchainFIList = createSelector([selectBlockchainAddressStore], store => store.fiList)

const selectMaxAddressAmount = createSelector(
  [selectBlockchainAddressStore],
  store => store.maxAddressAmount
)

const selectBlockchainAddressQuota = createSelector(
  [selectBlockchainAddressStore],
  store => store.addressQuota
)

const selectFinancialInstitutionName = createSelector(
  [selectBlockchainFIList],
  blockchainFIList => (financialInstitutionId: number | null, otherFiName: string | null) => {
    let name: string

    const initialNameSearch =
      blockchainFIList.find(
        (financialInstitution: { id: number }) => financialInstitution.id === financialInstitutionId
      )?.name || 'Personal Address (Non-Custodial)'

    if (initialNameSearch === FINANCIAL_INSTITUTION_NAME.OTHER) {
      name = otherFiName || initialNameSearch
    } else {
      name = initialNameSearch
    }

    return name
  }
)

const selectDistinctAddresses = createSelector(
  [selectBlockchainAddressList],
  blockchainAddressList => {
    const result: BlockchainAddress[] = []
    const tempObject: Record<string, boolean> = {}
    const compatibleBlockchainNetworksString = EVM_COMPATIBLE_BLOCKCHAIN_NETWORKS.join('-')

    blockchainAddressList.forEach(blockchainAddress => {
      let blockchainNetworkString = blockchainAddress.blockchain as string
      if (compatibleBlockchainNetworksString.includes(blockchainAddress.blockchain)) {
        blockchainNetworkString = compatibleBlockchainNetworksString
      }

      const tempObjKey = `${blockchainNetworkString}_${blockchainAddress.address}`
      if (tempObjKey in tempObject) return // go on to next iteration
      tempObject[tempObjKey] = true
      result.push(blockchainAddress)
    })

    return result
  }
)

const selectAddressesByBlockchainAndCurrency = createSelector(
  [
    selectBlockchainAddressList,
    (_state: RootState, blockchain: BlockchainNetwork | null, currency: STABLECOIN | null) => ({
      blockchain,
      currency,
    }),
  ],
  (blockchainAddressList, { blockchain, currency }) => {
    if (!blockchain || !currency) return []
    const result = blockchainAddressList.filter(
      b => b.blockchain === blockchain && b.blockchainCurrency === currency
    )
    return result
  }
)

export {
  selectBlockchainAddressStore,
  selectBlockchainAddressList,
  selectBlockchainFIList,
  selectMaxAddressAmount,
  selectBlockchainAddressQuota,
  selectIsBlockchainAddressesInitiated,
  selectDistinctAddresses,
  selectFinancialInstitutionName,
  selectAddressesByBlockchainAndCurrency,
}

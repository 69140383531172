import { ENDPOINTS, put } from '~/api'

type Props =  {
  successCallback?: () => void
}

const UserEndpoints = {
  RiskModalTncAccepted: ENDPOINTS.API_V3_USER_RISK_MODAL_TNC_ACCEPTED,
  Dpt2ModalTncAccepted: ENDPOINTS.API_V3_USER_DPT2_MODAL_TNC_ACCEPTED
}

const putRiskModalTncAccepted = ({ successCallback }: Props) => put(UserEndpoints.RiskModalTncAccepted, {})
  .then(() => {
    if (successCallback) {
      successCallback()
    }
  })

const putDpt2ModalTncAccepted = ({ successCallback }: Props) => put(UserEndpoints.Dpt2ModalTncAccepted, {})
  .then(() => {
      if (successCallback) {
        successCallback()
      }
  })

export { putRiskModalTncAccepted, putDpt2ModalTncAccepted }

/* eslint-disable max-lines */
import { Dispatch, GetState } from '~/biz/store/types'
import {
  ActionTypes,
  STRAITSX_LANDING_PAGE_ACTIONS,
  TRANSFER_METHOD,
  BankTransferNetwork,
  Blockchain,
  StablecoinFee,
  WithdrawWalletIdType,
} from './types'
import { get, post, ENDPOINTS, put } from '~/api'
import { ACCOUNT_TYPES, STABLECOIN, CURRENCY, BankAccount } from '~/types'
import { Account } from '~/biz/store/accounts/types'
import { toLocaleBalance } from '~/helpers/localeHelper'
import { formatDgwAmount, getSGCurrency, formatComma } from '~/helpers/helpers'
import { shouldConvertToLocalAmount } from '~/helpers/stablecoinHelpers'
import {
  selectDgwMerchantAccountLimitByCurrency,
  selectIsLinkedToAsb,
} from '~/biz/store/accounts/selectors'
import { WITHDRAW_LIMITS } from './const'
import { BlockchainAddress } from '~/biz/store/blockchainAddresses/types'
import { FLIPPER_KEYS } from '~/store/flippers/const'
import { selectFlipperWithId } from '~/store/flippers/selectors'
import { FEE } from '~/biz/store/straitsXLandingPage/const'
import { fetchUserInfo } from '~/biz/store/merchantInfo/actions'

export const setIsXsgdZrc2DepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED,
    isXsgdZrc2DepositEnabled: enabled,
  })
}

export const setIsXsgdErc20DepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED,
    isXsgdErc20DepositEnabled: enabled,
  })
}

export const setIsXsgdHtsDepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_DEPOSIT_ENABLED,
    isXsgdHtsDepositEnabled: enabled,
  })
}

export const setIsXsgdMaticDepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED,
    isXsgdMaticDepositEnabled: enabled,
  })
}

export const setIsXsgdAvaxDepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVAX_DEPOSIT_ENABLED,
    isXsgdAvaxDepositEnabled: enabled,
  })
}

export const setIsUsdtTrc20DepositEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRC20_DEPOSIT_ENABLED,
    isUsdtTrc20DepositEnabled: enabled,
  })
}

export const setIsXsgdZrc2WithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED,
    isXsgdZrc2WithdrawalEnabled: enabled,
  })
}

export const setIsXsgdErc20WithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED,
    isXsgdErc20WithdrawalEnabled: enabled,
  })
}

export const setIsXsgdHtsWithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_ENABLED,
    isXsgdHtsWithdrawalEnabled: enabled,
  })
}

export const setIsXsgdMaticWithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED,
    isXsgdMaticWithdrawalEnabled: enabled,
  })
}

export const setIsXsgdAvaxWithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVAX_WITHDRAWAL_ENABLED,
    isXsgdAvaxWithdrawalEnabled: enabled,
  })
}

export const setIsUsdtTrc20WithdrawalEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRC20_WITHDRAWAL_ENABLED,
    isUsdtTrc20WithdrawalEnabled: enabled,
  })
}

export const setIsDashboardBannerManagementEnabled = (isEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED,
    isDashboardBannerManagementEnabled: isEnabled,
  })
}

export const getDashboardBanners = (site: string) => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_DASHBOARD_BANNERS, `?site=${site}`).then(resp => {
    dispatch({
      type: STRAITSX_LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS,
      dashboardBanners: resp.reverse(),
    })
  })
}

export const setTab = (tab: string) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(setTransferMethod(null))
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_TAB,
    tab,
  })
}

export const setTransferMethod = (transferMethod: TRANSFER_METHOD | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setBankTransferNetwork(null))
  dispatch(setSelectedBlockchain(null))
  dispatch(setSelectedBankAccount(null))
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD,
    transferMethod,
  })
}

export const setBankTransferNetwork = (bankTransferNetwork: BankTransferNetwork | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK,
    bankTransferNetwork,
  })
}

export const setSelectedCurrency = (currency: STABLECOIN | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setBankTransferNetwork(null))
  dispatch(setSelectedBlockchain(null))
  dispatch(setSelectedBankAccount(null))
  dispatch(setSelectedBlockchainAddress(null))
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_CURRENCY,
    selectedCurrency: currency,
  })
}

export const setSelectedBlockchain = (blockchain: Blockchain | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setSelectedBlockchainAddress(null))
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN,
    selectedBlockchain: blockchain,
  })
}

export const setSelectedBankAccount = (bankAccount: BankAccount | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT,
    selectedBankAccount: bankAccount,
  })
}

export const setSelectedBlockchainAddress = (blockchainAddress: BlockchainAddress | null) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS,
    selectedBlockchainAddress: blockchainAddress,
  })
}

export const setWithdrawAmount = (amount: string) => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const currentState = getState()
  const {
    selectedCurrency,
    selectedBlockchainAddress,
    withdrawWalletId,
    isWithdrawalAutomatic,
  } = getState().straitsXLandingPage
  const { isUsingFiatCurrency } = getState().route

  if (!selectedCurrency) return

  const accounts = currentState.accounts.accountList || []
  const account = accounts.find(
    acc => acc.id === withdrawWalletId && acc.currency === selectedCurrency
  )
  const accountLimits = selectDgwMerchantAccountLimitByCurrency(currentState)(selectedCurrency)
  const LIMITS = WITHDRAW_LIMITS[selectedCurrency]

  const withdrawDailyRemaining = accountLimits?.withdrawalDailyRemaining || '0'

  if (accounts.length === 0 || !account) {
    return
  }

  const amountFloat = parseFloat(amount)
  const withdrawDailyRemainingFloat = parseFloat(withdrawDailyRemaining)

  const { isWhitelistingImprovementEnabled } = currentState.blockchainAddresses
  let stablecoinWithdrawRemaining: number =
    selectedBlockchainAddress?.remainingOfWithdrawalLimit ?? Infinity
  // TODO: remove this if block once isWhitelistingImprovementEnabled is enabled permanently
  if (!isWhitelistingImprovementEnabled && selectedCurrency === CURRENCY.USDC) {
    // set 50 for unapproved for now until whitelisted improvement has been implemented
    stablecoinWithdrawRemaining = selectedBlockchainAddress?.approved ? Infinity : 50
  }

  const stablecoinMaxWithdrawalLimit =
    selectedBlockchainAddress?.maxOfPerTransactionLimit || LIMITS.STABLECOIN_MAX

  const walletBalanceFloat = account ? parseFloat(account.balance) : 0
  const currencyLabel = selectedCurrency.toUpperCase()
  const minimumWalletBalance = () => {
    if (selectedCurrency !== CURRENCY.XSGD) return '0'

    return account.accountConfiguration ? account.accountConfiguration.minimumBalance : '10000' // default limit for XSGD
  }
  const minimumWalletBalanceFloat = parseFloat(minimumWalletBalance())

  const hasExceededMinimumWalletBalance =
    walletBalanceFloat - amountFloat < minimumWalletBalanceFloat

  let withdrawAmountError = ''
  if (selectedBlockchainAddress) {
    if (amountFloat > stablecoinMaxWithdrawalLimit) {
      withdrawAmountError = `You have exceeded the ${formatDgwAmount({
        amount: stablecoinMaxWithdrawalLimit,
        withCurrency: selectedCurrency,
      })} transfer out limit per transaction, please transfer out in multiple transactions.`
    } else if (amountFloat > stablecoinWithdrawRemaining) {
      withdrawAmountError = 'Your input amount exceeds your remaining transfer out limit.'
    } else if (amountFloat < LIMITS.STABLECOIN_MIN) {
      withdrawAmountError = `You need to transfer out a minimum of ${LIMITS.STABLECOIN_MIN} ${currencyLabel}.`
    } else if (amountFloat > walletBalanceFloat) {
      withdrawAmountError = 'Your input amount exceeds your account balance.'
    } else if (account.type === ACCOUNT_TYPES.BUSINESS && hasExceededMinimumWalletBalance) {
      withdrawAmountError = `Change to a lower withdrawal amount, as your account balance cannot be less than ${formatDgwAmount(
        {
          amount: minimumWalletBalanceFloat,
          withCurrency: selectedCurrency,
        }
      )}.`
    }
  } else {
    /* eslint-disable no-lonely-if */
    if (isWithdrawalAutomatic && amountFloat > LIMITS.FIAT_MAX) {
      withdrawAmountError =
        `You have exceeded the ${formatComma(LIMITS.FIAT_MAX)} ` +
        (isUsingFiatCurrency ? getSGCurrency(isUsingFiatCurrency) : currencyLabel) +
        ' withdrawal limit per transaction (including fee), please process the withdrawal in multiple transactions.'
    } else if (amountFloat < LIMITS.FIAT_MIN && walletBalanceFloat >= LIMITS.FIAT_MIN) {
      let amountWithCurrencyLabel: string
      if (shouldConvertToLocalAmount(selectedCurrency)) {
        amountWithCurrencyLabel = toLocaleBalance({
          balance: LIMITS.FIAT_MIN,
        })
      } else {
        amountWithCurrencyLabel = `${currencyLabel} ${LIMITS.FIAT_MIN}`
      }
      withdrawAmountError = `You need to transfer out a minimum of ${amountWithCurrencyLabel}.`
    } else if (account?.type === ACCOUNT_TYPES.BUSINESS && hasExceededMinimumWalletBalance) {
      let amountWithCurrencyLabel: string
      if (shouldConvertToLocalAmount(selectedCurrency)) {
        amountWithCurrencyLabel = toLocaleBalance({
          balance: minimumWalletBalanceFloat,
        })
      } else {
        amountWithCurrencyLabel = `${currencyLabel} ${minimumWalletBalanceFloat}`
      }
      withdrawAmountError = `Change to a lower withdrawal amount, as your account balance cannot be less than ${amountWithCurrencyLabel} (including fee).`
    }

    // XSGD
    if (selectedCurrency === CURRENCY.XSGD) {
      if (amountFloat > walletBalanceFloat) {
        withdrawAmountError = 'Your input amount (including fee) exceeds your account balance.'
      }
    }

    // USDC
    if (selectedCurrency === CURRENCY.USDC || selectedCurrency === CURRENCY.XUSD) {
      if (
        isWithdrawalAutomatic &&
        withdrawDailyRemainingFloat !== undefined &&
        amountFloat > withdrawDailyRemainingFloat
      ) {
        withdrawAmountError = 'Your input amount exceeds your bank daily withdrawal limit.'
      } else if (amountFloat > walletBalanceFloat) {
        withdrawAmountError = 'Your input amount exceeds your account balance.'
      }
    }
  }

  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT,
    amount: amountFloat.toString(),
    withdrawAmountError,
  })
}

export const setWithdrawWalletId = (withdrawWalletId: WithdrawWalletIdType) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID,
    withdrawWalletId,
  })
}

export const setIsHtsWithdrawalFeeCharging = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_FEE_CHARGING,
    isHtsWithdrawalFeeCharging: enabled,
  })
}

export const setHasFetchingFeeError = (hasError: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR,
    hasError,
  })
}

export const setIsFetchingFee = (isFetching: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING,
    isStablecoinFeeLoading: isFetching,
  })
}

export const fetchStablecoinFee = ({
  accountId,
  selectedBlockchainAddress,
  withdrawAmount,
}: {
  accountId?: number
  selectedBlockchainAddress: BlockchainAddress
  withdrawAmount: string
}) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(setHasFetchingFeeError(false))
  dispatch(setIsFetchingFee(true))

  const subRoute = `?account_id=${accountId}&amount=${withdrawAmount}&blockchain=${selectedBlockchainAddress.blockchain}`
  get(ENDPOINTS.API_V3_STABLECOIN_FEES, subRoute)
    .then(resp => {
      dispatch({
        type: STRAITSX_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE,
        amount: Number(resp.data.amount),
        identifier: resp.data.identifier,
        timestamp: resp.data.timestamp,
      })
    })
    .catch(err => {
      const message: string | undefined = err.response?.data?.error
      if (message && message.includes("can't get estimated fee")) {
        dispatch(setIsFetchingFee(false))
        dispatch(setHasFetchingFeeError(true))
      }
    })
}

const submitStablecoinWithdrawal = (
  requestBody: {
    google_auth_code?: string
    blockchain: string
    amount: number
    address: string
    address_custodial_type: string
    identifier?: string | undefined
  },
  accountId: number,
  redirectCallback: () => void,
  setLoading?: (loading: boolean) => void
) => (dispatch: Dispatch<ActionTypes>) => {
  const subRoute = `/${accountId}`
  post(ENDPOINTS.API_V3_STABLECOIN_WITHDRAWALS, requestBody, {}, subRoute)
    .then(() => {
      dispatch({
        type: STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: '',
      })
      redirectCallback()
    })
    .catch(err => {
      const message = err.response.data.error || 'Error'
      dispatch({
        type: STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: message,
      })
      // Note: Keep 2FA modal open if 2FA code fails
      if (message.includes('google_auth_code')) {
        dispatch({
          type: STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: true,
        })
      } else {
        dispatch({
          type: STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: false,
        })
        redirectCallback()
      }
      if (setLoading) setLoading(false)
    })
}

export const verifyStablecoin2Fa = ({
  authCode,
  selectedBlockchainAddress,
  withdrawAmount,
  stablecoinFee,
  accountId,
  redirectCallback,
  setLoading,
}: {
  authCode: string
  selectedBlockchainAddress: BlockchainAddress
  withdrawAmount: string
  stablecoinFee: StablecoinFee
  accountId: number
  redirectCallback: () => void
  setLoading: (loading: boolean) => void
}) => (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  // a temp solution to handle fee changing issue before we have fee identifier on ASB
  const isLinkedToAsb = selectIsLinkedToAsb(getState())
  const { email } = getState().route.user
  const flipperPassingTotalAmount = selectFlipperWithId(
    FLIPPER_KEYS.ASB_FE_TO_PASS_TOTAL_AMOUNT_FOR_STABLECOIN_WITHDRAWAL,
    email
  )(getState())
  const isPassingTotalAmount = isLinkedToAsb && !!flipperPassingTotalAmount?.enabled

  // the `withdrawAmount` allows 2 digit at most, so we can handle float point issue by rounding to 2 decimal places
  const fixedDigitsAmount = (Number(withdrawAmount) - (stablecoinFee.amount || 0)).toFixed(2)

  const requestBody = {
    google_auth_code: authCode,
    blockchain: selectedBlockchainAddress.blockchain,
    amount: isPassingTotalAmount ? Number(withdrawAmount) : Number(fixedDigitsAmount),
    address: selectedBlockchainAddress.address,
    address_custodial_type: selectedBlockchainAddress.financialInstitutionId
      ? 'custodial'
      : 'non_custodial',
    identifier: stablecoinFee.identifier,
  }
  dispatch(submitStablecoinWithdrawal(requestBody, accountId, redirectCallback, setLoading))
}

export const openConfirmModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN,
    open: true,
  })
}

export const closeConfirmModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN,
    open: false,
  })
}

export const proceedBankConfirmation = ({
  has2FaEnabled,
  accountId,
  withdrawAmount,
  withdrawToBankId,
  withdrawWalletId,
  redirectCallback,
  currency,
  openTwoFaModal,
}: {
  has2FaEnabled: boolean
  accountId: number
  withdrawAmount: string
  withdrawToBankId: number
  withdrawWalletId: WithdrawWalletIdType
  redirectCallback: () => void
  currency: STABLECOIN
  openTwoFaModal: () => void
}) => (dispatch: Dispatch<ActionTypes>) => {
  if (has2FaEnabled) {
    if (openTwoFaModal) {
      openTwoFaModal()
    }
  } else {
    const requestBody = {
      amount: withdrawAmount,
      wallet_id: withdrawWalletId,
      account_id: accountId,
      currency,
    }
    const additionalHeaders = {}
    const subRoute = `/${withdrawToBankId}/withdraw`

    post(ENDPOINTS.API_V3_USER_BANK_ACCOUNT, requestBody, additionalHeaders, subRoute)
      .then(() => {
        dispatch({
          type: STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
          error: '',
        })
        redirectCallback()
      })
      .catch(err => {
        const message = err.response.data.error
        dispatch({
          type: STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
          error: message,
        })
        redirectCallback()
      })
  }
}

export const verify2Fa = ({
  authCode,
  accountId,
  withdrawAmount,
  withdrawToBankId,
  withdrawWalletId,
  redirectCallback,
  setLoading,
  currency,
}: {
  authCode: string
  accountId: number
  withdrawAmount: string
  withdrawToBankId: number
  withdrawWalletId: WithdrawWalletIdType
  redirectCallback: () => void
  setLoading: (loading: boolean) => void
  currency: STABLECOIN
}) => (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  let amount = Number(withdrawAmount)
  const isLinkedToAsb = selectIsLinkedToAsb(getState())
  if (!isLinkedToAsb) amount -= FEE.SGD_FIAT_DEFAULT

  const requestBody = {
    amount,
    wallet_id: withdrawWalletId,
    google_auth_code: authCode,
    account_id: accountId,
    currency,
  }

  const additionalHeaders = {}
  const subRoute = `/${withdrawToBankId}/withdraw`

  post(ENDPOINTS.API_V3_USER_BANK_ACCOUNT, requestBody, additionalHeaders, subRoute)
    .then(() => {
      dispatch({
        type: STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: '',
      })
      redirectCallback()
      setLoading(false)
    })
    .catch(err => {
      const message = err.response.data.error
      dispatch({
        type: STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST,
        error: message,
      })
      // Note: Keep 2FA modal open if 2FA code fails
      if (message.includes('google_auth_code')) {
        dispatch({
          type: STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: true,
        })
      } else {
        dispatch({
          type: STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
          open: false,
        })
        redirectCallback()
      }
      setLoading(false)
    })
}

export const open2FaModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(closeConfirmModal())
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
    open: true,
  })
}

export const close2FaModal = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN,
    open: false,
  })
}

export const resetTransferMethod = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch(close2FaModal())
  dispatch(closeConfirmModal())
  dispatch(setTransferMethod(null))
  dispatch(setSelectedCurrency(null))
}

export const flagVisitedGettingStarted = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED,
    value: true,
  })
}

export const setIsWithdrawalAutomatic = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_WITHDRAWAL_AUTOMATIC,
    isWithdrawalAutomatic: enabled,
  })
}

export const setCurrentMerchantAccountId = (id: number) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_CURRENT_MERCHANT_ACCOUNT_ID,
    currentMerchantAccountId: id,
  })
}

export const setIsHederaUIEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HEDERA_UI_ENABLED,
    isHederaUIEnabled: enabled,
  })
}

export const setIsPolygonUIEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_POLYGON_UI_ENABLED,
    isPolygonUIEnabled: enabled,
  })
}

export const setIsAvalancheUIEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVALANCHE_UI_ENABLED,
    isAvalancheUIEnabled: enabled,
  })
}

export const setIsTronUIEnabled = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRON_UI_ENABLED,
    isTronUIEnabled: enabled,
  })
}

export const setIsSsoTwoFaAuthenticatorEnabled = (enabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_SSO_TWO_FA_AUTHENTICATED_ENABLED,
    isSsoTwoFaAuthenticatorEnabled: enabled,
  })
}

export const setIsDptRiskModalEnabled = (isDptRiskModalEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_DPT_RISK_MODAL_ENABLED,
    isDptRiskModalEnabled,
  })
}

export const setIsFazzVaForDashboardEnabled = (isFazzVaForDashboardEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED,
    isFazzVaForDashboardEnabled,
  })
}

export const setIsTravelRuleDepositDeclarationEnabled = (
  isTravelRuleDepositDeclarationEnabled: boolean
) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED,
    isTravelRuleDepositDeclarationEnabled,
  })
}

export const setIsTravelRuleAddrWhitelistingEnabled = (
  isTravelRuleAddrWhitelistingEnabled: boolean
) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED,
    isTravelRuleAddrWhitelistingEnabled,
  })
}

export const applyUsdVirtualAccount = (
  accountId: number | undefined,
  onSuccess: () => void,
  onError: () => void
) => (dispatch: Dispatch<ActionTypes>) => {
  const requestBody = { account_id: accountId }
  post(ENDPOINTS.API_V3_STABLECOIN_VIRTUAL_ACCOUNT, requestBody)
    .then(() => {
      dispatch(fetchUserInfo(onSuccess))
    })
    .catch(() => {
      onError()
    })
}

export const getFiatFee = (amount: number, account: Account, successCallback?: () => void) => (
  dispatch: Dispatch<ActionTypes>
) => {
  const subRoute = `?amount=${amount}&account_id=${account.accountId}`
  get(ENDPOINTS.API_V3_STABLECOIN_FEES_FIAT, subRoute).then(res => {
    dispatch({
      type: STRAITSX_LANDING_PAGE_ACTIONS.SET_FIAT_FEE,
      stablecoin: account.currency,
      amount: Number(res.amount),
      feeType: res.type,
      rate: Number(res.rate),
      maxAmount: Number(res.max_amount),
      minAmount: Number(res.min_amount),
    })
    if (successCallback) successCallback()
  })
}

export const setIsRenewAddressAcknowledged = (isRenewAddressAcknowledged: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED,
    isRenewAddressAcknowledged,
  })
}

export const acknowledgeBitgoAddress = () => () => {
  put(ENDPOINTS.API_V3_ACKNOWLEDGE_NEW_BITGO_ADDRESS, {}, {})
}

export const setXusdCoachmarkActive = (isXusdCoachmarkActive: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_XUSD_COACHMARK_ACTIVE,
    isXusdCoachmarkActive,
  })
}

import { FLIPPER_ACTIONS } from './const'

// DATA TYPES
export enum FLIPPER_STATUS {
  REQUESTED = 'REQUESTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export type Flipper = {
  featureKey: string
  flipperId?: string
  enabled?: boolean
  status: FLIPPER_STATUS
}

export type State = {
  flippers: Flipper[]
}
// END DATA TYPES

// ACTION TYPES
type FetchFlipperItemRequested = {
  type: typeof FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_REQUESTED
  featureKey: string
  flipperId?: string
}

type FetchFlipperItemSucceeded = {
  type: typeof FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_SUCCEEDED
  featureKey: string
  flipperId?: string
  enabled: boolean
}

type FetchFlipperItemFailed = {
  type: typeof FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_FAILED
  featureKey: string
  flipperId?: string
}

export type ActionTypes =
  | FetchFlipperItemRequested
  | FetchFlipperItemSucceeded
  | FetchFlipperItemFailed
// END ACTION TYPES

import React from 'react'
import StraitsXEarnBreadcrumbBar from '~/components/StraitsXEarnBreadcrumbBar'

function withStraitsXBreadcrumbBarAndLink(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WrappedComponent: React.ComponentType<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: React.ComponentType<any>
) {
  return class extends React.Component {
    render() {
      return (
        <>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <StraitsXEarnBreadcrumbBar {...this.props}>
            <Component />
          </StraitsXEarnBreadcrumbBar>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...this.props} />
        </>
      )
    }
  }
}

export default withStraitsXBreadcrumbBarAndLink

import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, SmallP, Link } from '@xfers/design-system'
import { ConfigManager } from '~/managers/ConfigManager'
import { SUPPORT_LINKS } from '~/common/constants'

enum ADVISORY_TYPE {
  DEFAULT = 'default',
  EARN = 'earn',
}

const XSGDAdvisory = () => {
  const DefaultAdvisory = (
    <Advisory>
      <div>
        <p>XSGD, XUSD and XIDR are issued by StraitsX.</p>
        <p>
          “STRAITSX”, "XSGD" and "XIDR" and all other URLs, logos and trademarks related to the
          StraitsX Services are either trademarks or registered trademarks of StraitsX or its
          licensors.
        </p>
        <p>
          StraitsX is the trading name of the StraitsX Group of Companies and its affiliated
          entities.
        </p>
      </div>
      <div>
        <p>
          Important Risk Warnings Regarding Digital Payment Tokens:{' '}
          <Link
            size="small"
            href={SUPPORT_LINKS.TERMS_OF_SERVICE}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </Link>
        </p>
      </div>
    </Advisory>
  )
  const EarnAdvisory = (
    <Advisory>
      <p>
        Consumer advisory - StraitsX Earn is a product offered by Xfers Invest Pte. Ltd. (”XIPL”),
        which is an affiliate of Xfers Pte. Ltd. XIPL is not licensed or regulated by the Monetary
        Authority of Singapore (”MAS”). MAS does not endorse StraitsX Earn or any other
        service/product offered by XIPL. StraitsX Earn is not a risk-free product. Funds can be lost
        due to risks associated with decentralised finance protocols, such as smart contract risks,
        protocol failures, hacks from malicious attackers and other similar risks. Users are advised
        to read our{' '}
        <Link
          size="small"
          href="https://www.straitsx.com/sg/earn-terms-of-service"
          rel="noopener noreferrer"
          target="_blank"
        >
          terms of service
        </Link>{' '}
        carefully.
      </p>
    </Advisory>
  )

  const history = useHistory()
  const currentPath = history.location.pathname

  const advisoryType = (() => {
    switch (currentPath) {
      case RegExp(/earn/).exec(currentPath)?.input:
        return ADVISORY_TYPE.EARN
      default:
        return ADVISORY_TYPE.DEFAULT
    }
  })()
  const SelectedAdvisory = (() => {
    switch (advisoryType) {
      case ADVISORY_TYPE.EARN:
        return EarnAdvisory
      default:
        return DefaultAdvisory
    }
  })()

  return SelectedAdvisory
}

const XIDRAdvisory = () => (
  <Advisory>
    <p>XSGD, XUSD and XIDR are issued by StraitsX.</p>
    <p>
      “STRAITSX”, "XSGD" and "XIDR" and all other URLs, logos and trademarks related to the StraitsX
      Services are either trademarks or registered trademarks of StraitsX or its licensors.
    </p>
    <p>
      StraitsX is the trading name of the StraitsX Group of Companies and its affiliated entities.
    </p>
  </Advisory>
)

const Footer = () => (
  <FooterContainer>
    {ConfigManager.isXSGDSite() && <XSGDAdvisory />}
    {ConfigManager.isXIDRSite() && <XIDRAdvisory />}
  </FooterContainer>
)

export default Footer

const FooterContainer = styled(SmallP)`
  min-width: 100%;
  flex-grow: 1;
  color: #777777;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${DESKTOP_BREAKPOINT} {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 24px;
  }
`

const Advisory = styled(SmallP)`
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const FooterNumber = styled.ol`
  padding-left: 20px;
  text-align: justify;
`

const ListLowerAlhpa = styled.ol`
  list-style-type: lower-alpha;
  padding-left: 20px;
  text-align: justify;
`

import { BlockchainNetwork, CURRENCY } from '~/types'

export const getBankTransferInfoKey = (currency: CURRENCY, transferInfoIdentifier: number) => {
  switch (currency) {
    case CURRENCY.USDC:
      return `${currency}__${transferInfoIdentifier}`
    default:
      return currency
  }
}

export const getBlockchainTransferInfoKey = (
  currency: CURRENCY,
  blockchainNetwork: BlockchainNetwork
) => `${currency}__${blockchainNetwork}`

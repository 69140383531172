import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { BankFilled } from '@xfers/design-system/es/icons'
import { useModal, useTheme, Modal, Button, BadgeIcon } from '@xfers/design-system'
import { createGaEvent } from '~/helpers/gaHelper'

import { post, ENDPOINTS } from '~/api'
import { AddBankFlowContext } from '~/components/AddBankFlow'
import { setBankAccountId } from '~/components/AddBankFlow/actions'
import { BankAccount } from '~/types'
import { keysToCamel } from '~/helpers/helpers'

type Props = {
  goBack: () => void
  goNext: (success: boolean, bankAccount?: BankAccount) => void
}

export default function({ goBack, goNext }: Props) {
  const [loading, setLoading] = useState(false)
  const { resetModal } = useModal()
  const { brand } = useTheme()
  const { state, dispatch } = useContext(AddBankFlowContext)
  const { accountNumber, selectedBank } = state

  const addBankAccount = () => {
    setLoading(true)
    const requestBody = {
      account_no: accountNumber,
      bank: selectedBank,
    }

    post(ENDPOINTS.API_V3_USER_BANK_ACCOUNT, requestBody)
      .then(resp => {
        setLoading(false)
        goNext(true, keysToCamel(resp[0]))
        dispatch(setBankAccountId(resp[0].id))
      })
      .catch(() => {
        setLoading(false)
        goNext(false)
      })
  }

  return (
    <Modal
      onClose={() => resetModal()}
      title="Add Bank Account"
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <>
          <Button type="secondary" onClick={() => goBack()}>
            Back
          </Button>
          <Button
            data-cy="button-addAccountConfirm"
            type="primary"
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              createGaEvent('My Account', 'confirmBankAndAccount', 'Confirm Bank and Bank Account')
              addBankAccount()
            }}
            loading={loading}
          >
            Next
          </Button>
        </>
      }
    >
      <Text>Please confirm your bank account details.</Text>
      <InfoRow>
        <InfoLeft>Bank Name</InfoLeft>
        <InfoRight data-cy="text-bankNameInfo">
          <BankFilled
            width={18}
            height={18}
            color={brand.primary.default}
            style={{
              marginRight: '5px',
              verticalAlign: 'middle',
            }}
          />
          {selectedBank}
        </InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Account Number</InfoLeft>
        <InfoRight data-cy="text-accountNumberInfo">{accountNumber}</InfoRight>
      </InfoRow>
    </Modal>
  )
}

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-top: 20px;
  margin-bottom: 20px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 30px;
  }
`

const InfoLeft = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
`

const InfoRight = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #1b2736;
`

export const BankIcon = styled.img`
  margin-right: 5px;
`

import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { myAccount } from './myAccount/reducer'
import { transactionDetails } from './transactionDetails/reducer'
import { deposit } from './deposit/reducer'
import { verification } from './verification/reducer'
import { internalTools } from './internalTools/reducer'
import { landingPage } from './landingPage/reducer'
import { route } from './route/reducer'
import { transactions } from './transactions/reducer'
import { tour } from './tour/reducer'
import { mint } from './mint/reducer'
import { accounts } from './accounts/reducer'
import { depositInstructions } from './depositIntructions/reducer'
import { userInfo } from './userInfo/reducer'
import { bankAccounts } from './bankAccounts/reducer'
import { swap } from './swap/reducer'
import { blockchainAddresses } from './blockchainAddresses/reducer'
import { flippers } from '~/store/flippers/reducer'
import { earn } from './earn/reducer'

export const rootReducer = combineReducers({
  myAccount,
  transactionDetails,
  deposit,
  verification,
  internalTools,
  landingPage,
  route,
  transactions,
  tour,
  depositInstructions,
  mint,
  userInfo,
  bankAccounts,
  accounts,
  swap,
  blockchainAddresses,
  flippers,
  earn,
})

const middlewares = [thunk]
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))

export default store

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
  UsdcLogo,
  XsgdLogo,
  XidrLogo,
  UsdtLogo,
  XusdLogo,
  ThreeDotsLogo,
  UsdLogo,
} from '@xfers/design-system'
import AddXSGDBankFlow from '~/components/AddBankFlow'
import AddUSDCBankFlow from '~/components/AddUSDCBankFlow'
import { CURRENCY, STABLECOIN } from '~/types'

export const getStablecoinIcon = (currency: CURRENCY, size?: string): JSX.Element | undefined => {
  const defaultSize = '1em'
  const props = {
    width: size || defaultSize,
    height: size || defaultSize,
  }
  switch (currency) {
    case CURRENCY.XSGD:
      return <XsgdLogo {...props} />
    case CURRENCY.XIDR:
      return <XidrLogo {...props} />
    case CURRENCY.USDC:
      return <UsdcLogo {...props} />
    case CURRENCY.USDT:
      return <UsdtLogo {...props} />
    case CURRENCY.XUSD:
      return <XusdLogo {...props} />
    case CURRENCY.USDP:
      return <ThreeDotsLogo {...props} />
    case CURRENCY.USD:
      return <UsdLogo {...props} />
    default:
      return undefined
  }
}

export const shouldConvertToLocalAmount = (currency: CURRENCY) => {
  const isRegionalCurrency = [CURRENCY.XSGD, CURRENCY.XIDR]
  return isRegionalCurrency.includes(currency)
}

// TODO: move this helper to ~/components/AddBankFlow folder when the directory is created
export const getAddBankFlow = (stablecoin: STABLECOIN) => {
  switch (stablecoin) {
    case CURRENCY.XSGD:
      return AddXSGDBankFlow
    case CURRENCY.USDC:
    case CURRENCY.XUSD:
      return AddUSDCBankFlow
    default:
      return AddXSGDBankFlow
  }
}

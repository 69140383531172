import React, { useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import { useModal, BadgeIcon, Button, Modal, H3, Input } from '@xfers/design-system'
import { BankFilled } from '@xfers/design-system/es/icons'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'
import { setBankAccount } from '~/components/AddUSDCBankFlow/actions'
import { Alert, SelectTitle } from './shared'
import { BankAccount } from '~/components/AddUSDCBankFlow/types'
import { isNbfiBankSwiftCode } from '~/helpers/helpers'

export type Props = {
  goNext: () => void
}

export default function({ goNext }: Props) {
  const { resetModal } = useModal()
  const { state, dispatch } = useContext(AddBankFlowContext)
  const { bankAccount, accountHolderName } = state
  const optionalFields = ['intermediarySwiftCode', 'routingCode']

  useEffect(() => {
    dispatch(setBankAccount({ ...bankAccount, accountHolderName }))
  }, [])

  const formatkey = (key: string) =>
    key[0].toUpperCase() + key.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')

  // format => [[ "bankName", "Bank Name must less than 35 chars"], ["routingNo", "Routing No is required"]]
  const columnErrorArray = Object.entries(bankAccount)
    .map(([key, value]) => {
      if (key === 'bankName' && value && typeof value === 'string') {
        if (value.length > 35) return [key, `${formatkey(key)} must less than 35 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if ((key === 'swiftCode' || key === 'intermediarySwiftCode') && value && typeof value === 'string') {
        if (!/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value))
          return [
            key,
            'Please input the SWIFT/BIC code for the bank. This unique code is usually 8 or 11 characters long and can be found on your bank statement or by contacting your bank directly. Sample SWIFT/BIC Code: ABCDEFGHXXX',
          ]
        if (isNbfiBankSwiftCode(value))
          return [
            key,
            'Nonbank-Financial Institutions is not supported. Please enter a SWIFT Code for a bank.',
          ]
      }
      if (key === 'accountNumber' && value && typeof value === 'string') {
        if (value.length > 34) return [key, `${formatkey(key)} must less than 34 numbers`]
        if (!/^[a-zA-Z0-9-]+$/.test(value)) return [key, `${formatkey(key)} must contain only alphabets, numbers, and hyphens`]
      }
      if (key === 'routingCode' && value && typeof value === 'string') {
        if (!/^[A-Z0-9]{1,31}$/.test(value)) return [key, 'Routing code must be alphanumeric and cannot exceed 31 characters']
      }
      if (!optionalFields.includes(key) && !value) {
        return [key, `${formatkey(key)} is required`]
      }
      return []
      
    })
    .filter(item => item.length > 0 && item[0] !== 'id')

  const columnErrorMap: BankAccount = Object.fromEntries(columnErrorArray)

  const onFieldChange = (fieldValue: string, fieldName: keyof BankAccount) => {
    dispatch(setBankAccount({ ...bankAccount, [fieldName]: fieldValue }))
  }

  return (
    <Modal
      onClose={() => resetModal()}
      title="Add Bank Account"
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <>
          <Button
            type="primary"
            disabled={columnErrorArray.length > 0}
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              if (columnErrorArray.length === 0) goNext()
            }}
          >
            Next
          </Button>
        </>
      }
    >
      <H3 style={{ marginBottom: '5px' }}>Bank Account Information</H3>
      <Alert>
        You can only deposit or withdraw funds to an account that is in{' '}
        <b className="bold">your own legal name</b>.
      </Alert>

      <SelectTitle>Account Holder Name</SelectTitle>
      <Input disabled value={bankAccount.accountHolderName} />
      <ErrorMessage>{columnErrorMap.accountHolderName}</ErrorMessage>

      <SelectTitle>SWIFT/BIC Code</SelectTitle>
      <Input
        id="Sdet-UsdcSwiftCode"
        placeholder={`Enter SWIFT/BIC Code`}
        value={bankAccount.swiftCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'swiftCode')
        }
      />
      <ErrorMessage>{columnErrorMap.swiftCode}</ErrorMessage>

      <SelectTitle>Intermediary SWIFT/BIC Code</SelectTitle>
      <Input
        id="Sdet-UsdcIntermediarySwiftCode"
        placeholder={`Enter Intermediary SWIFT/BIC Code`}
        value={bankAccount.intermediarySwiftCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'intermediarySwiftCode')
        }
      />
      <ErrorMessage>{columnErrorMap.intermediarySwiftCode}</ErrorMessage>

      <SelectTitle>Routing Code</SelectTitle>
      <Input
        id="Sdet-UsdcRoutingCode"
        placeholder={`Enter Routing Code`}
        value={bankAccount.routingCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'routingCode')
        }
      />
      <ErrorMessage>{columnErrorMap.routingCode}</ErrorMessage>

      <SelectTitle>Account Number</SelectTitle>
      <Input
        id="Sdet-UsdcAccountNumber"
        inputMode="numeric"
        placeholder="Enter account number"
        value={bankAccount.accountNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'accountNumber')
        }
      />
      <ErrorMessage>{columnErrorMap.accountNumber}</ErrorMessage>

      <SelectTitle>Bank Name</SelectTitle>
      <Input
        id="Sdet-UsdcBankName"
        placeholder="Enter bank name"
        value={bankAccount.bankName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'bankName')
        }
      />
      <ErrorMessage>{columnErrorMap.bankName}</ErrorMessage>
    </Modal>
  )
}

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`

import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { useModal, BadgeIcon, Button, Modal, Select, H3, P1, Input } from '@xfers/design-system'
import { BankFilled } from '@xfers/design-system/es/icons'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'
import { BankAccount, BillingAddress } from '~/components/AddUSDCBankFlow/types'
import { setBillingAddress, addBankAccountHandler } from '~/components/AddUSDCBankFlow/actions'
import { SelectTitle, countries } from './shared'

type Props = {
  goPrev: () => void
  goNext: (success: boolean, bankAccount?: BankAccount) => void
}

export default function({ goPrev, goNext }: Props) {
  const { resetModal } = useModal()
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AddBankFlowContext)
  const { bankAccount, billingAddress, accountHolderName } = state

  const formatkey = (key: string) =>
    key[0].toUpperCase() + key.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')

  // format => [[ "bankName", "Bank Name must less than 35 chars"], ["routingNo", "Routing No is required"]]
  const columnErrorArray = Object.entries(billingAddress)
    .map(([key, value]) => {
      if (key === 'line1' && value) {
        if (value.length > 35) return [key, `${formatkey(key)} must less than 35 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if (key === 'line2') {
        if (!value) return []
        if (value.length > 35) return [key, `${formatkey(key)} must less than 35 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if (key === 'city') {
        if (value.length > 80) return [key, `${formatkey(key)} must less than 80 letters`]
        if (!value.isSwiftCharSet())
          return [
            key,
            `${formatkey(
              key
            )} must consist of alpha-numeric with space characters or the characters in ["/", "-", "?", ":", "(", ")", ".", ",", "+"]`,
          ]
      }
      if (!value && key !== 'postalCode') return [key, `${formatkey(key)} is required`]
      return []
    })
    .filter(item => item.length > 0)

  const columnErrorMap: BillingAddress = Object.fromEntries(columnErrorArray)

  const onFieldChange = (fieldValue: string, fieldName: string) => {
    dispatch(setBillingAddress({ ...billingAddress, [fieldName]: fieldValue }))
  }

  return (
    <Modal
      onClose={() => resetModal()}
      title="Add Bank Account"
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <>
          <Button
            type="secondary"
            onClick={() => {
              if (goPrev) goPrev()
            }}
          >
            Back
          </Button>
          <Button
            id="Sdet-UsdcSubmitAccountDetails"
            type="primary"
            disabled={columnErrorArray.length > 0}
            loading={loading}
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              if (columnErrorArray.length > 0) return
              setLoading(true)
              addBankAccountHandler({ bankAccount, billingAddress, accountHolderName, goNext })
            }}
          >
            Submit
          </Button>
        </>
      }
    >
      <H3 style={{ marginBottom: '5px' }}>Billing Address</H3>
      <P1 style={{ marginBottom: '20px' }}>Please input your billing address</P1>

      <SelectTitle>Street Address</SelectTitle>
      <Input
        id="Sdet-UsdcBillingStreetAddress"
        placeholder="Enter Address"
        value={billingAddress.line1}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'line1')
        }
      />
      <ErrorMessage>{columnErrorMap.line1}</ErrorMessage>

      <SelectTitle>Address Line 2 (Optional)</SelectTitle>
      <Input
        id="Sdet-UsdcBillingOptionalAddress"
        placeholder="Enter Address"
        value={billingAddress.line2}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'line2')
        }
      />
      <ErrorMessage>{columnErrorMap.line2}</ErrorMessage>

      <SelectTitle>City</SelectTitle>
      <Input
        id="Sdet-UsdcBillingCityName"
        placeholder="Enter City"
        value={billingAddress.city}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFieldChange(e.target.value, 'city')}
      />
      <ErrorMessage>{columnErrorMap.city}</ErrorMessage>

      <SelectTitle>Postal Code</SelectTitle>
      <Input
        id="Sdet-UsdcBillingPostalCode"
        inputMode="numeric"
        placeholder="Enter postal code"
        value={billingAddress.postalCode}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onFieldChange(e.target.value, 'postalCode')
        }
      />
      <ErrorMessage>{columnErrorMap.postalCode}</ErrorMessage>

      <SelectTitle>Country</SelectTitle>
      <Select
        id="Sdet-UsdcBillingCountry"
        placeholder="Select Country"
        type="dropdown"
        onChange={(value: string) => onFieldChange(value, 'country')}
        value={billingAddress.country}
      >
        {countries.map(({ label, value }: { label: string; value: string }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
      <ErrorMessage>{columnErrorMap.country}</ErrorMessage>
    </Modal>
  )
}

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 20px;
`

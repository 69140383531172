export enum XIDR_MINT_ACTIONS {
  SET_MINT_INSTRUCTIONS = 'XIDR_MINT_ACTIONS.SET_MINT_INSTRUCTIONS',
  SET_MINT_DASHBOARD_ENABLED = 'XIDR_MINT_ACTIONS.SET_MINT_DASHBOARD_ENABLED',
  SET_SETUP_MODE = 'XIDR_MINT_ACTIONS.SET_SETUP_MODE',
}

export type MintInstruction = {
  sourceable: {
    id: string
    type: string
    accountNo: string
    bankId: string
  }
  destinationable: {
    type: string
    address: string
    financialInstitutionId: number
    nickname: string
    disabled: boolean
  }
}

export enum SetupMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

type SetMintInstructions = {
  type: typeof XIDR_MINT_ACTIONS.SET_MINT_INSTRUCTIONS
  mintInstructions: MintInstruction[] | null
}

type SetMintDashboardEnabled = {
  type: typeof XIDR_MINT_ACTIONS.SET_MINT_DASHBOARD_ENABLED
  enabled: boolean
}

type SetSetupMode = {
  type: typeof XIDR_MINT_ACTIONS.SET_SETUP_MODE
  setupMode: SetupMode
}

export type ActionTypes = SetMintInstructions | SetMintDashboardEnabled | SetSetupMode

import { createSelector } from 'reselect'
import { WALLET_TYPES, ACCOUNT_TYPES } from '~/types'
import { COUNTRY } from '~/managers/ConfigManager'
import { ModeManager } from '~/managers/ModeManager'
import { RootState } from '~/biz/store/types'
import { selectMerchantDetail } from '~/biz/store/merchantInfo/selectors'
import { selectAccountList, selectIsLinkedToAsb } from '~/biz/store/accounts/selectors'
import { selectFlippers } from '~/store/flippers/selectors'
import { FLIPPER_KEYS } from '~/store/flippers/const'

const selectFeatureMatrix = (state: RootState) => state.route.featureMatrix

const selectUser = (state: RootState) => state.route.user

const selectSandbox = () => ModeManager.isSandboxMode()

export const selectFeatureMatrixFactors = createSelector(
  [
    selectFeatureMatrix,
    selectSandbox,
    selectAccountList,
    selectMerchantDetail,
    selectIsLinkedToAsb,
    selectUser,
    selectFlippers,
  ],
  (featureMatrix, isSandbox, accounts, { merchantDetail }, isLinkedToAsb, user, flippers) => {
    const isBizVerified = featureMatrix?.businessVerificationStatus === 'verified'
    const hasBizAccount = accounts.some(account => account.type === ACCOUNT_TYPES.BUSINESS)

    const flipperAsbSandbox = flippers.find(
      flipper =>
        flipper.featureKey === FLIPPER_KEYS.ASB_SANDBOX_UI && flipper.flipperId === user?.email
    )

    const flipperAsbSandboxEnabled = flipperAsbSandbox?.enabled || false

    return {
      isSandbox,

      role: featureMatrix?.userRole,
      isId: featureMatrix?.country === COUNTRY.INDONESIA,
      isSg: featureMatrix?.country === COUNTRY.SINGAPORE,

      isBizVerified,
      isPersVerified: featureMatrix?.personalVerificationStatus === 'verified',
      isPersIdentified: featureMatrix?.isIdentificationDone,
      isExceed20k: featureMatrix?.companyTxnVolume > 20000,

      hasGW: featureMatrix?.availableWallets?.includes(WALLET_TYPES.GENERAL),
      hasDGW: featureMatrix?.availableWallets?.includes(WALLET_TYPES.DIGITAL_GOODS),

      hasFundPoolForIdDisbursement:
        featureMatrix?.availableFundPools?.includes(1) ||
        featureMatrix?.availableFundPools?.includes(3),
      hasFundPoolForIdWithdraw:
        featureMatrix?.availableFundPools?.includes(2) ||
        featureMatrix?.availableFundPools?.includes(1) ||
        featureMatrix?.availableFundPools?.includes(3),

      isHighRiskMerchant: merchantDetail?.highRisk || false,

      isSandboxEnabled: isLinkedToAsb ? flipperAsbSandboxEnabled : true,
      isDeveloperToolEnabled: isSandbox || (hasBizAccount && isBizVerified),
    }
  }
)

export const selectIsFiatWalletOnly = createSelector([selectUser], user => user.isFiatWalletOnly)

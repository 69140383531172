/* eslint max-lines: off */
import { Reducer } from 'redux'
import { Moment, PersonalVerificationStatus, BusinessVerificationStatus } from '~/types'
import {
  ActionTypes,
  XIDR_LANDING_PAGE_ACTIONS,
  BusinessAccount,
  TemporaryHoldingBalance,
  Transaction,
  DepositRequest,
  DashboardBanner,
  BankIconDetails,
  TransferMethod,
  BankTransferNetwork,
  Blockchain,
  BlockchainAddress,
  FinancialInstitution,
  StablecoinFee,
  WithdrawWalletIdType,
  RetailStore,
} from './types'
import { BankAccount } from '~/xidrBiz/store/userInfo/types'

const initialState: State = {
  isUserInfoInitiated: false,
  isBusinessAccountInfoInitiated: false,
  isDashboardBannersInitiated: false,
  isBankIconsInitiated: false,
  isBlockchainAddressesInitiated: false,
  hasSuccessfulFiatDeposit: false,

  // user related fields, currently getting from API_V3_USER
  name: '',
  personalVerificationStatus: null,
  businessVerificationStatus: null,

  // business account related fields
  businessAccount: null,
  temporaryHoldingBalance: null,
  latestBusinessTransactions: null,
  latestBusinessDepositRequests: null,
  limitationIncreaseAmount: null,
  limitationIncreaseDate: null,
  bankAccounts: null,

  // misc
  gauthEnabled: true,

  isErc20DepositEnabled: true,
  isZrc2DepositEnabled: true,
  isErc20WithdrawalEnabled: true,
  isZrc2WithdrawalEnabled: true,

  isMaticWithdrawalEnabled: false,
  isMaticDepositEnabled: false,
  isMaticEnabled: false,

  isIndomaretDepositEnabled: false,
  isAlfamartDepositEnabled: false,

  kycVerified: false,
  kycVerifiedDate: null,

  isDashboardBannerManagementEnabled: false,
  isPaymentMethodQrisEnabled: false,
  isVirtualAccountEnabled: null,
  isPaymentMethodRetailEnabled: false,
  dashboardBanners: [],

  bankIcons: [],
  tab: 'deposit',
  transferMethod: null,
  // change initial bankTransferNetwork to null once international transfer is implemented
  bankTransferNetwork: null,
  selectedBlockchain: null,
  selectedBankAccount: null,
  selectedBlockchainAddress: null,
  selectedRetailStore: null,

  blockchainAddresses: null,
  fiList: [],
  addressQuota: 0,

  withdrawAmount: '',
  withdrawAmountError: '',
  withdrawRequestError: '',
  withdrawWalletId: undefined,

  retailDepositAmount: '',
  retailDepositAmountError: '',

  // TODO get max amount from BE
  maxBankTransferAmount: 500 * 1000 * 1000,
  maxQrisTransferAmount: 5 * 1000 * 1000,
  minAlfaMartTransferAmount: 15 * 1000,
  maxAlfaMartTransferAmount: 25 * 100 * 1000,
  minIndomaretTransferAmount: 10 * 1000,
  maxIndomaretTransferAmount: 5 * 1000 * 1000,

  isStablecoinFeeLoading: false,
  stablecoinFee: {
    amount: undefined,
    identifier: undefined,
    timestamp: undefined,
  },
  hasFetchingFeeError: false,
  isConfirmOpen: false,
  is2FaOpen: false,
  has2FaEnabled: false,
  isTemporaryHoldingBalanceOpen: false,

  visitedGettingStarted: false,
  isXIDRWithdrawalFeeEnabled: false,
  isRenewAddressAcknowledged: false,
}

type State = {
  isUserInfoInitiated: boolean
  isBusinessAccountInfoInitiated: boolean
  isDashboardBannersInitiated: boolean
  isBankIconsInitiated: boolean
  isBlockchainAddressesInitiated: boolean
  hasSuccessfulFiatDeposit: boolean

  name: string
  personalVerificationStatus: PersonalVerificationStatus | null
  businessVerificationStatus: BusinessVerificationStatus | null

  businessAccount: BusinessAccount | null
  temporaryHoldingBalance: TemporaryHoldingBalance | null
  latestBusinessTransactions: Transaction[] | null
  latestBusinessDepositRequests: DepositRequest[] | null
  limitationIncreaseAmount: number | null
  limitationIncreaseDate: Moment | null
  bankAccounts: BankAccount[] | null

  gauthEnabled: boolean

  isErc20DepositEnabled: boolean
  isZrc2DepositEnabled: boolean
  isErc20WithdrawalEnabled: boolean
  isZrc2WithdrawalEnabled: boolean

  isMaticWithdrawalEnabled: boolean
  isMaticDepositEnabled: boolean
  isMaticEnabled: boolean

  isIndomaretDepositEnabled: boolean
  isAlfamartDepositEnabled: boolean

  kycVerified: boolean
  kycVerifiedDate: Moment | null
  isDashboardBannerManagementEnabled: boolean
  isPaymentMethodQrisEnabled: boolean
  isVirtualAccountEnabled: boolean | null
  isPaymentMethodRetailEnabled: boolean
  dashboardBanners: DashboardBanner[]

  bankIcons: BankIconDetails[]
  tab: string
  transferMethod: TransferMethod | null
  bankTransferNetwork: BankTransferNetwork
  selectedBlockchain: Blockchain | null
  selectedBankAccount: BankAccount | null
  selectedBlockchainAddress: BlockchainAddress | null
  selectedRetailStore: RetailStore | null

  blockchainAddresses: BlockchainAddress[] | null
  fiList: FinancialInstitution[]
  addressQuota: number

  withdrawAmount: string
  withdrawAmountError: string
  withdrawRequestError: string
  withdrawWalletId: WithdrawWalletIdType

  retailDepositAmount: string
  retailDepositAmountError: string

  maxBankTransferAmount: number
  maxQrisTransferAmount: number
  minAlfaMartTransferAmount: number
  maxAlfaMartTransferAmount: number
  minIndomaretTransferAmount: number
  maxIndomaretTransferAmount: number

  isStablecoinFeeLoading: boolean
  stablecoinFee: StablecoinFee
  hasFetchingFeeError: boolean
  isConfirmOpen: boolean
  is2FaOpen: boolean
  has2FaEnabled: boolean
  isTemporaryHoldingBalanceOpen: boolean

  visitedGettingStarted: boolean
  isXIDRWithdrawalFeeEnabled: boolean
  isRenewAddressAcknowledged: boolean
}

export const xidrLandingPage: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_LANDING_PAGE_ACTIONS.SET_USER_INFO: {
      return {
        ...state,
        isUserInfoInitiated: true,
        name: action.name,
        kycVerified: action.kycVerified,
        kycVerifiedDate: action.kycVerifiedDate,
        hasSuccessfulFiatDeposit: action.hasSuccessfulFiatDeposit,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_BUSINESS_ACCOUNT: {
      return {
        ...state,
        businessAccount: action.businessAccount,
        isBusinessAccountInfoInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED: {
      return {
        ...state,
        isZrc2DepositEnabled: action.isZrc2DepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED: {
      return {
        ...state,
        isErc20DepositEnabled: action.isErc20DepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED: {
      return {
        ...state,
        isMaticDepositEnabled: action.isMaticDepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isMaticWithdrawalEnabled: action.isMaticWithdrawalEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_ENABLED: {
      return {
        ...state,
        isMaticEnabled: action.isMaticEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isZrc2WithdrawalEnabled: action.isZrc2WithdrawalEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isErc20WithdrawalEnabled: action.isErc20WithdrawalEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_QRIS_ENABLED: {
      return {
        ...state,
        isPaymentMethodQrisEnabled: action.isPaymentMethodQrisEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_VIRTUAL_ACCOUNT_ENABLED: {
      return {
        ...state,
        isVirtualAccountEnabled: action.isVirtualAccountEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE: {
      return {
        ...state,
        temporaryHoldingBalance: action.temporaryHoldingBalance,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_TRANSACTIONS: {
      return {
        ...state,
        latestBusinessTransactions: action.latestBusinessTransactions,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_DEPOSIT_REQUESTS: {
      return {
        ...state,
        latestBusinessDepositRequests: action.latestBusinessDepositRequests,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_VERIFICATION_STATUSES: {
      return {
        ...state,
        personalVerificationStatus: action.personalVerificationStatus,
        businessVerificationStatus: action.businessVerificationStatus,
        has2FaEnabled: action.has2FaEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED: {
      return {
        ...state,
        isDashboardBannerManagementEnabled: action.isDashboardBannerManagementEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS: {
      return {
        ...state,
        dashboardBanners: action.dashboardBanners,
        isDashboardBannersInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.bankAccounts,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.GET_BANK_ICONS: {
      return {
        ...state,
        bankIcons: action.bankIcons,
        isBankIconsInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TAB: {
      return {
        ...state,
        tab: action.tab,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD: {
      return {
        ...state,
        transferMethod: action.transferMethod,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK: {
      return {
        ...state,
        bankTransferNetwork: action.bankTransferNetwork,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN: {
      return {
        ...state,
        selectedBlockchain: action.selectedBlockchain,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT: {
      return {
        ...state,
        selectedBankAccount: action.selectedBankAccount,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS: {
      return {
        ...state,
        selectedBlockchainAddress: action.selectedBlockchainAddress,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_BLOCKCHAIN_ADDRESSES: {
      return {
        ...state,
        blockchainAddresses: action.blockchainAddresses,
        fiList: action.fiList,
        addressQuota: action.addressQuota,
        isBlockchainAddressesInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT: {
      return {
        ...state,
        withdrawAmount: action.amount,
        withdrawAmountError: action.withdrawAmountError,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID: {
      return {
        ...state,
        withdrawWalletId: action.withdrawWalletId,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING: {
      return {
        ...state,
        isStablecoinFeeLoading: action.isStablecoinFeeLoading,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE: {
      return {
        ...state,
        isStablecoinFeeLoading: false,
        stablecoinFee: {
          amount: action.amount,
          identifier: action.identifier,
          timestamp: action.timestamp,
        },
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN: {
      return {
        ...state,
        isConfirmOpen: action.open,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN: {
      return {
        ...state,
        is2FaOpen: action.open,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        withdrawRequestError: action.error,
        isLoading: false,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN: {
      return {
        ...state,
        isTemporaryHoldingBalanceOpen: action.open,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED: {
      return {
        ...state,
        visitedGettingStarted: action.value,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_XIDR_WITHDRAWAL_FEE_ENABLED: {
      return {
        ...state,
        isXIDRWithdrawalFeeEnabled: action.value,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS: {
      let addresses = state.blockchainAddresses
      if (!addresses) {
        addresses = []
      }
      addresses.push(action.blockchainAddress)

      return {
        ...state,
        blockchainAddresses: [...addresses],
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR: {
      return {
        ...state,
        hasFetchingFeeError: action.hasError,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_INDOMARET_DEPOSIT_ENABLED: {
      return {
        ...state,
        isIndomaretDepositEnabled: action.isIndomaretDepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ALFAMART_DEPOSIT_ENABLED: {
      return {
        ...state,
        isAlfamartDepositEnabled: action.isAlfamartDepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_RETAIL_ENABLED: {
      return {
        ...state,
        isPaymentMethodRetailEnabled: action.isPaymentMethodRetailEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_STORE: {
      return {
        ...state,
        selectedRetailStore: action.selectedRetailStore,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_DEPOSIT_AMOUNT: {
      return {
        ...state,
        retailDepositAmount: action.amount,
        retailDepositAmountError: action.retailDepositAmountError,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED: {
      return {
        ...state,
        isRenewAddressAcknowledged: action.isRenewAddressAcknowledged,
      }
    }

    default:
      return state
  }
}

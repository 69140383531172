export enum API_VERSION {
  V3 = 'V3',
}

export enum TAB {
  API_KEY = 'apiKeys',
  CALLBACK_SETTINGS = 'callbackSettings',
}

export type V3KeyResponse = {
  api_key: string
  api_secret: string
  wallet_type: {
    id: number
    name: string
    contractual_model: boolean
    in_use: boolean
  }
  last_used: string
  created_at: string
  enabled: boolean
}

export type V3Key = {
  apiKey: string
  apiSecret: string
  lastUsed: string
  createdAt: string
  enabled: boolean
}

export type DetailType = {
  api_version: string
  enabled_events: string[]
  signing_secret: string
  tag: string
  url: string
}

export type callbackFunctions = {
  successCallback: () => void
  errorCallback: () => void
}

export enum DEVELOPER_TOOLS_ACTIONS {
  SET_IS_LOADING = 'DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING',
  SET_API_KEY_VERSION_V3 = 'DEVELOPER_TOOLS_ACTIONS.SET_API_KEY_VERSION_V3',
  GET_INITIAL_URL_V3 = 'DEVELOPER_TOOLS_ACTIONS.GET_INITIAL_URL_V3',
  SET_CALLBACK_URL = 'DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URL',
  UPDATE_CALLBACK_URL = 'DEVELOPER_TOOLS_ACTIONS.UPDATE_CALLBACK_URL',
  RESET_API_KEYS = 'DEVELOPER_TOOLS_ACTIONS.RESET_API_KEYS',
  FETCH_API_KEY = 'DEVELOPER_TOOLS_ACTIONS.FETCH_API_KEY',
  GENERATE_API_KEY = 'DEVELOPER_TOOLS_ACTIONS.GENERATE_API_KEY',
  CLEAR_API_KEYS = 'DEVELOPER_TOOLS_ACTIONS.CLEAR_API_KEYS',
  SET_V3_SECRET = 'DEVELOPER_TOOLS_ACTIONS.SET_V3_SECRET',
}

export type CallbackSettingsResponse = {
  event_name: string
  url: string
}[]

export type CallbackSettings = {
  callbackPayment: string
  callbackPayout: string
  callbackUsdVa: string
  callbackSwap: string
  callbackStablecoinWithdraw: string
}

type GetInitialUrlV3 = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.GET_INITIAL_URL_V3
  callbackSettings: CallbackSettings
}

type SetCallbackUrl = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URL
  apiVersion: API_VERSION.V3
  callbackType: string
  callbackUrl: string
}

type UpdateCallbackUrl = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.UPDATE_CALLBACK_URL
  callbackType: string
}

type SetIsLoading = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type SetApiKeyVersionV3 = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_API_KEY_VERSION_V3
  isV3Merchant: boolean
}

type ResetApiKeys = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.RESET_API_KEYS
}

type FetchApiKey = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.FETCH_API_KEY
  apiVersion: API_VERSION.V3
  apiKeys: V3Key[]
}

type ClearApiKeys = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.CLEAR_API_KEYS
}

type SetV3Secret = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_V3_SECRET
  secret: string
}

export type ActionTypes =
  | SetIsLoading
  | SetApiKeyVersionV3
  | GetInitialUrlV3
  | SetCallbackUrl
  | UpdateCallbackUrl
  | ResetApiKeys
  | FetchApiKey
  | ClearApiKeys
  | SetV3Secret

/* eslint-disable max-lines, react/jsx-props-no-spreading */
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, useTheme, Card, useModal } from '@xfers/design-system'
import { datadogRum } from '@datadog/browser-rum'
import straitsXBackground from '~/assets/straitsXBackground.png'
import PageLoader from '~/components/PageLoader'
import { RouteHooks, PrivateRoute } from '~/components/Route'
import { createGaEvent, createGaPageView } from '~/helpers/gaHelper'
import { ModeManager } from '~/managers/ModeManager'
import { init, setIsChangeToFiatCurrency } from '~/biz/store/route/actions'
import {
  selectMerchantDetail,
  selectIsKillSwitchActive,
  selectIsDpt2TncAccepted,
} from '~/biz/store/merchantInfo/selectors'
import { ConfigManager, SITE_TYPE } from '~/managers/ConfigManager'
import {
  setIsXsgdErc20DepositEnabled,
  setIsXsgdErc20WithdrawalEnabled,
  setIsXsgdZrc2DepositEnabled,
  setIsXsgdZrc2WithdrawalEnabled,
  setIsXsgdHtsDepositEnabled,
  setIsXsgdHtsWithdrawalEnabled,
  setIsXsgdMaticDepositEnabled,
  setIsXsgdMaticWithdrawalEnabled,
  setIsHederaUIEnabled,
  setIsPolygonUIEnabled,
  setIsSsoTwoFaAuthenticatorEnabled,
  setIsDptRiskModalEnabled,
  setIsFazzVaForDashboardEnabled,
  setIsTravelRuleDepositDeclarationEnabled,
  setIsTravelRuleAddrWhitelistingEnabled,
  setIsXsgdAvaxDepositEnabled,
  setIsXsgdAvaxWithdrawalEnabled,
  setIsAvalancheUIEnabled,
  setIsTronUIEnabled,
  setIsUsdtTrc20WithdrawalEnabled,
  setIsUsdtTrc20DepositEnabled,
} from '~/biz/store/straitsXLandingPage/actions'
import { getLatestTransactions } from '~/biz/store/straitsXTransactions/actions'
import { RootState } from '~/biz/store/types'
import { straitsXRoutes } from '~/biz/routes/config'
import { selectFeatureMatrixFactors } from './store/route/selectors'
import StraitsXFooter from '~/components/StraitsXFooter'
import StraitsXNavigation from '~/components/StraitsXNavigation'
import StraitsXUserInfoBar from '~/components/StraitsXUserInfoBar'
import SandboxBanner from '~/components/SandBoxBanner'
import { Notification } from './store/route/types'
import FeatureGatingManager from '~/managers/FeatureGatingManager'
import { useCleverTap } from '~/hooks/useCleverTap'
import { selectDgwMerchantAccount, selectHasBusinessAccount } from './store/accounts/selectors'
import { fetchAccountDetailList, fetchAccountList } from './store/accounts/actions'
import { fetchBankList } from './store/bankAccounts/actions'
import { selectBankAccountListByCurrency } from './store/bankAccounts/selectors'
import { fetchMerchantDetail, fetchUserInfo, fetchUserProfile } from './store/merchantInfo/actions'
import {
  selectBusinessVerificationStatus,
  selectPersonalVerificationStatus,
  selectUserProfile,
} from './store/merchantInfo/selectors'
import {
  selectBlockchainAddressList,
  selectIsBlockchainAddressesInitiated,
} from './store/blockchainAddresses/selectors'
import {
  fetchBlockchainAddresses,
  setBlockchainAddressList,
  setIsWhitelistingImprovementEnabled,
} from './store/blockchainAddresses/actions'
import { setIsAccountStatementUIEnabled } from './store/straitsXAccountStatement/actions'
import { getTotalTravelRuleComplianceBlockerCount } from './store/straitsXTransactions/actions'
import { useBizFlippers } from '~/store/flippers/hooks'
import { FLIPPER_KEYS } from '~/store/flippers/const'
import { FLIPPER_STATUS } from '~/store/flippers/types'
import { selectFlipperWithId } from '~/store/flippers/selectors'
import KillSwitchLockedPage from '~/components/KillSwitchLockedPage'
import Dpt2Tnc from '~/components/Dpt2Tnc'
import { USER_ROLES } from '~/types'
import Dpt2LockedPage from '~/components/Dpt2Tnc/Dpt2LockedPage'
import { PATHS } from './routes/paths'

export default () => {
  const dispatch = useDispatch()
  const { resetModal } = useModal()
  const history = useHistory()
  const {
    isInitiated,
    user,
    notifications: fetchedNotifications,
    isUserInfoInitialised,
  } = useSelector((state: RootState) => state.route)

  useBizFlippers({ email: user?.email })

  const bizAccount = useSelector(selectDgwMerchantAccount)
  const isBusinessAccount = useSelector(selectHasBusinessAccount)
  const { merchantDetail } = useSelector(selectMerchantDetail)

  const userProfile = useSelector(selectUserProfile)
  const flipperId = userProfile?.merchantId

  useEffect(() => {
    if (flipperId) {
      FeatureGatingManager.isEnabled('xsgd_use_fiat_currency_name', `Merchant_${flipperId}`).then(
        resp => {
          dispatch(setIsChangeToFiatCurrency(resp))
        }
      )
      FeatureGatingManager.isEnabled(
        [
          'straitsx_xsgd_account_statement_ui',
          'xsgd_erc20_deposit',
          'xsgd_erc20_withdrawal',
          'xsgd_zrc2_deposit',
          'xsgd_zrc2_withdrawal',
          'xsgd_hts_deposit',
          'xsgd_hts_withdrawal',
          'xsgd_matic_deposit',
          'xsgd_matic_withdrawal',
          'xsgd_avax_deposit',
          'xsgd_avax_withdrawal',
          'usdt_tron_deposit',
          'usdt_tron_withdrawal',
          'xsgd_dashboard_hedera_ui',
          'xsgd_dashboard_polygon_ui',
          'xsgd_dashboard_avalanche_ui',
          'usdt_dashboard_tron_ui',
          'whitelisting_improvement',
          'sso_two_fa_authenticator',
          'straitsx_dpt_risk_modal',
          'straitsx_enable_fazz_va_for_dashboard',
          'straitsx_travel_rule_deposit_declaration',
          'straitsx_travel_rule_addr_whitelisting',
        ],
        flipperId
      ).then(resp => {
        // testing purpose flippers
        dispatch(setIsAccountStatementUIEnabled(resp.straitsx_xsgd_account_statement_ui))
        dispatch(setIsDptRiskModalEnabled(resp.straitsx_dpt_risk_modal))

        // blockchain related flippers
        dispatch(setIsXsgdErc20DepositEnabled(resp.xsgd_erc20_deposit))
        dispatch(setIsXsgdErc20WithdrawalEnabled(resp.xsgd_erc20_withdrawal))
        dispatch(setIsXsgdZrc2DepositEnabled(resp.xsgd_zrc2_deposit))
        dispatch(setIsXsgdZrc2WithdrawalEnabled(resp.xsgd_zrc2_withdrawal))
        dispatch(setIsXsgdHtsDepositEnabled(resp.xsgd_hts_deposit))
        dispatch(setIsXsgdHtsWithdrawalEnabled(resp.xsgd_hts_withdrawal))
        dispatch(setIsXsgdMaticDepositEnabled(resp.xsgd_matic_deposit))
        dispatch(setIsXsgdMaticWithdrawalEnabled(resp.xsgd_matic_withdrawal))
        dispatch(setIsXsgdAvaxDepositEnabled(resp.xsgd_avax_deposit))
        dispatch(setIsXsgdAvaxWithdrawalEnabled(resp.xsgd_avax_withdrawal))
        dispatch(setIsUsdtTrc20DepositEnabled(resp.usdt_tron_deposit))
        dispatch(setIsUsdtTrc20WithdrawalEnabled(resp.usdt_tron_withdrawal))
        dispatch(setIsHederaUIEnabled(resp.xsgd_dashboard_hedera_ui))
        dispatch(setIsPolygonUIEnabled(resp.xsgd_dashboard_polygon_ui))
        dispatch(setIsAvalancheUIEnabled(resp.xsgd_dashboard_avalanche_ui))
        dispatch(setIsTronUIEnabled(resp.usdt_dashboard_tron_ui))
        dispatch(setIsWhitelistingImprovementEnabled(resp.whitelisting_improvement))
        dispatch(setIsSsoTwoFaAuthenticatorEnabled(resp.sso_two_fa_authenticator))
        dispatch(setIsFazzVaForDashboardEnabled(resp.straitsx_enable_fazz_va_for_dashboard))
        dispatch(
          setIsTravelRuleDepositDeclarationEnabled(resp.straitsx_travel_rule_deposit_declaration)
        )
        dispatch(
          setIsTravelRuleAddrWhitelistingEnabled(resp.straitsx_travel_rule_addr_whitelisting)
        )
      })
    }
  }, [dispatch, flipperId])

  const verificationStatus = useSelector(selectPersonalVerificationStatus)
  const businessVerificationStatus = useSelector(selectBusinessVerificationStatus)
  const { bankAccountList: bankAccounts, isBankAccountListInitiated } = useSelector(
    selectBankAccountListByCurrency
  )()

  const blockchainAddresses = useSelector(selectBlockchainAddressList)
  const isBlockchainAddressListInitiated = useSelector(selectIsBlockchainAddressesInitiated)

  const {
    isDeveloperToolEnabled: displayDevToolTab,
    isSandboxEnabled: displayModeSwitcher,
    role,
  } = useSelector(selectFeatureMatrixFactors)

  const { isAccountStatementUIEnabled } = useSelector(
    (state: RootState) => state.straitsXAccountStatement
  )

  const isKillSwitchActive = useSelector(selectIsKillSwitchActive)

  const isDpt2TncAccepted = useSelector(selectIsDpt2TncAccepted)

  const [displayGetStartedTab, setDisplayGetStartedTab] = useState(false)

  const { isAccountListInitiated } = useSelector((state: RootState) => state.accounts)

  const flipperOtcTrxUI = useSelector(
    selectFlipperWithId(FLIPPER_KEYS.SG_OTC_TRX_HISTORY_BIZ_DASHBOARD, user?.email)
  )

  const flipperDpt2Tnc = useSelector(selectFlipperWithId(FLIPPER_KEYS.DPT2_TNC, user?.email))

  // fetching application needed data
  useEffect(() => {
    dispatch(fetchUserInfo())
    dispatch(fetchBankList())
    dispatch(fetchAccountList())
    dispatch(fetchAccountDetailList())
    dispatch(fetchMerchantDetail())
    dispatch(fetchUserProfile())
    dispatch(getTotalTravelRuleComplianceBlockerCount())
  }, [dispatch])

  useEffect(() => {
    if (isAccountListInitiated && flipperOtcTrxUI?.status === FLIPPER_STATUS.SUCCEEDED)
      dispatch(getLatestTransactions())
  }, [isAccountListInitiated, flipperOtcTrxUI?.status])

  useEffect(() => {
    if (!isAccountListInitiated) return

    if (bizAccount?.accountId) {
      dispatch(fetchBlockchainAddresses(bizAccount.accountId))
    } else {
      dispatch(setBlockchainAddressList([]))
    }
  }, [dispatch, isAccountListInitiated, bizAccount?.accountId])

  // on sandbox<->production mode switched
  const onModeSwitched = () => {
    // TODO: think about better solution
    window.location.reload()
  }

  const isSandbox = ModeManager.isSandboxMode()
  useEffect(() => {
    const isVerificationPending = businessVerificationStatus && businessVerificationStatus !== 'verified'

    if (isSandbox) {
      setDisplayGetStartedTab(false)
      return
    }
    
    if (isVerificationPending && role === USER_ROLES.OWNER) {
      setDisplayGetStartedTab(true)
    }

  }, [
    isSandbox,
    businessVerificationStatus,
    role
  ])

  history.listen(location => {
    const urlPath = location.pathname + location.search
    createGaPageView(urlPath)
    createGaEvent('URL', urlPath, 'Changed URL')
  })

  useEffect(() => {
    if (!isInitiated) dispatch(init())
  }, [isInitiated]) // Run init just once

  const [filteredNotifications, setFilteredNotifications] = useState({
    data: [] as Notification[],
    unreadCount: 0,
  })

  useEffect(() => {
    if (fetchedNotifications.data.length !== 0) {
      let { data: filteredNoti, unreadCount } = fetchedNotifications

      const dateToFilter = new Date('2021-09-20T00:00:00.000+08:00')
      filteredNoti = fetchedNotifications.data.filter(
        noti => new Date(noti.created_at) >= dateToFilter
      )
      unreadCount = filteredNoti.filter(noti => noti.is_read === 0).length

      setFilteredNotifications({
        data: filteredNoti,
        unreadCount,
      })
    }
  }, [fetchedNotifications])

  const notifications = useMemo(
    () => ({
      isLoading: fetchedNotifications.isLoading,
      data: filteredNotifications.data,
      unreadCount: filteredNotifications.unreadCount,
    }),
    [fetchedNotifications.isLoading, filteredNotifications]
  )

  useCleverTap({
    email: user.email,
    fullName: userProfile?.fullName || '',
    kcId: user.kcId,
    merchantId: userProfile?.merchantId,
  })

  useEffect(() => {
    if (ConfigManager.isProd() && user?.email && user?.kcId) {
      datadogRum.setUser({
        email: user.email,
        kcId: user.kcId,
      })
    }
  }, [user])

  if (!isInitiated) {
    return <PageLoader />
  }

  if (isKillSwitchActive) {
    return <KillSwitchLockedPage />
  }

  if (
    !isDpt2TncAccepted &&
    isUserInfoInitialised &&
    !isKillSwitchActive &&
    flipperDpt2Tnc?.enabled
  ) {
    if (role === USER_ROLES.OWNER) {
      return <Dpt2Tnc siteType={SITE_TYPE.BIZ} />
    }
    return <Dpt2LockedPage />
  }

  return (
    <Root id="biz-root">
      <RouteHooks resetModal={resetModal} />

      {isKillSwitchActive !== undefined && (
        <Container>
          <NavigationWrapper>
            <StraitsXNavigation
              displayEarnTab={!!userProfile?.merchantId}
              displayGetStartedTab={displayGetStartedTab}
              displayDevToolTab={displayDevToolTab}
              displayAccountStatementTab={isAccountStatementUIEnabled}
              isSandbox={isSandbox}
              isBusinessAccount={isBusinessAccount}
            />
          </NavigationWrapper>
          <MainContainer isSandbox={isSandbox}>
            {isSandbox && <SandboxBanner />}
            <StraitsXUserInfoBar
              userInfo={{
                fullName: userProfile?.fullName || '',
                avatarUrl: userProfile?.avatarUrl || '',
                displayname: merchantDetail?.businessName || '',
              }}
              isSandbox={isSandbox}
              notifications={notifications}
              displayGetStartedTab={displayGetStartedTab}
              displayDevToolTab={displayDevToolTab}
              displayAccountStatementTab={isAccountStatementUIEnabled}
              displayEarnTab={!!userProfile?.merchantId}
              displayModeSwitcher={displayModeSwitcher}
              isBusinessAccount={isBusinessAccount}
              onChangeModeCallback={onModeSwitched}
            />

            <Suspense fallback={<LoadingPage />}>
              <Switch>
                <Route path="/verification">
                  <Redirect to={PATHS.LANDING_PAGE} />
                </Route>
                {straitsXRoutes.map((route, i) => (
                  <PrivateRoute key={i} {...route} />
                ))}
              </Switch>
            </Suspense>

            <StraitsXFooter />
          </MainContainer>
        </Container>
      )}
    </Root>
  )
}

function LoadingPage() {
  const { spacing } = useTheme()

  return (
    <Page>
      <Card style={{ padding: spacing.xl }}>
        <Skeleton style={{ width: '40%' }} />
        <Skeleton />
        <Skeleton />
      </Card>
    </Page>
  )
}

const Page = styled.div`
  padding: 16px;
  background: none;

  ${DESKTOP_BREAKPOINT} {
    background-color: #f6f7f9;
    padding: 24px;
  }
`

const Root = styled.div`
  z-index: 0;
`

const Container = styled.div`
  background-image: url(${straitsXBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 73px;
  width: 100%;

  ${DESKTOP_BREAKPOINT} {
    padding-top: ${(props: { isSandbox: boolean }) => (props.isSandbox ? '73px' : 0)};
  }
`

const NavigationWrapper = styled.div`
  z-index: 1;
  display: none;

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    width: 257px;
    flex-shrink: 0;
  }
`

const Skeleton = styled.div`
  float: left;
  width: 100%;
  height: 16px;

  margin: 10px;
  border-radius: 5px;
  background-size: 100wh;
  background-image: linear-gradient(90deg, #e8e8e8 0%, #f6f7f9 50%, #e8e8e8 100%);
  animation: shine-lines 1s infinite linear;

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
`

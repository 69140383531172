import React from 'react'
import styled from '@emotion/styled'
import { Tick } from '@xfers/design-system/es/icons'
import { Modal, Button, BadgeIcon } from '@xfers/design-system'

export default function() {
  return (
    <Modal
      onClose={() => window.location.reload()}
      title="Successfully Submitted"
      icon={<BadgeIcon type="success" icon={<Tick />} />}
      footer={
        <Button type="secondary" onClick={() => window.location.reload()}>
          Close
        </Button>
      }
    >
      <Text>
        Your bank statement has been uploaded successfully. It'll take approximately{' '}
        <b>1 business day</b> to review your submitted document.
      </Text>
    </Modal>
  )
}

const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-top: 20px;
  margin-bottom: 20px;
`

import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { BankFilled } from '@xfers/design-system/es/icons'
import { useModal, Modal, Button, Link, BadgeIcon } from '@xfers/design-system'
import { UploadFile } from 'antd/lib/upload/interface'
import UploadBox from '~/components/UploadBox'
import { createGaEvent } from '~/helpers/gaHelper'

import { put, ENDPOINTS } from '~/api'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'
import { BankAccount } from '~/components/AddUSDCBankFlow/types'

type Props = {
  goNext: (success: boolean, bankAccount?: BankAccount) => void
}

export default function({ goNext }: Props) {
  const { resetModal } = useModal()
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [bankStatementFile, setBankStatementFile] = useState<{
    fileData: string
    fileName: string
  } | null>(null)
  const { state } = useContext(AddBankFlowContext)
  const { id, accountNumber } = state.bankAccount

  const uploadBankStatement = () => {
    setLoading(true)
    put(
      ENDPOINTS.API_V3_USER_BANK_ACCOUNT,
      {
        account_no: accountNumber,
        bank_account_proof: bankStatementFile,
      },
      {},
      `/${id}`
    ).then(_resp => {
      // const respBankAccs = keysToCamel(resp) as OriginBankAccount[]
      // const updatedBankAcc = respBankAccs.find(item => item.accountNo === accountNumber)

      setLoading(false)
      goNext(true, state.bankAccount)
    })
  }

  return (
    <Modal
      onClose={() => resetModal()}
      title="Add Bank Account"
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <>
          <Button
            type="primary"
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              if (fileList.length !== 0) {
                createGaEvent(
                  'My Account',
                  'uploadBankStatementButton',
                  'Upload Bank Statement to Verify New Bank Account'
                )
                uploadBankStatement()
              }
            }}
            loading={loading}
            disabled={fileList.length === 0}
          >
            Submit
          </Button>
        </>
      }
    >
      <Container>
        <ModalSubtitle>Upload Bank Statement</ModalSubtitle>
        <Intro>
          Ensure that your statement has:{' '}
          <b>Name, Address, Bank Account Number, Bank Logo, Date of Issuance</b> (within 3 months
          from date of submission), <b>Header and footer</b> (top and bottom portions of the
          statement).
        </Intro>
        <Intro>
          Don't have a bank statement?{' '}
          <Link
            href="https://support.straitsx.com/hc/en-us/articles/4410447157529-How-do-I-verify-my-Singapore-bank-account-"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check out FAQ.
          </Link>
        </Intro>
        <Alert>Do note that cropping or editing of the document is not allowed.</Alert>
        <Intro>
          You need to upload your bank statement even if you submitted a statement as your Proof of
          Address. Identity verification and bank account verification are two different processes.
        </Intro>
        <UploadTitle>Upload</UploadTitle>
        <UploadBox fileList={fileList} setFileList={setFileList} setFile={setBankStatementFile} />
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  padding-bottom: 30px;
`

const Alert = styled.div`
  background: #fffbe9;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8d7700;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;

  b {
    font-weight: 600;
  }

  // display: none;
`

const ModalSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #1b2736;
  padding-top: 20px;
  margin-bottom: 5px;
`

const Intro = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #777777;
  margin-bottom: 5px;

  b {
    font-weight: 600;
  }

  // display: none;
`

const UploadTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
  margin-top: 20px;
  margin-bottom: 8px;
`

export type TransactionDetails = {
  key: string
  transactionId: string
  transactionType: string
  transactionHash?: string | null
  transactionHashUrl?: string | null
  walletType: string
  method: string
  accountHolderName?: string | null
  amount: { prefix: string; postfix: string }
  fee: string
  tax?: string | null
  netAmount: { prefix: string; postfix: string }
  currency: { source: string; destination: string }
  createdDate: string
  completedDate: string
  status: string
  note?: string | null
  sourceDestination?: { source: string; destination: string } | null
  merchantDescription?: string | null
  failureReason?: string | null
  blockedReasons?: string | null
  manualAssignments?: Array<{
    createdAt: string
    amount: { prefix: string; postfix: string }
    status: string
    externalId: string
  }> | null
  network?: string | null
  bankAccountNo: string | null
  bankName: string | null
  isDeclarationRequired: boolean | null
}

export enum TRANSACTION_DETAILS_ACTIONS {
  SET_IS_LOADING = 'TRANSACTION_DETAILS_ACTIONS.SET_IS_LOADING',
  GET_TRANSACTION_DETAILS = 'TRANSACTION_DETAILS_ACTIONS.GET_TRANSACTION_DETAILS',
}

type SetIsLoading = {
  type: typeof TRANSACTION_DETAILS_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type getTransactionDetails = {
  type: typeof TRANSACTION_DETAILS_ACTIONS.GET_TRANSACTION_DETAILS
  transactionDetails: TransactionDetails
}

export type ActionTypes = SetIsLoading | getTransactionDetails

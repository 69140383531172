import { Moment } from '~/types'

export type BankTransferInfo = {
  isInitiated: boolean
  recipientName: string
  recipientBankFullName: string
  recipientBankShortName: string
  recipientAccountNo: string
  recipientBankCode: string
  referenceNumber?: string
  expiredDate?: string
  depositAmount?: string
  userBankAccount?: string
  userHandPhoneNo?: string
}

export type QrisTransferInfo = {
  isInitiated: boolean
  qrCodeImageUrl: string
}

export type BlockchainAddress = {
  id: number
  blockchain: string
  address: string
}

export type RetailPaymentInfo = {
  retailOutletName: string
  expiredAt: string | Moment
  amount: string
  fees: string
  paymentCode: string
}

export type PaymentMethod = 'virtual_bank_accounts' | 'qris' | 'retail_outlet'

export enum XIDR_DEPOSIT_ACTIONS {
  SET_IS_LOADING = 'XIDR_DEPOSIT_ACTIONS.SET_IS_LOADING',
  GET_ACCOUNT_LIMITS = 'XIDR_DEPOSIT_ACTIONS.GET_ACCOUNT_LIMITS',
  SET_ACCOUNTS = 'XIDR_DEPOSIT_ACTIONS.SET_ACCOUNTS',
  SET_BANK_TRANSFER_INFO = 'XIDR_DEPOSIT_ACTIONS.SET_BANK_TRANSFER_INFO',
  SET_QRIS_TRANSFER_INFO = 'XIDR_DEPOSIT_ACTIONS.SET_QRIS_TRANSFER_INFO',
  SET_BLOCKCHAIN_ADDRESS = 'XIDR_DEPOSIT_ACTIONS.SET_BLOCKCHAIN_ADDRESSES',
  SET_RETAIL_PAYMENT_INFO = 'XIDR_DEPOSIT_ACTIONS.SET_RETAIL_PAYMENT_INFO',
}

type SetIsLoading = {
  type: typeof XIDR_DEPOSIT_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type SetBankTransferInfo = {
  type: typeof XIDR_DEPOSIT_ACTIONS.SET_BANK_TRANSFER_INFO
  bankTransferInfo: BankTransferInfo
}

type SetQrisTransferInfo = {
  type: typeof XIDR_DEPOSIT_ACTIONS.SET_QRIS_TRANSFER_INFO
  qrisTransferInfo: QrisTransferInfo
}

type SetBlockchainAddress = {
  type: typeof XIDR_DEPOSIT_ACTIONS.SET_BLOCKCHAIN_ADDRESS
  blockchainAddress: BlockchainAddress
}

type SetRetailPaymentInfo = {
  type: typeof XIDR_DEPOSIT_ACTIONS.SET_RETAIL_PAYMENT_INFO
  retailPaymentInfo: RetailPaymentInfo
}

export type ActionTypes =
  | SetIsLoading
  | SetBankTransferInfo
  | SetQrisTransferInfo
  | SetBlockchainAddress
  | SetRetailPaymentInfo

/* eslint max-lines: off */
import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useTheme, Button } from '@xfers/design-system'
import styled from '@emotion/styled'
import Upload from 'antd/es/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import { Upload as UploadIcon, ExternalLink, Delete } from '@xfers/design-system/es/icons'
import { VecEmptyPlainGreen as BoxImgGreen } from '@xfers/design-system/es/logos'

function getBase64(file: UploadFile['originFileObj']) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    if (file === undefined) {
      reject()
    } else {
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
    }
  })
}

type UploadBoxProps = {
  fileList: UploadFile[]
  setFileList: (value: UploadFile[]) => void
  setFile: (value: { fileData: string; fileName: string }) => void
  onFileDelete?: () => void
  note?: React.ReactNode
  actionText?: React.ReactNode
  capture?: boolean
  className?: string
}

export default function({
  fileList,
  setFileList,
  setFile,
  onFileDelete,
  note = DefaultNote(),
  actionText = DefaultActionText(),
  capture,
  className,
  ...props
}: UploadBoxProps) {
  const [base64, setBase64] = useState<string | undefined>(undefined)

  useEffect(() => {
    const previewUploadedFile = async () => {
      // set uploaded file base64 data for previewing
      if (fileList.length === 0) {
        return
      }
      const file = fileList[0]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const originFileObject = file.originFileObj as any
      const base64Data = await getBase64(originFileObject)
      const fileName = originFileObject ? originFileObject.name : 'bankStatementFile.png'
      setFile({
        fileData: base64Data as string,
        fileName: fileName as string,
      })
      setBase64(base64Data as string)
    }
    previewUploadedFile()
  }, [fileList])

  const uploaded = fileList.length > 0
  const isPdf = base64 && base64.includes('application/pdf')

  useLayoutEffect(() => {
    const uploadBox = document.getElementById('custom-upload-box')
    if (uploadBox && capture) {
      uploadBox.setAttribute('capture', 'camera')
    }
  }, [])

  /*
    This is to reinstate the capture property on file input
  */
  const resetToCameraUploadOnly = () => {
    const uploadBox = document.getElementById('custom-upload-box')
    if (uploadBox && capture) {
      uploadBox.setAttribute('capture', 'camera')
    }
  }

  const showPdfPreview = () => {
    return (
      <>
        <BoxImgGreen width={60} height={35} />
        <Note style={{ marginBottom: '10px' }}>Successfully uploaded</Note>
        <ButtonGroup>
          <Button
            type="secondary"
            size="small"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setFileList([])
              setBase64(undefined)
              if (onFileDelete) onFileDelete()
            }}
          >
            <Delete height={18} width={18} style={{ marginRight: '5px' }} />
            Delete
          </Button>
        </ButtonGroup>
      </>
    )
  }

  const showImagePreview = () => {
    return (
      <>
        <img
          src={base64}
          style={{
            width: '116px',
            marginBottom: '20px',
          }}
        />
        <ButtonGroup>
          <Button
            size="small"
            style={{
              marginRight: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
            type="secondary"
            onClick={() => {
              const w = window.open('about:blank')
              const image = new Image()
              if (base64 && w) {
                image.src = base64
                setTimeout(() => {
                  return w?.document?.write(image.outerHTML)
                }, 0)
              }
            }}
          >
            <ExternalLink height={18} width={18} style={{ marginRight: '5px' }} />
            Preview
          </Button>
          <Button
            type="secondary"
            size="small"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              setFileList([])
              setBase64(undefined)
              resetToCameraUploadOnly()
              if (onFileDelete) onFileDelete()
            }}
          >
            <Delete height={18} width={18} style={{ marginRight: '5px' }} />
            Delete
          </Button>
        </ButtonGroup>
      </>
    )
  }

  // If capture is true, phone upload only accepts files directly from camera
  // Setting accept property is also required for Android phones
  const accept = capture ? 'image/*' : 'image/*, application/pdf'

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <StyleOverwrite className={className}>
      <Upload.Dragger
        {...props}
        id="custom-upload-box"
        fileList={fileList}
        disabled={uploaded}
        accept={accept}
        beforeUpload={() => {
          return false
        }}
        onChange={info => {
          const list = info.fileList.slice(-1) // limit to 1 file
          const file = list[0]
          const MAX_FILE_MB = 5
          const exceedMaxFileSize = file.size / 1024 / 1024 > MAX_FILE_MB

          // Reject if file exceed max size allowed
          if (exceedMaxFileSize) return false

          setFileList(list)
          return true
        }}
      >
        <Background uploaded={uploaded}>
          <Container uploaded={uploaded}>
            <Center>
              {uploaded && !isPdf && showImagePreview()}
              {uploaded && isPdf && showPdfPreview()}
              {!uploaded && (
                <>
                  <BoxImgGreen width={60} height={35} />
                  <MockButton style={{ margin: '10px 0px 10px 0px' }}>{actionText}</MockButton>
                  {note}
                </>
              )}
            </Center>
          </Container>
        </Background>
      </Upload.Dragger>
    </StyleOverwrite>
  )
}

function DefaultNote() {
  return (
    <Note>
      Drag & Drop JPEG, PNG, JPG, or PDF
      <br />
      Max file size: <b>5 MB</b>
    </Note>
  )
}

function DefaultActionText() {
  const { brand } = useTheme()
  return (
    <>
      <UploadIcon
        height={18}
        width={18}
        color={brand.primary.default}
        style={{ marginRight: '5px' }}
      />
      <span style={{ color: brand.primary.default }}>Upload</span>
    </>
  )
}

const StyleOverwrite = styled.div`
  & *:focus {
    outline: none;
  }

  & .ant-upload-list-item {
    display: none;
  }

  .ant-upload.ant-upload-drag {
    border: none;
    .ant-upload.ant-upload-btn {
      padding: 0;
    }
  }
`

const Background = styled.div<{ uploaded: boolean }>`
  cursor: pointer;

  // generated using https://kovart.github.io/dashed-border-generator/
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23E8E8E8FF' stroke-width='3' stroke-dasharray='3%2c 7%2c 3%2c 7' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;

  ${props =>
    props.uploaded &&
    `
      height: auto;
      background-image: none;
      cursor: default;
    `}
`

const Container = styled.div<{ uploaded: boolean }>`
  padding: 3px;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  ${props =>
    props.uploaded &&
    `
      border: 1px solid #2f8dee;
    `}
`

const Center = styled.div`
  background: #f6f7f9;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const Note = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #777777;
`

const MockButton = styled.div`
  height: 51px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const ButtonGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`

export enum BLOCKCHAIN_ADDRESS_ACTIONS {
  SET_IS_BLOCKCHAIN_ADDRESSES_LOADING = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_IS_BLOCKCHAIN_ADDRESSES_LOADING',
  SET_BLOCKCHAIN_ADDRESS_LIST = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_BLOCKCHAIN_ADDRESS_LIST',
  SET_FI_LIST = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_FI_LIST',
  SET_MAX_ADDRESS_AMOUNT = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_MAX_ADDRESS_AMOUNT',
  SET_ADDRESS_QUOTA = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_ADDRESS_QUOTA',
  SET_BLOCKCHAIN_ADDRESS_DELETION = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_BLOCKCHAIN_ADDRESS_DELETION',
  SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS',
  UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS = 'BLOCKCHAIN_ADDRESS_ACTIONS.UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS',
  UPDATE_BLOCKCHAIN_ADDRESS = 'BLOCKCHAIN_ADDRESS_ACTIONS.UPDATE_BLOCKCHAIN_ADDRESS',
  SET_IS_WHITELISTING_IMPROVEMENT_ENABLED = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_IS_WHITELISTING_IMPROVEMENT_ENABLED',
}

import { USER_INFO_ACTIONS } from './const'
import { UserInfo, ActionTypes, UserProfile } from './types'
import { Dispatch, GetState } from '~/app/store/types'
import { ENDPOINTS, get, put } from '~/api'
import { keysToCamel } from '~/helpers/helpers'
import { setBankAccountList } from '~/app/store/bankAccounts/actions'
import { setKycType } from '~/app/store/verification/actions'
import { BankAccount, CURRENCY } from '~/types'
import { ROUTE_ACTIONS } from '../route/types'

export const setUserInfo = (userInfo: UserInfo) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: USER_INFO_ACTIONS.SET_USER_INFO,
    userInfo,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchUserInfo = (onSuccess?: () => void) => async (dispatch: Dispatch<any>) => {
  const resp = await get(ENDPOINTS.API_V3_USER)

  const userInfo: UserInfo = keysToCamel(resp)
  dispatch(setUserInfo(userInfo))

  const isEnabledBank = (item: BankAccount) => item.disabled === 'false'

  // TODO: have an API for bank accounts, so the actions can be separated
  if (userInfo.bankAccounts) {
    const bankAccounts = userInfo.bankAccounts.filter(isEnabledBank)
    dispatch(setBankAccountList(bankAccounts, CURRENCY.XSGD))
  }

  if (userInfo.usdcExtension?.bankAccounts) {
    const bankAccounts = userInfo.usdcExtension.bankAccounts.filter(isEnabledBank)
    dispatch(setBankAccountList(bankAccounts, CURRENCY.USDC))
  }

  if (userInfo.xusdExtension?.bankAccounts) {
    const bankAccounts = userInfo.xusdExtension.bankAccounts.filter(isEnabledBank)
    dispatch(setBankAccountList(bankAccounts, CURRENCY.XUSD))
  }

  dispatch(setKycType(resp.kyc_type?.toLowerCase()))

  dispatch({
    type: ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED,
    isUserInfoInitialised: true,
  })

  if (onSuccess) {
    onSuccess()
  }
}

export const updateUserAvatar = (
  {
    deleteAvatar,
    displayImage,
  }: {
    deleteAvatar?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayImage?: { fileData?: any; fileName?: any }
  },
  onSuccess?: () => void
) => async (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  const updatingInfo = {
    delete_avatar: deleteAvatar,
    display_image: displayImage
      ? {
          file_data: displayImage.fileData,
          file_name: displayImage.fileName,
        }
      : null,
  }
  const resp = await put(ENDPOINTS.API_V3_STABLECOIN_UPDATE_USER_PROFILE, updatingInfo)

  const { userProfile } = getState().userInfo
  if (userProfile && resp?.display_image?.file_data) {
    const newUserProfile: UserProfile = {
      ...userProfile,
      avatarUrl: resp.display_image.file_data,
    }
    dispatch(setUserProfile(newUserProfile))
  }

  if (onSuccess) {
    onSuccess()
  }
}

export const setUserProfile = (userProfile: UserProfile) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: USER_INFO_ACTIONS.SET_USER_PROFILE,
    userProfile,
  })
}

export const fetchUserProfile = (onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const resp = await get(ENDPOINTS.API_V3_STABLECOIN_USER_PROFILE)

  if (resp) {
    const userProfile = keysToCamel(resp)
    dispatch(setUserProfile(userProfile as UserProfile))
  }

  if (onSuccess) {
    onSuccess()
  }
}

import { Reducer } from 'redux'

import {
  ActionTypes,
  RetailPaymentDetails,
  TransactionDetails,
  XIDR_TRANSACTION_DETAILS_ACTIONS,
} from './types'

const initialState = {
  isLoading: false,
  isRetailPending: false,
  transactionId: '',
  transactionDetails: {
    key: '',
    transactionId: '',
    transactionType: '',
    description: {
      type: '',
      direction: '',
      data: '',
    },
    metadata: {
      fees: '',
      payment_method: '',
      retail_outlet_name: '',
    },
    transactionHash: null,
    transactionHashUrl: null,
    walletType: '',
    method: '',
    accountHolderName: '',
    amount: {
      prefix: '',
      postfix: '',
    },
    fee: '',
    tax: '',
    netAmount: {
      prefix: '',
      postfix: '',
    },
    createdDate: '',
    completedDate: '',
    status: '',
    note: null,
    sourceDestination: null,
    merchantDescription: null,
    network: null,
    bankAccountNo: null,
    bankName: null,
  },
  retailPaymentDetails: {
    retailOutletName: '',
    expiredAt: '',
    amount: '',
    fees: '',
    paymentCode: '',
  },
}

type State = {
  isLoading: boolean
  isRetailPending: boolean | null
  transactionId: string
  transactionDetails: TransactionDetails
  retailPaymentDetails: RetailPaymentDetails
}

export const xidrTransactionDetails: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case XIDR_TRANSACTION_DETAILS_ACTIONS.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case XIDR_TRANSACTION_DETAILS_ACTIONS.GET_XIDR_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: action.transactionDetails,
        retailPaymentDetails: action.retailPaymentDetails,
        isRetailPending: action.isRetailPending,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

import { Reducer } from 'redux'

import { ActionTypes, Wallet, OptionType, INTERNAL_TOOLS_ACTIONS } from './types'

const initialState = {
  isLoading: true,
  error: '',
  wallets: [],
  contractTypes: [],
  contractStates: [],
  selectedAccountId: undefined,
  selectedContractType: undefined,
  selectedContractState: undefined,
  selectedRole: undefined,
}

type State = {
  isLoading: boolean
  error: string
  wallets: Wallet[]
  contractTypes: OptionType[]
  contractStates: OptionType[]
  selectedAccountId: number | undefined
  selectedContractType: string | undefined
  selectedContractState: string | undefined
  selectedRole: string | undefined
}

export const internalTools: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case INTERNAL_TOOLS_ACTIONS.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.GET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
        isLoading: false,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.GET_CONTRACT_TYPES: {
      return {
        ...state,
        contractTypes: action.contractTypes,
        isLoading: false,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.GET_CONTRACT_STATES: {
      return {
        ...state,
        contractStates: action.contractStates,
        isLoading: false,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.CREATE_CONTRACT: {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.SET_SELECTED_ACCOUNT_ID: {
      return {
        ...state,
        selectedAccountId: action.selectedAccountId,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.SET_SELECTED_CONTRACT_TYPE: {
      return {
        ...state,
        selectedContractType: action.selectedContractType,
        selectedContractState: undefined,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.SET_SELECTED_CONTRACT_STATE: {
      return {
        ...state,
        selectedContractState: action.selectedContractState,
      }
    }

    case INTERNAL_TOOLS_ACTIONS.SET_SELECTED_ROLE: {
      return {
        ...state,
        selectedRole: action.selectedRole,
      }
    }

    default:
      return state
  }
}

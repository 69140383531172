/* eslint max-lines: off */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Collapse } from 'antd'
import {
  Document,
  useModal,
  Modal,
  Button,
  P1Bold,
  P1,
  H2,
  useTheme,
  DESKTOP_BREAKPOINT,
  Spin,
  straitsXTheme,
  BadgeIcon,
  Link,
  NEUTRAL_COLORS,
  Checkbox,
} from '@xfers/design-system'
import { TickLogo, SendLogo } from '@xfers/design-system/es/logos'

import { css } from 'emotion'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '~/app/store/types'
import PeriodicReviewDoneModal from './PeriodicReviewDoneModal'
import { getScreenFromLastSavedStep } from '~/app/store/verification/actions'
import { ENDPOINTS, post } from '~/api'
import { setIsPeriodicReviewRequired } from '~/app/store/landingPage/actions'

type Props = {
  accountId?: number
  showReverifyButton?: boolean
  showUpdateButton?: boolean
  showConfirmButton?: boolean
  titleText: string
  checkboxText: JSX.Element
  setModalNumber?: React.Dispatch<React.SetStateAction<number>>
}

export default function({
  accountId,
  showReverifyButton,
  showUpdateButton,
  showConfirmButton,
  titleText,
  checkboxText,
  setModalNumber,
}: Props) {
  const dispatch = useDispatch()
  const { brand } = useTheme()
  const { setModal } = useModal()
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(true)
  const { Panel } = Collapse
  const empty = '-NIL-'
  useEffect(() => {
    dispatch(getScreenFromLastSavedStep())
  }, [])

  const { userDetails, myInfoOnlyDetails } = useSelector((state: RootState) => state.verification)
  useEffect(() => {
    if (userDetails.fullName && myInfoOnlyDetails.race !== undefined) {
      setLoading(false)
    }
  }, [userDetails.fullName, myInfoOnlyDetails.race])

  const {
    fullName,
    dateOfBirth,
    countryOfBirth,
    nationality,
    gender,
    addressLine1,
    addressLine2,
    country,
    postalCode,
    idNumber,
    expiryDate,
    occupation,
    employmentSector,
    employer,
    annualIncomeRange,
  } = userDetails
  let addressString = ''
  if (addressLine1 !== undefined && addressLine1 !== '') addressString += addressLine1 + ' '
  if (addressLine2 !== undefined && addressLine2 !== '') addressString += addressLine2 + ' '
  if (country !== undefined && country !== '') addressString += country + ' '
  if (postalCode !== undefined && postalCode !== '') addressString += postalCode

  let dateOfBirthString = ''
  if (dateOfBirth !== undefined && dateOfBirth !== '') {
    dateOfBirthString = dateOfBirth.format('MM/DD/YYYY').toString()
  }

  let expiryDateString = ''
  if (expiryDate !== undefined && expiryDate !== '') {
    expiryDateString = expiryDate.format('MM/DD/YYYY').toString()
  }

  const { race, aliasName, residentialStatus } = myInfoOnlyDetails
  const reviewModal = css`
    max-width: 800px !important;
  `

  const styledPanel = css`
    padding: 15px 0;
    margin: 0 30px;
    background: 'white';

    .ant-collapse-content {
      border-top: 0;
    }

    :last-child {
      margin: 0;
      padding: 15px 30px;
    }
  `

  return (
    <Modal
      className={reviewModal}
      title={titleText}
      icon={<BadgeIcon type="success" icon={<Document color={brand.primary.default} />} />}
      disabledCloseIcon
      footer={
        <>
          {showReverifyButton && (
            <ReverifyButton type="primary" disabled={!checked || loading}>
              <Link
                target="_blank"
                href="https://support.straitsx.com/hc/en-us/requests/new?ticket_form_id=900001857566"
              >
                Request to reverify
              </Link>
            </ReverifyButton>
          )}

          {showUpdateButton && (
            <UpdateButton
              type="secondary"
              onClick={() => {
                if (accountId !== null && accountId !== undefined) {
                  setLoading(true)
                  let header = 'Email Sent!'
                  let message = 'Please refer to your email inbox for further action needed.'
                  const requestBody = {
                    account_id: accountId,
                  }
                  post(
                    ENDPOINTS.API_V3_STABLECOIN_PERIODIC_REVIEW,
                    requestBody,
                    {},
                    `/change_send_email`
                  )
                    .catch(() => {
                      header = 'Request Not Submitted'
                      message =
                        'Periodic Review not submitted yet due to error. Please click try again later.'
                    })
                    .finally(() => {
                      setLoading(false)
                      setModal(
                        <PeriodicReviewDoneModal
                          header={header}
                          message={message}
                          logo={
                            header === 'Email Sent!' ? (
                              <SendLogo height="200px" width="200px" />
                            ) : (
                              <div />
                            )
                          }
                          setModalNumber={setModalNumber}
                        />
                      )
                    })
                }
              }}
              disabled={!checked || loading}
            >
              Update Info
            </UpdateButton>
          )}

          {showConfirmButton && (
            <ConfirmButton
              type="primary"
              onClick={() => {
                if (accountId !== null && accountId !== undefined) {
                  setLoading(true)
                  let header = 'Thank you for your response!'
                  let message = 'No further action needed.'
                  const requestBody = {
                    account_id: accountId,
                  }
                  post(
                    ENDPOINTS.API_V3_STABLECOIN_PERIODIC_REVIEW,
                    requestBody,
                    {},
                    `/periodic_review_done`
                  )
                    .then(resp => {
                      dispatch(setIsPeriodicReviewRequired(resp.review_required))
                    })
                    .catch(() => {
                      header = 'There has been an error'
                      message =
                        'Periodic Review not submitted yet due to error. Please click try again later.'
                    })
                    .finally(() => {
                      setLoading(false)
                      setModal(
                        <PeriodicReviewDoneModal
                          header={header}
                          message={message}
                          logo={
                            header === 'Thank you for your response!' ? (
                              <TickLogo height="200px" width="200px" />
                            ) : (
                              <div />
                            )
                          }
                          setModalNumber={setModalNumber}
                        />
                      )
                    })
                }
              }}
              disabled={!checked || loading}
            >
              Confirm
            </ConfirmButton>
          )}
        </>
      }
    >
      <P1 style={{ marginTop: '10px' }}>
        Please ensure your information is updated and accurate. Your information is collected to
        verify your identity and to meet the requirements of all applicable laws and financial
        regulations.
      </P1>
      <br />
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      ) : (
        <Collapse
          style={{
            backgroundColor: 'white',
            borderRadius: '5px',
            borderColor: NEUTRAL_COLORS.G200,
          }}
          accordion
          defaultActiveKey={['1']}
        >
          <Panel header={<H2>Personal Details</H2>} key="1" className={styledPanel}>
            <SplitContainer>
              <Half>
                <UserBarBody>
                  <Details>
                    <Header>Full Name</Header>
                    <Content>{fullName || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Gender</Header>
                    <Content>{gender || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Country of Birth</Header>
                    <Content>{countryOfBirth || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Race</Header>
                    <Content>{race || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Registered Address</Header>
                    <Content>{addressString || empty}</Content>
                  </Details>
                </UserBarBody>
              </Half>
              <Half>
                <UserBarBody>
                  <Details>
                    <Header>Alias Name</Header>
                    <Content>{aliasName || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Date of Birth</Header>
                    <Content data-cy="profileEmail">{dateOfBirthString || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Nationality</Header>
                    <Content data-cy="profileMobileNumber">{nationality || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Residential Status</Header>
                    <Content data-cy="profileStatus">{residentialStatus || empty}</Content>
                  </Details>
                </UserBarBody>
              </Half>
            </SplitContainer>
          </Panel>

          <Panel header={<H2>ID Details</H2>} key="2" className={styledPanel}>
            <SplitContainer>
              <Half>
                <UserBarBody>
                  <Details>
                    <Header>NRIC / ID Number</Header>
                    <Content>{idNumber || empty}</Content>
                  </Details>
                </UserBarBody>
              </Half>
              <Half>
                <UserBarBody>
                  <Details>
                    <Header>Expiry Date</Header>
                    <Content>{expiryDateString || empty}</Content>
                  </Details>
                </UserBarBody>
              </Half>
            </SplitContainer>
          </Panel>

          <Panel header={<H2>Occupation Details</H2>} key="3" className={styledPanel}>
            <SplitContainer>
              <Half>
                <UserBarBody>
                  <Details>
                    <Header>Occupation</Header>
                    <Content>{occupation || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Name of Employer</Header>
                    <Content>{employer || empty}</Content>
                  </Details>
                </UserBarBody>
              </Half>
              <Half>
                <UserBarBody>
                  <Details>
                    <Header>Employment Sector</Header>
                    <Content>{employmentSector || empty}</Content>
                  </Details>
                  <Details>
                    <Header>Annual Income(S$)</Header>
                    <Content>{annualIncomeRange || empty}</Content>
                  </Details>
                </UserBarBody>
              </Half>
            </SplitContainer>
          </Panel>
        </Collapse>
      )}
      <br />
      <CustomCheckbox
        data-cy="checkbox-directorBo"
        value={checked ? [true] : [false]}
        options={[
          {
            label: checkboxText,
            value: true,
          },
        ]}
        onChange={() => {
          setChecked(!checked)
        }}
      ></CustomCheckbox>
    </Modal>
  )
}

const CustomCheckbox = styled(Checkbox.Group)`
  margin-bottom: 20px;

  & .ant-checkbox-group-item {
    border: 1px solid ${NEUTRAL_COLORS.G200};

    &:hover {
      border: 1px solid ${NEUTRAL_COLORS.G200};
    }

    &:focus-within {
      box-shadow: none;
      border: 1px solid ${NEUTRAL_COLORS.G200};
    }
  }

  & .ant-checkbox-wrapper-checked {
    border: 1px solid ${NEUTRAL_COLORS.BLACK};

    &:hover {
      border: 1px solid ${NEUTRAL_COLORS.BLACK};
    }

    &:focus-within {
      border: 1px solid ${NEUTRAL_COLORS.BLACK};
    }
  }

  .ant-checkbox-checked::after {
    border: none;
  }
`

const SplitContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const Half = styled.div`
  width: 50%;
  padding-left: 2.5%;
`
const UserBarBody = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
`

const Details = styled.div`
  flex-grow: 1;
  margin-bottom: 20px;

  ${DESKTOP_BREAKPOINT} {
    align: left;
  }
`

const Content = styled(P1)`
  height: auto;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
`

const Header = styled(P1Bold)`
  margin-bottom: 5px;
  overflow-wrap: break-word;
`

const UpdateButton = styled(Button)`
  &.ant-btn {
    padding: 15px 20px !important;
    color: ${straitsXTheme.brand.primary.default} !important;
  }
  &.ant-btn:disabled {
    background-color: #fff;
    color: ${straitsXTheme.brand.primary.default};
  }
  &.ant-btn:disabled:hover {
    background-color: #fff;
    color: ${straitsXTheme.brand.primary.default};
  }
`

const ConfirmButton = styled(Button)`
  &.ant-btn {
    padding: 15px 20px !important;
    margin-left: 10px !important;
    opacity: 1;
  }
  &.ant-btn:disabled {
    background-color: ${straitsXTheme.brand.primary.default};
    opacity: 0.5;
    color: #ffffff;
  }
  &.ant-btn:disabled:hover {
    background-color: ${straitsXTheme.brand.primary.default};
    opacity: 0.5;
    color: #ffffff;
`
const ReverifyButton = styled(Button)`
  &.ant-btn {
    padding: 15px 20px !important;
    margin-left: 10px !important;
    opacity: 1;
  }
  &.ant-btn:disabled {
    background-color: ${straitsXTheme.brand.primary.default};
    opacity: 0.5;
    color: #ffffff;
  }
  &.ant-btn:disabled:hover {
    background-color: ${straitsXTheme.brand.primary.default};
    opacity: 0.5;
    color: #ffffff;
`

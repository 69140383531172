import { createSelector } from 'reselect'
import { RootState } from '~/biz/store/types'
import { BankAccount, CURRENCY, STABLECOIN } from '~/types'

const defaultCurrency = CURRENCY.XSGD

const selectBankAccountStore = (state: RootState) => state.bankAccounts

const selectBankAccountStoreByCurrency = createSelector(
  [selectBankAccountStore],
  bankAccountStore => (stablecoin: STABLECOIN = defaultCurrency) => bankAccountStore[stablecoin]
)

const selectBankAccountListByCurrency = createSelector(
  [selectBankAccountStore],
  bankAccountStore => (stablecoin: STABLECOIN = defaultCurrency) => ({
    isBankAccountListInitiated: bankAccountStore[stablecoin]?.isBankAccountListInitiated || false,
    bankAccountList: bankAccountStore[stablecoin]?.bankAccountList || [],
  })
)

const selectBankAccountListForWithdrawal = createSelector(
  [selectBankAccountStore],
  bankAccountStore => (stablecoin: STABLECOIN = defaultCurrency) => {
    const isXsgdBankAccountListInitiated =
      bankAccountStore[CURRENCY.XSGD]?.isBankAccountListInitiated || false
    const isUsdcBankAccountListInitiated =
      bankAccountStore[CURRENCY.USDC]?.isBankAccountListInitiated || false
    const isXusdBankAccountListInitiated =
      bankAccountStore[CURRENCY.XUSD]?.isBankAccountListInitiated || false
    const XsgdBankAccountList = bankAccountStore[CURRENCY.XSGD]?.bankAccountList || []
    const UsdcBankAccountList = bankAccountStore[CURRENCY.USDC]?.bankAccountList || []
    const XusdBankAccountList = bankAccountStore[CURRENCY.XUSD]?.bankAccountList || []
    const bankAccountList = [...XsgdBankAccountList, ...UsdcBankAccountList, ...XusdBankAccountList]
    const isBankAccountListInitiated =
      isXsgdBankAccountListInitiated && isUsdcBankAccountListInitiated
    // isXusdBankAccountListInitiated
    let withdrawableBankAccountList: BankAccount[] = []

    switch (stablecoin) {
      case CURRENCY.XSGD:
        withdrawableBankAccountList = bankAccountList.filter(bankAccount => !!bankAccount.bankId)
        break
      case CURRENCY.USDC:
      case CURRENCY.XUSD:
        withdrawableBankAccountList = bankAccountList.filter(bankAccount => !!bankAccount.swiftCode)
    }

    return { isBankAccountListInitiated, bankAccountList: withdrawableBankAccountList }
  }
)

const selectBankListByCurrency = createSelector(
  [selectBankAccountStore],
  bankAccountStore => (stablecoin: STABLECOIN = defaultCurrency) => ({
    isBankListInitiated: bankAccountStore[stablecoin]?.isBankListInitiated || false,
    bankList: bankAccountStore[stablecoin]?.bankList || [],
  })
)

const selectIsAllCurrenciesBankAccountListInitiated = createSelector(
  [selectBankAccountStoreByCurrency],
  bankAccountStore => {
    return (
      bankAccountStore(CURRENCY.XSGD).isBankAccountListInitiated &&
      bankAccountStore(CURRENCY.USDC).isBankAccountListInitiated &&
      bankAccountStore(CURRENCY.XUSD).isBankAccountListInitiated
    )
  }
)

const selectAllCurrenciesBankAccountList = createSelector(
  [selectBankAccountStoreByCurrency],
  bankAccountStore => {
    return [
      ...bankAccountStore(CURRENCY.XSGD).bankAccountList,
      ...bankAccountStore(CURRENCY.USDC).bankAccountList,
      ...bankAccountStore(CURRENCY.XUSD).bankAccountList,
    ]
  }
)

export {
  selectBankAccountStoreByCurrency,
  selectBankAccountListByCurrency,
  selectBankAccountListForWithdrawal,
  selectBankListByCurrency,
  selectIsAllCurrenciesBankAccountListInitiated,
  selectAllCurrenciesBankAccountList,
}

import React from 'react'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, H2, NEUTRAL_COLORS, Link } from '@xfers/design-system'

const SandboxBanner = () => {
  return (
    <Container>
      <Title>Sandbox mode</Title>
      <Divider />
      <Subtitle>
        All transactions made are simulated.{' '}
        <Link
          target="_blank"
          href="https://support.straitsx.com/hc/en-us/articles/4408009833497-What-is-a-Sandbox-Account-"
          rel="noreferrer noopener"
        >
          Learn more
        </Link>
      </Subtitle>
    </Container>
  )
}

export default SandboxBanner

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  box-shadow: 0px 2px 8px rgba(0, 169, 101, 0.2);
  padding: 20px 15px;

  ${DESKTOP_BREAKPOINT} {
    position: fixed;
    top: 0;
    width: calc(100% - 256px);
    z-index: 2; // because of SwitchItem in landing page
  }
`

const Title = styled(H2)`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;

  ${DESKTOP_BREAKPOINT} {
    font-weight: 600;
  }
`
const Divider = styled.span`
  border-left: 1px solid ${NEUTRAL_COLORS.BLACK};
  height: 20px;
  margin: 0 15px;
`
const Subtitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 0;
`

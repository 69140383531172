import moment from 'moment'
import { createSelector } from 'reselect'
import { RootState } from '~/app/store/types'
import { CURRENCY, DGWAccountLimit } from '~/types'
import { Account, AccountDetail, Wallet, WALLET_TYPES, ACCOUNT_TYPES } from './types'

const selectAccountStore = (state: RootState) => state.accounts

const selectAccountDetailList = (state: RootState) => state.accounts.accountDetailList || []

const selectAccountList = (state: RootState) => state.accounts.accountList || []

const selectDigitalGoodAccounts = createSelector([selectAccountList], (accountList: Account[]) => {
  return accountList && accountList.filter(acc => acc.id === WALLET_TYPES.DIGITAL_GOODS)
})

const hasDigitalGoodAccounts = createSelector(
  [selectAccountList],
  (accountList: Account[]) =>
    accountList && !!accountList.find(acc => acc.id === WALLET_TYPES.DIGITAL_GOODS)
)

// Currently, this dashboard only uses DGW with personal account type
export const selectDgwAccountByCurrency = createSelector(
  [selectDigitalGoodAccounts],
  accounts => (currency: CURRENCY | null) =>
    accounts.find(acc => acc.currency === currency && acc.type === ACCOUNT_TYPES.PERSONAL)
)

export const selectSGDWalletByTypeId = createSelector(
  [selectAccountList],
  accounts => (walletTypeId: number | undefined) =>
    walletTypeId
      ? accounts.find(acc => acc.id === walletTypeId && acc.currency === CURRENCY.SGD)
      : undefined
)

export const selectUserWallets = createSelector([selectAccountList], accounts => {
  const wallets: Wallet[] = accounts.map(acc => {
    let label: string

    if (acc.id === WALLET_TYPES.GENERAL) {
      label = 'General Wallet'
    } else if (acc.id === WALLET_TYPES.DIGITAL_GOODS) {
      label = 'Digital Goods Wallet'
    } else if (acc.id === WALLET_TYPES.TEMPORARY_HOLDING_BALANCE) {
      label = 'Temporary Holding Balance'
    } else {
      label = 'Account withdraw not supported yet, its probably a bug'
    }

    return {
      label,
      type: acc.id,
      balance: acc.balance,
      formattedBalance: acc.formattedBalance,
      accountId: acc.accountId,
      accountType: acc.type,
      fundPoolId: acc.fundPoolId,
    }
  })

  return wallets
})

const parseAccountLimits = (accountDetail: AccountDetail | undefined): DGWAccountLimit => {
  const limits = accountDetail?.attributes?.limitations
  const incrementDate = accountDetail?.attributes?.transactionLimitIncrementDate

  return {
    holdingRemaining: limits?.storage?.remaining || '0',
    holdingMax: limits?.storage?.max || '0',
    annualTransactionRemaining: limits?.chargeRollingYearly?.remaining || '0',
    annualTransactionMax: limits?.chargeRollingYearly?.max || '0',
    withdrawalDailyRemaining: limits?.withdrawalDaily?.remaining || '0',
    withdrawalDailyMax: limits?.withdrawalDaily?.max || '0',
    limitationIncreaseAmount: incrementDate?.sum || '0',
    limitationIncreaseDate: incrementDate?.date ? moment(incrementDate.date) : undefined,
  } as DGWAccountLimit
}

// Currently, this dashboard only uses DGW with personal account type
export const selectDgwAccountLimitByCurrency = createSelector(
  [selectAccountDetailList],
  accounts => (currency: CURRENCY | null) => {
    const digitalGoodsWallet = accounts.find(
      acc =>
        acc.attributes.walletId === WALLET_TYPES.DIGITAL_GOODS &&
        acc.attributes.currency === currency &&
        acc.attributes.type === ACCOUNT_TYPES.PERSONAL
    )

    return parseAccountLimits(digitalGoodsWallet)
  }
)

const selectTempHoldingAccountDetail = createSelector(
  [selectAccountDetailList],
  accountDetails =>
    accountDetails &&
    accountDetails.find(acc => acc.attributes.walletId === WALLET_TYPES.TEMPORARY_HOLDING_BALANCE)
)

const selectTempHoldingAccountLimit = createSelector(
  [selectTempHoldingAccountDetail],
  accountDetail => parseAccountLimits(accountDetail)
)

const selectIsAccountListInitiated = createSelector(
  [selectAccountStore],
  store => store.isAccountListInitiated
)

const selectIsLinkedToAsb = createSelector([selectAccountDetailList], accounts => {
  const firstAccount = accounts?.[0]

  if (!accounts || !firstAccount) return false
  return !!firstAccount.attributes.asb
})

export {
  selectAccountStore,
  selectAccountDetailList,
  selectAccountList,
  hasDigitalGoodAccounts,
  selectDigitalGoodAccounts,
  selectTempHoldingAccountDetail,
  selectTempHoldingAccountLimit,
  selectIsAccountListInitiated,
  selectIsLinkedToAsb,
}

import { Reducer } from 'redux'
import { ActionTypes, MintInstruction, XIDR_MINT_ACTIONS, SetupMode } from './types'

const initialState: State = {
  mintInstructions: null,
  isMintDashboardEnabled: null,
  setupMode: SetupMode.CREATE,
}

type State = {
  mintInstructions: null | MintInstruction[]
  isMintDashboardEnabled: null | boolean
  setupMode: SetupMode
}

export const xidrMint: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_MINT_ACTIONS.SET_MINT_INSTRUCTIONS:
      return {
        ...state,
        mintInstructions: action.mintInstructions,
      }
    case XIDR_MINT_ACTIONS.SET_MINT_DASHBOARD_ENABLED:
      return {
        ...state,
        isMintDashboardEnabled: action.enabled,
      }
    case XIDR_MINT_ACTIONS.SET_SETUP_MODE:
      return {
        ...state,
        setupMode: action.setupMode,
      }
    default:
      return state
  }
}

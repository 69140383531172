import { XIDR_ACCOUNT_ACTIONS } from './const'

// DATA TYPES
export enum ACCOUNT_CURRENCIES {
  SGD = 'sgd',
  IDR = 'idr',
  XSGD = 'xsgd',
  XIDR = 'xidr',
}

export enum ACCOUNT_TYPES {
  PERSONAL = 'PersonalAccount',
  CORPORATE = 'CorporateAccount',
  BUSINESS = 'BusinessAccount',
  TEMPORARY_HOLDING_BALANCE = 'TemporaryHoldingBalanceAccount',
  BUSINESS_SETTLEMENT = 'BusinessSettlementAccount',
  MANAGED = 'ManagedAccount',
  SOBATKU_PERSONAL = 'SobatkuPersonalAccount',
}

export type AccountTransactionLimitIncrementDate = {
  sum: string
  date: string
}

export enum WALLET_TYPES {
  GENERAL = 1,
  DIGITAL_GOODS = 2,
  TEMPORARY_HOLDING_BALANCE = 9,
  INDO_GENERAL_FUNDS = 2354,
}

export type AccountLimitation = {
  max: string
  remaining: string
  used: string
}

export type AccountDetailAttributes = {
  walletId: WALLET_TYPES
  fundPoolId: number
  type: ACCOUNT_TYPES
  currency: ACCOUNT_CURRENCIES
  deletedAt: string
  title: string
  balance: string
  walletName: string
  limitations: {
    [key: string]: AccountLimitation
  }
  transactionLimitIncrementDate: AccountTransactionLimitIncrementDate
}

export type AccountDetail = {
  id: string
  attributes: AccountDetailAttributes
}

export enum ACCOUNT_NAMES {
  GENERAL_WALLET = 'General Wallet',
  DIGITAL_GOODS = 'Digital Goods',
}

export type Account = {
  id: WALLET_TYPES
  balance: string
  type: ACCOUNT_TYPES
  name: ACCOUNT_NAMES
  formattedBalance: string
  settlementBalance?: string
  totalBalance?: string
  formattedAvailableBalance?: string
  formattedSettlementBalance?: string
  formattedTotalBalance?: string
  uniqueId: string
  accountId: number
  fundPoolId: number
}

export type AccountGeneralLimits = {
  holdingRemaining: string
  holdingMax: string
  annualTransactionRemaining: string
  annualTransactionMax: string
}
// END DATA TYPES

// ACTION TYPES
type SetAccountList = {
  type: typeof XIDR_ACCOUNT_ACTIONS.SET_ACCOUNT_LIST
  accountList: Account[]
}

type SetAccountDetailList = {
  type: typeof XIDR_ACCOUNT_ACTIONS.SET_ACCOUNT_DETAIL_LIST
  accountDetailList: AccountDetail[]
}
// END ACTION TYPES

export type ActionTypes = SetAccountDetailList | SetAccountList

/* eslint-disable max-lines */
import { Reducer } from 'redux'

import { ActionTypes, CallbackSettings, DEVELOPER_TOOLS_ACTIONS, V3Key } from './types'

const initialState = {
  isV3Merchant: false,

  isLoading: false,
  verificationStatus: '',
  callbackSettings: {
    V3: {
      secret: '',
      callbackUsdVa: '',
      callbackPayment: '',
      callbackPayout: '',
      callbackSwap: '',
      callbackStablecoinWithdraw: '',
    },
  },
  apiKeys: {
    V3: {
      v3Keys: [],
      isLoading: false,
    },
  },
}

type State = {
  isV3Merchant: boolean

  isLoading: boolean
  verificationStatus: string
  callbackSettings: {
    V3: CallbackSettings & {
      secret: string
    }
  }
  apiKeys: {
    V3: {
      v3Keys: V3Key[]
      isLoading: boolean
    }
  }
}

export const straitsXDeveloperTools: Reducer<State, ActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DEVELOPER_TOOLS_ACTIONS.SET_API_KEY_VERSION_V3: {
      const { isV3Merchant } = action
      return {
        ...state,
        isV3Merchant,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.GET_INITIAL_URL_V3: {
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: {
            ...state.callbackSettings.V3,
            ...action.callbackSettings,
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URL: {
      const { apiVersion, callbackType, callbackUrl } = action

      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          [apiVersion]: {
            ...state.callbackSettings[apiVersion],
            [callbackType]: callbackUrl,
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.UPDATE_CALLBACK_URL: {
      return {
        ...state,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.RESET_API_KEYS: {
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          V3: {
            ...state.apiKeys.V3,
            v3Keys: [],
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.FETCH_API_KEY: {
      const { apiKeys, apiVersion } = action

      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          [apiVersion]: {
            ...state.apiKeys[apiVersion],
            v3Keys: apiKeys,
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING: {
      const { isLoading } = action
      return {
        ...state,
        isLoading,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_V3_SECRET: {
      const { secret } = action
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: {
            ...state.callbackSettings.V3,
            secret,
          },
        },
      }
    }

    default:
      return state
  }
}

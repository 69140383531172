import { Reducer } from 'redux'
import { SWAP_ACTIONS } from './const'
import { ActionTypes, FXQuote, FX_ACTION_STATUS, SwapTransactionLimit } from './types'

type State = {
  fxRate: number | undefined
  fxRateStatus: FX_ACTION_STATUS
  fxQuote: FXQuote | undefined
  fxQuoteStatus: FX_ACTION_STATUS
  transactionLimits: SwapTransactionLimit[] | undefined
}

const initialState: State = {
  fxRateStatus: FX_ACTION_STATUS.NOT_FETCHED,
  fxRate: undefined,
  fxQuote: undefined,
  fxQuoteStatus: FX_ACTION_STATUS.NOT_FETCHED,
  transactionLimits: undefined,
}

export const swap: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case SWAP_ACTIONS.SET_SWAP_FX_RATE: {
      return {
        ...state,
        fxRate: action.data,
      }
    }

    case SWAP_ACTIONS.SET_SWAP_FX_RATE_STATUS: {
      return {
        ...state,
        fxRateStatus: action.status,
      }
    }

    case SWAP_ACTIONS.SET_SWAP_FX_QUOTE: {
      return {
        ...state,
        fxQuote: action.data,
      }
    }

    case SWAP_ACTIONS.SET_SWAP_FX_QUOTE_STATUS: {
      return {
        ...state,
        fxQuoteStatus: action.status,
      }
    }

    case SWAP_ACTIONS.SET_SWAP_TRANSACTION_LIMITS: {
      return {
        ...state,
        transactionLimits: action.transactionLimits,
      }
    }

    default:
      return state
  }
}

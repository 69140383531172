import { createSelector } from 'reselect'
import { RootState } from '~/xidrBiz/store/types'
import { Account, AccountDetail, AccountGeneralLimits, WALLET_TYPES } from './types'

const selectXIDRAccountDetailList = (state: RootState) => state.xidrAccount.accountDetailList || []

const selectXIDRAccountList = (state: RootState) => state.xidrAccount.accountList || []

const selectXIDRDigitalGoodAccount = createSelector(
  [selectXIDRAccountList],
  (accountList: Account[]) =>
    accountList && accountList.find(acc => acc.id === WALLET_TYPES.DIGITAL_GOODS)
)

const selectXIDRTempHoldingAccount = createSelector(
  [selectXIDRAccountList],
  (accountList: Account[]) =>
    accountList && accountList.find(acc => acc.id === WALLET_TYPES.TEMPORARY_HOLDING_BALANCE)
)

const hasXIDRDigitalGoodAccount = createSelector(
  [selectXIDRAccountList],
  (accountList: Account[]) =>
    accountList && !!accountList.find(acc => acc.id === WALLET_TYPES.DIGITAL_GOODS)
)

const selectGeneralAccountLimit = createSelector(
  [selectXIDRAccountDetailList],
  (accountDetailList: AccountDetail[]): AccountGeneralLimits | null => {
    const generalAccount = accountDetailList.find(
      accDetail => accDetail.attributes.walletId === WALLET_TYPES.GENERAL
    )
    if (!generalAccount) {
      return null
    }

    const storageLimit = generalAccount.attributes.limitations.storage
    const yearlyLimit = generalAccount.attributes.limitations.charge_rolling_yearly

    if (!yearlyLimit && !storageLimit) {
      return null
    }

    return {
      holdingMax: storageLimit?.max || '0',
      holdingRemaining: storageLimit?.remaining || '0',
      annualTransactionMax: yearlyLimit?.max || '0',
      annualTransactionRemaining: yearlyLimit?.remaining || '0',
    }
  }
)

export {
  selectXIDRAccountDetailList,
  selectXIDRAccountList,
  hasXIDRDigitalGoodAccount,
  selectXIDRDigitalGoodAccount,
  selectGeneralAccountLimit,
  selectXIDRTempHoldingAccount,
}

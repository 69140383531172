/* eslint-disable no-extend-native */
// Extending the String Prototype

String.prototype.removeSpace = function removeSpace() {
  return this.replace(/\s/g, '')
}

String.prototype.toCamelCase = function toCamelCase() {
  return `${this.charAt(0).toLowerCase()}${this.slice(1)}`
}

String.prototype.capitalize = function capitalize() {
  return `${this.charAt(0).toUpperCase()}${this.slice(1)}`
}

String.prototype.ellipsis = function ellipsis(head: number, tail: number) {
  if (head + tail >= this.length) return this.toString()
  return `${this.slice(0, head)}...${this.slice(-tail)}`
}

String.prototype.isSwiftCharSet = function isSwiftCharSet() {
  return /^[A-Za-z0-9\s/\-?().+:,']+$/.test(this.toString())
}

String.prototype.isAlphaNumeric = function isAlphaNumeric() {
  return /^[A-Za-z0-9]+$/.test(this.toString())
}

export {}

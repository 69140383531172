import { BlockchainNetwork, CURRENCY } from '~/types'

export const SESSION_STORAGE_VALUES = {
  hasNewAddressModalAcknowledged: 'newAddressModalAcknowledged',
  dismissXusdTooltip: 'dismissXusdTooltip',
} as const

export const LOCAL_STORAGE_VALUES = {
  myInfoState: 'myInfoState',
} as const

export const ETH_WITHDRAW_FEES_CAP = {
  biz: {
    fee: 30,
  },
  personal: {
    fee: 30,
  },
} as const

export const POLYGON_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 1,
  },
  biz: {
    fee: 5,
  },
} as const

export const AVALANCHE_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 1,
  },
  biz: {
    fee: 1,
  },
} as const

export const TRON_WITHDRAW_FEES_CAP = {
  personal: {
    fee: 2,
  },
  biz: {
    fee: 2,
  },
} as const

export const coolingDownThresholdInMinutes = 720

export const SUPPORT_LINKS = {
  NEW_REQUEST: 'https://support.straitsx.com/hc/en-us/requests/new',
  KILLSWITCH_SUPPORT: 'https://support.straitsx.com/hc/en-us/articles/32688405035289-Kill-Switch',
  TRANSFERRING_XSGD:
    'https://support.straitsx.com/hc/en-us/articles/4410444764697-Transferring-XSGD-In',
  LEARN_MORE_XSGD: 'https://support.straitsx.com/hc/en-us/categories/4410287615641-XSGD',
  LEARN_MORE_XUSD: 'https://www.straitsx.com/xusd',
  TRANSFERRING_USDT:
    'https://support.straitsx.com/hc/en-us/articles/30827535516825-Transferring-USDT-In',
  TRANSFERRING_USDC:
    'https://support.straitsx.com/hc/en-us/articles/12751247588505-Transferring-USDC-In',
  WHITELISTING_REQUEST:
    'https://support.straitsx.com/hc/en-us/articles/4410434003993-How-do-I-submit-a-whitelisting-request-for-my-Singapore-based-account',
  SETUP_2FA_LINK:
    'https://support.straitsx.com/hc/en-us/articles/4410444863129-How-to-update-2-Factor-Authentication-2FA-device-or-app',
  WHAT_IS_XSGD: 'https://support.straitsx.com/hc/en-us/articles/4410373376409-What-is-XSGD',
  WHAT_IS_XIDR: 'https://support.straitsx.com/hc/en-us/articles/10744883323929-What-is-XIDR',
  TERMS_OF_SERVICE: 'https://www.straitsx.com/sg/terms-of-service-new',
}
export const usdBasedCurrency = [CURRENCY.USDC, CURRENCY.XUSD, CURRENCY.USDT]

export const BLOCKCHAIN_NETWORKS = {
  ETHEREUM: 'ethereum',
  AVALANCHE: 'avalanche',
  TRON: 'tron',
  POLYGON: 'polygon',
  ZILLIQA: 'zilliqa',
  HEDERA: 'hedera',
} as const

export const BLOCKCHAIN_NETWORK_ARRAY = Object.values(BLOCKCHAIN_NETWORKS)
export const EVM_COMPATIBLE_NETWORK = 'evm-compatible-network'
export const EVM_COMPATIBLE_BLOCKCHAIN_NETWORKS: BlockchainNetwork[] = [
  'ethereum',
  'polygon',
  'avalanche',
]

export const BLOCKCHAIN_NETWORKS_WITH_FEES: BlockchainNetwork[] = [
  ...EVM_COMPATIBLE_BLOCKCHAIN_NETWORKS,
  'zilliqa',
  'tron',
]

export const BLOCKCHAIN_NETWORKS_WITHOUT_FEES_ESTIMATION: BlockchainNetwork[] = ['tron']
/* eslint-disable max-lines */
import {
  BankAccount,
  BlockchainNetwork,
  FinancialInstitutionName,
  WALLET_TYPES,
  STABLECOIN,
  CURRENCY,
} from '~/types'
import { BlockchainAddress } from '~/app/store/blockchainAddresses/types'

export type TemporaryHoldingBalance = {
  totalBalance: string
  withdrawDailyRemaining: string
}

export type DepositRequest = {
  amount: string
  bank_account: {
    account_no: string
    bank_name: string
    bank_abbrev: string
  }
  created_at: string
  expired_at: string
}

export type DashboardBanner = {
  title: string
  tag: string
  content: string
}

export type FinancialInstitution = {
  id: number
  name: FinancialInstitutionName
  enabled: boolean
}

export type StablecoinFee = {
  amount: number | undefined
  identifier: string | undefined
  timestamp: string | undefined
}

export type PersonalVerificationStatus = 'initial' | 'pending' | 'verified' | 'rejected'
export type FeeType = 'PercentageFeeInstruction' | 'FixedFeeInstruction'

export type FiatFee = {
  amount: number | undefined
  feeType: FeeType | undefined
  rate: number | undefined
  maxAmount: number | undefined
  minAmount: number | undefined
}

export enum TRANSFER_METHOD {
  // eslint-disable-next-line no-shadow
  STABLECOIN = 'stablecoin',
  BANK = 'bank',
}
export type BankTransferNetwork = BANK_TRANSFER_NETWORKS | null

export type WithdrawWalletIdType =
  | WALLET_TYPES.DIGITAL_GOODS
  | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE
  | undefined

export enum BANK_TRANSFER_NETWORKS {
  LOCAL = 'local',
}

export type WithdrawLimits = {
  [key in STABLECOIN]: {
    FIAT_MIN: number
    FIAT_MAX: number
    STABLECOIN_MAX: number
    STABLECOIN_MIN: number
  }
}

export enum LANDING_PAGE_ACTIONS {
  SET_PERSONAL_ACCOUNT_MAP = 'LANDING_PAGE_ACTIONS.SET_PERSONAL_ACCOUNT_MAP',
  SET_TEMP_HOLDING_BALANCE = 'LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE',
  SET_LATEST_PERSONAL_DEPOSIT_REQUESTS = 'LANDING_PAGE_ACTIONS.SET_LATEST_PERSONAL_DEPOSIT_REQUESTS',
  SET_IS_ZRC2_DEPOSIT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED',
  SET_IS_ERC20_DEPOSIT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED',
  SET_IS_HTS_DEPOSIT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_HTS_DEPOSIT_ENABLED',
  SET_IS_MATIC_DEPOSIT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED',
  SET_IS_AVAX_DEPOSIT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_AVAX_DEPOSIT_ENABLED',
  SET_IS_TRC20_DEPOSIT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_TRC20_DEPOSIT_ENABLED',
  SET_IS_ZRC2_WITHDRAWAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED',
  SET_IS_ERC20_WITHDRAWAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED',
  SET_IS_HTS_WITHDRAWAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_ENABLED',
  SET_IS_MATIC_WITHDRAWAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED',
  SET_IS_AVAX_WITHDRAWAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_AVAX_WITHDRAWAL_ENABLED',
  SET_IS_TRC20_WITHDRAWAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_TRC20_WITHDRAWAL_ENABLED',
  SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED',
  SET_IS_ZRC2_NOTICE_BANNER_VISIBLE = 'LANDING_PAGE_ACTIONS.SET_IS_ZRC2_NOTICE_BANNER_VISIBLE',
  SET_DASHBOARD_BANNERS = 'ROUTE_ACTIONS.SET_DASHBOARD_BANNERS',
  SET_TAB = 'LANDING_PAGE_ACTIONS.SET_TAB',
  SET_TRANSFER_METHOD = 'LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD',
  SET_BANK_TRANSFER_NETWORK = 'LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK',
  SET_SELECTED_CURRENCY = 'LANDING_PAGE_ACTIONS.SET_SELECTED_CURRENCY',
  SET_SELECTED_BLOCKCHAIN = 'LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN',
  SET_SELECTED_BANK_ACCOUNT = 'LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT',
  SET_SELECTED_BLOCKCHAIN_ADDRESS = 'LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS',
  SET_WITHDRAW_AMOUNT = 'LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT',
  SET_IS_STABLECOIN_FEE_LOADING = 'LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING',
  SET_STABLECOIN_FEE = 'LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE',
  SET_FIAT_FEE = 'LANDING_PAGE_ACTIONS.SET_FIAT_FEE',
  SET_IS_HTS_WITHDRAWAL_FEE_CHARGING = 'LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_FEE_CHARGING',
  SET_CONFIRM_MODAL_OPEN = 'LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN',
  SET_2_FA_MODAL_OPEN = 'LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN',
  CREATE_WITHDRAWAL_REQUEST = 'LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST',
  SET_TEMP_HOLDING_BALANCE_MODAL_OPEN = 'LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN',
  SET_WITHDRAW_WALLET_ID = 'LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID',
  SET_VISITED_GETTING_STARTED = 'LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED',
  SET_IS_HEDERA_UI_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_HEDERA_UI_ENABLED',
  SET_IS_POLYGON_UI_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_POLYGON_UI_ENABLED',
  SET_IS_AVALANCHE_UI_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_AVALANCHE_UI_ENABLED',
  SET_IS_TRON_UI_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_TRON_UI_ENABLED',
  SET_IS_TEMPORARY_HOLDING_BALANCE_WITHDRAWAL = 'LANDING_PAGE_ACTIONS.SET_IS_TEMPORARY_HOLDING_BALANCE_WITHDRAWAL',
  SET_IS_PERIODIC_REVIEW_REQUIRED = 'LANDING_PAGE_ACTIONS.SET_IS_PERIODIC_REVIEW_REQUIRED ',
  SET_HAS_FETCHING_FEE_ERROR = 'LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR ',
  SET_IS_DPT_APPLIED = 'LANDING_PAGE_ACTIONS.SET_IS_DPT_APPLIED',
  SET_IS_SSO_TWO_FA_AUTHENTICATOR_ENABLED = 'BLOCKCHAIN_ADDRESS_ACTIONS.SET_IS_SSO_TWO_FA_AUTHENTICATOR_ENABLED',
  SET_IS_DIRECT_MINT_LIMIT_REMOVAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_DIRECT_MINT_LIMIT_REMOVAL_ENABLED',
  SET_IS_DPT_RISK_MODAL_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_DPT_RISK_MODAL_ENABLED',
  SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED',
  SET_IS_FAZZ_VA_FOR_DIRECT_MINT_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DIRECT_MINT_ENABLED',
  SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED',
  SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED = 'LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED',
  SET_IS_RENEW_ADDRESS_ACKNOWLEDGED = 'LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED',
  SET_IS_XUSD_COACHMARK_ACTIVE = 'LANDING_PAGE_ACTIONS.SET_IS_XUSD_COACHMARK_ACTIVE',
}

type SetIsXsgdZrc2DepositEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED
  isXsgdZrc2DepositEnabled: boolean
}

type SetIsXsgdErc20DepositEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED
  isXsgdErc20DepositEnabled: boolean
}

type SetIsXsgdHtsDepositEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_HTS_DEPOSIT_ENABLED
  isXsgdHtsDepositEnabled: boolean
}

type SetIsXsgdMaticDepositEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED
  isXsgdMaticDepositEnabled: boolean
}

type SetIsXsgdAvaxDepositEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_AVAX_DEPOSIT_ENABLED
  isXsgdAvaxDepositEnabled: boolean
}

type SetIsUsdtTrc20DepositEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_TRC20_DEPOSIT_ENABLED
  isUsdtTrc20DepositEnabled: boolean
}

type SetIsXsgdZrc2WithdrawalEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED
  isXsgdZrc2WithdrawalEnabled: boolean
}

type SetIsXsgdErc20WithdrawalEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED
  isXsgdErc20WithdrawalEnabled: boolean
}

type SetIsXsgdHtsWithdrawalEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_ENABLED
  isXsgdHtsWithdrawalEnabled: boolean
}

type SetIsXsgdMaticWithdrawalEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED
  isXsgdMaticWithdrawalEnabled: boolean
}

type SetIsXsgdAvaxWithdrawalEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_AVAX_WITHDRAWAL_ENABLED
  isXsgdAvaxWithdrawalEnabled: boolean
}

type SetIsUsdtTrc20WithdrawalEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_TRC20_WITHDRAWAL_ENABLED
  isUsdtTrc20WithdrawalEnabled: boolean
}

type SetTempHoldingBalance = {
  type: typeof LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE
  temporaryHoldingBalance: TemporaryHoldingBalance | null
}

type SetLatestPersonalDepositRequests = {
  type: typeof LANDING_PAGE_ACTIONS.SET_LATEST_PERSONAL_DEPOSIT_REQUESTS
  latestPersonalDepositRequests: DepositRequest[]
}

type SetIsZrc2NoticeBannerEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_ZRC2_NOTICE_BANNER_VISIBLE
  isZrc2NoticeBannerVisible: boolean
}

type SetIsDashboardBannerManagementEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED
  isDashboardBannerManagementEnabled: boolean
}

type SetDashboardBanners = {
  type: typeof LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS
  dashboardBanners: DashboardBanner[]
}

type SetTab = {
  type: typeof LANDING_PAGE_ACTIONS.SET_TAB
  tab: string
}

type SetTransferMethod = {
  type: typeof LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD
  transferMethod: TRANSFER_METHOD | null
}

type SetBankTransferNetwork = {
  type: typeof LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK
  bankTransferNetwork: BankTransferNetwork | null
}

type SetSelectedCurrency = {
  type: typeof LANDING_PAGE_ACTIONS.SET_SELECTED_CURRENCY
  selectedCurrency: STABLECOIN | null
}

type SetSelectedBlockchain = {
  type: typeof LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN
  selectedBlockchain: BlockchainNetwork | null
}

type SetSelectedBankAccount = {
  type: typeof LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT
  selectedBankAccount: BankAccount | null
}

type SetSelectedBlockchainAddress = {
  type: typeof LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS
  selectedBlockchainAddress: BlockchainAddress | null
}

type SetWithdrawAmount = {
  type: typeof LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT
  amount: string
  withdrawAmountError: string
}

type SetWithdrawWalletId = {
  type: typeof LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID
  withdrawWalletId: WithdrawWalletIdType
}

type SetIsStablecoinFeeLoading = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING
  isStablecoinFeeLoading: boolean
}

type SetStablecoinFee = {
  type: typeof LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE
  amount: number
  identifier: string
  timestamp: string
}

type SetFiatFee = {
  type: typeof LANDING_PAGE_ACTIONS.SET_FIAT_FEE
  stablecoin: CURRENCY
  amount: number
  feeType: FeeType
  rate: number
  maxAmount: number
  minAmount: number
}

type SetIsHtsWithdrawalFeeCharging = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_FEE_CHARGING
  isHtsWithdrawalFeeCharging: boolean
}

type SetConfirmModalOpen = {
  type: typeof LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN
  open: boolean
}

type Set2FaModalOpen = {
  type: typeof LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN
  open: boolean
}

type CreateWithdrawalRequest = {
  type: typeof LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST
  error: string
}

type SetTempHoldingBalanceModalOpen = {
  type: typeof LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN
  open: boolean
}

type SetVisitedGettingStarted = {
  type: typeof LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED
  value: boolean
}

type SetIsHederaUIEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_HEDERA_UI_ENABLED
  isHederaUIEnabled: boolean
}

type SetIsAvalancheUIEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_AVALANCHE_UI_ENABLED
  isAvalancheUIEnabled: boolean
}

type SetIsTronUIEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_TRON_UI_ENABLED
  isTronUIEnabled: boolean
}

type SetIsPolygonUIEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_POLYGON_UI_ENABLED
  isPolygonUIEnabled: boolean
}

type SetIsTemporaryHoldingBalanceWithdrawal = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_TEMPORARY_HOLDING_BALANCE_WITHDRAWAL
  isTemporaryHoldingBalanceWithdrawal: boolean
}

type SetIsPeriodicReviewRequired = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_PERIODIC_REVIEW_REQUIRED
  isPeriodicReviewRequired: boolean
}

type SetHasFetchingFeeError = {
  type: typeof LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR
  hasError: boolean
}

type SetIsDptApplied = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_DPT_APPLIED
  isDptApplied: boolean
}

type SetIsSsoTwoFaAuthenticatorEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_SSO_TWO_FA_AUTHENTICATOR_ENABLED
  isSsoTwoFaAuthenticatorEnabled: boolean
}

type SetIsDptRiskModalApplied = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_DPT_RISK_MODAL_ENABLED
  isDptRiskModalEnabled: boolean
}

type SetIsFazzVaForDashboardEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED
  isFazzVaForDashboardEnabled: boolean
}

type SetIsFazzVaForDirectMintEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DIRECT_MINT_ENABLED
  isFazzVaForDirectMintEnabled: boolean
}

type SetIsTravelRuleDepositDeclarationEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED
  isTravelRuleDepositDeclarationEnabled: boolean
}

type SetIsTravelRuleAddrWhitelistingEnabled = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED
  isTravelRuleAddrWhitelistingEnabled: boolean
}

type SetIsRenewAddressAcknowledged = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED
  isRenewAddressAcknowledged: boolean
}

type SetIsXusdCoachMarkActive = {
  type: typeof LANDING_PAGE_ACTIONS.SET_IS_XUSD_COACHMARK_ACTIVE
  isXusdCoachmarkActive: boolean
}

export type ActionTypes =
  | SetTempHoldingBalance
  | SetLatestPersonalDepositRequests
  | SetIsXsgdZrc2DepositEnabled
  | SetIsXsgdErc20DepositEnabled
  | SetIsXsgdHtsDepositEnabled
  | SetIsXsgdMaticDepositEnabled
  | SetIsXsgdAvaxDepositEnabled
  | SetIsUsdtTrc20DepositEnabled
  | SetIsXsgdZrc2WithdrawalEnabled
  | SetIsXsgdErc20WithdrawalEnabled
  | SetIsXsgdHtsWithdrawalEnabled
  | SetIsXsgdMaticWithdrawalEnabled
  | SetIsXsgdAvaxWithdrawalEnabled
  | SetIsUsdtTrc20WithdrawalEnabled
  | SetIsZrc2NoticeBannerEnabled
  | SetIsDashboardBannerManagementEnabled
  | SetDashboardBanners
  | SetTab
  | SetTransferMethod
  | SetBankTransferNetwork
  | SetSelectedCurrency
  | SetSelectedBlockchain
  | SetSelectedBankAccount
  | SetSelectedBlockchainAddress
  | SetWithdrawAmount
  | SetWithdrawWalletId
  | SetIsStablecoinFeeLoading
  | SetStablecoinFee
  | SetFiatFee
  | SetIsHtsWithdrawalFeeCharging
  | SetConfirmModalOpen
  | Set2FaModalOpen
  | CreateWithdrawalRequest
  | SetTempHoldingBalanceModalOpen
  | SetVisitedGettingStarted
  | SetIsHederaUIEnabled
  | SetIsPolygonUIEnabled
  | SetIsAvalancheUIEnabled
  | SetIsTronUIEnabled
  | SetIsTemporaryHoldingBalanceWithdrawal
  | SetIsPeriodicReviewRequired
  | SetHasFetchingFeeError
  | SetIsDptApplied
  | SetIsSsoTwoFaAuthenticatorEnabled
  | SetIsDptRiskModalApplied
  | SetIsFazzVaForDashboardEnabled
  | SetIsFazzVaForDirectMintEnabled
  | SetIsTravelRuleDepositDeclarationEnabled
  | SetIsTravelRuleAddrWhitelistingEnabled
  | SetIsRenewAddressAcknowledged
  | SetIsXusdCoachMarkActive

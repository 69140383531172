import { SCREENS, IkycRetrievedDetails } from './types'
import { INTERNATIONAL_INFO_VERIFICATION_STATUS } from './international/types'

const isI18nCountryOfResidenceCompleted = (retrievedDetails: IkycRetrievedDetails): boolean => {
  if (!retrievedDetails?.countryOfResidence) return false

  const { value, verificationStatus } = retrievedDetails.countryOfResidence
  return (
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.VERIFIED ||
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.PENDING ||
    (verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.INITIAL && !!value)
  )
}

const isI18nJumioCompleted = (retrievedDetails: IkycRetrievedDetails): boolean => {
  if (!retrievedDetails?.jumio) return false

  const { trxId, verificationStatus } = retrievedDetails.jumio
  return (
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.VERIFIED ||
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.PENDING ||
    (verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.INITIAL && !!trxId)
  )
}

const isI18nDeclarationsCompleted = (retrievedDetails: IkycRetrievedDetails): boolean => {
  if (!retrievedDetails?.declaration) return false

  const {
    expectedTransactionAmount,
    expectedTotalTransaction,
    expectedTransactionFrequency,
    sourceOfFunds,
    annualIncome,
    verificationStatus,
    occupation,
    businessIndustry,
  } = retrievedDetails.declaration

  const allInfoSubmitted =
    !!expectedTransactionAmount &&
    !!expectedTotalTransaction &&
    !!expectedTransactionFrequency &&
    !!sourceOfFunds &&
    !!annualIncome &&
    !!occupation &&
    !!businessIndustry

  return (
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.VERIFIED ||
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.PENDING ||
    (verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.INITIAL && allInfoSubmitted)
  )
}

const isI18nProofOfAddressCompleted = (retrievedDetails: IkycRetrievedDetails): boolean => {
  if (!retrievedDetails?.proofOfAddress) return false

  const { s3PresignUrlKey, verificationStatus } = retrievedDetails.proofOfAddress
  return (
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.VERIFIED ||
    verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.PENDING ||
    (verificationStatus === INTERNATIONAL_INFO_VERIFICATION_STATUS.INITIAL && !!s3PresignUrlKey)
  )
}

export const getNextIncompleteI18nScreen = (retrievedDetails: IkycRetrievedDetails): SCREENS => {
  if (!isI18nCountryOfResidenceCompleted(retrievedDetails)) return SCREENS.CHOOSE_COUNTRY
  if (!isI18nJumioCompleted(retrievedDetails)) return SCREENS.JUMIO
  if (!isI18nDeclarationsCompleted(retrievedDetails)) return SCREENS.DECLARATIONS
  if (!isI18nProofOfAddressCompleted(retrievedDetails)) return SCREENS.PROOF_OF_ADDRESS

  // If none of the above, then user should have completed documents
  return SCREENS.STATUS
}

import { Moment } from '~/types'
import { ACCOUNT_STATEMENT_ACTIONS } from './const'

// DATA TYPES
export enum ACCOUNT_STATEMENT_MODE {
  DOWNLOAD_URLS = 'download_urls',
  EMAIL = 'email',
}

export enum ACCOUNT_TYPE {
  BUSINESS_ACCOUNT = 'BusinessAccount',
  BUSINESS_SETTLEMENT_ACCOUNT = 'BusinessSettlementAccount',
  CORPORATE_ACCOUNT = 'CorporateAccount',
}

export type AccountOptions = {
  accounts: Array<{ id: string; type: ACCOUNT_TYPE | string; createdAt: Moment | string }>
  earliestInvalidDate: Moment | string
}

export type AccountStatementAccounts = {
  accountsIds: Array<{ id: string }>
  date: Moment | string
}

export type AccountStatementFilterValues = {
  mode: ACCOUNT_STATEMENT_MODE | null
  userEmail: string | null
  accountIds: string[] | undefined
  startDate: Moment | string
  endDate: Moment | string
  [key: string]: AccountStatementFilterObject[keyof AccountStatementFilterObject]
}

export type AccountStatementFilterObject = {
  [filterKey: string]:
    | ACCOUNT_STATEMENT_MODE
    | Moment
    | string
    | string[]
    | AccountStatementAccounts[]
    | undefined
    | null
}

export type DownloadUrls = {
  [key: string]: string
}

// END DATA TYPES

// ACTION TYPES
type SetAccountStatementUIEnabled = {
  type: typeof ACCOUNT_STATEMENT_ACTIONS.SET_IS_ACCOUNT_STATEMENT_UI_ENABLED
  isAccountStatementUIEnabled: boolean
}

type GetAccountOptions = {
  type: typeof ACCOUNT_STATEMENT_ACTIONS.GET_ACCOUNT_OPTIONS
  accountOptions: AccountOptions
}

type SetAccountStatementFilterValues = {
  type: typeof ACCOUNT_STATEMENT_ACTIONS.SET_ACCOUNT_STATEMENT_FILTER_VALUES
  accountStatementFilterObject: AccountStatementFilterObject
}

type GetDownloadUrls = {
  type: typeof ACCOUNT_STATEMENT_ACTIONS.GET_DOWNLOAD_URLS
  downloadUrls: DownloadUrls
}
// END ACTION TYPES

export type ActionTypes =
  | SetAccountStatementUIEnabled
  | GetAccountOptions
  | SetAccountStatementFilterValues
  | GetDownloadUrls

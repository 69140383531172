import { CURRENCY } from '~/types'
import { ACCOUNT_ACTIONS } from './const'

// DATA TYPES
export enum ACCOUNT_TYPES {
  PERSONAL = 'PersonalAccount',
  CORPORATE = 'CorporateAccount',
  BUSINESS = 'BusinessAccount',
  TEMPORARY_HOLDING_BALANCE = 'TemporaryHoldingBalanceAccount',
  BUSINESS_SETTLEMENT = 'BusinessSettlementAccount',
  MANAGED = 'ManagedAccount',
  SOBATKU_PERSONAL = 'SobatkuPersonalAccount',
}

export type AccountTransactionLimitIncrementDate = {
  sum: string
  date: string
}

export enum WALLET_TYPES {
  GENERAL = 1,
  DIGITAL_GOODS = 2,
  TEMPORARY_HOLDING_BALANCE = 9,
}

export type AccountLimitation = {
  max: string
  remaining: string
  used: string
}

export type AccountDetailAttributes = {
  walletId: WALLET_TYPES
  fundPoolId: number
  type: ACCOUNT_TYPES
  currency: CURRENCY
  deletedAt: string
  title: string
  balance: string
  walletName: string
  limitations: {
    [key: string]: AccountLimitation
  }
  transactionLimitIncrementDate: AccountTransactionLimitIncrementDate
  asb?: boolean
}

export type AccountDetail = {
  id: string
  attributes: AccountDetailAttributes
}

export enum ACCOUNT_NAMES {
  GENERAL_WALLET = 'General Wallet',
  DIGITAL_GOODS = 'Digital Goods',
}

export type Account = {
  id?: WALLET_TYPES
  balance: string
  type?: ACCOUNT_TYPES
  name?: ACCOUNT_NAMES
  formattedBalance: string
  settlementBalance?: string
  totalBalance?: string
  formattedAvailableBalance?: string
  formattedSettlementBalance?: string
  formattedTotalBalance?: string
  uniqueId?: string
  accountId?: number
  fundPoolId?: number
  currency: CURRENCY
  accountConfiguration?: {
    minimumBalance: string
  }
}

export type AccountGeneralLimits = {
  holdingRemaining: string
  holdingMax: string
  annualTransactionRemaining: string
  annualTransactionMax: string
}

export type WalletIdType =
  | WALLET_TYPES.DIGITAL_GOODS
  | WALLET_TYPES.GENERAL
  | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE
  | undefined

export type Wallet = {
  type?: WalletIdType
  balance: string
  formattedBalance: string
  label?: string
  accountId?: number
  accountType?: string
  fundPoolId?: number
}
// END DATA TYPES

// ACTION TYPES
type SetAccountList = {
  type: typeof ACCOUNT_ACTIONS.SET_ACCOUNT_LIST
  accountList: Account[]
}

type SetAccountDetailList = {
  type: typeof ACCOUNT_ACTIONS.SET_ACCOUNT_DETAIL_LIST
  accountDetailList: AccountDetail[]
}
// END ACTION TYPES

export type ActionTypes = SetAccountDetailList | SetAccountList

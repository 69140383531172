import { STEPS, ACTIONS } from './types'

export const setStep = (step: STEPS) => ({
  type: ACTIONS.SET_STEP,
  step,
})

export const setBankAccountId = (bankAccountId: number) => ({
  type: ACTIONS.SET_BANK_ACCOUNT_ID,
  bankAccountId,
})

export const setAccountNumber = (accountNumber: string) => {
  const regex = RegExp('^[0-9]*$')
  if (regex.test(accountNumber)) {
    return {
      type: ACTIONS.SET_ACCOUNT_NUMBER,
      accountNumber,
    }
  }
  return {}
}

export const setSelectedBank = (selectedBank: string) => ({
  type: ACTIONS.SET_SELECTED_BANK,
  selectedBank,
})

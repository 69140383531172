import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import {
  Card,
  Button,
  DESKTOP_BREAKPOINT,
  BadgeIcon,
  H3,
  P1,
  Checkbox,
  straitsXTheme,
  useModal,
} from '@xfers/design-system'
import { Tips } from '@xfers/design-system/es/icons'
import straitsXBackground from '~/assets/straitsXBackground.png'
import StraitsXFooter from '~/components/StraitsXFooter'
import { SITE_TYPE } from '~/managers/ConfigManager'
import KycDetailsModal from './Modals/KycDetailsModal'
import { putDpt2ModalTncAccepted } from '~/api/User/User.api'
import KybDetailsModal from './Modals/KybDetails/KybDetailsModal'
import { selectUserVerificationStatus } from '~/app/store/userInfo/selectors'
import { selectBusinessVerificationStatus, selectPersonalVerificationStatus } from '~/biz/store/merchantInfo/selectors'
import TermsOfService from '../TermsOfService'

type Props = {
  siteType: SITE_TYPE
}

function Dpt2Tnc({ siteType }: Props) {
  const { setModal } = useModal()
  const [isTncChecked, setIsTncChecked] = useState(false)
  const [isShareKycKybChecked, setIsShareKycKybChecked] = useState(false)
  const isKycOrKyb = siteType === SITE_TYPE.BIZ ? 'KYB' : 'KYC'

  const continueHandler = () => {
    putDpt2ModalTncAccepted({
      successCallback: () => window.location.reload(),
    })
  }

  const isVerifiedUser = (siteType: SITE_TYPE) => {
    if (siteType === SITE_TYPE.BIZ) {
      const bizPersonalVerificationStatus = useSelector(selectPersonalVerificationStatus)
      const businessVerificationStatus = useSelector(selectBusinessVerificationStatus)
      return (
        bizPersonalVerificationStatus === 'verified' && businessVerificationStatus === 'verified'
      )
    }
    const appVerificationStatus = useSelector(selectUserVerificationStatus)
    return appVerificationStatus === 'verified'
  }

  const handleOnClickKycKyb = (siteType: SITE_TYPE) => {
    if (siteType === SITE_TYPE.APP) {
      setModal(<KycDetailsModal />)
    } else {
      setModal(<KybDetailsModal />)
    }
  }

  const isContinueButtonDisabled = () => {
    if (isVerifiedUser(siteType)) {
      return !isTncChecked || !isShareKycKybChecked
    }
    return !isTncChecked
  }

  return (
    <PageContainer>
      <PageContent>
        <CardContainer>
          <CardContent>
            <CardTopSection>
              <Header>
                <BadgeIcon type="warning" icon={<Tips />} />
                <H3>Updated Terms and Conditions</H3>
              </Header>
              <Divider />
              <CardBody>
                <TncContent>
                  <TermsOfService />
                </TncContent>
              </CardBody>
            </CardTopSection>
            <CardFooter>
              <TncHelpContainer>
                <TncP1>
                  <b>
                    Your experience and utilisation of the StraitsX Dashboard and Services will not
                    be affected by the changes to the StraitsX Terms and Conditions.{' '}
                  </b>
                </TncP1>
                <TncP1>
                  If you have any questions or prefer not to accept the new terms, please reach out
                  to our customer support team for assistance.
                </TncP1>
              </TncHelpContainer>
              <CustomizedCheckbox
                value={isTncChecked ? ['checked'] : []}
                onChange={() => {
                  setIsTncChecked(!isTncChecked)
                }}
                options={[
                  {
                    label: <BodyText>I agree to the updated Terms and Conditions.</BodyText>,
                    value: 'checked',
                  },
                ]}
                isChecked={isTncChecked}
              />
              {isVerifiedUser(siteType) && (
                <CustomizedCheckbox
                  value={isShareKycKybChecked ? ['checked'] : []}
                  onChange={() => {
                    setIsShareKycKybChecked(!isShareKycKybChecked)
                  }}
                  options={[
                    {
                      label: (
                        <BodyText>
                          I consent to the disclosure of{' '}
                          <OpenModalLink
                            onClick={e => {
                              e.preventDefault()
                              handleOnClickKycKyb(siteType)
                            }}
                          >
                            my {isKycOrKyb} details
                          </OpenModalLink>{' '}
                          to StraitsX Payment Services Pte. Ltd.
                        </BodyText>
                      ),
                      value: 'checked',
                    },
                  ]}
                  isChecked={isShareKycKybChecked}
                />
              )}
              <ContinueButton
                type="secondary"
                onClick={continueHandler}
                disabled={isContinueButtonDisabled()}
              >
                Continue
              </ContinueButton>
            </CardFooter>
          </CardContent>
        </CardContainer>
        <StraitsXFooter />
      </PageContent>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  background-image: url(${straitsXBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
  min-height: 100vh;
  width: 100vw;
`

const PageContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`

const CardContainer = styled(Card)`
  width: 90vw;
  margin-top: 60px;
  radius: 5px;
  box-shadow: 0px 2px 8px 0px rgba(0, 169, 101, 0.2);
  height: 100%;

  .ant-card-body {
    padding: 0 !important;
    height: 100% !important;
  }

  ${DESKTOP_BREAKPOINT} {
    width: 50vw;
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CardTopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 30px 0 30px;
`

const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 30px;
  margin-top: auto;
`

const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const TncContent = styled.div`
  background-color: #f6f7f9;
`

const TncP1 = styled(P1)`
  font-size: 15px !important;
  line-height: 22px !important;
  padding: 0 12px;
`

const CardBody = styled.div`
  max-height: 388px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Divider = styled.div`
  background: #e8e8e8;
  height: 1px;
`

const CustomizedCheckbox = styled(Checkbox.Group)<{ isChecked: boolean }>`
  & .ant-checkbox-wrapper.ant-checkbox-group-item {
    box-shadow: none;
    border: 1px solid
      ${props => (props.isChecked ? straitsXTheme.button.color : straitsXTheme.button.border)};
  }

  & .ant-checkbox-wrapper {
    background: #fff !important;
  }
`

const BodyText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
`

const ContinueButton = styled(Button)`
  padding: 15px 20px !important;
  color: ${straitsXTheme.brand.primary.contrast} !important;
  background-color: ${straitsXTheme.brand.primary.default} !important;
  &:disabled {
    opacity: 0.5 !important;
  }
`

const OpenModalLink = styled.span`
  color: #00a965;
`

const TncHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #fff;
  padding: 12px 0;
`

export default Dpt2Tnc

/* eslint-disable max-lines */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, P1Bold, P1, useModal } from '@xfers/design-system'
import { LockWithKeyHole } from '@xfers/design-system/es/icons'
import styled from '@emotion/styled'
import { RootState } from '~/app/store/types'
import 'antd/dist/antd.css'
import { redirect } from '~/app/store/route/actions'
import { PATHS } from '~/app/routes/paths'
import PeriodicReviewModal from '~/app/pages/LandingPage/PeriodicReviewModal'
import { selectUserInfo } from '~/app/store/userInfo/selectors'

export default function() {
  const { setModal } = useModal()
  const dispatch = useDispatch()
  const { user, isLockedFeatureOn } = useSelector((state: RootState) => state.route)
  const { accountLocked, periodicReviewRequired } = useSelector(selectUserInfo)

  useEffect(() => {
    if (!(accountLocked && periodicReviewRequired) || !isLockedFeatureOn) {
      dispatch(redirect(PATHS.LANDING_PAGE))
    }
  }, [accountLocked])

  return (
    <Container>
      <LockContainer>
        <LockWithKeyHole width="150" height="90" />
      </LockContainer>
      <Header>Your account has been locked</Header>
      <Content>
        Your StraitsX account {user.email} address has been locked as we require your account
        details to be reverified. <br />
        <br />
        Click on the button below to reverify your account.
      </Content>
      <VerifyButton
        onClick={() => {
          setModal(
            <PeriodicReviewModal
              showReverifyButton={true}
              titleText="Reverify my account"
              checkboxText={
                <>
                  I have reviewed the information. (Please select the “
                  <b>Update personal particulars</b>” option from the Customer Support ticket while
                  submitting your request.)
                </>
              }
            />
          )
        }}
      >
        Reverify Now
      </VerifyButton>
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
`

const LockContainer = styled.div`
  text-align: center;
  width: 100%;
  max-width: 560px;
  margin: 100px auto 0 auto;
`

const Header = styled(P1Bold)`
  width: 100%;
  max-width: 560px;
  margin: 30px auto 0 auto;

  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
`

const Content = styled(P1)`
  width: 100%;
  max-width: 560px;
  margin: 5px auto 15px auto;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
`

const VerifyButton = styled(Button)`
  &.ant-btn {
    padding: 15px 20px !important;
  }
  margin: 0 auto;
`

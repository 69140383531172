import { Dispatch } from '~/biz/store/types'
import { ENDPOINTS, get } from '~/api'
import { ACCOUNT_ACTIONS } from './const'
import { Account, AccountDetail, ActionTypes } from './types'
import { keysToCamel } from '~/helpers/helpers'

export const setAccountList = (accountList: Account[]) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ACCOUNT_ACTIONS.SET_ACCOUNT_LIST,
    accountList,
  })
}

export const setAccountDetailList = (accountDetailList: AccountDetail[]) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: ACCOUNT_ACTIONS.SET_ACCOUNT_DETAIL_LIST,
    accountDetailList,
  })
}

export const fetchAccountList = (onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const resp = await get(ENDPOINTS.API_XFERS_ACCOUNTS)

  dispatch(setAccountList(keysToCamel(resp) as Account[]))

  if (onSuccess) {
    onSuccess()
  }
}

export const fetchAccountDetailList = (onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const resp = await get(ENDPOINTS.API_XFERS_ACCOUNTS_INFO_FOR_SDK)

  dispatch(setAccountDetailList(keysToCamel(resp.data) as AccountDetail[]))

  if (onSuccess) {
    onSuccess()
  }
}

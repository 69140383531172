export enum INTERNATIONAL_INFO_VERIFICATION_STATUS {
  INITIAL = 'initial',
  PENDING = 'pending',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
}

export enum VERIFICATION_OPTIONS {
  COUNTRIES = 'countries',
  DECLARATIONS = 'declarations',
  JUMIO = 'jumio_sdk',
  PROOF_OF_ADDRESS = 'proof_of_address',
}

export type JumioRetrievedDetails = {
  trxId: string
  verificationStatus: INTERNATIONAL_INFO_VERIFICATION_STATUS
  passportRejectionReason: string[]
  selfieRejectionReason: string[]
}

export type CountryOfResidenceRetrievedDetails = {
  value: string
  verificationStatus: INTERNATIONAL_INFO_VERIFICATION_STATUS
  rejectionReason: string[]
}

export type DeclarationRetrievedDetails = {
  expectedTransactionAmount: string
  expectedTotalTransaction: string
  expectedTransactionFrequency: string
  sourceOfFunds: string
  annualIncome: string
  businessIndustry: string
  occupation: string
  verificationStatus: INTERNATIONAL_INFO_VERIFICATION_STATUS
  rejectionReason: string[]
}

export type ProofOfAddressRetrievedDetails = {
  s3PresignUrlKey: string
  verificationStatus: INTERNATIONAL_INFO_VERIFICATION_STATUS
  rejectionReason: string[]
}

export type I18nVerificationDeclarationDetails = {
  annualIncomeRange: string
  expectedTransactionAmount: string
  expectedTotalTransaction: string
  expectedTransactionFrequency: string
  sourceOfFunds: string
  businessIndustry: string
  occupation: string
}

export type I18nVerificationDetails = {
  externalId?: string
  countryOfResidence?: string
  jumioTrxId?: string
  declarations?: I18nVerificationDeclarationDetails
  proofOfAddressKey?: string
}

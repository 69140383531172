import clevertap, { onUserLogin } from 'clevertap-web-sdk'
import { ConfigManager, DEPLOY_ENVIRONMENT } from '~/managers/ConfigManager'

const CleverTapProjectIds = {
  prodEnv: 'R95-R4K-ZW7Z',
  testEnv: 'TEST-K95-R4K-ZW7Z',
}

export type ClevertapUserPropertyType = {
  fullName: string
  email: string
  kcId?: string
  merchantId?: string
}

export default {
  setupClevertap() {
    clevertap.init(
      ConfigManager.getEnvironmentMode() === DEPLOY_ENVIRONMENT.PRODUCTION
        ? CleverTapProjectIds.prodEnv
        : CleverTapProjectIds.testEnv,
      'sg1'
    )

    // enable location tracking
    clevertap.privacy.push({ useIP: true })

    const cleverTapLogLevel = ConfigManager.isProd() ? 1 : 3
    clevertap.setLogLevel(cleverTapLogLevel)
  },

  onUserLogin: (userProperties: ClevertapUserPropertyType) => {
    const identity = ConfigManager.isBizSite()
      ? `kcId:${userProperties.kcId}|merchantId:${userProperties.merchantId}`
      : userProperties.kcId

    onUserLogin.push({
      Site: {
        // CleverTap attributes
        Name: userProperties.fullName,
        Identity: identity,
        // Customized attributes
        FullName: userProperties.fullName,
        Email: userProperties.email,
        KcId: userProperties.kcId,
        ...(ConfigManager.isBizSite() && { MerchantId: userProperties.merchantId }),
      },
    })
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPushEvent: (eventName: string, eventProperties?: any) => {
    clevertap.event.push(eventName, eventProperties || {})
  },
}

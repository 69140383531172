/* eslint-disable max-lines */
import { WALLET_TYPES, STABLECOIN, BankAccount, CURRENCY } from '~/types'
import { BlockchainAddress } from '~/biz/store/blockchainAddresses/types'

export type AccountLimits = {
  withdrawDailyRemaining: string
  withdrawDailyMax: string
}

export type MerchantAccount = {
  digitalGoodsWallet: {
    availableBalance: string
    fundPoolId?: number
    accountLimit: AccountLimits
  } | null
  hasBusinessAccount?: boolean
}

export type DashboardBanner = {
  title: string
  tag: string
  content: string
}

export type StablecoinFee = {
  amount: number | undefined
  identifier: string | undefined
  timestamp: string | undefined
}

export type FiatFee = {
  amount: number | undefined
  feeType: FeeType | undefined
  rate: number | undefined
  maxAmount: number | undefined
  minAmount: number | undefined
}

export enum TRANSFER_METHOD {
  // eslint-disable-next-line no-shadow
  STABLECOIN = 'stablecoin',
  BANK = 'bank',
}
export type BankTransferNetwork = BANK_TRANSFER_NETWORKS | null
export type Blockchain = 'zilliqa' | 'ethereum' | 'hedera' | 'polygon' | 'avalanche' | 'tron'

export type WithdrawWalletIdType = WALLET_TYPES.DIGITAL_GOODS | undefined
export type FeeType = 'PercentageFeeInstruction' | 'FixedFeeInstruction'

export enum BANK_TRANSFER_NETWORKS {
  LOCAL = 'local',
}

export type WithdrawLimits = {
  [key in STABLECOIN]: {
    FIAT_MIN: number
    FIAT_MAX: number
    STABLECOIN_MAX: number
    STABLECOIN_MIN: number
    STABLECOIN_UNAPPROVED_MAX: number
  }
}

export enum STRAITSX_LANDING_PAGE_ACTIONS {
  SET_LATEST_BUSINESS_DEPOSIT_REQUESTS = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_LATEST_BUSINESS_DEPOSIT_REQUESTS',
  SET_IS_ZRC2_DEPOSIT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED',
  SET_IS_ERC20_DEPOSIT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED',
  SET_IS_HTS_DEPOSIT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_DEPOSIT_ENABLED',
  SET_IS_MATIC_DEPOSIT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED',
  SET_IS_AVAX_DEPOSIT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVAX_DEPOSIT_ENABLED',
  SET_IS_TRC20_DEPOSIT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRC20_DEPOSIT_ENABLED',
  SET_IS_ZRC2_WITHDRAWAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED',
  SET_IS_ERC20_WITHDRAWAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED',
  SET_IS_HTS_WITHDRAWAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_ENABLED',
  SET_IS_MATIC_WITHDRAWAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED',
  SET_IS_AVAX_WITHDRAWAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVAX_WITHDRAWAL_ENABLED',
  SET_IS_TRC20_WITHDRAWAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRC20_WITHDRAWAL_ENABLED',
  SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED',
  SET_IS_ZRC2_NOTICE_BANNER_VISIBLE = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_NOTICE_BANNER_VISIBLE',
  SET_DASHBOARD_BANNERS = 'ROUTE_ACTIONS.SET_DASHBOARD_BANNERS',
  SET_TAB = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_TAB',
  SET_TRANSFER_METHOD = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD',
  SET_BANK_TRANSFER_NETWORK = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK',
  SET_SELECTED_CURRENCY = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_CURRENCY',
  SET_SELECTED_BLOCKCHAIN = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN',
  SET_SELECTED_BANK_ACCOUNT = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT',
  SET_SELECTED_BLOCKCHAIN_ADDRESS = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS',
  SET_WITHDRAW_AMOUNT = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT',
  SET_IS_STABLECOIN_FEE_LOADING = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING',
  SET_STABLECOIN_FEE = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE',
  SET_IS_FIAT_FEE_LOADING = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_FIAT_FEE_LOADING',
  SET_FIAT_FEE = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_FIAT_FEE',
  SET_IS_HTS_WITHDRAWAL_FEE_CHARGING = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_FEE_CHARGING',
  SET_IS_WITHDRAWAL_AUTOMATIC = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_WITHDRAWAL_AUTOMATIC',
  SET_CONFIRM_MODAL_OPEN = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN',
  SET_2_FA_MODAL_OPEN = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN',
  CREATE_WITHDRAWAL_REQUEST = 'STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST',
  SET_WITHDRAW_WALLET_ID = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID',
  SET_VISITED_GETTING_STARTED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED',
  SET_CURRENT_MERCHANT_ACCOUNT_ID = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_CURRENT_MERCHANT_ACCOUNT_ID',
  SET_IS_HEDERA_UI_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HEDERA_UI_ENABLED',
  SET_IS_POLYGON_UI_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_POLYGON_UI_ENABLED',
  SET_IS_AVALANCHE_UI_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVALANCHE_UI_ENABLED',
  SET_IS_TRON_UI_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRON_UI_ENABLED',
  SET_IS_USDC_TRANSFER_UI_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_USDC_TRANSFER_UI_ENABLED',
  SET_HAS_FETCHING_FEE_ERROR = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR ',
  SET_IS_SSO_TWO_FA_AUTHENTICATED_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_SSO_TWO_FA_AUTHENTICATED_ENABLED ',
  SET_IS_DPT_RISK_MODAL_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_DPT_RISK_MODAL_ENABLED',
  SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_FLAZZ_VA_FOR_DASHBOARD_ENABLED',
  SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED',
  SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED',
  SET_IS_RENEW_ADDRESS_ACKNOWLEDGED = 'STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED',
  SET_IS_XUSD_COACHMARK_ACTIVE = 'LANDING_PAGE_ACTIONS.SET_IS_XUSD_COACHMARK_ACTIVE',
}

type SetIsZrc2DepositEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED
  isXsgdZrc2DepositEnabled: boolean
}

type SetIsXsgdErc20DepositEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED
  isXsgdErc20DepositEnabled: boolean
}

type SetIsXsgdHtsDepositEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_DEPOSIT_ENABLED
  isXsgdHtsDepositEnabled: boolean
}

type SetIsXsgdMaticDepositEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED
  isXsgdMaticDepositEnabled: boolean
}

type SetIsXsgdAvaxDepositEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVAX_DEPOSIT_ENABLED
  isXsgdAvaxDepositEnabled: boolean
}

type SetIsUsdtTrc20DepositEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRC20_DEPOSIT_ENABLED
  isUsdtTrc20DepositEnabled: boolean
}

type SetIsXsgdZrc2WithdrawalEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED
  isXsgdZrc2WithdrawalEnabled: boolean
}

type SetIsXsgdErc20WithdrawalEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED
  isXsgdErc20WithdrawalEnabled: boolean
}

type SetIsXsgdHtsWithdrawalEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_ENABLED
  isXsgdHtsWithdrawalEnabled: boolean
}

type SetIsXsgdMaticWithdrawalEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED
  isXsgdMaticWithdrawalEnabled: boolean
}

type SetIsXsgdAvaxWithdrawalEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVAX_WITHDRAWAL_ENABLED
  isXsgdAvaxWithdrawalEnabled: boolean
}

type SetIsUsdtTrc20WithdrawalEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRC20_WITHDRAWAL_ENABLED
  isUsdtTrc20WithdrawalEnabled: boolean
}

type SetIsZrc2NoticeBannerEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_NOTICE_BANNER_VISIBLE
  isZrc2NoticeBannerVisible: boolean
}

type SetIsDashboardBannerManagementEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED
  isDashboardBannerManagementEnabled: boolean
}

type SetDashboardBanners = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS
  dashboardBanners: DashboardBanner[]
}

type SetTab = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_TAB
  tab: string
}

type SetTransferMethod = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD
  transferMethod: TRANSFER_METHOD | null
}

type SetBankTransferNetwork = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK
  bankTransferNetwork: BankTransferNetwork | null
}

type SetSelectedCurrency = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_CURRENCY
  selectedCurrency: STABLECOIN | null
}

type SetSelectedBlockchain = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN
  selectedBlockchain: Blockchain | null
}

type SetSelectedBankAccount = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT
  selectedBankAccount: BankAccount | null
}

type SetSelectedBlockchainAddress = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS
  selectedBlockchainAddress: BlockchainAddress | null
}

type SetWithdrawAmount = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT
  amount: string
  withdrawAmountError: string
}

type SetWithdrawWalletId = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID
  withdrawWalletId: WithdrawWalletIdType
}

type SetIsStablecoinFeeLoading = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING
  isStablecoinFeeLoading: boolean
}

type SetStablecoinFee = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE
  amount: number
  identifier: string
  timestamp: string
}

type SetFiatFee = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_FIAT_FEE
  stablecoin: CURRENCY
  amount: number
  feeType: FeeType
  rate: number
  maxAmount: number
  minAmount: number
}

type SetIsHtsWithdrawalFeeCharging = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HTS_WITHDRAWAL_FEE_CHARGING
  isHtsWithdrawalFeeCharging: boolean
}

type SetIsWithdrawalAutomatic = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_WITHDRAWAL_AUTOMATIC
  isWithdrawalAutomatic: boolean
}

type SetConfirmModalOpen = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN
  open: boolean
}

type Set2FaModalOpen = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN
  open: boolean
}

type CreateWithdrawalRequest = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST
  error: string
}

type SetVisitedGettingStarted = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED
  value: boolean
}

type SetCurrentMerchantAccountId = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_CURRENT_MERCHANT_ACCOUNT_ID
  currentMerchantAccountId: number
}

type SetIsHederaUIEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_HEDERA_UI_ENABLED
  isHederaUIEnabled: boolean
}

type SetIsPolygonUIEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_POLYGON_UI_ENABLED
  isPolygonUIEnabled: boolean
}

type SetIsAvalancheUIEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_AVALANCHE_UI_ENABLED
  isAvalancheUIEnabled: boolean
}

type SetIsTronUIEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRON_UI_ENABLED
  isTronUIEnabled: boolean
}

type SetIsSsoTwoFaAuthenticatorEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_SSO_TWO_FA_AUTHENTICATED_ENABLED
  isSsoTwoFaAuthenticatorEnabled: boolean
}

type SetHasFetchingFeeError = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR
  hasError: boolean
}

type SetIsDptRiskModalApplied = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_DPT_RISK_MODAL_ENABLED
  isDptRiskModalEnabled: boolean
}

type SetIsFazzVaForDashboardEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_FAZZ_VA_FOR_DASHBOARD_ENABLED
  isFazzVaForDashboardEnabled: boolean
}

type SetIsTravelRuleDepositDeclarationEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_DEPOSIT_DECLARATION_ENABLED
  isTravelRuleDepositDeclarationEnabled: boolean
}

type SetIsTravelRuleAddrWhitelistingEnabled = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_TRAVEL_RULE_ADDR_WHITELISTING_ENABLED
  isTravelRuleAddrWhitelistingEnabled: boolean
}

type SetIsRenewAddressAcknowledged = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED
  isRenewAddressAcknowledged: boolean
}

type SetIsXusdCoachMarkActive = {
  type: typeof STRAITSX_LANDING_PAGE_ACTIONS.SET_IS_XUSD_COACHMARK_ACTIVE
  isXusdCoachmarkActive: boolean
}

export type ActionTypes =
  | SetIsZrc2DepositEnabled
  | SetIsXsgdErc20DepositEnabled
  | SetIsXsgdHtsDepositEnabled
  | SetIsXsgdMaticDepositEnabled
  | SetIsXsgdAvaxDepositEnabled
  | SetIsUsdtTrc20DepositEnabled
  | SetIsXsgdZrc2WithdrawalEnabled
  | SetIsXsgdErc20WithdrawalEnabled
  | SetIsXsgdHtsWithdrawalEnabled
  | SetIsXsgdMaticWithdrawalEnabled
  | SetIsXsgdAvaxWithdrawalEnabled
  | SetIsUsdtTrc20WithdrawalEnabled
  | SetIsZrc2NoticeBannerEnabled
  | SetIsDashboardBannerManagementEnabled
  | SetDashboardBanners
  | SetTab
  | SetTransferMethod
  | SetBankTransferNetwork
  | SetSelectedCurrency
  | SetSelectedBlockchain
  | SetSelectedBankAccount
  | SetSelectedBlockchainAddress
  | SetWithdrawAmount
  | SetWithdrawWalletId
  | SetIsStablecoinFeeLoading
  | SetStablecoinFee
  | SetFiatFee
  | SetIsHtsWithdrawalFeeCharging
  | SetIsWithdrawalAutomatic
  | SetConfirmModalOpen
  | Set2FaModalOpen
  | CreateWithdrawalRequest
  | SetVisitedGettingStarted
  | SetCurrentMerchantAccountId
  | SetIsHederaUIEnabled
  | SetIsPolygonUIEnabled
  | SetIsAvalancheUIEnabled
  | SetIsTronUIEnabled
  | SetIsSsoTwoFaAuthenticatorEnabled
  | SetHasFetchingFeeError
  | SetIsDptRiskModalApplied
  | SetIsFazzVaForDashboardEnabled
  | SetIsTravelRuleDepositDeclarationEnabled
  | SetIsTravelRuleAddrWhitelistingEnabled
  | SetIsRenewAddressAcknowledged
  | SetIsXusdCoachMarkActive

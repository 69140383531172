import React, { useReducer } from 'react'
import { STEPS, AddBankFlowProps, State } from './types'
import AddBankFlowComponent from './steps'
import reducer, { initialState } from './reducer'

export const AddBankFlowContext = React.createContext<{
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

export default function(props: AddBankFlowProps) {
  const [state, dispatch] = useReducer(reducer, props, init)
  return (
    <AddBankFlowContext.Provider value={{ state, dispatch }}>
      <AddBankFlowComponent />
    </AddBankFlowContext.Provider>
  )
}

function init({
  isVAEnabled,
  bankAccountId,
  selectedBank,
  accountNumber,
  successCallback,
  includeVerification,
  personalVerificationStatus = null,
  businessVerificationStatus = null,
  onVerificationMethodNextButtonClick,
}: AddBankFlowProps): State {
  const step =
    bankAccountId && includeVerification
      ? STEPS.CHOOSE_VERIFY_METHODS
      : STEPS.CHOOSE_BANK_AND_ACCOUNT_NUMBER

  return {
    step,
    isVAEnabled: isVAEnabled || false, // isVAEnabled could be undefined
    bankAccountId,
    accountNumber,
    selectedBank,
    includeVerification,
    successCallback,
    personalVerificationStatus,
    businessVerificationStatus,
    onVerificationMethodNextButtonClick,
  }
}

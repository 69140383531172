export enum XIDR_USER_INFO_ACTIONS {
  SET_USER_INFO = 'XIDR_USER_INFO_ACTIONS.SET_USER_INFO',
  SET_USER_PROFILE = 'XIDR_USER_INFO_ACTIONS.SET_USER_PROFILE',
  SET_BLOCKCHAIN_ADDRESSES = 'XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESSES',
  SET_BANK_ACCOUNTS = 'XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNTS',
  SET_BANK_LIST = 'XIDR_USER_INFO_ACTIONS.SET_BANK_LIST',
  SET_VIRTUAL_NETWORKS = 'XIDR_USER_INFO_ACTIONS.SET_VIRTUAL_NETWORKS',
  SET_FI_LIST = 'XIDR_USER_INFO_ACTIONS.SET_FI_LIST',
  SET_MAX_ADDRESS_AMOUNT = 'XIDR_USER_INFO_ACTIONS.SET_MAX_ADDRESS_AMOUNT',
  SET_ADDRESS_QUOTA = 'XIDR_USER_INFO_ACTIONS.SET_ADDRESS_QUOTA',
  SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS = 'XIDR_USER_INFO_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS',
  UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS = 'XIDR_USER_INFO_ACTIONS.UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS',
  SET_SUBMITTING_KYC_DATA = 'XIDR_USER_INFO_ACTIONS.SET_SUBMITTING_KYC_DATA',
}

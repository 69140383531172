import React from 'react'
import { useSelector } from 'react-redux'
import {
  H2,
} from '@xfers/design-system'
import {   
  Column,
  ColumnTitle,
  ColumnText,
  CustomCard,
  Divider,
  Row, 
} from '~/styledComponent/styled'
import { selectMerchantDetail } from '~/biz/store/merchantInfo/selectors'
import { MerchantDetail } from '~/biz/store/merchantInfo/types'

export default function() {
  const { merchantDetail } = useSelector(selectMerchantDetail)

  const {
    accountManager: {
      fullName = '-',
      isDirector = false,
    } = {},
  } = merchantDetail as MerchantDetail

  return (
    <>
      <CustomCard>
        <H2>Account Manager</H2>
        <Divider />
        <Row>
          <Column>
            <ColumnTitle>Full Name</ColumnTitle>
            <ColumnText>{fullName}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Director / Beneficial Owner</ColumnTitle>
            <ColumnText>{isDirector ? 'Director, Beneficial Owner' : 'Beneficial Owner'}</ColumnText>
          </Column>
        </Row>
      </CustomCard>
    </>
  )
}
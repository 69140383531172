import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { css } from 'emotion'
import styled from '@emotion/styled'
import {
  useModal,
  Modal,
  Button,
  P1,
  BadgeIcon,
  straitsXTheme,
  DESKTOP_BREAKPOINT,
  Link,
} from '@xfers/design-system'
import { Document, InfoFilled } from '@xfers/design-system/es/icons'
import { selectMerchantDetail } from '~/biz/store/merchantInfo/selectors'
import BusinessInformation from './DetailComponents/BusinessInformation'
import { MerchantDetail, BeneficialOwner as BeneficialOwnerType } from '~/biz/store/merchantInfo/types'
import BusinessActivities from './DetailComponents/BusinessActivities'
import AccountManager from './DetailComponents/AccountManager'
import BusinessDocuments from './DetailComponents/BusinessDocuments'
import BeneficialOwner from './DetailComponents/BeneficialOwnerorDirector'

export default function() {
  const { resetModal } = useModal()
  const { merchantDetail } = useSelector(selectMerchantDetail)

  const {
    businessAddressLine1,
    businessAddressLine2,
    businessPostalCode,
    city,
    country,
    accountManager,
    beneficialOwners,
  } = merchantDetail as MerchantDetail

  let addressString = ''
  if (businessAddressLine1 !== undefined && businessAddressLine1 !== '') addressString += businessAddressLine1 + ' '
  if (businessAddressLine2 !== undefined && businessAddressLine2 !== '') addressString += businessAddressLine2 + ' '
  if (city !== undefined && city !== '') addressString += city + ' '
  if (country !== undefined && country !== '') addressString += country + ' '
  if (businessPostalCode !== undefined && businessPostalCode !== '') addressString += businessPostalCode

  const styledModal = css`
    max-width: 90vw !important;
    top: 3vh!important;

    ${DESKTOP_BREAKPOINT} {
      max-width: 60vw !important;
    } 
  `

  const getBoAndDirector = useMemo(() => {
    const { directorsOnlyList, beneficialOwnersOnlyList } = beneficialOwners.reduce((acc: { directorsOnlyList: BeneficialOwnerType[], beneficialOwnersOnlyList: BeneficialOwnerType[] }, bo: BeneficialOwnerType) => {
      if (bo.businessPosition === 'director') {
        acc.directorsOnlyList.push(bo);
      } else if (bo.businessPosition === 'beneficial_owner') {
        acc.beneficialOwnersOnlyList.push(bo);
      }
      return acc;
    }, { directorsOnlyList: [], beneficialOwnersOnlyList: [] });
  
    return {
      directorsOnlyList,
      beneficialOwnersOnlyList,
    };
  }, [beneficialOwners]);
  
  const {
    directorsOnlyList,
    beneficialOwnersOnlyList,
  } = getBoAndDirector

  const confirmHandler = () => {
    resetModal()
  }

  return (
    <Modal
      className={styledModal}
      icon={<BadgeIcon type="success" icon={<Document />} />}
      title="My KYB Details"
      onClose={resetModal}
      footer={
        <>
          <ConfirmButton type="secondary" onClick={confirmHandler}>
            Done
          </ConfirmButton>
        </>
      }
    >
      <ModalContent>
        <BusinessInformation />
        <BusinessActivities />
        <BusinessDocuments />
        {
          accountManager && 
          <AccountManager />
        }
        {
          beneficialOwnersOnlyList &&
          beneficialOwnersOnlyList.map((bo: BeneficialOwnerType, index: number) => <BeneficialOwner key={`bo-${index}`} beneficialOwner={bo} />)
        }
        {
          directorsOnlyList &&
          directorsOnlyList.map((bo: BeneficialOwnerType, index: number) => <BeneficialOwner key={`director-${index}`} beneficialOwner={bo} />)
        }
        <DisclaimerContainer>
          <InfoFilled
            height={18}
            width={18}
            color={`#3583E6`}
          />
          <P1>
            If your KYB information is outdated, please contact our{' '} 
            <Link
              href="https://support.straitsx.com/hc/en-us/requests/new"
              target="_blank"
              rel="noreferrer noopener"
            >
              support team
            </Link>{' '} 
            and provide us with your latest information.
          </P1>
        </DisclaimerContainer>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const DisclaimerContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: start;

  ${DESKTOP_BREAKPOINT} {
    align-items: center;
  }
`

const ConfirmButton = styled(Button)`
  margin-left: 10px !important;
  padding: 15px 20px !important;
  color: ${straitsXTheme.brand.primary.contrast} !important;
  background-color: ${straitsXTheme.brand.primary.default} !important;
  &:disabled {
    opacity: 0.5 !important;
  }
`
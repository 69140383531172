import { WithdrawLimits } from './types'

export const WITHDRAW_LIMITS: WithdrawLimits = {
  xsgd: {
    FIAT_MIN: 10,
    FIAT_MAX: 200 * 1000,
    STABLECOIN_MAX: 2 * 1000 * 1000,
    STABLECOIN_MIN: 10,
  },
  usdc: {
    FIAT_MIN: 100,
    FIAT_MAX: Infinity,
    STABLECOIN_MAX: 2 * 1000 * 1000,
    STABLECOIN_MIN: 10,
  },
  usdt: {
    FIAT_MIN: 100,
    FIAT_MAX: Infinity,
    STABLECOIN_MAX: 2 * 1000 * 1000,
    STABLECOIN_MIN: 10,
  },
  xusd: {
    FIAT_MIN: 100,
    FIAT_MAX: Infinity,
    STABLECOIN_MAX: 2 * 1000 * 1000,
    STABLECOIN_MIN: 10,
  },
}

export const FEE = {
  USD_WITHDRAWAL_FEE_RATE: 0.002, // 0.2%
  USD_DEPOSIT_MIN_FEE: 5,
  USD_DEPOSIT_PROMOTION_FEE_RATE: 0.0005, // 0.05%
  USD_DEPOSIT_FEE_RATE: 0.001, // 0.1%
}

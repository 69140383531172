import { Reducer } from 'redux'
import { ActionTypes, BUSINESS_VERIFICATION_ACTIONS, IndividualAddFlowMeta, State } from './types'

const initialState: State = {
  initialized: false,
  currentPath: undefined,
  verificationStatus: undefined,
  businessInformation: {
    businessType: undefined,
    businessName: undefined,
    merchantsFormerNames: [],
    companyDisplayname: undefined,
    businessRegistrationNumber: undefined,
    businessLicenseExpiryDate: undefined,
    businessContactNo: undefined,
    businessDateOfIncorporation: undefined,
    countryOfIncorporation: undefined,
    businessAddressLine1: undefined,
    businessAddressLine2: undefined,
    businessPostalCode: undefined,
    country: undefined,
    city: undefined,
    tinNpwp: undefined,
  },
  businessRelation: {
    methods: [],
  },
  businessActivity: {
    countriesOfActivities: [],
    businessWebsite: undefined,
    businessCategory: undefined,
    businessSubCategory: undefined,
    businessActivitySummary: undefined,
    expectedTransactionVolume: undefined,
    expectedValuePerTransaction: undefined,
    expectedGtv: undefined,
    sourceOfWealth: undefined,
    sourceOfFunds: undefined,
    sourceOfGoods: undefined,
  },
  businessTaxResidency: {
    taxAmnestyParticipation: undefined,
    merchantTaxResidences: [],
  },
  uploadDocument: {
    putIdFrontUrl: undefined,
    getIdFrontUrl: undefined,
    putBizfileUrl: undefined,
    getBizfileUrl: undefined,
    putMemorandumUrl: undefined,
    getMemorandumUrl: undefined,
    putCertificateOfIncorporationUrl: undefined,
    getCertificateOfIncorporationUrl: undefined,
    putRegisterOfMembersUrl: undefined,
    getRegisterOfMembersUrl: undefined,
    putRegisterOfDirectorsUrl: undefined,
    getRegisterOfDirectorsUrl: undefined,
    putMolhrUrl: undefined,
    getMolhrUrl: undefined,
    idFrontUrl: undefined,
    isIdFrontPdf: false,
    bizfileUrl: undefined,
    isBizfilePdf: false,
    memorandumUrl: undefined,
    isMemorandumPdf: false,
    certificateOfIncorporationUrl: undefined,
    isCertificateOfIncorporationPdf: false,
    registerOfMembersUrl: undefined,
    isRegisterOfMembersPdf: false,
    registerOfDirectorsUrl: undefined,
    isRegisterOfDirectorsPdf: false,
    molhrUrl: undefined,
    isMolhrPdf: false,
  },
  accountManager: {
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    nricType: undefined,
    NRIC: undefined,
    nricIssueDate: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    village: undefined,
    subDistrict: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    rtRw: undefined,
    ownershipPercentage: undefined,
    verificationStatus: undefined,
    idFrontUrl: undefined,
    isIdFrontPdf: false,
    idBackUrl: undefined,
    isIdBackPdf: false,
    proofOfAddressUrl: undefined,
    isProofOfAddressPdf: false,
  },
  businessCategoriesList: undefined,
  highRiskSubcategoriesList: undefined,
  addFlowStep: null,
  addFlowMeta: {
    type: 'individual',
    isDirectorOrBo: false,
    role: undefined,
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    nricType: undefined,
    NRIC: undefined,
    nricIssueDate: undefined,
    nricExpiryDate: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    ownershipPercentage: undefined,
    putIdFrontUrl: undefined,
    getIdFrontUrl: undefined,
    putIdBackUrl: undefined,
    getIdBackUrl: undefined,
    putProofOfAddressUrl: undefined,
    getProofOfAddressUrl: undefined,
    idFrontUrl: undefined,
    isIdFrontPdf: false,
    idBackUrl: undefined,
    isIdBackPdf: false,
    proofOfAddressUrl: undefined,
    isProofOfAddressPdf: false,
    deedOfAppointmentUrl: undefined,
    isDeedOfAppointmentPdf: false,
    idFrontDisabled: false,
    idBackDisabled: false,
    proofOfAddressDisabled: false,
  },
  directorAndBoList: [
    {
      name: '',
      type: 'individual',
      role: 'account_manager',
      ownershipPercentage: '-',
      is_shadow_account_manager: false,
    } as IndividualAddFlowMeta,
    // {
    //   name: 'Dustin Williamson',
    //   type: 'Individual',
    //   role: ['Director', 'Beneficial Owner'],
    //   ownershipPercentage: '25%',
    //   is_shadow_account_manager: true,
    // },
    // {
    //   name: 'Ronald Robertson',
    //   type: 'Individual',
    //   role: ['Director'],
    //   ownershipPercentage: '15%',
    //   is_shadow_account_manager: false,
    // },
    // {
    //   name: 'Coinhako',
    //   type: 'Company',
    //   role: ['Beneficial Owner'],
    //   ownershipPercentage: '30%',
    //   is_shadow_account_manager: false,
    // },
    // {
    //   name: 'Binance SG',
    //   type: 'Company',
    //   role: ['Beneficial Owner'],
    //   ownershipPercentage: '5%',
    // },
    // {
    //   name: 'Guy Hawkins',
    //   type: 'Individual',
    //   role: ['Director'],
    //   ownershipPercentage: '5%',
    // },
    // {
    //   name: 'Coinbase',
    //   type: 'Company',
    //   role: ['Beneficial Owner'],
    //   ownershipPercentage: '4%',
    // },
    // {
    //   name: 'Company 1',
    //   type: 'Company',
    //   role: ['Beneficial Owner'],
    //   ownershipPercentage: '2%',
    // },
    // {
    //   name: 'Company 2',
    //   type: 'Company',
    //   role: ['Beneficial Owner'],
    //   ownershipPercentage: '2%',
    // },
    // {
    //   name: 'Company 3',
    //   type: 'Company',
    //   role: ['Beneficial Owner'],
    //   ownershipPercentage: '10%',
    // },
  ],
}

export const businessVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS_VERIFICATION_ACTIONS.INIT_BUSINESS_DATA: {
      return {
        ...state,
        ...action.data,
        initialized: true,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_BUSINESS_DATA: {
      return {
        ...state,
        [action.page]: {
          ...state[action.page],
          [action.key]: action.value,
        },
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_DIRECT_URL: {
      return {
        ...state,
        [action.page]: { ...state[action.page], ...action.urls },
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_STEP: {
      return {
        ...state,
        addFlowStep: action.step,
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_ADD_FLOW_META: {
      return {
        ...state,
        addFlowMeta: { ...state.addFlowMeta, ...action.meta },
      }
    }

    case BUSINESS_VERIFICATION_ACTIONS.SET_CURRENT_PATH: {
      return {
        ...state,
        currentPath: action.path,
      }
    }

    default:
      return state
  }
}

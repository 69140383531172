import moment from 'moment'
import { Dispatch, GetState } from '~/xidrApp/store/types'
import {
  ActionTypes,
  XIDR_TRANSACTIONS_ACTIONS,
  CSV_FORMAT_VERSION,
  TRANSACTIONS_TAB_KEY,
  FilterValuesObject,
  Transactions,
  Transaction,
} from './types'
import { get, ENDPOINTS } from '~/api'
import transactionTypeFormatter from '~/helpers/transactionTypeHelper'

export const refreshTransactionsData = (resetPage: boolean = true) => (
  dispatch: Dispatch<ActionTypes>
) => {
  if (resetPage) {
    dispatch(resetCurrentPage())
  }
  dispatch(getTransactions())
  dispatch(getTransactionTotalCount())
}

export const getTransactionOptions = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_TRANSACTIONS_FILTER_OPTIONS).then(resp => {
    const transactionTabOptions = []
    if (
      Object.prototype.hasOwnProperty.call(
        resp.account_types_by_tabs,
        TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT
      )
    ) {
      transactionTabOptions.push(TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT)
    }
    if (
      Object.prototype.hasOwnProperty.call(
        resp.account_types_by_tabs,
        TRANSACTIONS_TAB_KEY.XFERS_WALLET
      )
    ) {
      transactionTabOptions.push(TRANSACTIONS_TAB_KEY.XFERS_WALLET)
    }

    if (transactionTabOptions.length !== 0) {
      dispatch({
        type: XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS,
        transactionTabOptions,
      })

      dispatch({
        type: XIDR_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB,
        transactionsTabKey: transactionTabOptions[0],
      })
    }

    dispatch({
      type: XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS,
      viewType: resp.view_type,
      filterOptions: {
        [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: {
          transactionTypes: transactionTypeFormatter({
            initialTypes: resp.transaction_types,
          }),
          transactionStatuses: resp.transaction_statuses,
          sortTypes: resp.sort_types,
          accountTypes: resp.account_types_by_tabs[TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT] || undefined,
        },
        [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: {
          transactionTypes: transactionTypeFormatter({
            initialTypes: resp.transaction_types,
          }),
          transactionStatuses: resp.transaction_statuses,
          sortTypes: resp.sort_types,
          accountTypes: resp.account_types_by_tabs[TRANSACTIONS_TAB_KEY.XFERS_WALLET] || undefined,
        },
      },
    })

    dispatch(refreshTransactionsData())
  })
}

export const getTransactions = () => (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  const { transactionsTabKey, filterOptions } = getState().xidrTransactions

  if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')
  if (filterOptions === null) throw new Error('filterOptions cannot be null!')

  const {
    startDate,
    endDate,
    transactionStatuses,
    transactionTypes,
    sortType,
    accountIds,
    currentPage,
    pageLimit,
    searchString,
  } = getState().xidrTransactions.filterValues[transactionsTabKey]

  const isoStartDate = startDate
    ? moment(startDate)
        .startOf('day')
        .toISOString()
    : ''
  const isoEndDate = endDate
    ? moment(endDate)
        .endOf('day')
        .toISOString()
    : ''

  const { accountTypes } = filterOptions[transactionsTabKey]
  const defaultAccountIds = accountTypes && accountTypes.map(element => element.value)
  // Note: If user haven't filtered by walllet type, use the account ids given by default for the particular tab
  const selectedAccountIds = accountIds.length <= 0 ? defaultAccountIds : accountIds

  const subRoute = `?start_date=${isoStartDate}&end_date=${isoEndDate}&transaction_statuses=${transactionStatuses}&transaction_types=${transactionTypes}&sort_type=${sortType}&account_ids=${selectedAccountIds}&current_page=${currentPage}&page_limit=${pageLimit}&search_string=${encodeURIComponent(
    searchString
  )}`

  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING,
    isTxnLoading: true,
  })

  get(ENDPOINTS.API_V3_STABLECOIN_TRANSACTIONS, subRoute).then(resp => {
    const transactionsCopy = JSON.parse(JSON.stringify(resp.transactions))
    resp.transactions.forEach((transaction: Transactions, i: number) => {
      switch (transaction.description.type) {
        case 'Deposit':
          transactionsCopy[i].description.type = 'Bank Transfer In'
          break
        case 'Withdrawal':
          transactionsCopy[i].description.type = 'Bank Transfer Out'
          break
        case 'Stablecoin Deposit':
          transactionsCopy[i].description.type = 'Blockchain Transfer In'
          break
        case 'Stablecoin Withdrawal':
          transactionsCopy[i].description.type = 'Blockchain Transfer Out'
          break
      }
      // Refer to Api::V3::Xfers::Transactions::StablecoinTransferContractSerializer
      if (transaction.description.data === 'Digital Goods Wallet') {
        transactionsCopy[i].description.data = 'StraitsX Account'
      }
    })

    dispatch({
      type: XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS,
      transactions: transactionsCopy,
      contractsExist: resp.contracts_exist,
    })
  })
}

export const getTransactionTotalCount = () => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const { transactionsTabKey, filterOptions } = getState().xidrTransactions

  if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')
  if (filterOptions === null) throw new Error('filterOptions cannot be null!')

  const {
    startDate,
    endDate,
    transactionStatuses,
    transactionTypes,
    sortType,
    accountIds,
    currentPage,
    pageLimit,
    searchString,
  } = getState().xidrTransactions.filterValues[transactionsTabKey]

  const isoStartDate = startDate
    ? moment(startDate)
        .startOf('day')
        .toISOString()
    : ''
  const isoEndDate = endDate
    ? moment(endDate)
        .endOf('day')
        .toISOString()
    : ''

  const { accountTypes } = filterOptions[transactionsTabKey]
  const defaultAccountIds = accountTypes && accountTypes.map(element => element.value)
  // Note: If user haven't filtered by walllet type, use the account ids given by default for the particular tab
  const selectedAccountIds = accountIds.length <= 0 ? defaultAccountIds : accountIds

  const subRoute = `?start_date=${isoStartDate}&end_date=${isoEndDate}&transaction_statuses=${transactionStatuses}&transaction_types=${transactionTypes}&sort_type=${sortType}&account_ids=${selectedAccountIds}&current_page=${currentPage}&page_limit=${pageLimit}&search_string=${encodeURIComponent(
    searchString
  )}`

  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING,
    isPaginationLoading: true,
  })

  get(ENDPOINTS.API_V3_STABLECOIN_TRANSACTIONS_TOTAL_COUNT, subRoute).then(resp => {
    dispatch({
      type: XIDR_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT,
      totalCount: resp.total_count,
    })
  })
}

export const setFilters = (filterValuesObject: FilterValuesObject) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.SET_FILTERS,
    filterValuesObject,
  })

  let resetCurrentPage = true
  if ('currentPage' in filterValuesObject) {
    resetCurrentPage = false
  }
  dispatch(refreshTransactionsData(resetCurrentPage))
}

export const resetCurrentPage = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE,
  })
}

export const getCsvFormatVersion = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION,
    csvFormatVersion: CSV_FORMAT_VERSION.V3,
  })
}

export const switchTransactionsTab = (transactionsTabKey: TRANSACTIONS_TAB_KEY) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB,
    transactionsTabKey,
  })

  dispatch(refreshTransactionsData())
}

export const getLatestTransactions = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_V3_STABLECOIN_TRANSACTIONS, `?current_page=1&page_limit=5`).then(txnResp => {
    const transactionsCopy = JSON.parse(JSON.stringify(txnResp.transactions))
    txnResp.transactions.forEach((transaction: Transaction, i: number) => {
      switch (transaction.description.type) {
        case 'Deposit':
          transactionsCopy[i].description.type = 'Bank Transfer In'
          break
        case 'Withdrawal':
          transactionsCopy[i].description.type = 'Bank Transfer Out'
          break
        case 'Stablecoin Deposit':
          transactionsCopy[i].description.type = 'Blockchain Transfer In'
          break
        case 'Stablecoin Withdrawal':
          transactionsCopy[i].description.type = 'Blockchain Transfer Out'
          break
      }
      // Refer to Api::V3::Xfers::Transactions::StablecoinTransferContractSerializer
      if (transaction.description.data === 'Digital Goods Wallet') {
        transactionsCopy[i].description.data = 'StraitsX Account'
      }
    })
    dispatch({
      type: XIDR_TRANSACTIONS_ACTIONS.SET_LATEST_PERSONAL_TRANSACTIONS,
      latestPersonalTransactions: transactionsCopy,
    })
  })
}

export const setLatestTransactions = (latestPersonalTransactions: Transaction[]) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_TRANSACTIONS_ACTIONS.SET_LATEST_PERSONAL_TRANSACTIONS,
    latestPersonalTransactions,
  })
}

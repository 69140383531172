/* eslint-disable max-lines, react/jsx-props-no-spreading */
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { datadogRum } from '@datadog/browser-rum'
import { DESKTOP_BREAKPOINT, useTheme, Card, useModal } from '@xfers/design-system'
import straitsXBackground from '~/assets/straitsXBackground.png'
import { useAppFlippers } from '~/store/flippers/hooks'
import {
  selectUserEmail,
  selectUserName,
  selectIsKillSwitchActive,
  selectIsDpt2TncAccepted,
} from '~/app/store/userInfo/selectors'
import { FLIPPER_KEYS } from '~/store/flippers/const'
import { FLIPPER_STATUS } from '~/store/flippers/types'
import { selectFlipperWithId } from '~/store/flippers/selectors'
import { setBlockchainAddressList } from '~/app/store/blockchainAddresses/actions'

import {
  init,
  setIsLockedFeatureOn,
  setIsTypeformSurveyRequired,
  setIsTypeformSurveyVisible,
} from '~/app/store/route/actions'
import {
  setIsDptApplied,
  setIsHtsWithdrawalFeeCharging,
  setIsHederaUIEnabled,
  setIsPolygonUIEnabled,
  setIsSsoTwoFaAuthenticatorEnabled,
  setIsDptRiskModalEnabled,
  setIsFazzVaForDashboardEnabled,
  setIsFazzVaForDirectMintEnabled,
  setIsTravelRuleDepositDeclarationEnabled,
  setIsTravelRuleAddrWhitelistingEnabled,
  setIsAvalancheUIEnabled,
  setIsTronUIEnabled,
} from '~/app/store/landingPage/actions'
import { setIsEarnEnabled } from '~/app/store/earn/actions'
import RouteHooks from './RouteHooks'
import { straitsXRoutes } from '~/app/routes/config'
import { PrivateRoute } from '~/components/Route'
import StraitsXFooter from '~/components/StraitsXFooter'
import StraitsXNavigation from '~/components/StraitsXNavigation'
import StraitsXUserInfoBar from '~/components/StraitsXUserInfoBar'
import KillSwitchLockedPage from '~/components/KillSwitchLockedPage'
import { RootState } from '~/app/store/types'
import Tour from '~/app/pages/Tour'
import { Notification } from './store/route/types'
import { useCleverTap } from '~/hooks/useCleverTap'
import FeatureGatingManager from '~/managers/FeatureGatingManager'
import {
  getLatestTransactions,
  getTotalTravelRuleComplianceBlockerCount,
} from './store/transactions/actions'
import { fetchUserInfo } from './store/userInfo/actions'
import {
  selectUserInfo,
  selectUserVerificationStatus,
  selectUserRequiresManualPeriodicReview,
} from './store/userInfo/selectors'
import {
  selectBlockchainAddressList,
  selectIsBlockchainAddressesInitiated,
} from './store/blockchainAddresses/selectors'
import { fetchBankList } from './store/bankAccounts/actions'
import {
  fetchBlockchainAddresses,
  setIsWhitelistingImprovementEnabled,
} from './store/blockchainAddresses/actions'
import { selectDgwAccountByCurrency } from './store/accounts/selectors'
import { CURRENCY } from '~/types'
import { fetchAccountDetailList, fetchAccountList } from './store/accounts/actions'
import { ConfigManager, SITE_TYPE } from '~/managers/ConfigManager'
import Dpt2Tnc from '~/components/Dpt2Tnc'

export default () => {
  const dispatch = useDispatch()
  const { resetModal } = useModal()

  const isSandbox = false

  const { accountLocked } = useSelector(selectUserInfo)

  const {
    user,
    isTypeformSurveyVisible,
    isTypeformSurveyRequired,
    isLockedFeatureOn,
    isUserInfoInitialised,
  } = useSelector((state: RootState) => state.route)
  const fetchedNotifications = useSelector((state: RootState) => state.route.notifications)

  const { isTourRunning } = useSelector((state: RootState) => state.tour)

  const { isEarnEnabled } = useSelector((state: RootState) => state.earn)

  const { latestPersonalTransactions } = useSelector((state: RootState) => state.transactions)

  const { isAccountListInitiated } = useSelector((state: RootState) => state.accounts)

  const email = useSelector(selectUserEmail)

  const isKillSwitchActive = useSelector(selectIsKillSwitchActive)
  const isDpt2TncAccepted = useSelector(selectIsDpt2TncAccepted)

  const flipperOtcTrxUI = useSelector(
    selectFlipperWithId(FLIPPER_KEYS.SG_OTC_TRX_HISTORY_APP_DASHBOARD, email)
  )

  const flipperDpt2Tnc = useSelector(selectFlipperWithId(FLIPPER_KEYS.DPT2_TNC, email))

  // fetching application needed data
  useEffect(() => {
    dispatch(fetchAccountList())
    dispatch(fetchAccountDetailList())
    dispatch(fetchBankList())
    dispatch(fetchUserInfo())
    dispatch(getTotalTravelRuleComplianceBlockerCount())
  }, [dispatch])

  useEffect(() => {
    if (isAccountListInitiated && flipperOtcTrxUI?.status === FLIPPER_STATUS.SUCCEEDED)
      dispatch(getLatestTransactions())
  }, [isAccountListInitiated, flipperOtcTrxUI?.status])

  const sgdDgwAccount = useSelector(selectDgwAccountByCurrency)(CURRENCY.XSGD)
  useEffect(() => {
    if (!isAccountListInitiated) return

    if (sgdDgwAccount) {
      dispatch(fetchBlockchainAddresses(sgdDgwAccount.accountId))
    } else {
      dispatch(setBlockchainAddressList([]))
    }
  }, [dispatch, isAccountListInitiated, sgdDgwAccount?.accountId])

  useEffect(() => {
    if (latestPersonalTransactions && latestPersonalTransactions.length > 0) {
      const latestTransactionDate = new Date(latestPersonalTransactions[0].createdAt)
      const thresholdDate = new Date()
      thresholdDate.setDate(thresholdDate.getDate() - 90)

      dispatch(
        setIsTypeformSurveyRequired(latestTransactionDate.getTime() >= thresholdDate.getTime())
      )
    }
  }, [latestPersonalTransactions?.length])

  // TODO: move framework integration to hook
  useEffect(() => {
    if (isTypeformSurveyVisible && isTypeformSurveyRequired) {
      const script = document.createElement('script')

      script.src = 'https://embed.typeform.com/next/embed.js'
      script.async = true

      document.body.appendChild(script)
    }
  }, [isTypeformSurveyRequired, isTypeformSurveyVisible])

  const blockchainAddresses = useSelector(selectBlockchainAddressList)
  const isBlockchainAddressesInitiated = useSelector(selectIsBlockchainAddressesInitiated)
  const verificationStatus = useSelector(selectUserVerificationStatus)
  const userName = useSelector(selectUserName)

  const [displayGetStartedTab, setDisplayGetStarted] = useState(false)

  useEffect(() => {
    if (verificationStatus && verificationStatus !== 'verified') {
      setDisplayGetStarted(true)
    }
  }, [isBlockchainAddressesInitiated, blockchainAddresses, verificationStatus])

  const requiresManualPeriodicReview = useSelector(selectUserRequiresManualPeriodicReview)

  const threeConsecutiveDigits = /\d{3}/
  const desktopBreakpointWidth = threeConsecutiveDigits.exec(DESKTOP_BREAKPOINT)?.[0] || 0
  const shouldRenderUserInfoBar =
    isLockedFeatureOn === false ||
    (isLockedFeatureOn === true && !(accountLocked && requiresManualPeriodicReview))

  // required for tour to work properly; prevents rendering antd Menu component twice
  const shouldRenderDesktopViewNavbar =
    (document.documentElement.clientWidth >= desktopBreakpointWidth || !isTourRunning) &&
    (isLockedFeatureOn === false ||
      (isLockedFeatureOn === true && !(accountLocked && requiresManualPeriodicReview)))

  const displayMintTab = verificationStatus === 'verified'

  useEffect(() => {
    dispatch(init())
  }, []) // Run init just once

  const [filteredNotifications, setFilteredNotifications] = useState({
    data: [] as Notification[],
    unreadCount: 0,
  })

  useEffect(() => {
    if (fetchedNotifications.data.length !== 0) {
      let { data: filteredNoti, unreadCount } = fetchedNotifications

      // filter out notifications before Sep 20 2021 (SGT) because this is when StraitsX dashboard was revamped
      const dateToFilter = new Date('2021-09-20T00:00:00.000+08:00')
      filteredNoti = fetchedNotifications.data.filter(
        noti => new Date(noti.created_at) >= dateToFilter
      )
      unreadCount = filteredNoti.filter(noti => noti.is_read === 0).length

      setFilteredNotifications({
        data: filteredNoti,
        unreadCount,
      })
    }
  }, [fetchedNotifications])

  const notifications = useMemo(
    () => ({
      isLoading: fetchedNotifications.isLoading,
      data: filteredNotifications.data,
      unreadCount: filteredNotifications.unreadCount,
    }),
    [fetchedNotifications.isLoading, filteredNotifications]
  )

  useCleverTap(user)

  useEffect(() => {
    if (ConfigManager.isProd() && user?.email && user?.kcId) {
      datadogRum.setUser({
        email: user.email,
        kcId: user.kcId,
      })
    }
  }, [user])

  useEffect(() => {
    if (user?.email) {
      FeatureGatingManager.isEnabled(
        [
          'straitsx_typeform_survey',
          'straitsx_dpt_changeover',
          'xsgd_hts_withdrawal_fee_charging',
          'xsgd_dashboard_hedera_ui',
          'xsgd_dashboard_polygon_ui',
          'xsgd_dashboard_avalanche_ui',
          'usdt_dashboard_tron_ui',
          'whitelisting_improvement',
          'sso_two_fa_authenticator',
          'straitsx_dpt_risk_modal',
          'straitsx_enable_fazz_va_for_dashboard',
          'straitsx_enable_fazz_va_for_direct_mint',
          'straitsx_app_dashboard_use_earn',
          'straitsx_travel_rule_deposit_declaration',
          'straitsx_travel_rule_addr_whitelisting',
        ],
        user?.email
      ).then(resp => {
        dispatch(setIsTypeformSurveyVisible(resp.straitsx_typeform_survey))
        dispatch(setIsDptApplied(resp.straitsx_dpt_changeover))
        dispatch(setIsHtsWithdrawalFeeCharging(resp.xsgd_hts_withdrawal_fee_charging))
        dispatch(setIsHederaUIEnabled(resp.xsgd_dashboard_hedera_ui))
        dispatch(setIsPolygonUIEnabled(resp.xsgd_dashboard_polygon_ui))
        dispatch(setIsAvalancheUIEnabled(resp.xsgd_dashboard_avalanche_ui))
        dispatch(setIsTronUIEnabled(resp.usdt_dashboard_tron_ui))
        dispatch(setIsWhitelistingImprovementEnabled(resp.whitelisting_improvement))
        dispatch(setIsSsoTwoFaAuthenticatorEnabled(resp.sso_two_fa_authenticator))
        dispatch(setIsDptRiskModalEnabled(resp.straitsx_dpt_risk_modal))
        dispatch(setIsFazzVaForDashboardEnabled(resp.straitsx_enable_fazz_va_for_dashboard))
        dispatch(setIsFazzVaForDirectMintEnabled(resp.straitsx_enable_fazz_va_for_direct_mint))
        dispatch(setIsEarnEnabled(resp.straitsx_app_dashboard_use_earn))
        dispatch(
          setIsTravelRuleDepositDeclarationEnabled(resp.straitsx_travel_rule_deposit_declaration)
        )
        dispatch(
          setIsTravelRuleAddrWhitelistingEnabled(resp.straitsx_travel_rule_addr_whitelisting)
        )
      })
    }
  }, [user?.email])

  useAppFlippers({ email: user?.email })

  // double check the lock feature
  useEffect(() => {
    FeatureGatingManager.isEnabled('straitsx_locked_page').then(resp => {
      dispatch(setIsLockedFeatureOn(resp))
    })
  }, [])

  if (isKillSwitchActive) {
    return <KillSwitchLockedPage />
  }

  if (
    !isDpt2TncAccepted &&
    isUserInfoInitialised &&
    !isKillSwitchActive &&
    flipperDpt2Tnc?.enabled
  ) {
    return <Dpt2Tnc siteType={SITE_TYPE.APP} />
  }

  return (
    <Root id="app-root">
      {isTypeformSurveyVisible && isTypeformSurveyRequired && (
        <div
          data-tf-popover="YsqtCzHk"
          data-tf-custom-icon="https://images.typeform.com/images/T62HziiCcpD9"
          data-tf-button-color="#00D37E"
          data-tf-button-text="Launch me"
          data-tf-iframe-props="title=StraitsX Relationship NPS Survey | XSGD"
          data-tf-medium="snippet"
          data-tf-hidden={{ utm_source: 'dashboard', id: user.email }}
          style={{ all: 'unset' }}
        ></div>
      )}
      <RouteHooks resetModal={resetModal} />
      {isKillSwitchActive !== undefined && (
        <Container>
          {shouldRenderDesktopViewNavbar && (
            <NavigationWrapper>
              <StraitsXNavigation
                displayGetStartedTab={displayGetStartedTab}
                displayMintTab={displayMintTab}
                isSandbox={isSandbox}
                displayEarnTab={isEarnEnabled}
              />
            </NavigationWrapper>
          )}
          <MainContainer>
            {shouldRenderUserInfoBar && (
              <StraitsXUserInfoBar
                userInfo={{ ...user, fullName: userName }}
                isSandbox={isSandbox}
                notifications={notifications}
                displayGetStartedTab={displayGetStartedTab}
                displayMintTab={displayMintTab}
                displayEarnTab={isEarnEnabled}
              />
            )}

            {// only show loading page when not receiving accountLocked from api yet
            accountLocked === null || isLockedFeatureOn === null ? (
              <LoadingPage />
            ) : (
              <Suspense fallback={<LoadingPage />}>
                <Switch>
                  {straitsXRoutes.map((route, i) => (
                    <PrivateRoute
                      key={i}
                      {...route}
                      isAccountLocked={accountLocked}
                      isLockedFeatureOn={isLockedFeatureOn}
                      isPeriodicReviewRequired={requiresManualPeriodicReview}
                    />
                  ))}
                </Switch>
              </Suspense>
            )}

            <StraitsXFooter />
          </MainContainer>
          {isTourRunning && (
            <>
              <Global
                styles={css`
                  ${DESKTOP_BREAKPOINT} {
                    #react-joyride-step-3 > .__floater {
                      position: fixed !important;
                      transform: none !important;
                      top: 250px !important;
                      left: 240px !important;
                    }
                  }
                `}
              />
              <Tour />
            </>
          )}
        </Container>
      )}
    </Root>
  )
}

function LoadingPage() {
  const { spacing } = useTheme()

  return (
    <Page>
      <Card style={{ padding: spacing.xl }}>
        <Skeleton style={{ width: '40%' }} />
        <Skeleton />
        <Skeleton />
      </Card>
    </Page>
  )
}

const Page = styled.div`
  padding: 16px;

  ${DESKTOP_BREAKPOINT} {
    padding: 24px;
  }
`

const Root = styled.div`
  z-index: 0;
`

const Container = styled.div`
  background-image: url(${straitsXBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 73px;
  width: 100%;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`

const NavigationWrapper = styled.div`
  z-index: 1;
  display: none;

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    width: 257px;
    flex-shrink: 0;
  }
`

const Skeleton = styled.div`
  float: left;
  width: 100%;
  height: 16px;

  margin: 10px;
  border-radius: 5px;
  background-size: 100wh;
  background-image: linear-gradient(90deg, #e8e8e8 0%, #f6f7f9 50%, #e8e8e8 100%);
  animation: shine-lines 1s infinite linear;

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
`

import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Tick } from '@xfers/design-system/es/icons'
import { useModal, BadgeIcon, Button, Modal } from '@xfers/design-system'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'

export default function() {
  const { resetModal } = useModal()
  const { state } = useContext(AddBankFlowContext)
  const { bankAccount } = state

  return (
    <Modal
      onClose={() => resetModal()}
      title="Successfully Added"
      icon={<BadgeIcon type="success" icon={<Tick />} />}
      footer={
        <>
          <Button type="secondary" onClick={() => resetModal()}>
            Close
          </Button>
        </>
      }
    >
      <Text>Your bank account has been added. You can proceed to verify your bank account.</Text>
      <InfoRow>
        <InfoLeft>Currency</InfoLeft>
        <InfoRight>USD</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Bank Name</InfoLeft>
        <InfoRight>{bankAccount.bankName}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Account Number</InfoLeft>
        <InfoRight>{bankAccount.accountNumber}</InfoRight>
      </InfoRow>
    </Modal>
  )
}

// TODO reuse style with other currency add bank flow
const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-bottom: 20px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const InfoLeft = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
`

const InfoRight = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #1b2736;
`

export const BankIcon = styled.img`
  margin-right: 5px;
`

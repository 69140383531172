import { post, ENDPOINTS } from '~/api'
import { keysToCamel } from '~/helpers/helpers'
import { STEPS, ACTIONS, BankAccount, BillingAddress } from './types'
import { CURRENCY } from '~/types'

type RequestBody = {
  currency: string
  account_no: string
  swift_code: string
  intermediary_swift_code?: string
  routing_code?: string
  bank_name: string
  account_holder_name?: string
  billing_address: {
    city: string
    country: string
    line1: string
    line2?: string
    postal_code?: string
  }
}

export const setStep = (step: STEPS) => ({
  type: ACTIONS.SET_STEP,
  step,
})

export const setBankAccount = (bankAccount: BankAccount) => {
  return {
    type: ACTIONS.SET_BANK_ACCOUNT,
    bankAccount,
  }
}

export const setBillingAddress = (billingAddress: BillingAddress) => ({
  type: ACTIONS.SET_BILLING_ADDRESS,
  billingAddress,
})

export const addBankAccountHandler = ({
  bankAccount,
  billingAddress,
  accountHolderName,
  goNext,
  callback,
}: {
  bankAccount: BankAccount
  billingAddress: BillingAddress
  accountHolderName: string
  goNext: (success: boolean, bankAccount?: BankAccount) => void
  callback?: () => void
}) => {
  const { city, country, line1, line2, postalCode } = billingAddress
  const { accountNumber, swiftCode, bankName, intermediarySwiftCode, routingCode } = bankAccount
  const requestBody: RequestBody = {
    currency: CURRENCY.USDC.toUpperCase(),
    account_no: accountNumber,
    swift_code: swiftCode,
    bank_name: bankName,
    account_holder_name: accountHolderName,
    billing_address: {
      city,
      country,
      line1,
      line2,
      ...(postalCode && { postal_code: postalCode }),
    },
    ...(intermediarySwiftCode && { intermediary_swift_code: intermediarySwiftCode }),
    ...(routingCode && { routing_code: routingCode }),
  }

  post(ENDPOINTS.API_V3_USER_BANK_ACCOUNT, requestBody)
    .then((resp: BankAccount) => {
      goNext(true, keysToCamel(resp))
    })
    .catch(() => {
      goNext(false)
    })
    .finally(() => {
      if (callback) callback()
    })
}

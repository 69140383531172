import moment from 'moment'
import { createSelector } from 'reselect'
import { RootState } from '~/biz/store/types'
import { ACCOUNT_TYPES, CURRENCY, DGWAccountLimit } from '~/types'
import { Account, AccountDetail, Wallet, WALLET_TYPES } from './types'

const selectAccountStore = (state: RootState) => state.accounts

const selectAccountDetailList = (state: RootState) => state.accounts.accountDetailList || []

const selectAccountList = (state: RootState) => state.accounts.accountList || []

const selectDigitalGoodAccountList = createSelector(
  [selectAccountList],
  (accountList: Account[]) =>
    accountList && accountList.filter(acc => acc.id === WALLET_TYPES.DIGITAL_GOODS)
)

const selectDigitalGoodAccountDetailList = createSelector(
  [selectAccountDetailList],
  (accountList: AccountDetail[]) =>
    accountList && accountList.filter(acc => acc.attributes.walletId === WALLET_TYPES.DIGITAL_GOODS)
)

const selectHasDigitalGoodAccounts = createSelector(
  [selectAccountList],
  (accountList: Account[]) =>
    accountList && accountList.some(acc => acc.id === WALLET_TYPES.DIGITAL_GOODS)
)

const selectHasBusinessAccount = createSelector(
  [selectAccountList],
  (accountList: Account[]) =>
    accountList && accountList.some(acc => acc.type === ACCOUNT_TYPES.BUSINESS)
)

export const selectDgwMerchantAccountByCurrency = createSelector(
  [selectDigitalGoodAccountList],
  accounts => (currency: CURRENCY | null) => {
    if (!currency) return undefined

    const bizAccount = accounts.find(
      acc => acc.type === ACCOUNT_TYPES.BUSINESS && acc.currency === currency
    )
    if (bizAccount) return bizAccount

    return accounts.find(acc => acc.type === ACCOUNT_TYPES.CORPORATE && acc.currency === currency)
  }
)

export const selectDgwMerchantAccount = createSelector([selectDigitalGoodAccountList], accounts => {
  const bizAccount = accounts.find(acc => acc.type === ACCOUNT_TYPES.BUSINESS)
  if (bizAccount) return bizAccount

  return accounts.find(acc => acc.type === ACCOUNT_TYPES.CORPORATE)
})

export const selectXSGDWalletByTypeId = createSelector(
  [selectAccountList],
  accounts => (walletTypeId: number | undefined) =>
    walletTypeId
      ? accounts.find(acc => acc.id === walletTypeId && acc.currency === CURRENCY.XSGD)
      : undefined
)

export const selectUserWallets = createSelector([selectAccountList], accounts => {
  const wallets: Wallet[] = accounts.map(acc => {
    let label: string

    if (acc.id === WALLET_TYPES.GENERAL) {
      label = 'General Wallet'
    } else if (acc.id === WALLET_TYPES.DIGITAL_GOODS) {
      label = 'Digital Goods Wallet'
    } else {
      label = 'Account withdraw not supported yet, its probably a bug'
    }

    return {
      label,
      type: acc.id,
      balance: acc.balance,
      formattedBalance: acc.formattedBalance,
      accountId: acc.accountId,
      accountType: acc.type,
      fundPoolId: acc.fundPoolId,
    }
  })

  return wallets
})

export const selectSGDDigitalGoodAccountLimit = createSelector(
  [selectAccountDetailList],
  accounts => {
    const digitalGoodsWallet = accounts.find(
      acc =>
        acc.attributes.walletId === WALLET_TYPES.DIGITAL_GOODS &&
        acc.attributes.currency === CURRENCY.SGD
    )

    const limits = digitalGoodsWallet?.attributes?.limitations

    return {
      holdingRemaining: limits?.storage?.remaining || '0',
      holdingMax: limits?.storage?.max || '0',
      annualTransactionRemaining: limits?.chargeRollingYearly?.remaining || '0',
      annualTransactionMax: limits?.chargeRollingYearly?.max || '0',
    } as DGWAccountLimit
  }
)

const parseAccountLimits = (accountDetail: AccountDetail | undefined): DGWAccountLimit => {
  const limits = accountDetail?.attributes?.limitations
  const incrementDate = accountDetail?.attributes?.transactionLimitIncrementDate

  return {
    holdingRemaining: limits?.storage?.remaining || '0',
    holdingMax: limits?.storage?.max || '0',
    annualTransactionRemaining: limits?.chargeRollingYearly?.remaining || '0',
    annualTransactionMax: limits?.chargeRollingYearly?.max || '0',
    withdrawalDailyRemaining: limits?.withdrawalDaily?.remaining || '0',
    withdrawalDailyMax: limits?.withdrawalDaily?.max || '0',
    limitationIncreaseAmount: incrementDate?.sum || '0',
    limitationIncreaseDate: incrementDate?.date ? moment(incrementDate.date) : undefined,
  } as DGWAccountLimit
}

export const selectDgwMerchantAccountLimitByCurrency = createSelector(
  [selectDigitalGoodAccountDetailList],
  accountDetailList => (currency: CURRENCY | null) => {
    if (!currency) return undefined

    const bizAccount = accountDetailList.find(
      acc => acc.attributes.type === ACCOUNT_TYPES.BUSINESS && acc.attributes.currency === currency
    )
    if (bizAccount) return parseAccountLimits(bizAccount)

    const corpAccount = accountDetailList.find(
      acc => acc.attributes.type === ACCOUNT_TYPES.CORPORATE && acc.attributes.currency === currency
    )

    return corpAccount ? parseAccountLimits(corpAccount) : undefined
  }
)

export const selectIsAccountListInitiated = createSelector(
  [selectAccountStore],
  store => store.isAccountListInitiated
)

const selectIsLinkedToAsb = createSelector([selectAccountDetailList], accounts => {
  const firstAccount = accounts?.[0]

  if (!accounts || !firstAccount) return false
  return !!firstAccount.attributes.asb
})

export {
  selectAccountStore,
  selectAccountDetailList,
  selectAccountList,
  selectHasDigitalGoodAccounts,
  selectDigitalGoodAccountList,
  parseAccountLimits,
  selectHasBusinessAccount,
  selectIsLinkedToAsb,
}

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { checkFlipperIsEnabled } from './actions'
import { FLIPPER_KEYS } from '~/store/flippers/const'

type FlipperRequest = {
  featureKey: string
  flipperId?: string
  defaultValue?: boolean
}

type Prop = {
  email?: undefined | string
  merchantId?: undefined | number
}

export const useAppFlippers = ({ email }: Prop) => {
  useFlippers([{ featureKey: FLIPPER_KEYS.WHITELISTING_IMPROVEMENT }], null)
  useFlippers(
    [
      { featureKey: FLIPPER_KEYS.ASB_FE_TO_PASS_TOTAL_AMOUNT_FOR_STABLECOIN_WITHDRAWAL },
      { featureKey: FLIPPER_KEYS.USDC_SWAP },
      { featureKey: FLIPPER_KEYS.APP_DASHBOARD_SWAP },
      { featureKey: FLIPPER_KEYS.USD_TRANSFER_IN },
      { featureKey: FLIPPER_KEYS.USD_TRANSFER_OUT },
      { featureKey: FLIPPER_KEYS.USD_ADD_BANK_ACCOUNT },
      { featureKey: FLIPPER_KEYS.SG_MINT_DASHBOARD },
      { featureKey: FLIPPER_KEYS.SG_TRANSACTION_TIMEZONE_CONTROL },
      { featureKey: FLIPPER_KEYS.SG_RECIPIENT_BANK_ACCOUNT_CHANGED_REMINDER },
      { featureKey: FLIPPER_KEYS.SG_OTC_FORM_APP_DASHBOARD },
      { featureKey: FLIPPER_KEYS.SG_OTC_TRX_HISTORY_APP_DASHBOARD },
      { featureKey: FLIPPER_KEYS.USDC_ERC20_DEPOSIT },
      { featureKey: FLIPPER_KEYS.USDC_ERC20_WITHDRAWAL },
      { featureKey: FLIPPER_KEYS.USDT_ERC20_DEPOSIT },
      { featureKey: FLIPPER_KEYS.USDT_ERC20_WITHDRAWAL },
      { featureKey: FLIPPER_KEYS.APP_DASHBOARD_USDT },
      { featureKey: FLIPPER_KEYS.XUSD_DASHBOARD_ENABLE },
      { featureKey: FLIPPER_KEYS.MANUAL_ADD_ADDRESS },
      { featureKey: FLIPPER_KEYS.USDT_USDC_SWAP_APP_DASHBOARD },
      { featureKey: FLIPPER_KEYS.USDT_XUSD_SWAP_APP_DASHBOARD },
      { featureKey: FLIPPER_KEYS.RENEW_DEPOSIT_ADDRESS },
      { featureKey: FLIPPER_KEYS.BITGO_DEPOSIT_ADDRESS_ROTATION_STAGING },
      { featureKey: FLIPPER_KEYS.BITGO_DEPOSIT_ADDRESS_ROTATION_PRODUCTION },
      { featureKey: FLIPPER_KEYS.JUMIO_FLOW_FOR_SG_USER },
      { featureKey: FLIPPER_KEYS.ZRC_HTS_DIRECT_MINT_DISABLED },
      { featureKey: FLIPPER_KEYS.KILLSWITCH },
      { featureKey: FLIPPER_KEYS.COOLING_DOWN },
      { featureKey: FLIPPER_KEYS.SWAP_DISABLED_ACCURATE_FEE_CALCULATION },
      { featureKey: FLIPPER_KEYS.DPT2_TNC },
    ],
    email || ''
  )
}

export const useBizFlippers = ({ email }: Prop) => {
  useFlippers(
    [
      { featureKey: FLIPPER_KEYS.ASB_FE_TO_PASS_TOTAL_AMOUNT_FOR_STABLECOIN_WITHDRAWAL },
      { featureKey: FLIPPER_KEYS.WHITELISTING_IMPROVEMENT },
      { featureKey: FLIPPER_KEYS.USDC_SWAP },
      { featureKey: FLIPPER_KEYS.BIZ_DASHBOARD_SWAP },
      { featureKey: FLIPPER_KEYS.SWAP_DISABLED_ACCURATE_FEE_CALCULATION },
      { featureKey: FLIPPER_KEYS.USD_TRANSFER_IN },
      { featureKey: FLIPPER_KEYS.USD_TRANSFER_OUT },
      { featureKey: FLIPPER_KEYS.USD_ADD_BANK_ACCOUNT },
      { featureKey: FLIPPER_KEYS.SG_BIZ_MANUAL_ENHANCEMENT },
      { featureKey: FLIPPER_KEYS.SG_TRANSACTION_TIMEZONE_CONTROL },
      { featureKey: FLIPPER_KEYS.SG_RECIPIENT_BANK_ACCOUNT_CHANGED_REMINDER },
      { featureKey: FLIPPER_KEYS.SG_OTC_FORM_BIZ_DASHBOARD },
      { featureKey: FLIPPER_KEYS.SG_OTC_TRX_HISTORY_BIZ_DASHBOARD },
      { featureKey: FLIPPER_KEYS.ASB_SANDBOX_UI },
      { featureKey: FLIPPER_KEYS.USDC_ERC20_DEPOSIT },
      { featureKey: FLIPPER_KEYS.USDC_ERC20_WITHDRAWAL },
      { featureKey: FLIPPER_KEYS.USDT_ERC20_DEPOSIT },
      { featureKey: FLIPPER_KEYS.USDT_ERC20_WITHDRAWAL },
      { featureKey: FLIPPER_KEYS.BIZ_DASHBOARD_USDT },
      { featureKey: FLIPPER_KEYS.MANUAL_ADD_ADDRESS },
      { featureKey: FLIPPER_KEYS.USDT_USDC_SWAP_BIZ_DASHBOARD },
      { featureKey: FLIPPER_KEYS.USDT_XUSD_SWAP_BIZ_DASHBOARD },
      { featureKey: FLIPPER_KEYS.XUSD_DASHBOARD_ENABLE },
      { featureKey: FLIPPER_KEYS.RENEW_DEPOSIT_ADDRESS },
      { featureKey: FLIPPER_KEYS.BITGO_DEPOSIT_ADDRESS_ROTATION_STAGING },
      { featureKey: FLIPPER_KEYS.BITGO_DEPOSIT_ADDRESS_ROTATION_PRODUCTION },
      { featureKey: FLIPPER_KEYS.KILLSWITCH },
      { featureKey: FLIPPER_KEYS.COOLING_DOWN },
      { featureKey: FLIPPER_KEYS.DPT2_TNC },
    ],
    email || ''
  )
}

export const useXidrAppFlippers = ({ email }: Prop) => {
  useFlippers([{ featureKey: FLIPPER_KEYS.RENEW_DEPOSIT_ADDRESS }], email || '')
}

export const useXidrBizFlippers = ({ email }: Prop) => {
  useFlippers([{ featureKey: FLIPPER_KEYS.RENEW_DEPOSIT_ADDRESS }], email || '')
}

/**
 * useFlippers is to check flippers that needed in page components,
 * the API check only happens at the beginning
 *
 * @param requestFlippers Set of flippers need to be checked
 */
export const useFlippers = (requestFlippers: FlipperRequest[], sharedFlipperId: null | string) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const featureKeys: string[] = requestFlippers.map(k => k.featureKey)

    if (sharedFlipperId && sharedFlipperId.length > 0) {
      // flipperId is specified and fetched
      dispatch(checkFlipperIsEnabled(featureKeys, sharedFlipperId))
    } else if (sharedFlipperId === null) {
      // no flipperId
      dispatch(checkFlipperIsEnabled(featureKeys))
    }
  }, [dispatch, sharedFlipperId])
}

import binanceIcon from '~/assets/binanceIcon.svg'
import coinhakoIcon from '~/assets/coinhako.svg'
import mewAddressIcon from '~/assets/mewAddressIcon.svg'
import hodlnautIcon from '~/assets/hodlnaut.svg'
import oncIcon from '~/assets/onchain-custodian.svg'
import sparrowIcon from '~/assets/sparrow.svg'
import tokenizeXchangeIcon from '~/assets/tokenize-xchange.svg'
import bitgoIcon from '~/assets/bitgo.svg'
import cdcIcon from '~/assets/cdc.svg'
import liquidIcon from '~/assets/liquid.svg'
import qcpIcon from '~/assets/qcp.svg'
import coinutIcon from '~/assets/coinut.svg'
import wirexIcon from '~/assets/wirex.svg'
import finblox from '~/assets/finblox.svg'
import coinstoreIcon from '~/assets/coinstore.svg'

function getFiIconUrl(fi: string) {
  let icon
  switch (fi) {
    case 'Binance Singapore':
      icon = binanceIcon
      break
    case 'Coinhako':
      icon = coinhakoIcon
      break
    case 'Hodlnaut':
      icon = hodlnautIcon
      break
    case 'OnChain Custodian':
      icon = oncIcon
      break
    case 'Sparrow':
      icon = sparrowIcon
      break
    case 'Tokenize Exchange':
      icon = tokenizeXchangeIcon
      break
    case 'Bitgo':
      icon = bitgoIcon
      break
    case 'Crypto.com':
      icon = cdcIcon
      break
    case 'Liquid':
      icon = liquidIcon
      break
    case 'QCP Capital':
      icon = qcpIcon
      break
    case 'Coinut':
      icon = coinutIcon
      break
    case 'Wirex':
      icon = wirexIcon
      break
    case 'Finblox':
      icon = finblox
      break
    case 'Coinstore':
      icon = coinstoreIcon
      break
    default:
      icon = mewAddressIcon
      break
  }
  return icon
}

export default getFiIconUrl

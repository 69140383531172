import { Reducer } from 'redux'

import { ActionTypes, MY_ACCOUNT_ACTIONS, Wallet } from './types'

const initialState = {
  wallets: null,
}

type State = {
  wallets: Wallet[] | null
}

export const myAccount: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case MY_ACCOUNT_ACTIONS.GET_WALLETS: {
      return {
        ...state,
        wallets: action.wallets,
      }
    }

    default:
      return state
  }
}

import React from 'react'
import capitalize from 'lodash/capitalize'
import * as logos from '@xfers/design-system/es/logos'
import { BadgeIcon, WalletFilled, H3 } from '@xfers/design-system'
import { BankFilled } from '@xfers/design-system/es/icons'
import { css } from 'emotion'
import getFiIconUrl from '~/common/fiIconMapper'
import { FinancialInstitution } from '~/types'

export const getLogoComponent = (
  name: string,
  fallbackElm?: React.ReactElement,
  svgProps?: Record<string, unknown>
): React.ReactElement => {
  if (!name) {
    return fallbackElm || <></>
  }

  // mapping FE string to logos
  const logoName = capitalize(name)
  switch (logoName) {
    case 'Cimb_niaga':
      return logos.Cimb(svgProps)
    case 'Sahabat_sampoerna':
      return logos.Bss(svgProps)
    case 'Hsbc2':
      return logos.Hsbc(svgProps)
    case 'Sif':
      return logos.Sifl(svgProps)
  }
  const mapKey =
    Object.keys(logos).find((key: string) => key.toLowerCase() === name.toLowerCase()) || ''

  if (!mapKey || !logos[mapKey]) {
    return fallbackElm || <></>
  }

  return logos[mapKey]?.(svgProps)
}

export const getAddressLogo = (financialInstitutionId: number, fiList: FinancialInstitution[]) => {
  const fi =
    fiList.find(financialInstitution => financialInstitution.id === financialInstitutionId)?.name ||
    'Personal Address (Non-Custodial)'
  return (
    <>
      {fi && !fi.includes('Personal Address') ? (
        <img src={getFiIconUrl(fi)} />
      ) : (
        <BadgeIcon type="primary" icon={<WalletFilled />} />
      )}
    </>
  )
}

export const getBankLogoComponent = (
  bankAbbrev: string,
  defaultBankColor: string,
  svgProps?: Record<string, unknown>
) => {
  const defaultH3ClassName = css`
    display: flex;
    align-items: center;
    height: 100%;
  `

  return getLogoComponent(
    bankAbbrev,
    <H3 className={defaultH3ClassName}>
      <BankFilled color={defaultBankColor} />
      {bankAbbrev}
    </H3>,
    svgProps
  )
}

export enum STEPS {
  REMINDER,
  ADD_BANK_ACCOUNT,
  ADD_BILLING_ADDRESS,
  SUCCESSFULLY_SUBMITTED,
  FAILED_SUBMITTED,
  VERIFY_WITH_BANK_STATEMENT,
  SUCCESSFULLY_UPLOAD_BANK_STATEMENT,
}

export type BankAccount = {
  id?: number
  bankName: string
  accountHolderName?: string
  swiftCode: string
  intermediarySwiftCode?: string
  routingCode?: string
  accountNumber: string
}

export type BillingAddress = {
  city: string
  country: string
  line1: string
  line2: string
  postalCode: string
}

export type AddBankFlowProps = {
  step?: STEPS
  successCallback: (bankAccount: BankAccount) => void
  accountHolderName?: string
  bankAccount?: BankAccount
  billingAddress?: BillingAddress
}

export type State = {
  step: STEPS
  accountHolderName: string
  bankAccount: BankAccount
  billingAddress: BillingAddress
  successCallback: (bankAccount: BankAccount) => void
}

export enum ACTIONS {
  SET_STEP = 'SET_STEP',
  SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT',
  SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS',
}

export type ActionTypes = {
  type: ACTIONS
  step: STEPS
  bankAccount: BankAccount
  billingAddress: BillingAddress
}

import { XIDR_USER_INFO_ACTIONS } from './const'
import {
  XIDRUserInfo,
  ActionTypes,
  BankAccount,
  BlockchainAddress,
  Bank,
  VirtualNetwork,
  FinancialInstitution,
  UserProfile,
} from './types'
import { Dispatch, GetState } from '~/xidrBiz/store/types'
import { del, ENDPOINTS, get, put } from '~/api'
import { keysToCamel, objectToQueryParams } from '~/helpers/helpers'

export const setUserInfo = (userInfo: XIDRUserInfo) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_USER_INFO,
    userInfo,
  })
}

export const setBankAccountList = (bankAccountList: BankAccount[]) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNT_LIST,
    bankAccountList,
  })
}

export const fetchUserInfo = (onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const optionIncludedSubRoute = '?include_documents=true&show_rejected=true'
  const resp = await get(ENDPOINTS.API_V3_STABLECOIN_USERS, optionIncludedSubRoute)

  const userInfo: XIDRUserInfo = keysToCamel(resp)
  dispatch(setUserInfo(userInfo))

  if (resp.bank_accounts) {
    let bankAccs = (resp.bank_accounts as []).map(item => keysToCamel(item))
    if (bankAccs.length !== 0) {
      bankAccs = bankAccs.filter(item => item.disabled === 'false')
    }
    dispatch(setBankAccountList(bankAccs as BankAccount[]))
  }

  if (onSuccess) {
    onSuccess()
  }
}

export const deleteBankAccount = (bankId: number, onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const bankAccounts = getState().xidrUserInfo.bankAccountList

  // TODO: handle resp?
  await del(ENDPOINTS.API_V3_STABLECOIN_USER_BANK_ACCOUNTS, {}, `/${bankId}`)

  const remainingAccounts = bankAccounts.filter((account: BankAccount) => account.id !== bankId)
  dispatch(setBankAccountList(remainingAccounts))

  if (onSuccess) {
    onSuccess()
  }
}

export const setBlockchainAddressList = (blockchainAddressList: BlockchainAddress[]) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESS_LIST,
    blockchainAddressList,
  })
}

export const setMaxAddressAmount = (maxAddressAmount: number) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_MAX_ADDRESS_AMOUNT,
    maxAddressAmount,
  })
}

export const setAddressQuota = (addressQuota: number) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_ADDRESS_QUOTA,
    addressQuota,
  })
}

export const setFIList = (fiList: FinancialInstitution[]) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_FI_LIST,
    fiList,
  })
}

export const fetchBlockchainAddressList = (successCallback?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const resp = await get(ENDPOINTS.API_V3_STABLECOIN_XIDR_ADDRESSES)
  if (resp.data.address_list) {
    const respList = resp.data.address_list as []
    const addressList: BlockchainAddress[] = respList.map(blockchainAddress =>
      keysToCamel(blockchainAddress)
    )
    dispatch(setBlockchainAddressList(addressList))
  }

  if (resp.data.fi_list) {
    dispatch(setFIList(resp.data.fi_list as FinancialInstitution[]))
  }

  if (resp.data.address_quota && !Number.isNaN(resp.data.address_quota)) {
    dispatch(setAddressQuota(resp.data.address_quota))
  }

  if (resp.data.max_address_amount && !Number.isNaN(resp.data.max_address_amount)) {
    dispatch(setMaxAddressAmount(resp.data.max_address_amount))
  }

  if (successCallback) {
    successCallback()
  }
}

export const deleteBlockchainAddress = (
  deletingAddress: BlockchainAddress,
  onSuccess?: () => void
) => async (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  const addressList = getState().xidrUserInfo.blockchainAddressList

  // TODO: handle resp?
  const queryParams = objectToQueryParams({ blockchain: deletingAddress.blockchain })
  const subRoute = `/${deletingAddress.id}?${queryParams}`
  await del(ENDPOINTS.API_V3_STABLECOIN_XIDR_ADDRESSES, {}, subRoute)

  const remainingAccounts = addressList.filter(account => account !== deletingAddress)
  dispatch(setBlockchainAddressList(remainingAccounts))

  if (onSuccess) {
    onSuccess()
  }
}

export const setBankList = (bankList: Bank[]) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_BANK_LIST,
    bankList,
  })
}

export const fetchBankList = ({ isVASupported = false }: { isVASupported: boolean }) => async (
  dispatch: Dispatch<ActionTypes>,
  onSuccess?: () => void
) => {
  if (isVASupported) {
    const resp = (await get(ENDPOINTS.API_V3_STABLECOIN_BANKS_VIRTUAL_NETWORKS)) as {
      data: VirtualNetwork[]
    }
    const { data: vnList } = keysToCamel(resp)
    dispatch(setVirtualNetworks(vnList))
  } else {
    const { data: bankList } = (await get(ENDPOINTS.API_V3_STABLECOIN_BANKS)) as { data: Bank[] }
    dispatch(setBankList(bankList))
  }

  if (onSuccess) {
    onSuccess()
  }
}

export const setVirtualNetworks = (virtualNetworks: VirtualNetwork[]) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_VIRTUAL_NETWORKS,
    virtualNetworks,
  })
}

export const updateUserAvatar = (
  {
    deleteAvatar,
    displayImage,
  }: {
    deleteAvatar?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    displayImage?: { fileData?: any; fileName?: any }
  },
  onSuccess?: () => void
) => async (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  const updatingInfo = {
    delete_avatar: deleteAvatar,
    display_image: displayImage
      ? {
          file_data: displayImage.fileData,
          file_name: displayImage.fileName,
        }
      : null,
  }
  const resp = await put(ENDPOINTS.API_V3_STABLECOIN_UPDATE_USER_PROFILE, updatingInfo)

  const { userProfile } = getState().xidrUserInfo
  if (userProfile && resp?.display_image?.file_data) {
    const newUserProfile: UserProfile = {
      ...userProfile,
      avatarUrl: resp.display_image.file_data,
    }
    dispatch(setUserProfile(newUserProfile))
  }

  if (onSuccess) {
    onSuccess()
  }
}

export const setUserProfile = (userProfile: UserProfile) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_USER_PROFILE,
    userProfile,
  })
}

export const fetchUserProfile = (onSuccess?: () => void) => async (
  dispatch: Dispatch<ActionTypes>
) => {
  const resp = await get(ENDPOINTS.API_V3_STABLECOIN_USER_PROFILE)

  if (resp) {
    const userProfile = keysToCamel(resp)
    dispatch(setUserProfile(userProfile as UserProfile))
  }

  if (onSuccess) {
    onSuccess()
  }
}

export const setBlockchainAddressDeletion = (blockchainAddressDeletion: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESS_DELETION,
    blockchainAddressDeletion,
  })
}

export const setBankACcountDeletion = (bankAccountDeletion: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNT_DELETION,
    bankAccountDeletion,
  })
}

export const setNewlyCreatedBlockchainAddress = (address: BlockchainAddress) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: XIDR_USER_INFO_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS,
    blockchainAddress: address,
  })
}

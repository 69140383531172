import { Reducer } from 'redux'
import { UserInfo, ActionTypes, UserProfile } from './types'
import { USER_INFO_ACTIONS } from './const'

type State = {
  isUserInfoInitiated: boolean
  isUserProfileInitiated: boolean

  userInfo: UserInfo
  userProfile?: UserProfile
}

const initialState: State = {
  isUserInfoInitiated: false,
  isUserProfileInitiated: false,

  userInfo: {} as UserInfo,
}

export const userInfo: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO_ACTIONS.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
        isUserInfoInitiated: true,
      }
    }

    case USER_INFO_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.userProfile,
        isUserProfileInitiated: true,
      }
    }

    default:
      return state
  }
}

import { css } from 'emotion'

function preventDefault(e: Event) {
  e.preventDefault()
}

export function disableScroll() {
  document.body.classList.add(stopScrolling)
  window.addEventListener('touchmove', preventDefault) // mobile
}

export function enableScroll() {
  document.body.classList.remove(stopScrolling)
  window.removeEventListener('touchmove', preventDefault) // mobile
}

const stopScrolling = css`
  height: 100%;
  overflow: hidden;
`

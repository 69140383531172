import { Reducer } from 'redux'
import { FLIPPER_ACTIONS } from './const'
import { ActionTypes, Flipper, FLIPPER_STATUS, State } from './types'

const initialState = {
  flippers: [],
}

export const flippers: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_REQUESTED: {
      const itemInd = state.flippers.findIndex(
        i => i.featureKey === action.featureKey && i.flipperId === action.flipperId
      )

      const flipperItem: Flipper = {
        featureKey: action.featureKey,
        flipperId: action.flipperId,
        status: FLIPPER_STATUS.REQUESTED,
      }

      if (itemInd === -1) {
        state.flippers.push(flipperItem)
        return {
          flippers: [...state.flippers],
        }
      }

      state.flippers.splice(itemInd, 1, flipperItem)
      return {
        flippers: [...state.flippers],
      }
    }

    case FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_SUCCEEDED: {
      const itemInd = state.flippers.findIndex(
        i => i.featureKey === action.featureKey && i.flipperId === action.flipperId
      )
      state.flippers.splice(itemInd, 1, {
        featureKey: action.featureKey,
        flipperId: action.flipperId,
        enabled: action.enabled,
        status: FLIPPER_STATUS.SUCCEEDED,
      })
      return { flippers: [...state.flippers] }
    }

    case FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_FAILED: {
      const itemInd = state.flippers.findIndex(
        i => i.featureKey === action.featureKey && i.flipperId === action.flipperId
      )
      state.flippers.splice(itemInd, 1, {
        featureKey: action.featureKey,
        flipperId: action.flipperId,
        enabled: undefined,
        status: FLIPPER_STATUS.FAILED,
      })
      return { flippers: [...state.flippers] }
    }

    default:
      return state
  }
}

import { Reducer } from 'redux'
import { ActionTypes, XIDRVerificationPOB } from './types'
/* eslint max-lines: off */
import { XIDR_USER_VERIFICATION_ACTIONS } from './const'

type State = {
  pobList: XIDRVerificationPOB[]
}

const initialState: State = {
  pobList: [] as XIDRVerificationPOB[],
}

export const xidrUserVerification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_USER_VERIFICATION_ACTIONS.SET_POB_LIST: {
      return {
        ...state,
        pobList: action.pobList,
      }
    }

    default:
      return state
  }
}

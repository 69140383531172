import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { ConfigManager, SITE_TYPE } from '~/managers/ConfigManager'
import PageLoader from '~/components/PageLoader'
import appStore from '~/app/store/store'
import xidrAppStore from '~/xidrApp/store/store'
import bizStore from '~/biz/store/store'
import xidrBizStore from '~/xidrBiz/store/store'
import AppRoot from '~/app/Root'
import XIDRAppRoot from '~/xidrApp/Root'
import BizRoot from '~/biz/Root'
import XIDRBizRoot from '~/xidrBiz/Root'
import SSOManager from '~/managers/SSOManager'
import StraitsXTheme from './StraitsXTheme'

ConfigManager.setupGA()
ConfigManager.setupGTM()

const App = () => {
  useEffect(() => {
    if (!SSOManager.isLogined()) {
      SSOManager.ssoLogin()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!SSOManager.isLogined()) {
    return <PageLoader />
  }

  const rootComponent = (() => {
    switch (ConfigManager.getSiteType()) {
      case SITE_TYPE.APP:
        return <AppRoot />

      case SITE_TYPE.XIDR_APP:
        return <XIDRAppRoot />

      case SITE_TYPE.BIZ:
        return <BizRoot />

      case SITE_TYPE.XIDR_BIZ:
        return <XIDRBizRoot />
    }
    return null
  })()

  if (ConfigManager.isProd()) {
    datadogRum.init({
      applicationId: 'ecbedc13-46c6-4260-990b-3dc5da067af7',
      clientToken: 'pub9eac6d4c217ad300fbe6e154364e35e2',
      site: 'datadoghq.com',
      service: 'straitsx-dashboard',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }

  const reduxStore = (() => {
    switch (ConfigManager.getSiteType()) {
      case SITE_TYPE.APP:
        return appStore

      case SITE_TYPE.XIDR_APP:
        return xidrAppStore

      case SITE_TYPE.BIZ:
        return bizStore

      case SITE_TYPE.XIDR_BIZ:
        return xidrBizStore
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any // this for avoid TS to raise type warning
  })()

  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <StraitsXTheme>{rootComponent}</StraitsXTheme>
      </BrowserRouter>
    </Provider>
  )
}

export default App

import React, { useReducer } from 'react'
import { STEPS, AddBankFlowProps, State } from './types'
import AddBankFlowComponent from './steps'
import reducer, { initialState } from './reducer'

export const AddBankFlowContext = React.createContext<{
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: React.Dispatch<any>
}>({
  state: initialState,
  dispatch: () => null,
})

export default function(props: AddBankFlowProps) {
  const [state, dispatch] = useReducer(reducer, props, init)
  return (
    <AddBankFlowContext.Provider value={{ state, dispatch }}>
      <AddBankFlowComponent />
    </AddBankFlowContext.Provider>
  )
}

function init({
  step = STEPS.ADD_BANK_ACCOUNT,
  accountHolderName = '',
  bankAccount,
  billingAddress,
  successCallback,
}: AddBankFlowProps): State {
  return {
    step,
    accountHolderName,
    bankAccount: { ...initialState.bankAccount, ...bankAccount },
    billingAddress: { ...initialState.billingAddress, ...billingAddress },
    successCallback,
  }
}

export type EarnAccount = {
  email: string
  name: string
  platform_account_identifier: string
  uen: number
} | null

export type PlatformInfo = {
  name: string
  baseInterestRate: string
  baseInterestRatePercent: string
} | null

export type EarnCurrencyBalance = {
  rewardRateType: string
  apy: string
  apyRate: string | Array<{ lower_bound: string; upper_bound: string; apy: string; rate: string }>
  currencyCode: string
  currentBalance: number
  holdingLimitAmount: number
  availableBalance: number
  balanceLimit: number
  pendingInterestPayout: number
  totalInterestEarned: number
} | null

export type EarnTransaction = {
  amount: string
  apy: string
  balance: string
  createdAt: string
  currency: string
  exactAmount: string
  exactBalance: string
  exactStatus: string
  exactTransactionTypeTotal: string
  id: string
  platformAccountIdentifier: string
  referenceId: string
  status: string
  transactionType: string
  transactionTypeTotal: string
  updatedAt: string
}

export type EarnTransactionDetails = {
  id: string
  referenceId: string
  transactionType: EARN_TRANSACTION_TYPE | null
  amount: { prefix: string; postfix: string }
  createdDate: string
  completedDate: string
  currencyCode: string
  status: string
  details: string
}

export enum EarnTransferRequestType {
  DEPOSIT,
  WITHDRAWAL,
}

export enum EARN_TRANSACTION_TYPE {
  INTEREST = 'interest',
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum EARN_TRANSACTIONS_SORT_ORDER {
  NEWEST = 'desc',
  OLDEST = 'asc',
}

export enum EARN_CURRENCY {
  XSGD = 'XSGD',
  USDC = 'USDC',
}

export enum EARN_TRANSACTION_STATUS {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
}

export enum EARN_ACTIONS {
  SET_EARN_ACCOUNT = 'EARN_ACTIONS.CREATE_EARN_ACCOUNT',
  SET_IS_EARN_ENABLED = 'EARN_ACTIONS.SET_IS_EARN_ENABLED',
  SET_IS_LOADING = 'EARN_ACTIONS.SET_IS_LOADING',
  SET_PLATFORM_INFO = 'EARN_ACTIONS.SET_PLATFORM_INFO',
  SET_EARN_CURRENCY_BALANCE = 'EARN_ACTIONS.SET_EARN_CURRENCY_BALANCE',
  SET_LATEST_TRANSFER_REQUEST = 'EARN_ACTIONS.SET_LATEST_TRANSFER_REQUEST',
  SET_EARN_TRANSACTIONS = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS',
  SET_EARN_TRANSACTIONS_TYPE = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS_TYPE',
  SET_EARN_TRANSACTIONS_CURRENT_PAGE = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS_CURRENT_PAGE',
  SET_EARN_TRANSACTIONS_SORT_ORDER = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS_SORT_ORDER',
  SET_EARN_TRANSACTIONS_FILTER_CURRENCY = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY',
  SET_EARN_TRANSACTIONS_FILTER_DATE = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE',
  SET_EARN_TRANSACTIONS_FILTER_STATUS = 'EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS',
  SET_TRANSACTION_DETAILS = 'EARN_ACTIONS.SET_TRANSACTION_DETAILS',
}

type SetEarnAccount = {
  type: typeof EARN_ACTIONS.SET_EARN_ACCOUNT
  earnAccount: EarnAccount
}

type SetIsEarnEnabled = {
  type: typeof EARN_ACTIONS.SET_IS_EARN_ENABLED
  isEnabled: boolean
}

type SetIsLoading = {
  type: typeof EARN_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type SetPlatformInfo = {
  type: typeof EARN_ACTIONS.SET_PLATFORM_INFO
  platformInfo: PlatformInfo
}

type SetEarnCurrencyBalance = {
  type: typeof EARN_ACTIONS.SET_EARN_CURRENCY_BALANCE
  currency: string
  earnCurrencyBalance: EarnCurrencyBalance
}

type SetEarnTransactedAmount = {
  type: typeof EARN_ACTIONS.SET_LATEST_TRANSFER_REQUEST
  amount: number
  currencyCode: string
  requestType: EarnTransferRequestType
}

type SetEarnTransactions = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS
  transactions: EarnTransaction[]
  totalCount: number
  totalPages: number
}

type SetEarnTransactionsCurrentPage = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS_CURRENT_PAGE
  currentPage: number
}

type SetEarnTransactionsType = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS_TYPE
  transactionType: EARN_TRANSACTION_TYPE
}

type SetEarnTransactionsSortOrder = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS_SORT_ORDER
  sortOrder: EARN_TRANSACTIONS_SORT_ORDER
}

type SetEarnTransactionsFilterCurrency = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY
  currency: EARN_CURRENCY | null
}

type SetEarnTransactionsFilterDate = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE
  startDate: string | null
  endDate: string | null
}

type SetEarnTransactionsFilterStatus = {
  type: typeof EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS
  status: EARN_TRANSACTION_STATUS | null
}

type SetTransactionDetails = {
  type: typeof EARN_ACTIONS.SET_TRANSACTION_DETAILS
  transactionDetails: EarnTransactionDetails
}

export type ActionTypes =
  | SetIsEarnEnabled
  | SetEarnAccount
  | SetIsLoading
  | SetPlatformInfo
  | SetEarnCurrencyBalance
  | SetEarnTransactedAmount
  | SetEarnTransactions
  | SetEarnTransactionsCurrentPage
  | SetEarnTransactionsSortOrder
  | SetEarnTransactionsType
  | SetEarnTransactionsFilterCurrency
  | SetEarnTransactionsFilterDate
  | SetEarnTransactionsFilterStatus
  | SetTransactionDetails

import { useEffect } from 'react'
import cleverTapHelper, { ClevertapUserPropertyType } from '~/helpers/cleverTapHelper'

export const useCleverTap = (user: ClevertapUserPropertyType) => {
  useEffect(() => {
    if (!user.email || !user.fullName) return

    cleverTapHelper.setupClevertap()

    cleverTapHelper.onUserLogin({
      email: user.email,
      fullName: user.fullName,
      kcId: user.kcId,
      merchantId: user.merchantId,
    })
  }, [user.email, user.fullName])
}

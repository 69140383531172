import { CURRENCY } from '~/types'
import { SWAP_ACTIONS } from './const'

// DATA TYPES
export enum FX_ACTION_STATUS {
  NOT_FETCHED,
  FETCHING,
  FAILED,
  SUCCESS,
}

export type FXQuote = {
  sourceAmount: number
  destinationAmount: number
  sourceCurrency: string
  destinationCurrency: string
  id: string
  rate: number
  expiresAt: string
}

export type SwapTransactionLimit = {
  currency: CURRENCY
  maximumAmount: number
  minimumAmount: number
}
// END DATA TYPES

// ACTION TYPES
type SetFXRateAction = {
  type: typeof SWAP_ACTIONS.SET_SWAP_FX_RATE
  data: number | undefined
}

type SetFXRateStatusAction = {
  type: typeof SWAP_ACTIONS.SET_SWAP_FX_RATE_STATUS
  status: FX_ACTION_STATUS
}

type SetFXQuoteAction = {
  type: typeof SWAP_ACTIONS.SET_SWAP_FX_QUOTE
  data: FXQuote | undefined
}

type SetFXQuoteStatusAction = {
  type: typeof SWAP_ACTIONS.SET_SWAP_FX_QUOTE_STATUS
  status: FX_ACTION_STATUS
}

type SetSwapTransactionLimitsAction = {
  type: typeof SWAP_ACTIONS.SET_SWAP_TRANSACTION_LIMITS
  transactionLimits: SwapTransactionLimit[]
}

export type ActionTypes =
  | SetFXRateAction
  | SetFXRateStatusAction
  | SetFXQuoteAction
  | SetFXQuoteStatusAction
  | SetSwapTransactionLimitsAction
// END ACTION TYPES

import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ErrorPage } from '@xfers/design-system'
import { getError } from '~/helpers/helpers'

const AccessDenied = () => {
  const fetchError = getError('401')

  const fallbackContentHandler = () => {
    return (
      <Link to={'/'}>
        <Button type="primary">Return to Dashboard</Button>
      </Link>
    )
  }

  return (
    <ErrorPage
      title={fetchError.errorTitle}
      subtitle={fetchError.errorSubtitle}
      content={fetchError.errorContent}
      fallbackContent={fallbackContentHandler()}
      backgroundColor="transparent"
    />
  )
}

export default AccessDenied

import ReactGA from 'react-ga'
import { ConfigManager } from '~/managers/ConfigManager'

// eslint-disable-next-line consistent-return
export const createGaEvent = (category = '', action = '', label = '') => {
  if (ConfigManager.isXIDRSite()) {
    return
  }
  ReactGA.event({
    category,
    action,
    label,
  })
}

export const createGaPageView = (urlPath = '') => {
  if (ConfigManager.isXIDRSite()) {
    return
  }
  ReactGA.pageview(urlPath)
}

export const setGaAttribute = (obj = {}) => {
  if (ConfigManager.isXIDRSite()) {
    return
  }
  ReactGA.set(obj)
}

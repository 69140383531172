import article1 from './article1.json'
import article2 from './article2.json'
import article3 from './article3.json'
import article4 from './article4.json'
import notes from './notes.json'

import { Document } from '../../types'

export const StraitsXTokens: Document = {
  notes,
  "sections": [
    article1,
    article2,
    article3,
    article4
  ]
}

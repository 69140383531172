/* eslint-disable max-lines */
import { Reducer } from 'redux'

import { ActionTypes, DEVELOPER_TOOLS_ACTIONS, V3Key, ApiTokenInfo } from './types'

const initialState: State = {
  isFlipperDevToolsOn: null,
  isFlipperSandboxOn: false,
  isV3Merchant: false,

  isLoading: false,
  callbackSettings: {
    V3: {
      connectTokenDisabled: '',
      userVerificationStatusUpdated: '',
      userBankAccountVerificationStatusUpdated: '',
      withdrawalCompleted: '',
      payoutCompleted: '',
      chargeCompleted: '',
      virtualAccountTopupCompleted: '',
      v3PaymentGatewayPaymentExpired: '',
      v3PaymentGatewayPaymentPaid: '',
      v3PaymentGatewayPaymentCompleted: '',
      signature: '',
      showSig: false,
    },
  },
  apiKeys: {
    V3: {
      v3Keys: [],
      isLoading: false,
    },
  },

  // StraitsX owned api token service
  apiTokenInfo: {
    token: '',
    secret: '',
    lastUsed: '',
    createdAt: '',
  },
  callbackUrls: {
    payment: '',
    payout: '',
    retailPayment: '',
  },
}

type State = {
  isFlipperDevToolsOn: boolean | null // null means not fetched yet
  isFlipperSandboxOn: boolean
  isV3Merchant: boolean

  isLoading: boolean
  callbackSettings: {
    V3: {
      connectTokenDisabled: string
      userVerificationStatusUpdated: string
      userBankAccountVerificationStatusUpdated: string
      withdrawalCompleted: string
      payoutCompleted: string
      chargeCompleted: string
      virtualAccountTopupCompleted: string
      v3PaymentGatewayPaymentExpired: string
      v3PaymentGatewayPaymentPaid: string
      v3PaymentGatewayPaymentCompleted: string
      signature: string
      showSig: boolean
    }
  }
  apiKeys: {
    V3: {
      v3Keys: V3Key[]
      isLoading: boolean
    }
  }

  // StraitsX owned api token service
  apiTokenInfo: ApiTokenInfo
  callbackUrls: {
    payment: string
    payout: string
    retailPayment: string
  }
}

export const xidrDeveloperTools: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case DEVELOPER_TOOLS_ACTIONS.SET_API_KEY_VERSION_V3: {
      const { isV3Merchant } = action
      return {
        ...state,
        isV3Merchant,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.GET_INITIAL_URL_V3: {
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: action.v3CallbackSettings,
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URLS: {
      return {
        ...state,
        callbackUrls: {
          ...action.callbackUrls,
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.UPDATE_CALLBACK_URL: {
      return {
        ...state,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.RESET_API_TOKEN_INFO: {
      return {
        ...state,
        apiTokenInfo: {
          token: '',
          secret: '',
          lastUsed: '',
          createdAt: '',
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.FETCH_API_KEY: {
      const { apiKeys, apiVersion } = action

      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          [apiVersion]: {
            ...state.apiKeys[apiVersion],
            v3Keys: apiKeys,
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING: {
      const { isLoading } = action
      return {
        ...state,
        isLoading,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SHOW_V3_SIGNATURE: {
      const { showSig } = action
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: {
            ...state.callbackSettings.V3,
            showSig,
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_V3_SIGNATURE: {
      const { signature } = action
      return {
        ...state,
        callbackSettings: {
          ...state.callbackSettings,
          V3: {
            ...state.callbackSettings.V3,
            signature,
          },
        },
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_DEVELOPER_TOOL_ENABLED: {
      return {
        ...state,
        isFlipperDevToolsOn: action.enabled,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_API_TOKEN: {
      return {
        ...state,
        apiTokenInfo: action.apiTokenInfo,
      }
    }

    case DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_SANDBOX_ON: {
      return {
        ...state,
        isFlipperSandboxOn: action.enabled,
      }
    }

    default:
      return state
  }
}

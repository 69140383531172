import React from 'react'
import Empty from 'antd/es/empty'
import styled from '@emotion/styled'
import { H3, P1, NEUTRAL_COLORS, Button } from '@xfers/design-system'
import StraitsxEmptyWithPattern from '~/assets/straitsx-empty-with-pattern.svg'

type EmptyDataProps = {
  title?: string
  subtitle?: string
  info?: React.ReactNode
  onButtonClick?: () => void
  buttonLabel?: string
}

const EmptyData = ({
  title = 'No results found',
  subtitle,
  info,
  onButtonClick,
  buttonLabel = 'Make A Transfer',
}: EmptyDataProps) => {
  const centerSvgStyle = {
    marginBottom: 10,
    display: 'block',
    margin: 'auto',
    height: '80px',
    width: '130px',
  }
  return (
    <Container>
      <Empty image={StraitsxEmptyWithPattern} imageStyle={centerSvgStyle} description={false} />
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {info && <Info>{info}</Info>}
      {onButtonClick && (
        <div style={{ textAlign: 'center' }}>
          <Button
            type="primary"
            onClick={() => {
              onButtonClick()
            }}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding-top: 4px;
  display: block;
  align-items: center;
  flex-direction: column;
`

const Title = styled(H3)`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
`

const Subtitle = styled(P1)`
  text-align: center;
  color: ${NEUTRAL_COLORS.S500};
  margin-top: 5px;
  margin-bottom: 20px;
  align-items: center;
`

const Info = styled(P1)`
  text-align: center;
  color: ${NEUTRAL_COLORS.BLACK};
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`

export default EmptyData

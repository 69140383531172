/* eslint-disable max-lines, react/jsx-props-no-spreading */
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import styled from '@emotion/styled'
import { DESKTOP_BREAKPOINT, useTheme, Card, useModal } from '@xfers/design-system'
import straitsXBackground from '~/assets/straitsXBackground.png'
import favicon from '~/assets/straitsXFavicon.png'
import { PATHS } from '~/xidrApp/routes/paths'
import {
  setIsTypeformSurveyVisible,
  setIsTypeformSurveyRequired,
  XIDRInit,
} from '~/xidrApp/store/route/actions'
import {
  setIsXIDRWithdrawalFeeEnabled,
  setIsErc20DepositEnabled,
  setIsErc20WithdrawalEnabled,
  setIsZrc2DepositEnabled,
  setIsZrc2WithdrawalEnabled,
  setIsMaticWithdrawalEnabled,
  setIsMaticDepositEnabled,
  setIsMaticEnabled,
  setIsVirtualAccountEnabled,
  setIsPaymentMethodRetailEnabled,
  setIsAlfamartDepositEnabled,
  setIsIndomaretDepositEnabled,
} from '~/xidrApp/store/xidrLandingPage/actions'
import {
  fetchUserInfo,
  fetchBlockchainAddresses,
  fetchBankList,
} from '~/xidrApp/store/xidrUserInfo/actions'
import RouteHooks from './RouteHooks'
import { XIDRRoutes } from '~/xidrApp/routes/config'
import { PrivateRoute } from '~/components/Route'
import StraitsXFooter from '~/components/StraitsXFooter'
import StraitsXNavigation from '~/components/StraitsXNavigation'
import StraitsXUserInfoBar from '~/components/StraitsXUserInfoBar'
import { RootState } from '~/xidrApp/store/types'
import { Notification } from './store/route/types'
import FeatureGatingManager from '~/managers/FeatureGatingManager'
import { getLatestTransactions, setLatestTransactions } from './store/xidrTransactions/actions'
import MakeFirstFiatDepositReminderModal from './pages/XIDRLandingPage/Mint/MakeFirstFiatDepositReminderModal'
import { useXidrAppFlippers } from '~/store/flippers/hooks'
import { ConfigManager } from '~/managers/ConfigManager'
import { datadogRum } from '@datadog/browser-rum'

export default () => {
  const dispatch = useDispatch()
  const { setModal } = useModal()

  const isSandbox = false
  const { user, isTypeformSurveyVisible, isTypeformSurveyRequired } = useSelector(
    (state: RootState) => state.route
  )
  const fetchedNotifications = useSelector((state: RootState) => state.route.notifications)

  const { isTourRunning } = useSelector((state: RootState) => state.xidrTour)
  const { latestPersonalTransactions } = useSelector((state: RootState) => state.xidrTransactions)

  const {
    isBankAccountsInitiated,
    bankAccounts,
    isUserInfoInitiated,
    userInfo,
    isBlockchainAddressesInitiated,
    blockchainAddresses,
  } = useSelector((state: RootState) => state.xidrUserInfo)

  const [displayGetStartedTab, setDisplayGetStarted] = useState(false)

  useEffect(() => {
    if (!userInfo?.verificationStatus) return

    if (userInfo?.verificationStatus === 'verified') dispatch(getLatestTransactions())
    else dispatch(setLatestTransactions([]))
  }, [userInfo?.verificationStatus])

  useEffect(() => {
    if (!isUserInfoInitiated || !isBlockchainAddressesInitiated || !isBankAccountsInitiated) return

    if (
      (userInfo?.verificationStatus && userInfo?.verificationStatus !== 'verified') ||
      blockchainAddresses.length === 0 ||
      bankAccounts.length === 0
    ) {
      setDisplayGetStarted(true)
    }
  }, [
    isUserInfoInitiated,
    userInfo?.verificationStatus,
    isBlockchainAddressesInitiated,
    blockchainAddresses,
    isBankAccountsInitiated,
    bankAccounts,
  ])

  const threeConsecutiveDigits = /\d{3}/
  const desktopBreakpointWidth = threeConsecutiveDigits.exec(DESKTOP_BREAKPOINT)?.[0] || 0
  // required for tour to work properly; prevents rendering antd Menu component twice
  const shouldRenderDesktopViewNavbar =
    document.documentElement.clientWidth >= desktopBreakpointWidth || !isTourRunning

  useEffect(() => {
    if (latestPersonalTransactions && latestPersonalTransactions.length > 0) {
      const latestTransactionDate = new Date(latestPersonalTransactions[0].created_at)
      const ThresholdDate = new Date()
      ThresholdDate.setDate(ThresholdDate.getDate() - 90)

      dispatch(
        setIsTypeformSurveyRequired(latestTransactionDate.getTime() >= ThresholdDate.getTime())
      )
    }
  }, [latestPersonalTransactions?.length])

  useEffect(() => {
    if (isTypeformSurveyVisible && isTypeformSurveyRequired) {
      const script = document.createElement('script')

      script.src = 'https://embed.typeform.com/next/embed.js'
      script.async = true

      document.body.appendChild(script)
    }
  }, [isTypeformSurveyRequired, isTypeformSurveyVisible])

  useEffect(() => {
    dispatch(XIDRInit())
    dispatch(fetchUserInfo())
    dispatch(fetchBlockchainAddresses())
    dispatch(fetchBankList({ isVASupported: false }))
  }, []) // Run init just once

  const [filteredNotifications, setFilteredNotifications] = useState({
    data: [] as Notification[],
    unreadCount: 0,
  })

  useEffect(() => {
    function faviconUpdate() {
      const faviconEl: HTMLLinkElement | null = document.querySelector("link[rel='shortcut icon']")
      if (faviconEl) {
        faviconEl.href = favicon
      }
    }

    faviconUpdate()

    if (fetchedNotifications.data.length !== 0) {
      let { data: filteredNoti, unreadCount } = fetchedNotifications

      // filter out notifications before Sep 20 2021 (SGT) because this is when StraitsX dashboard was revamped
      const dateToFilter = new Date('2021-09-20T00:00:00.000+08:00')
      filteredNoti = fetchedNotifications.data.filter(
        noti => new Date(noti.created_at) >= dateToFilter
      )
      unreadCount = filteredNoti.filter(noti => noti.is_read === 0).length

      setFilteredNotifications({
        data: filteredNoti,
        unreadCount,
      })
    }
  }, [fetchedNotifications])

  const notifications = useMemo(
    () => ({
      isLoading: fetchedNotifications.isLoading,
      data: filteredNotifications.data,
      unreadCount: filteredNotifications.unreadCount,
    }),
    [fetchedNotifications.isLoading, filteredNotifications]
  )

  const appRoutes = useMemo(() => {
    return XIDRRoutes
  }, [])

  useEffect(() => {
    if (ConfigManager.isProd() && user?.email && user?.kcId) {
      datadogRum.setUser({
        email: user.email,
        kcId: user.kcId,
      })
    }
  }, [user])

  useEffect(() => {
    if (isUserInfoInitiated && user.email) {
      FeatureGatingManager.isEnabled(
        [
          'straitsx_typeform_survey',
          'straitsx_dashboard_banner_management_system',
          'xidr_erc20_deposit',
          'xidr_erc20_withdrawal',
          'xidr_zrc2_deposit',
          'xidr_zrc2_withdrawal',
          'xidr_matic_deposit',
          'xidr_matic_withdrawal',
          'xidr_support_polygon',
          'xidr_withdrawal_fee',
          'xidr_virtual_account_ui',
          'xidr_payment_method_retail',
          'xidr_payment_method_retail_alfamart',
          'xidr_payment_method_retail_indomaret',
        ],
        user.email
      ).then(resp => {
        dispatch(setIsTypeformSurveyVisible(resp.straitsx_typeform_survey))
        dispatch(setIsErc20DepositEnabled(resp.xidr_erc20_deposit))
        dispatch(setIsErc20WithdrawalEnabled(resp.xidr_erc20_withdrawal))
        dispatch(setIsZrc2DepositEnabled(resp.xidr_zrc2_deposit))
        dispatch(setIsZrc2WithdrawalEnabled(resp.xidr_zrc2_withdrawal))
        dispatch(setIsMaticDepositEnabled(resp.xidr_matic_deposit))
        dispatch(setIsMaticWithdrawalEnabled(resp.xidr_matic_withdrawal))
        dispatch(setIsMaticEnabled(resp.xidr_support_polygon))
        dispatch(setIsXIDRWithdrawalFeeEnabled(resp.xidr_withdrawal_fee))
        dispatch(setIsVirtualAccountEnabled(resp.xidr_virtual_account_ui))
        dispatch(setIsPaymentMethodRetailEnabled(resp.xidr_payment_method_retail))
        dispatch(setIsAlfamartDepositEnabled(resp.xidr_payment_method_retail_alfamart))
        dispatch(setIsIndomaretDepositEnabled(resp.xidr_payment_method_retail_indomaret))
      })
    }
  }, [dispatch, isUserInfoInitiated])

  useXidrAppFlippers({ email: user?.email })

  const displayMintTab = userInfo?.verificationStatus === 'verified'

  const navigationCustomCallbacks = useMemo(() => {
    if (!isUserInfoInitiated) return {}

    return {
      ...(!userInfo?.hasSuccessfulFiatDeposit && {
        [PATHS.MINT]: () => setModal(<MakeFirstFiatDepositReminderModal />),
      }),
    }
  }, [isUserInfoInitiated])

  return (
    <Root id="app-root">
      <RouteHooks />
      <Container>
        <NavigationWrapper>
          {shouldRenderDesktopViewNavbar && (
            <StraitsXNavigation
              displayGetStartedTab={displayGetStartedTab}
              displayMintTab={displayMintTab}
              onClickCallbacks={navigationCustomCallbacks}
              isSandbox={isSandbox}
            />
          )}
        </NavigationWrapper>

        <MainContainer>
          {isTypeformSurveyRequired && isTypeformSurveyVisible && (
            <div
              data-tf-popover="YsqtCzHk"
              data-tf-custom-icon="https://images.typeform.com/images/T62HziiCcpD9"
              data-tf-button-color="#00D37E"
              data-tf-button-text="Launch me"
              data-tf-iframe-props="title=StraitsX Relationship NPS Survey | XIDR"
              data-tf-medium="snippet"
              data-tf-hidden={{ utm_source: 'dashboard', id: user.email }}
              style={{ all: 'unset' }}
            ></div>
          )}
          <StraitsXUserInfoBar
            userInfo={user}
            isSandbox={isSandbox}
            notifications={notifications}
            displayGetStartedTab={displayGetStartedTab}
            displayMintTab={displayMintTab}
            onClickCallbacks={navigationCustomCallbacks}
          />

          <Suspense fallback={<LoadingPage />}>
            <Switch>
              {appRoutes.map((route, i) => (
                <PrivateRoute key={i} {...route} />
              ))}
            </Switch>
          </Suspense>

          <StraitsXFooter />
        </MainContainer>
      </Container>
    </Root>
  )
}

function LoadingPage() {
  const { spacing } = useTheme()

  return (
    <Page>
      <Card style={{ padding: spacing.xl }}>
        <Skeleton style={{ width: '40%' }} />
        <Skeleton />
        <Skeleton />
      </Card>
    </Page>
  )
}

const Page = styled.div`
  padding: 16px;

  ${DESKTOP_BREAKPOINT} {
    padding: 24px;
  }
`

const Root = styled.div`
  z-index: 0;
`

const Container = styled.div`
  background-image: url(${straitsXBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 73px;
  width: 100%;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`

const NavigationWrapper = styled.div`
  z-index: 1;
  display: none;

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    width: 257px;
    flex-shrink: 0;
  }
`

const Skeleton = styled.div`
  float: left;
  width: 100%;
  height: 16px;

  margin: 10px;
  border-radius: 5px;
  background-size: 100wh;
  background-image: linear-gradient(90deg, #e8e8e8 0%, #f6f7f9 50%, #e8e8e8 100%);
  animation: shine-lines 1s infinite linear;

  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 140px;
    }
  }
`

import { PATHS } from '~/xidrBiz/routes/paths'
import { WALLET_TYPES, ACCOUNT_TYPES, USER_ROLES } from '~/types'
import { COUNTRY } from '~/managers/ConfigManager'

export type User = {
  kcId: string | undefined
  fullName: string
  email: string
  mobileNumber: string
  countryCode: string
  avatarUrl: string
  displayname: string
  authServerUrl: string
}

export type Account = {
  id: WALLET_TYPES
  balance: string
  type: ACCOUNT_TYPES
  name: 'General Wallet' | 'Digital Goods'
  formattedBalance: string
  settlementBalance?: string
  totalBalance?: string
  formattedAvailableBalance?: string
  formattedSettlementBalance?: string
  formattedTotalBalance?: string
  uniqueId: string
  accountId: number
  fundPoolId: number
}

export type Credential = {
  id: string
  type: string
  createdDate: number
  credentialData: string;
}

export type UserCredentialMetadata = {
  credential: Credential
}

export type Credentials = {
  type: string
  category: string
  displayName: string
  helptext: string
  iconCssClass: string
  updateAction?: string
  createAction?: string
  removeable: boolean
  userCredentialMetadatas: UserCredentialMetadata[]
}

export type WalletIdType =
  | WALLET_TYPES.DIGITAL_GOODS
  | WALLET_TYPES.GENERAL
  | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE
  | undefined

export type Wallet = {
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export type Notification = {
  id: number
  title: string
  message: string
  created_at: string
  is_read: number
  external_link: string
}

export type FeatureMatrix = {
  userRole: USER_ROLES | null
  country: COUNTRY
}

export enum ROUTE_ACTIONS {
  SET_REDIRECT = 'ROUTE_ACTIONS.SET_REDIRECT',
  SET_USER_PROFILE = 'ROUTE_ACTIONS.SET_USER_PROFILE',
  UPDATE_USER_PROFILE = 'ROUTE_ACTIONS.UPDATE_USER_PROFILE',
  SET_TRANSACTION_ACCOUNTS = 'ROUTE_ACTIONS.SET_TRANSACTION_ACCOUNTS',
  SET_NOTIFICATIONS = 'ROUTE_ACTIONS.SET_NOTIFICATIONS',
  TOGGLE_NOTIFICATIONS_LOADING = 'ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING',
  SET_IS_WELCOME_MODAL_OPENED = 'SET_IS_WELCOME_MODAL_OPENED',
  SET_FEATURE_MATRIX = 'ROUTE_ACTIONS.SET_FEATURE_MATRIX',
  SET_IS_USER_HAS_PASSWORD = 'ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD',
}

type SetRedirect = {
  type: typeof ROUTE_ACTIONS.SET_REDIRECT
  to: PATHS | null
}

type SetUserProfile = {
  type: typeof ROUTE_ACTIONS.SET_USER_PROFILE
  user: User
}

type UpdateUserProfile = {
  type: typeof ROUTE_ACTIONS.UPDATE_USER_PROFILE
  avatarUrl: string
}

type SetTransactionAccounts = {
  type: typeof ROUTE_ACTIONS.SET_TRANSACTION_ACCOUNTS
  accounts: Account[]
}

type SetIsWelcomeModalOpened = {
  type: typeof ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED
  enabled: boolean
}

type SetFeatureMatrix = {
  type: typeof ROUTE_ACTIONS.SET_FEATURE_MATRIX
  featureMatrix: FeatureMatrix
}

type SetIsUserHasPassword = {
  type: typeof ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD
  isUserHasPassword: boolean
}

export type ActionTypes =
  | SetRedirect
  | SetUserProfile
  | UpdateUserProfile
  | SetTransactionAccounts
  | SetIsWelcomeModalOpened
  | SetFeatureMatrix
  | SetIsUserHasPassword

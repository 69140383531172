import { createSelector } from 'reselect'
import { selectXIDRDigitalGoodAccount } from '~/xidrBiz/store/account/selectors'
import { Account, Wallet } from './types'
import { WALLET_TYPES, ACCOUNT_TYPES } from '~/types'
import { COUNTRY } from '~/managers/ConfigManager'
import { ModeManager } from '~/managers/ModeManager'
import { RootState } from '~/xidrBiz/store/types'

const selectAccounts = (accounts: Account[]) => accounts

export const checkDigitalGoodsExist = createSelector([selectAccounts], accounts => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const walletIds = accounts.map((acc: any) => acc.id)
  return walletIds.includes(2)
})

const selectFeatureMatrix = (state: RootState) => state.route.featureMatrix
const selectVerificationStatuses = (state: RootState) => ({
  isBizVerified: state.xidrLandingPage.businessVerificationStatus === 'verified',
  isPersVerified: state.xidrLandingPage.personalVerificationStatus === 'verified',
})

const selectSandbox = () => ModeManager.isSandboxMode()

const selectRouteAccounts = (state: RootState): Account[] => state.route.accounts

export const selectFeatureMatrixFactors = createSelector(
  [
    selectFeatureMatrix,
    selectSandbox,
    selectVerificationStatuses,
    selectXIDRDigitalGoodAccount,
    selectRouteAccounts,
  ],
  (featureMatrix, isSandbox, verificationStatuses, dgAccount, accounts) => {
    const { isBizVerified } = verificationStatuses
    const hasBizAccount = accounts.some(account => account.type === ACCOUNT_TYPES.BUSINESS)

    return {
      isSandbox,
      role: featureMatrix?.userRole,
      isId: featureMatrix?.country === COUNTRY.INDONESIA,
      isSg: featureMatrix?.country === COUNTRY.SINGAPORE,
      isBizVerified,
      isPersVerified: verificationStatuses.isPersVerified,
      isPersIdentified: true,
      isAccountManagerVerified: verificationStatuses.isPersVerified,
      isExceed20k: false,
      hasGW: false,
      hasDGW: !!dgAccount,
      hasFundPoolForIdDisbursement: false,
      hasFundPoolForIdWithdraw: false,
      isHighRiskMerchant: false,

      isSandboxEnabled: hasBizAccount,
      isDeveloperToolEnabled: isSandbox || (hasBizAccount && isBizVerified),
    }
  }
)

export const selectUserWallets = createSelector([selectAccounts], accounts => {
  const wallets: Wallet[] = accounts.map((acc: any) => {
    let label: string

    if (acc.id === WALLET_TYPES.GENERAL) {
      label = 'General Wallet'
    } else if (acc.id === WALLET_TYPES.DIGITAL_GOODS) {
      label = 'Digital Goods Wallet'
    } else if (acc.id === WALLET_TYPES.TEMPORARY_HOLDING_BALANCE) {
      label = 'Temporary Holding Balance'
    } else {
      label = 'Account withdraw not supported yet, its probably a bug'
    }

    return {
      label,
      type: acc.id,
      balance: acc.balance,
      formattedBalance: acc.formattedBalance,
      accountId: acc.accountId,
      accountType: acc.type,
      fundPoolId: acc.fundPoolId,
    }
  })

  return wallets
})

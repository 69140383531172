import { ConfigManager, SITE_TYPE } from '~/managers/ConfigManager'

export async function importRoutes() {
  const module = await import(`~/biz/routes/config`)
  return module.default
}

export async function importStraitsXRoutes() {
  switch (ConfigManager.getSiteType()) {
    case SITE_TYPE.APP: {
      const module = await import(`~/app/routes/config`)
      return module.straitsXRoutes
    }
    case SITE_TYPE.XIDR_APP: {
      const module = await import(`~/xidrApp/routes/config`)
      return module.XIDRRoutes
    }

    case SITE_TYPE.BIZ: {
      const module = await import(`~/biz/routes/config`)
      return module.straitsXRoutes
    }

    case SITE_TYPE.XIDR_BIZ: {
      const module = await import(`~/xidrBiz/routes/config`)
      return module.default
    }
  }

  return []
}

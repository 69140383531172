import { Reducer } from 'redux'
import { ActionTypes, AccountDetail, Account } from './types'
import { ACCOUNT_ACTIONS } from './const'
import { CURRENCY } from '~/types'

type State = {
  accountList: Account[]
  isAccountListInitiated: boolean
  accountDetailList: AccountDetail[]
  isAccountDetailListInitiated: boolean
}

export const accountListInitialState: Account[] = [
  {
    currency: CURRENCY.XSGD,
    balance: "0.0",
    formattedBalance: "$0.00",
  },
  {
    currency: CURRENCY.XUSD,
    balance: "0.0",
    formattedBalance: "$0.00",
  },
  {
    currency: CURRENCY.USDC,
    balance: "0.0",
    formattedBalance: "$0.00",
  },
  {
    currency: CURRENCY.USDT,
    balance: "0.0",
    formattedBalance: "$0.00",
  },
]

const initialState: State = {
  accountDetailList: [],
  isAccountDetailListInitiated: false,
  accountList: [...accountListInitialState],
  isAccountListInitiated: false,
}

export const accounts: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_ACTIONS.SET_ACCOUNT_DETAIL_LIST: {
      return {
        ...state,
        accountDetailList: action.accountDetailList,
        isAccountDetailListInitiated: true,
      }
    }

    case ACCOUNT_ACTIONS.SET_ACCOUNT_LIST: {
      return {
        ...state,
        accountList: action.accountList,
        isAccountListInitiated: true,
      }
    }

    default:
      return state
  }
}

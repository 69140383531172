export enum XIDR_USER_INFO_ACTIONS {
  SET_USER_INFO = 'XIDR_USER_INFO_ACTIONS.SET_USER_INFO',
  SET_USER_PROFILE = 'XIDR_USER_INFO_ACTIONS.SET_USER_PROFILE',
  SET_BLOCKCHAIN_ADDRESS_LIST = 'XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESS_LIST',
  SET_BANK_ACCOUNT_LIST = 'XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNT_LIST',
  SET_BANK_LIST = 'XIDR_USER_INFO_ACTIONS.SET_BANK_LIST',
  SET_VIRTUAL_NETWORKS = 'XIDR_USER_INFO_ACTIONS.SET_VIRTUAL_NETWORKS',
  SET_FI_LIST = 'XIDR_USER_INFO_ACTIONS.SET_FI_LIST',
  SET_MAX_ADDRESS_AMOUNT = 'XIDR_USER_INFO_ACTIONS.SET_MAX_ADDRESS_AMOUNT',
  SET_ADDRESS_QUOTA = 'XIDR_USER_INFO_ACTIONS.SET_ADDRESS_QUOTA',
  SET_BLOCKCHAIN_ADDRESS_DELETION = 'XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESS_DELETION',
  SET_BANK_ACCOUNT_DELETION = 'XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNT_DELETION',
  SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS = 'XIDR_USER_INFO_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS',
}

/* eslint-disable no-underscore-dangle */
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

/* Note:
  1. Build Mode: Refers to what webpack build settings are applied, like whether the js source is minified,
  or whether you’re visiting the site via a webpack-dev-server
  2. DeployEnvironmentType: Refers to what kind of aws/hosting environment that this build is going to be deploy on,
  the build mode should always be 'production' when this setting is applied, we’ll need to change some of the asset
  host url on behave of this variable
  3. Site Type: Depends on this site in the user/hosting domain context it’s meant for
  personal use or biz use, you may change it in development to see different set of features
  4. Country: Decides if the site is in ID or SG environment, you may change it in development to see
  different set of features
*/
export enum BUILD_MODE {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export enum DEPLOY_ENVIRONMENT {
  PRODUCTION = 'production',
  STAGING = 'staging',
}

export enum SITE_TYPE {
  APP = 'app',
  BIZ = 'biz',
  REGIONAL = 'regional',
  XIDR_APP = 'xidr-app',
  XIDR_BIZ = 'xidr-biz',
}

export enum COUNTRY {
  SINGAPORE = 'sg',
  INDONESIA = 'id',
}

export enum STABLECOIN_ASSET {
  XSGD = 'XSGD',
  XIDR = 'XIDR',
}

const DEFAULT = {
  DEPLOY_ENVIRONMENT: DEPLOY_ENVIRONMENT.STAGING,
  BUILD_MODE: BUILD_MODE.DEVELOPMENT,
  SITE_TYPE: SITE_TYPE.APP,
  COUNTRY: COUNTRY.SINGAPORE,
}

// Override here for dynamic changes to these values
// which will only require browser refresh to take effect
const OVERRIDE = {
  DEPLOY_ENVIRONMENT: undefined,
  BUILD_MODE: undefined,
  SITE_TYPE: undefined,
  COUNTRY: undefined,
}

class Config {
  private readonly country: COUNTRY

  private readonly buildMode: BUILD_MODE

  private readonly environmentMode: DEPLOY_ENVIRONMENT

  private readonly siteType: SITE_TYPE

  public constructor() {
    const envCountry = process.env.COUNTRY as COUNTRY
    const envSiteType = process.env.SITE_TYPE as SITE_TYPE
    const envBuildMode = process.env.NODE_ENV as BUILD_MODE
    const envDeployEnv = process.env.MODE as DEPLOY_ENVIRONMENT

    this.country = OVERRIDE.COUNTRY || envCountry || DEFAULT.COUNTRY
    this.siteType = OVERRIDE.SITE_TYPE || envSiteType || DEFAULT.SITE_TYPE
    this.buildMode = OVERRIDE.BUILD_MODE || envBuildMode || DEFAULT.BUILD_MODE
    this.environmentMode = OVERRIDE.DEPLOY_ENVIRONMENT || envDeployEnv || DEFAULT.DEPLOY_ENVIRONMENT

    if (!Config.countrySupported(this.country)) {
      throw new Error(`Invalid Country - options are: ${Object.values(COUNTRY).join(', ')}`)
    }
  }

  public getCountry() {
    if (window.Cypress) {
      // for overwriting country in e2e testing
      return (window.__CYPRESS_OVERWRITE_COUNTRY__ as COUNTRY) || this.country
    } else {
      return this.country
    }
  }

  public static countrySupported(country: COUNTRY) {
    return Object.values(COUNTRY).includes(country)
  }

  public getReactEnv() {
    return this.buildMode
  }

  public isDev() {
    return this.getReactEnv() === BUILD_MODE.DEVELOPMENT
  }

  public isProd() {
    return this.getReactEnv() === BUILD_MODE.PRODUCTION
  }

  // eslint-disable-next-line consistent-return
  public setupGA() {
    // XIDR personal dashboard is using GA4, only need to setup GTM
    if (this.isXIDRSite()) {
      return
    }

    switch (this.buildMode) {
      case BUILD_MODE.DEVELOPMENT:
        ReactGA.initialize('UA-40679649-4', {
          debug: false,
          gaOptions: {
            siteSpeedSampleRate: 100,
          },
        })
        break
      case BUILD_MODE.PRODUCTION: {
        let trackingId = ''
        if (this.isAppSite()) {
          trackingId = 'UA-40679649-3'
        } else if (this.isBizSite() && this.getCountry() === COUNTRY.SINGAPORE) {
          trackingId = 'UA-40679649-6'
        } else if (this.isBizSite() && this.getCountry() === COUNTRY.INDONESIA) {
          trackingId = 'UA-40679649-7'
        } else if (this.isRegionalSite()) {
          trackingId = 'UA-40679649-8'
        } else {
          // throw error if it doesn't fit into any of the three cases
          throw new Error('Does not match any existing site type and country in Prod')
        }

        ReactGA.initialize(trackingId)
        break
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public setupGTM() {
    TagManager.initialize({ gtmId: 'GTM-PF27FJ6' })
  }

  public getEnvironmentMode() {
    return this.environmentMode
  }

  public getSiteType() {
    if (window.Cypress) {
      // for overwriting site type in e2e testing
      return (window.__CYPRESS_OVERWRITE_SITE_TYPE__ as SITE_TYPE) || this.siteType
    } else {
      return this.siteType
    }
  }

  public isBizSite() {
    return this.getSiteType() === SITE_TYPE.BIZ || this.getSiteType() === SITE_TYPE.XIDR_BIZ
  }

  public isAppSite() {
    return this.getSiteType() === SITE_TYPE.APP || this.getSiteType() === SITE_TYPE.XIDR_APP
  }

  public isXSGDSite() {
    return (
      this.getSiteType() === SITE_TYPE.APP ||
      (this.getSiteType() === SITE_TYPE.BIZ && this.getCountry() === COUNTRY.SINGAPORE)
    )
  }

  public isXSGDPersonalSite() {
    return this.getSiteType() === SITE_TYPE.APP
  }

  public isXIDRSite() {
    return this.getSiteType() === SITE_TYPE.XIDR_APP || this.getSiteType() === SITE_TYPE.XIDR_BIZ
  }

  public isRegionalSite() {
    return this.getSiteType() === SITE_TYPE.REGIONAL
  }

  public isStraitsX() {
    return this.isAppSite() || (this.isBizSite() && this.getCountry() === COUNTRY.SINGAPORE)
  }

  public isIdBizSite() {
    return this.getCountry() === COUNTRY.INDONESIA && this.isBizSite()
  }

  public getStraitsXAsset() {
    if (!this.isStraitsX()) {
      throw new Error('Not a StraitsX application')
    }

    if (this.isXSGDSite()) {
      return STABLECOIN_ASSET.XSGD
    }
    if (this.isXIDRSite()) {
      return STABLECOIN_ASSET.XIDR
    }

    throw new Error(`Unsupported site type: ${this.getSiteType()}`)
  }

  /** Stop using these isSpecificCountry methods -
   * this is because it makes it harder to add another country.
   * use getCountry and do a switch without a default so that
   * the tsc compiler will have a compiler failure for an enum
   * case unhandled at all the places when a new country is added
   * @see localeHelper
   * */
  // eslint-disable-next-line @typescript-eslint/camelcase
  public DEPRECATED_isIndonesia() {
    return this.getCountry() === COUNTRY.INDONESIA
  }

  /** Stop using these isSpecificCountry methods -
   * this is because it makes it harder to add another country.
   * use getCountry and do a switch without a default so that
   * the tsc compiler will have a compiler failure for an enum
   * case unhandled at all the places when a new country is added
   * @see localeHelper
   * */
  // eslint-disable-next-line @typescript-eslint/camelcase
  public DEPRECATED_isSingapore() {
    return this.getCountry() === COUNTRY.SINGAPORE
  }
}

export const ConfigManager = new Config()

import styled from '@emotion/styled'
import { LAPTOP_BREAKPOINT, Modal, NEUTRAL_COLORS, P1, P1Bold } from '@xfers/design-system'

export const Column = styled.div`
  flex-grow: 1;
  flex-basis: 0px;
  margin-right: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ColumnTitle = styled(P1Bold)`
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 5px;
`

export const ColumnText = styled(P1)`
  font-size: 15px;
  line-height: 22px;
`

export const CustomCard = styled.div`
  border: 1px solid ${NEUTRAL_COLORS.G200};
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 20px;
  }
`

export const CustomModal = styled(Modal)`
  max-width: 950px !important;
`

export const Divider = styled.div`
  margin: 20px 0;
  background: ${NEUTRAL_COLORS.G200};
  height: 1px;
`

export const Row = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0px;
  }

  ${LAPTOP_BREAKPOINT} {
    flex-direction: row;
  }
`

import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { route } from './route/reducer'
import { xidrTour } from './tour/reducer'
import { xidrLandingPage } from './landingPage/reducer'
import { xidrDeposit } from './deposit/reducer'
import { xidrTransactionDetails } from './transactionDetails/reducer'
import { xidrUserVerification } from './userVerification/reducer'
import { xidrUserInfo } from './userInfo/reducer'
import { xidrAccount } from './account/reducer'
import { xidrTransactions } from './transactions/reducer'
import { xidrTeam } from './team/reducer'
import { xidrDeveloperTools } from './developerTools/reducer'
import { xidrSandbox } from './sandbox/reducer'
import { businessVerification } from './businessVerification/reducer'
import { flippers } from '~/store/flippers/reducer'

export const rootReducer = combineReducers({
  route,
  xidrLandingPage,
  xidrDeposit,
  xidrTour,
  xidrTransactionDetails,
  xidrUserVerification,
  xidrUserInfo,
  xidrAccount,
  xidrTransactions,
  xidrTeam,
  xidrDeveloperTools,
  xidrSandbox,
  businessVerification,
  flippers,
})

const middlewares = [thunk]
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))

export default store

/* eslint-disable max-lines */

/**
 * Role Based Access rules:
 * 1. Static permissions are those permissions which don't need any data apart from the user role.
 * 2. Dynamic permissions are permissions which need additional data to determine access.
 *
 * Live Document:
 * (Please make sure the document update accordingly whenever rule changes)
 * https://www.notion.so/fazzfinancialgroup/WIP-PRD-Fazz-Biz-Role-Based-Access-v1-0-eb1a16c7443243a1ab02506c83cf8c9f
 * https://www.notion.so/xfers/AuthZ-in-Multi-tenant-system-9fa7f5583da9485eb4b4d384a12f917f
 *
 * Note:
 * Initially feature matrix was going to be generated by express server and sent to FE but decided to hard code for MVP.
 * Hence handling permission on FE might be deprecated in the future.
 */

import { PERMISSIONS, USER_ROLES, FeatureMatrixSelectorsType } from '~/types'
import { ConfigManager } from '~/managers/ConfigManager'

function check(factors: (boolean | undefined)[]) {
  return factors.every(v => v === true)
}

type RuleType = Partial<
  Record<
    USER_ROLES,
    {
      static: Partial<PERMISSIONS[]>
      dynamic: Partial<Record<PERMISSIONS, (args: FeatureMatrixSelectorsType) => void>>
    }
  >
>

const rules: RuleType = {
  [USER_ROLES.OWNER]: {
    static: [PERMISSIONS.TRANSACTION, PERMISSIONS.VERIFICATION],
    dynamic: {
      [PERMISSIONS.DISBURSEMENTS]: ({ hasGW, isBizVerified }: FeatureMatrixSelectorsType) => {
        return check([hasGW, isBizVerified])
      },
      [PERMISSIONS.ACCOUNT_STATEMENT]: ({ isBizVerified }: FeatureMatrixSelectorsType) => {
        return isBizVerified
      },
      [PERMISSIONS.DISBURSEMENTS_PROCEED_TRANSFER_DETAILS]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.DEPOSIT]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isBizVerified, isAccountManagerVerified])
        }
        return isBizVerified
      },
      [PERMISSIONS.SWAP]: ({ isBizVerified }: FeatureMatrixSelectorsType) => {
        return isBizVerified
      },
      [PERMISSIONS.WITHDRAW]: ({
        isId,
        isBizVerified,
        isAccountManagerVerified,
        hasFundPoolForIdWithdraw,
      }: FeatureMatrixSelectorsType) => {
        if (isId) {
          return check([isBizVerified, hasFundPoolForIdWithdraw])
        } else if (ConfigManager.isRegionalSite()) {
          return check([isBizVerified, isAccountManagerVerified])
        } else if (ConfigManager.isStraitsX()) {
          return check([isBizVerified])
        }
        return false
      },
      [PERMISSIONS.TEAM]: ({ isSandbox }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return !isSandbox
        }
        return true
      },
      [PERMISSIONS.MERCHANT_USERS]: ({ isSandbox, isId }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        return isId
      },
      [PERMISSIONS.MANAGE_ACCOUNT_BLOCKCHAIN_ADDRESS]: ({
        hasDGW,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isStraitsX()) {
          return check([isBizVerified, hasDGW])
        }
        return hasDGW
      },
      [PERMISSIONS.MANAGE_ACCOUNT_BANK]: ({
        isSandbox,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isStraitsX()) {
          return check([!isSandbox, isBizVerified])
        }
        return !isSandbox
      },
      [PERMISSIONS.MANAGE_ACCOUNT_BANK_ADD_ACCOUNT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.SETTINGS]: ({ isSandbox }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return !isSandbox
        }

        return false
      },
      [PERMISSIONS.INVOICING]: ({
        isHighRiskMerchant,
        isSandbox,
        isId,
      }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        if (isHighRiskMerchant) return false
        return ConfigManager.isRegionalSite() || isId
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
        isSandbox,
      }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }

        return false
      },
      [PERMISSIONS.SANDBOX]: ({ isSandboxEnabled }: FeatureMatrixSelectorsType) => isSandboxEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS]: ({ isDeveloperToolEnabled }: FeatureMatrixSelectorsType) =>
        isDeveloperToolEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
    },
  },
  [USER_ROLES.ADMIN]: {
    static: [PERMISSIONS.TRANSACTION],
    dynamic: {
      [PERMISSIONS.DISBURSEMENTS]: ({ hasGW, isBizVerified }: FeatureMatrixSelectorsType) => {
        check([hasGW, isBizVerified])
      },
      [PERMISSIONS.DISBURSEMENTS_PROCEED_TRANSFER_DETAILS]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.DEPOSIT]: ({
        isId,
        hasGW,
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        if (isId) {
          return check([hasGW, isBizVerified])
        } else if (ConfigManager.isStraitsX()) {
          return check([isBizVerified])
        } else if (ConfigManager.isRegionalSite()) {
          return check([hasGW, isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.SWAP]: ({ isBizVerified }: FeatureMatrixSelectorsType) => {
        return isBizVerified
      },
      [PERMISSIONS.WITHDRAW]: ({
        isId,
        isBizVerified,
        isAccountManagerVerified,
        hasFundPoolForIdWithdraw,
      }: FeatureMatrixSelectorsType) => {
        if (isId) {
          return check([isBizVerified, hasFundPoolForIdWithdraw])
        } else if (ConfigManager.isRegionalSite()) {
          return check([isBizVerified, isAccountManagerVerified])
        } else if (ConfigManager.isStraitsX()) {
          return check([isBizVerified])
        }
        return false
      },
      [PERMISSIONS.TEAM]: ({
        isAccountManagerVerified,
        isBizVerified,
        isSandbox,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified, !isSandbox])
        }
        return true
      },
      [PERMISSIONS.MERCHANT_USERS]: ({ isSandbox, isId }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        return isId
      },
      [PERMISSIONS.MANAGE_ACCOUNT_BLOCKCHAIN_ADDRESS]: ({
        hasDGW,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isStraitsX()) {
          return check([isBizVerified, hasDGW])
        }
        return hasDGW
      },
      [PERMISSIONS.MANAGE_ACCOUNT_BANK]: ({
        isSandbox,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isStraitsX()) {
          return check([!isSandbox, isBizVerified])
        }
        return !isSandbox
      },
      [PERMISSIONS.MANAGE_ACCOUNT_BANK_ADD_ACCOUNT]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.SETTINGS]: ({ isSandbox }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return !isSandbox
        }

        return false
      },
      [PERMISSIONS.INVOICING]: ({
        isHighRiskMerchant,
        isSandbox,
        isId,
      }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        if (isHighRiskMerchant) return false
        return ConfigManager.isRegionalSite() || isId
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
        isSandbox,
      }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }

        return false
      },
      [PERMISSIONS.SANDBOX]: ({ isSandboxEnabled }: FeatureMatrixSelectorsType) => isSandboxEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS]: ({ isDeveloperToolEnabled }: FeatureMatrixSelectorsType) =>
        isDeveloperToolEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
    },
  },
  [USER_ROLES.OPERATIONS]: {
    static: [PERMISSIONS.TRANSACTION],
    dynamic: {
      [PERMISSIONS.ACCOUNT_STATEMENT]: ({ isBizVerified }: FeatureMatrixSelectorsType) => {
        return isBizVerified
      },
      [PERMISSIONS.DEPOSIT]: ({
        isId,
        isAccountManagerVerified,
        isBizVerified,
        hasGW,
        hasDGW,
      }: FeatureMatrixSelectorsType) => {
        if (isId) {
          return check([hasGW, isBizVerified])
        } else if (ConfigManager.isStraitsX()) {
          return check([hasDGW, isBizVerified])
        } else if (ConfigManager.isRegionalSite()) {
          return check([hasGW, isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.WITHDRAW]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        } else if (ConfigManager.isStraitsX()) {
          return check([isBizVerified])
        }
        return false
      },
      [PERMISSIONS.SWAP]: ({ isBizVerified }: FeatureMatrixSelectorsType) => {
        return isBizVerified
      },
      [PERMISSIONS.DISBURSEMENTS]: ({ isSandbox }: FeatureMatrixSelectorsType) => {
        return check([ConfigManager.isRegionalSite(), !isSandbox])
      },
      [PERMISSIONS.DISBURSEMENTS_PROCEED_TRANSFER_DETAILS]: ({
        isBizVerified,
        isAccountManagerVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
      [PERMISSIONS.INVOICING]: ({
        isHighRiskMerchant,
        isSandbox,
        isId,
      }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        if (isHighRiskMerchant) return false
        return ConfigManager.isRegionalSite() || isId
      },
      [PERMISSIONS.INVOICING_CREATE_INVOICE]: ({
        isAccountManagerVerified,
        isBizVerified,
        isSandbox,
      }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }

        return false
      },
      [PERMISSIONS.SANDBOX]: ({ isSandboxEnabled }: FeatureMatrixSelectorsType) => isSandboxEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS]: ({ isSandbox }: FeatureMatrixSelectorsType) => isSandbox,
    },
  },
  [USER_ROLES.DEVELOPER]: {
    static: [PERMISSIONS.TRANSACTION],
    dynamic: {
      [PERMISSIONS.SANDBOX]: ({ isSandboxEnabled }: FeatureMatrixSelectorsType) => isSandboxEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS]: ({ isDeveloperToolEnabled }: FeatureMatrixSelectorsType) =>
        isDeveloperToolEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS_GENERATE_SECRET_KEY]: ({
        isAccountManagerVerified,
        isBizVerified,
      }: FeatureMatrixSelectorsType) => {
        if (ConfigManager.isRegionalSite()) {
          return check([isAccountManagerVerified, isBizVerified])
        }
        return false
      },
    },
  },
  [USER_ROLES.VIEWER]: {
    static: [PERMISSIONS.TRANSACTION],
    dynamic: {
      [PERMISSIONS.MERCHANT_USERS]: ({ isSandbox, isId }: FeatureMatrixSelectorsType) => {
        if (isSandbox) return false
        return isId
      },
      [PERMISSIONS.SANDBOX]: ({ isSandboxEnabled }: FeatureMatrixSelectorsType) => isSandboxEnabled,
      [PERMISSIONS.DEVELOPER_TOOLS]: ({ isSandbox }: FeatureMatrixSelectorsType) => isSandbox,
    },
  },
}

export default rules

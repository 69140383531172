import { Reducer } from 'redux'
import {
  XIDRUserInfo,
  ActionTypes,
  BlockchainAddress,
  BankAccount,
  Bank,
  FinancialInstitution,
  UserProfile,
  VirtualNetwork,
} from './types'
import { XIDR_USER_INFO_ACTIONS } from './const'

type State = {
  isUserInfoInitiated: boolean
  isBlockchainAddressListInitiated: boolean
  isBankAccountListInitiated: boolean
  isBankListInitiated: boolean
  isUserProfileInitiated: boolean

  userInfo?: XIDRUserInfo
  blockchainAddressList: BlockchainAddress[]
  bankAccountList: BankAccount[]
  bankList: Bank[]
  virtualNetworks: VirtualNetwork[]
  fiList: FinancialInstitution[]
  maxAddressAmount: number
  addressQuota: number
  userProfile?: UserProfile
  blockchainAddressDeletion: boolean
  bankAccountDeletion: boolean
}

const initialState: State = {
  isUserInfoInitiated: false,
  isBlockchainAddressListInitiated: false,
  isBankAccountListInitiated: false,
  isBankListInitiated: false,
  isUserProfileInitiated: false,

  blockchainAddressList: [],
  bankAccountList: [],
  bankList: [],
  virtualNetworks: [],
  fiList: [],
  maxAddressAmount: 0,
  addressQuota: -1,
  blockchainAddressDeletion: false,
  bankAccountDeletion: false,
}

export const xidrUserInfo: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_USER_INFO_ACTIONS.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
        isUserInfoInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESS_LIST: {
      return {
        ...state,
        blockchainAddressList: action.blockchainAddressList,
        isBlockchainAddressListInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNT_LIST: {
      return {
        ...state,
        bankAccountList: action.bankAccountList,
        isBankAccountListInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BANK_LIST: {
      return {
        ...state,
        bankIconList: action.bankList,
        bankList: action.bankList,
        isBankListInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_VIRTUAL_NETWORKS: {
      return {
        ...state,
        virtualNetworks: action.virtualNetworks,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_FI_LIST: {
      return {
        ...state,
        fiList: action.fiList,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_MAX_ADDRESS_AMOUNT: {
      return {
        ...state,
        maxAddressAmount: action.maxAddressAmount,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_ADDRESS_QUOTA: {
      return {
        ...state,
        addressQuota: action.addressQuota,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.userProfile,
        isUserProfileInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESS_DELETION: {
      return {
        ...state,
        blockchainAddressDeletion: action.blockchainAddressDeletion,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNT_DELETION: {
      return {
        ...state,
        bankAccountDeletion: action.bankAccountDeletion,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS: {
      let addresses = state.blockchainAddressList
      if (!addresses) {
        addresses = []
      }
      addresses.push(action.blockchainAddress)

      return {
        ...state,
        blockchainAddressList: [...addresses],
      }
    }

    default:
      return state
  }
}

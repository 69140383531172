export enum TOUR_ACTIONS {
  SET_CURRENT_STEP_INDEX = 'TOUR_ACTIONS.SET_CURRENT_STEP_INDEX',
  SET_IS_TOUR_RUNNING = 'TOUR_ACTIONS.SET_IS_TOUR_RUNNING',
}

type SetCurrentStepIndex = {
  type: typeof TOUR_ACTIONS.SET_CURRENT_STEP_INDEX
  currentStepIndex: number
}

type SetIsTourRunning = {
  type: typeof TOUR_ACTIONS.SET_IS_TOUR_RUNNING
  isTourRunning: boolean
}

export type ActionTypes = SetCurrentStepIndex | SetIsTourRunning

import { createSelector } from 'reselect'
import { RootState as AppRootState } from '~/app/store/types'
import { RootState as XidrAppRootState } from '~/xidrApp/store/types'
import { RootState as BizRootState } from '~/biz/store/types'
import { RootState as XidrBizRootState } from '~/xidrBiz/store/types'
import { Flipper } from './types'

export const selectFlippers = (
  state: AppRootState | BizRootState | XidrAppRootState | XidrBizRootState
) => state.flippers.flippers

export const selectFlipper = (featureKey: string) =>
  createSelector([selectFlippers], (flippers: Flipper[]) =>
    flippers.find(i => i.featureKey === featureKey && !i.flipperId)
  )

export const selectFlipperWithId = (featureKey: string, flipperId: string) =>
  createSelector([selectFlippers], (flippers: Flipper[]) =>
    flippers.find(i => i.featureKey === featureKey && i.flipperId === flipperId)
  )

/* eslint max-lines: off */
import { Reducer } from 'redux'
import {
  ActionTypes,
  XIDR_LANDING_PAGE_ACTIONS,
  PersonalAccount,
  TemporaryHoldingBalance,
  DepositRequest,
  PersonalVerificationStatus,
  DashboardBanner,
  TransferMethod,
  BankTransferNetwork,
  Blockchain,
  BlockchainAddress,
  StablecoinFee,
  WithdrawWalletIdType,
  RetailStore,
} from './types'
import { Moment } from '~/types'
import { BankAccount } from '~/xidrApp/store/xidrUserInfo/types'

const initialState: State = {
  isUserInfoInitiated: false,
  isPersonalAccountInfoInitiated: false,
  isDashboardBannersInitiated: false,
  isBankIconsInitiated: false,

  // personal account related fields
  personalAccount: null,
  temporaryHoldingBalance: null,
  latestPersonalDepositRequests: null,
  limitationIncreaseAmount: null,
  limitationIncreaseDate: null,

  // misc
  personalVerificationStatus: null,

  isErc20DepositEnabled: true,
  isZrc2DepositEnabled: true,
  isErc20WithdrawalEnabled: true,
  isZrc2WithdrawalEnabled: true,

  isMaticEnabled: false,
  isMaticWithdrawalEnabled: false,
  isMaticDepositEnabled: false,

  isIndomaretDepositEnabled: false,
  isAlfamartDepositEnabled: false,

  isDashboardBannerManagementEnabled: false,
  isPaymentMethodQrisEnabled: false,
  isVirtualAccountEnabled: null,
  isPaymentMethodRetailEnabled: false,
  dashboardBanners: [],

  tab: 'deposit',
  transferMethod: null,
  selectedRetailStore: null,
  // change initial bankTransferNetwork to null once international transfer is implemented
  bankTransferNetwork: null,
  selectedBlockchain: null,
  selectedBankAccount: null,
  selectedBlockchainAddress: null,

  withdrawAmount: '',
  withdrawAmountError: '',
  withdrawRequestError: '',
  withdrawWalletId: undefined,

  retailDepositAmount: '',
  retailDepositAmountError: '',

  // TODO get max amount from BE
  maxBankTransferAmount: 500 * 1000 * 1000,
  maxQrisTransferAmount: 5 * 1000 * 1000,
  minAlfaMartTransferAmount: 15 * 1000,
  maxAlfaMartTransferAmount: 25 * 100 * 1000,
  minIndomaretTransferAmount: 10 * 1000,
  maxIndomaretTransferAmount: 5 * 1000 * 1000,

  isStablecoinFeeLoading: false,
  stablecoinFee: {
    amount: undefined,
    identifier: undefined,
    timestamp: undefined,
  },
  hasFetchingFeeError: false,
  isConfirmOpen: false,
  is2FaOpen: false,
  isTemporaryHoldingBalanceOpen: false,

  visitedGettingStarted: false,
  isXIDRWithdrawalFeeEnabled: false,
  shouldResetTransferMethodAfterNavigated: true,
  isRenewAddressAcknowledged: false,
}

type State = {
  isUserInfoInitiated: boolean
  isPersonalAccountInfoInitiated: boolean
  isDashboardBannersInitiated: boolean
  isBankIconsInitiated: boolean

  personalAccount: PersonalAccount | null
  temporaryHoldingBalance: TemporaryHoldingBalance | null

  latestPersonalDepositRequests: DepositRequest[] | null
  limitationIncreaseAmount: number | null
  limitationIncreaseDate: Moment | null

  personalVerificationStatus: PersonalVerificationStatus | null

  isErc20DepositEnabled: boolean
  isZrc2DepositEnabled: boolean
  isErc20WithdrawalEnabled: boolean
  isZrc2WithdrawalEnabled: boolean

  isIndomaretDepositEnabled: boolean
  isAlfamartDepositEnabled: boolean

  isMaticWithdrawalEnabled: boolean
  isMaticDepositEnabled: boolean
  isMaticEnabled: boolean

  isDashboardBannerManagementEnabled: boolean
  isPaymentMethodQrisEnabled: boolean
  isVirtualAccountEnabled: boolean | null
  isPaymentMethodRetailEnabled: boolean
  dashboardBanners: DashboardBanner[]

  tab: string
  transferMethod: TransferMethod | null
  selectedRetailStore: RetailStore | null
  bankTransferNetwork: BankTransferNetwork
  selectedBlockchain: Blockchain | null
  selectedBankAccount: BankAccount | null
  selectedBlockchainAddress: BlockchainAddress | null

  withdrawAmount: string
  withdrawAmountError: string
  withdrawRequestError: string
  withdrawWalletId: WithdrawWalletIdType

  retailDepositAmount: string
  retailDepositAmountError: string

  maxBankTransferAmount: number
  maxQrisTransferAmount: number
  minAlfaMartTransferAmount: number
  maxAlfaMartTransferAmount: number
  minIndomaretTransferAmount: number
  maxIndomaretTransferAmount: number

  isStablecoinFeeLoading: boolean
  stablecoinFee: StablecoinFee
  hasFetchingFeeError: boolean
  isConfirmOpen: boolean
  is2FaOpen: boolean
  isTemporaryHoldingBalanceOpen: boolean

  visitedGettingStarted: boolean
  isXIDRWithdrawalFeeEnabled: boolean
  shouldResetTransferMethodAfterNavigated: boolean
  isRenewAddressAcknowledged: boolean
}

export const xidrLandingPage: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_LANDING_PAGE_ACTIONS.SET_PERSONAL_ACCOUNT: {
      return {
        ...state,
        personalAccount: action.personalAccount,
        isPersonalAccountInfoInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_DEPOSIT_ENABLED: {
      return {
        ...state,
        isZrc2DepositEnabled: action.isZrc2DepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_DEPOSIT_ENABLED: {
      return {
        ...state,
        isErc20DepositEnabled: action.isErc20DepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_DEPOSIT_ENABLED: {
      return {
        ...state,
        isMaticDepositEnabled: action.isMaticDepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isMaticWithdrawalEnabled: action.isMaticWithdrawalEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_MATIC_ENABLED: {
      return {
        ...state,
        isMaticEnabled: action.isMaticEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ZRC2_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isZrc2WithdrawalEnabled: action.isZrc2WithdrawalEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ERC20_WITHDRAWAL_ENABLED: {
      return {
        ...state,
        isErc20WithdrawalEnabled: action.isErc20WithdrawalEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_INDOMARET_DEPOSIT_ENABLED: {
      return {
        ...state,
        isIndomaretDepositEnabled: action.isIndomaretDepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_ALFAMART_DEPOSIT_ENABLED: {
      return {
        ...state,
        isAlfamartDepositEnabled: action.isAlfamartDepositEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_QRIS_ENABLED: {
      return {
        ...state,
        isPaymentMethodQrisEnabled: action.isPaymentMethodQrisEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_VIRTUAL_ACCOUNT_ENABLED: {
      return {
        ...state,
        isVirtualAccountEnabled: action.isVirtualAccountEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_PAYMENT_METHOD_RETAIL_ENABLED: {
      return {
        ...state,
        isPaymentMethodRetailEnabled: action.isPaymentMethodRetailEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE: {
      return {
        ...state,
        temporaryHoldingBalance: action.temporaryHoldingBalance,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_LATEST_PERSONAL_DEPOSIT_REQUESTS: {
      return {
        ...state,
        latestPersonalDepositRequests: action.latestPersonalDepositRequests,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_PERSONAL_VERIFICATION_STATUS: {
      return {
        ...state,
        personalVerificationStatus: action.personalVerificationStatus,
        has2FaEnabled: action.has2FaEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_DASHBOARD_BANNER_MANAGEMENT_ENABLED: {
      return {
        ...state,
        isDashboardBannerManagementEnabled: action.isDashboardBannerManagementEnabled,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_DASHBOARD_BANNERS: {
      return {
        ...state,
        dashboardBanners: action.dashboardBanners,
        isDashboardBannersInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.bankAccounts,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.GET_BANK_ICONS: {
      return {
        ...state,
        bankIcons: action.bankIcons,
        isBankIconsInitiated: true,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TAB: {
      return {
        ...state,
        tab: action.tab,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TRANSFER_METHOD: {
      return {
        ...state,
        transferMethod: action.transferMethod,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_STORE: {
      return {
        ...state,
        selectedRetailStore: action.selectedRetailStore,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_BANK_TRANSFER_NETWORK: {
      return {
        ...state,
        bankTransferNetwork: action.bankTransferNetwork,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN: {
      return {
        ...state,
        selectedBlockchain: action.selectedBlockchain,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BANK_ACCOUNT: {
      return {
        ...state,
        selectedBankAccount: action.selectedBankAccount,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SELECTED_BLOCKCHAIN_ADDRESS: {
      return {
        ...state,
        selectedBlockchainAddress: action.selectedBlockchainAddress,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_AMOUNT: {
      return {
        ...state,
        withdrawAmount: action.amount,
        withdrawAmountError: action.withdrawAmountError,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_RETAIL_DEPOSIT_AMOUNT: {
      return {
        ...state,
        retailDepositAmount: action.amount,
        retailDepositAmountError: action.retailDepositAmountError,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_WITHDRAW_WALLET_ID: {
      return {
        ...state,
        withdrawWalletId: action.withdrawWalletId,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_STABLECOIN_FEE_LOADING: {
      return {
        ...state,
        isStablecoinFeeLoading: action.isStablecoinFeeLoading,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_STABLECOIN_FEE: {
      return {
        ...state,
        isStablecoinFeeLoading: false,
        stablecoinFee: {
          amount: action.amount,
          identifier: action.identifier,
          timestamp: action.timestamp,
        },
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_CONFIRM_MODAL_OPEN: {
      return {
        ...state,
        isConfirmOpen: action.open,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_2_FA_MODAL_OPEN: {
      return {
        ...state,
        is2FaOpen: action.open,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.CREATE_WITHDRAWAL_REQUEST: {
      return {
        ...state,
        withdrawRequestError: action.error,
        isLoading: false,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_TEMP_HOLDING_BALANCE_MODAL_OPEN: {
      return {
        ...state,
        isTemporaryHoldingBalanceOpen: action.open,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_VISITED_GETTING_STARTED: {
      return {
        ...state,
        visitedGettingStarted: action.value,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_XIDR_WITHDRAWAL_FEE_ENABLED: {
      return {
        ...state,
        isXIDRWithdrawalFeeEnabled: action.value,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_HAS_FETCHING_FEE_ERROR: {
      return {
        ...state,
        hasFetchingFeeError: action.hasError,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_SHOULD_RESET_TRANSFER_METHOD_AFTER_NAVIGATED: {
      return {
        ...state,
        shouldResetTransferMethodAfterNavigated: action.shouldResetTransferMethodAfterNavigated,
      }
    }

    case XIDR_LANDING_PAGE_ACTIONS.SET_IS_RENEW_ADDRESS_ACKNOWLEDGED: {
      return {
        ...state,
        isRenewAddressAcknowledged: action.isRenewAddressAcknowledged,
      }
    }

    default:
      return state
  }
}

import React, { useState, useEffect, useContext, useRef } from 'react'
import styled from '@emotion/styled'
import { BankFilled } from '@xfers/design-system/es/icons'
import { useModal, useTheme, BadgeIcon, Input, Select, Button, Modal } from '@xfers/design-system'
import { createGaEvent } from '~/helpers/gaHelper'
import { get, ENDPOINTS } from '~/api'
import { AddBankFlowContext } from '~/components/AddBankFlow'
import { setSelectedBank, setAccountNumber } from '~/components/AddBankFlow/actions'
import { FixMeLater } from '~/types'
import { getLogoComponent } from '~/helpers/logo'

type BankOptions = {
  name: string
  abbreviation: string
  imgSrc: string
}

type Props = {
  goNext: () => void
}

export default function({ goNext }: Props) {
  const [bankOptions, setBankOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const { resetModal } = useModal()
  const { brand } = useTheme()
  const { state, dispatch } = useContext(AddBankFlowContext)
  const { accountNumber, selectedBank } = state

  useEffect(() => {
    get(ENDPOINTS.API_V3_BANKS).then(resp => {
      setBankOptions(
        resp.map((bank: { abbreviation: string; name: string }) => ({
          abbreviation: bank.abbreviation,
          name: bank.name,
        }))
      )
      setLoading(false)
    })
  }, [])

  const selectRef = useRef<{ blur: () => void }>(null)

  return (
    <Modal
      onClose={() => resetModal()}
      title="Add Bank Account"
      icon={<BadgeIcon type="primary" icon={<BankFilled />} />}
      footer={
        <Button
          data-cy="button-addAccountNext"
          type="primary"
          disabled={!accountNumber || !selectedBank}
          onClick={() => {
            createGaEvent('My Account', 'selectBankAndAccount', 'Select Bank and Bank Account')
            goNext()
          }}
        >
          Next
        </Button>
      }
    >
      <Alert>
        You can only deposit or withdraw funds to an account that is in <b>your own legal name.</b>
      </Alert>
      <SelectTitle>Bank Name</SelectTitle>
      <Select
        data-cy="select-bank"
        showSearch
        optionFilterProp="key"
        loading={loading}
        disabled={loading}
        filterOption={(input: FixMeLater, option: FixMeLater) => {
          if (input && option) {
            const key = option.key ? (option.key as string).toLowerCase() : ''
            const inputString = input.toLowerCase()
            return key.includes(inputString)
          } else {
            return false
          }
        }}
        type="dropdown"
        placeholder="Select your bank"
        listHeight={124}
        style={{
          marginBottom: '20px',
        }}
        value={selectedBank || undefined}
        onChange={(value: string) => {
          if (selectRef.current?.blur) {
            selectRef.current.blur()
          }
          return dispatch(setSelectedBank(value))
        }}
        selectRef={selectRef}
      >
        {bankOptions.map((bank: BankOptions) => {
          const bankName = bank.name || ''
          const bankAbbreviation = bank.abbreviation || ''
          const key = `${bankName} ${bankAbbreviation}`
          return (
            <Select.Option data-cy={bankAbbreviation} key={key} value={bankAbbreviation}>
              <SelectedOption>
                <IconContainer>
                  {getLogoComponent(
                    bankAbbreviation,
                    <BankFilled
                      width={18}
                      height={18}
                      color={brand.primary.default}
                      style={{
                        marginRight: '5px',
                      }}
                    />
                  )}
                </IconContainer>
                <b>
                  {bankName} ({bankAbbreviation})
                </b>
              </SelectedOption>
            </Select.Option>
          )
        })}
      </Select>
      <SelectTitle>Account Number</SelectTitle>
      <Input
        data-cy="input-accountNumber"
        type="text"
        inputMode="numeric"
        placeholder="Enter account number"
        style={{
          marginBottom: '20px',
        }}
        value={accountNumber}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          dispatch(setAccountNumber(e.target.value))
        }
      />
    </Modal>
  )
}

const IconContainer = styled.div`
  position: relative;
  height: 23px;

  & > svg {
    width: 40px;
    height: 100%;
    margin-right: 10px;
  }
`

const Alert = styled.div`
  background: #fffbe9;
  border-radius: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8d7700;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px;

  b {
    font-weight: 600;
  }
`

const SelectTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
  margin-bottom: 5px;
`

export const SelectedOption = styled.div`
  display: flex;
  align-items: center;

  b {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #1b2736;
    margin-right: 5px;
  }
`

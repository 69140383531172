import React, { useState, useContext } from 'react'
import styled from '@emotion/styled'
import { CrossMini } from '@xfers/design-system/es/icons'
import { useModal, BadgeIcon, Button, Modal } from '@xfers/design-system'
import { AddBankFlowContext } from '~/components/AddUSDCBankFlow'
import { addBankAccountHandler } from '~/components/AddUSDCBankFlow/actions'
import { BankAccount } from '~/components/AddUSDCBankFlow/types'

type Props = {
  goNext: (success: boolean, bankAccount?: BankAccount) => void
}

export default function({ goNext }: Props) {
  const { resetModal } = useModal()
  const [loading, setLoading] = useState(false)
  const { state } = useContext(AddBankFlowContext)
  const { bankAccount, billingAddress, accountHolderName } = state

  return (
    <Modal
      onClose={() => resetModal()}
      title="Error"
      icon={<BadgeIcon type="error" icon={<CrossMini />} />}
      footer={
        <>
          <Button type="secondary" onClick={() => resetModal()}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={loading}
            style={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setLoading(true)
              addBankAccountHandler({
                bankAccount,
                billingAddress,
                accountHolderName,
                goNext,
                callback: () => setLoading(false),
              })
            }}
          >
            Try Again
          </Button>
        </>
      }
    >
      <Text>
        Failed to add your bank account number <b>{bankAccount.accountNumber}</b> as something went
        wrong. Please try again.
      </Text>
      <InfoRow>
        <InfoLeft>Currency</InfoLeft>
        <InfoRight>USD</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Bank Name</InfoLeft>
        <InfoRight>{bankAccount.bankName}</InfoRight>
      </InfoRow>
      <InfoRow>
        <InfoLeft>Account Number</InfoLeft>
        <InfoRight>{bankAccount.accountNumber}</InfoRight>
      </InfoRow>
    </Modal>
  )
}

// TODO reuse style with other currency add bank flow
const Text = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #1b2736;
  margin-bottom: 20px;
`

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const InfoLeft = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1b2736;
`

const InfoRight = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #1b2736;
`

export const BankIcon = styled.img`
  margin-right: 5px;
`

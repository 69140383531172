import { Dispatch, GetState } from '~/app/store/types'
import { ActionTypes, TOUR_ACTIONS } from './types'

export const incrementStepIndex = () => (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  const { currentStepIndex } = getState().tour
  dispatch({
    type: TOUR_ACTIONS.SET_CURRENT_STEP_INDEX,
    currentStepIndex: currentStepIndex + 1,
  })
}

export const endTour = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: TOUR_ACTIONS.SET_CURRENT_STEP_INDEX,
    currentStepIndex: 0,
  })

  dispatch({
    type: TOUR_ACTIONS.SET_IS_TOUR_RUNNING,
    isTourRunning: false,
  })
}

export const beginTour = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: TOUR_ACTIONS.SET_IS_TOUR_RUNNING,
    isTourRunning: true,
  })
}

export type Wallet = {
  name: string
  type: string
  accountId: string
}

export type OptionType = {
  label: string
  value: string
}

export enum INTERNAL_TOOLS_ACTIONS {
  SET_IS_LOADING = 'INTERNAL_TOOLS_ACTIONS.SET_IS_LOADING',
  GET_WALLETS = 'INTERNAL_TOOLS_ACTIONS.GET_WALLETS',
  GET_CONTRACT_TYPES = 'INTERNAL_TOOLS_ACTIONS.GET_CONTRACT_TYPES',
  GET_CONTRACT_STATES = 'INTERNAL_TOOLS_ACTIONS.GET_CONTRACT_STATES',
  SET_SELECTED_ACCOUNT_ID = 'INTERNAL_TOOLS_ACTIONS.SET_SELECTED_ACCOUNT_ID',
  SET_SELECTED_CONTRACT_TYPE = 'INTERNAL_TOOLS_ACTIONS.SET_SELECTED_CONTRACT_TYPE',
  SET_SELECTED_CONTRACT_STATE = 'INTERNAL_TOOLS_ACTIONS.SET_SELECTED_CONTRACT_STATE',
  SET_SELECTED_ROLE = 'INTERNAL_TOOLS_ACTIONS.SET_SELECTED_ROLE',
  CREATE_CONTRACT = 'INTERNAL_TOOLS_ACTIONS.CREATE_CONTRACT',
}

type SetIsLoading = {
  type: typeof INTERNAL_TOOLS_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type GetWallets = {
  type: typeof INTERNAL_TOOLS_ACTIONS.GET_WALLETS
  wallets: Wallet[]
}

type GetContractTypes = {
  type: typeof INTERNAL_TOOLS_ACTIONS.GET_CONTRACT_TYPES
  contractTypes: OptionType[]
}

type GetContractStates = {
  type: typeof INTERNAL_TOOLS_ACTIONS.GET_CONTRACT_STATES
  contractStates: OptionType[]
}

type SetSelectedAccountId = {
  type: typeof INTERNAL_TOOLS_ACTIONS.SET_SELECTED_ACCOUNT_ID
  selectedAccountId: number
}

type SetSelectedContractType = {
  type: typeof INTERNAL_TOOLS_ACTIONS.SET_SELECTED_CONTRACT_TYPE
  selectedContractType: string
}

type SetSelectedContractState = {
  type: typeof INTERNAL_TOOLS_ACTIONS.SET_SELECTED_CONTRACT_STATE
  selectedContractState: string
}

type SetSelectedRole = {
  type: typeof INTERNAL_TOOLS_ACTIONS.SET_SELECTED_ROLE
  selectedRole: string
}

type CreateContract = {
  type: typeof INTERNAL_TOOLS_ACTIONS.CREATE_CONTRACT
  error: string
}

export type ActionTypes =
  | SetIsLoading
  | GetWallets
  | GetContractTypes
  | GetContractStates
  | SetSelectedAccountId
  | SetSelectedContractType
  | SetSelectedContractState
  | SetSelectedRole
  | CreateContract

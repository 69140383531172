export enum XIDR_TOUR_ACTIONS {
  SET_CURRENT_STEP_INDEX = 'XIDR_TOUR_ACTIONS.SET_CURRENT_STEP_INDEX',
  SET_IS_TOUR_RUNNING = 'XIDR_TOUR_ACTIONS.SET_IS_TOUR_RUNNING',
  SET_CURRENT_TARGET = 'XIDR_TOUR_ACTIONS.SET_CURRENT_TARGET',
}

type SetCurrentStepIndex = {
  type: typeof XIDR_TOUR_ACTIONS.SET_CURRENT_STEP_INDEX
  currentStepIndex: number
}

type SetIsTourRunning = {
  type: typeof XIDR_TOUR_ACTIONS.SET_IS_TOUR_RUNNING
  isTourRunning: boolean
}

type SetCurrentTarget = {
  type: typeof XIDR_TOUR_ACTIONS.SET_CURRENT_TARGET
  currentTarget: string
}

export type ActionTypes = SetCurrentStepIndex | SetIsTourRunning | SetCurrentTarget

import React from 'react'
import { useSelector } from 'react-redux'
import { css } from 'emotion'
import styled from '@emotion/styled'
import {
  useModal,
  Modal,
  Button,
  P1,
  BadgeIcon,
  straitsXTheme,
  H2,
  DESKTOP_BREAKPOINT,
  Link,
} from '@xfers/design-system'
import { Document, InfoFilled } from '@xfers/design-system/es/icons'
import {   
  Column,
  ColumnTitle,
  ColumnText,
  CustomCard,
  Divider,
  Row, 
} from '~/styledComponent/styled'
import { RootState } from '~/app/store/types'

export default function() {
  const { resetModal } = useModal()
  const { userInfo } = useSelector((state: RootState) => state.userInfo)

  const {
    fullName,
    dateOfBirth,
    nationality,
    gender,
    addressLine1,
    addressLine2,
    country,
    postalCode,
    identityNo,
    nricExpiryDate,
    occupation,
    employmentSector,
    employer,
  } = userInfo

  let addressString = ''
  if (addressLine1 !== undefined && addressLine1 !== '') addressString += addressLine1 + ' '
  if (addressLine2 !== undefined && addressLine2 !== '') addressString += addressLine2 + ' '
  if (country !== undefined && country !== '') addressString += country + ' '
  if (postalCode !== undefined && postalCode !== '') addressString += postalCode

  const styledModal = css`
    max-width: 90vw !important;
    top: 3vh!important;

    ${DESKTOP_BREAKPOINT} {
      max-width: 60vw !important;
    } 
  `

  const confirmHandler = () => {
    resetModal()
  }

  return (
    <Modal
      className={styledModal}
      icon={<BadgeIcon type="success" icon={<Document />} />}
      title="My KYC Details"
      onClose={resetModal}
      footer={
        <>
          <ConfirmButton type="secondary" onClick={confirmHandler}>
            Done
          </ConfirmButton>
        </>
      }
    >
      <ModalContent>
        <CustomCard>
          <H2>Personal Details</H2>
          <Divider />
          <Row>
            <Column>
              <ColumnTitle>NRIC / ID Number</ColumnTitle>
              <ColumnText>{identityNo || '-'}</ColumnText>
            </Column>
            <Column>
              <ColumnTitle>ID Expiry Date</ColumnTitle>
              <ColumnText>{nricExpiryDate || '-'}</ColumnText>
            </Column>
          </Row>
          <Row>
            <Column>
              <ColumnTitle>Full Name</ColumnTitle>
              <ColumnText>{fullName || '-'}</ColumnText>
            </Column>
            <Column>
              <ColumnTitle>Gender</ColumnTitle>
              <ColumnText>{gender || '-'}</ColumnText>
            </Column>
          </Row>
          <Row>
            <Column>
              <ColumnTitle>Date of Birth</ColumnTitle>
              <ColumnText>{dateOfBirth || '-'}</ColumnText>
            </Column>
            <Column>
              <ColumnTitle>Nationality</ColumnTitle>
              <ColumnText>{nationality || '-'}</ColumnText>
            </Column>
          </Row>
          <Row>
            <Column>
              <ColumnTitle>Registered Address</ColumnTitle>
              <ColumnText>{addressString || '-'}</ColumnText>
            </Column>
          </Row>
        </CustomCard>
        <CustomCard>
          <H2>Occupation Details</H2>
          <Divider />
          <Row>
            <Column>
              <ColumnTitle>Occupation</ColumnTitle>
              <ColumnText>{occupation || '-'}</ColumnText>
            </Column>
            <Column>
              <ColumnTitle>Employment Sector</ColumnTitle>
              <ColumnText>{employmentSector || '-'}</ColumnText>
            </Column>
          </Row>
          <Row>
            <Column>
              <ColumnTitle>Name of Employer</ColumnTitle>
              <ColumnText>{employer || '-'}</ColumnText>
            </Column>
          </Row>
        </CustomCard>
        <DisclaimerContainer>
          <InfoFilled
            height={18}
            width={18}
            color={`#3583E6`}
          />
          <P1>
            If your KYC information is outdated, please contact our{' '} 
            <Link
              href="https://support.straitsx.com/hc/en-us/requests/new"
              target="_blank"
              rel="noreferrer noopener"
            >
              support team
            </Link>{' '} 
            and provide us with your latest information.
          </P1>
        </DisclaimerContainer>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const DisclaimerContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: start;

  ${DESKTOP_BREAKPOINT} {
    align-items: center;
  }
`

const ConfirmButton = styled(Button)`
  margin-left: 10px !important;
  padding: 15px 20px !important;
  color: ${straitsXTheme.brand.primary.contrast} !important;
  background-color: ${straitsXTheme.brand.primary.default} !important;
  &:disabled {
    opacity: 0.5 !important;
  }
`
import React from 'react'
import { useSelector } from 'react-redux'
import {
  H2,
  Link,
} from '@xfers/design-system'
import {   
  Column,
  ColumnTitle,
  ColumnText,
  CustomCard,
  Divider,
  Row,
} from '~/styledComponent/styled'
import { selectMerchantDetail } from '~/biz/store/merchantInfo/selectors'
import { MerchantDetail } from '~/biz/store/merchantInfo/types'

export default function() {
  const { merchantDetail } = useSelector(selectMerchantDetail)

  const {
    bizfile,
    memorandum,
  } = merchantDetail as MerchantDetail

  return (
    <>
      <CustomCard>
        <H2>Documents</H2>
        <Divider />
        <Row>
          <Column>
            <ColumnTitle>ACRA / Biz File</ColumnTitle>
            {
              (bizfile && bizfile.url) 
                ? (
                  <Link
                    href={bizfile.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '##00A965' }}
                  >
                    {bizfile.filename ? bizfile.filename : 'document'}
                  </Link>
                  )
                : <ColumnText>{'-'}</ColumnText>
            }
          </Column>
          <Column>
            <ColumnTitle>Memorandum or Articles of Association</ColumnTitle>
            {
              (memorandum && memorandum.url) 
              ? (
                <Link
                  href={memorandum.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '##00A965' }}
                >
                  {memorandum.filename ? memorandum.filename : 'document'}
                </Link>
                )
              : <ColumnText>{'-'}</ColumnText>
            }
          </Column>
        </Row>
      </CustomCard>
    </>
  )
}
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { transactionDetails } from './transactionDetails/reducer'
import { verification } from './verification/reducer'
import { straitsXDeveloperTools } from './straitsXDeveloperTools/reducer'
import { internalTools } from './internalTools/reducer'
import { businessVerification } from './businessVerification/reducer'
import { route } from './route/reducer'
import { team } from './team/reducer'
import { straitsXLandingPage } from './straitsXLandingPage/reducer'
import { straitsXTransactions } from './straitsXTransactions/reducer'
import { straitsXSimulateDeposit } from './straitsXSimulateDeposit/reducer'
import { straitsXEarn } from './straitsXEarn/reducer'
import { straitsXEarnTransactionDetails } from './straitsXEarnTransactionDetails/reducer'
import { depositInstructions } from './depositIntructions/reducer'
import { accounts } from './accounts/reducer'
import { merchantInfo } from './merchantInfo/reducer'
import { bankAccounts } from './bankAccounts/reducer'
import { blockchainAddresses } from './blockchainAddresses/reducer'
import { deposit } from './deposit/reducer'
import { straitsXAccountStatement } from './straitsXAccountStatement/reducer'
import { swap } from './swap/reducer'
import { flippers } from '~/store/flippers/reducer'

export const rootReducer = combineReducers({
  transactionDetails,
  verification,
  straitsXDeveloperTools,
  internalTools,
  businessVerification,
  route,
  depositInstructions,
  team,
  straitsXLandingPage,
  straitsXTransactions,
  straitsXSimulateDeposit,
  straitsXEarn,
  straitsXEarnTransactionDetails,
  accounts,
  merchantInfo,
  bankAccounts,
  blockchainAddresses,
  deposit,
  straitsXAccountStatement,
  swap,
  flippers,
})

const middlewares = [thunk]
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))

export default store

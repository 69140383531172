import cookie from 'js-cookie'

class SSOManager {
  public ssoLogin = () => {
    const currentPath = window.location.pathname || '/'

    let encodedSearchParam = ''
    if (window.location.search) {
      encodedSearchParam = encodeURIComponent(window.location.search)
    }

    // hardcoded client to 'xfers-international-app' if the user is international
    // link to client_app/packages/xfers-dashboard/server/src/middleware/ssoMiddleware.js
    let ssoLoginPath = 'sso_login'
    if (new URLSearchParams(window.location.search).get('client_id') === 'xfers-international-app')
      ssoLoginPath = 'sso_international_login'

    window.location.replace(`/${ssoLoginPath}?redirect_url=${currentPath}${encodedSearchParam}`)
  }

  public ssoLogout = () => {
    sessionStorage.clear()
    window.location.replace('/sso_logout?redirect_url=/')
  }

  public isLogined = () => cookie.get('SESSIONID') !== undefined
}

export default new SSOManager()

import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import * as H from 'history'

import { RootState } from '~/app/store/types'
import { resetRedirect } from '~/app/store/route/actions'

const mapState = ({ route }: RootState) => ({
  currentRedirect: route.currentRedirect,
})

const mapDispatch = {
  resetRedirect,
}

const connecter = connect(mapState, mapDispatch)

type RouterProps = {
  history: H.History
  location: any // eslint-disable-line
  match: any // eslint-disable-line
  resetModal: () => void
}

type ReduxProps = ConnectedProps<typeof connecter>

class RouteHooks extends React.Component<RouterProps & ReduxProps> {
  componentDidMount() {
    this.whenSwitchRoute()

    this.unlisten = this.props.history.listen(() => {
      if (this.props.resetModal) {
        this.props.resetModal()
      }
      this.whenSwitchRoute()
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  unlisten = () => {}

  whenSwitchRoute() {
    window.scrollTo(0, 0)
    this.props.resetRedirect()
  }

  render() {
    if (this.props.currentRedirect === null) {
      return null
    } else {
      return <Redirect push to={this.props.currentRedirect} />
    }
  }
}

export default connecter(withRouter(RouteHooks))

/* eslint-disable max-lines */
import { lazy } from 'react'
import { PATHS } from './paths'
import { PERMISSIONS, Route } from '~/types'
import withStraitsXBreadcrumbBar from '~/common/withStraitsXBreadcrumbBar'

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '~/xidrBiz/pages/NotFound'))

const Error = lazy(() => import(/* webpackChunkName: "Error" */ '~/xidrBiz/pages/Error'))

const XIDRGetStarted = lazy(() =>
  import(/* webpackChunkName: "XIDRGetStarted" */ '~/xidrBiz/pages/GetStarted')
)

const XIDRLandingPage = lazy(() =>
  /* webpackChunkName: "XIDRLandingPage" */
  import('~/xidrBiz/pages/LandingPage')
)

const XIDRDeposit = lazy(() =>
  /* webpackChunkName: "XIDRDeposit" */
  import('~/xidrBiz/pages/Deposit')
)

const XIDRWithdraw = lazy(() =>
  /* webpackChunkName: "XIDRWithdraw" */
  import('~/xidrBiz/pages/Withdraw')
)

const XIDRDeveloperTools = lazy(() =>
  /* webpackChunkName: "DeveloperTools" */
  import('~/xidrBiz/pages/DeveloperTools')
)

const XIDRVerification = lazy(() =>
  /* webpackChunkName: "XIDRVerification" */
  import('~/xidrBiz/pages/Verification')
)

const XIDRMyAccount = lazy(() =>
  /* webpackChunkName: "XIDRMyAccount" */
  import('~/xidrBiz/pages/MyAccount')
)

const XIDRTransactionDetails = lazy(() =>
  import(/* webpackChunkName: "XIDRTransactionDetails" */ '~/xidrBiz/pages/TransactionDetails')
)

const XIDRTransactions = lazy(() =>
  import(/* webpackChunkName: "XIDRTransactions" */ '~/xidrBiz/pages/Transactions')
)

const XIDRTeam = lazy(() => import(/* webpackChunkName: "XIDRTeam" */ '~/xidrBiz/pages/Team'))

const XIDRSandbox = lazy(() =>
  import(/* webpackChunkName: "XIDRSandbox" */ '~/xidrBiz/pages/Sandbox')
)
const BizKycInformation = lazy(() =>
  import(
    /* webpackChunkName: "BizKycInformation" */ '~/xidrBiz/pages/BusinessVerification/BusinessInformations'
  )
)
const BizKycIntendedRelations = lazy(() =>
  import(
    /* webpackChunkName: "BizKycIntendedRelations" */ '~/xidrBiz/pages/BusinessVerification/IntendedRelations'
  )
)
const BizKycBusinessActivities = lazy(() =>
  import(
    /* webpackChunkName: "BizKycBusinessActivities" */ '~/xidrBiz/pages/BusinessVerification/BusinessActivities'
  )
)
const BizKycUploadDocuments = lazy(() =>
  import(
    /* webpackChunkName: "BizKycUploadDocuments" */ '~/xidrBiz/pages/BusinessVerification/UploadDocuments'
  )
)
const BizKycReview = lazy(() =>
  import(/* webpackChunkName: "BizKycReview" */ '~/xidrBiz/pages/BusinessVerification/Review')
)
const BizKycStatus = lazy(() =>
  import(/* webpackChunkName: "BizKycStatus" */ '~/xidrBiz/pages/BusinessVerification/Status')
)

const XIDRRoutes: Route[] = [
  {
    path: PATHS.GET_STARTED,
    component: XIDRGetStarted,
    exact: true,
    sideBar: 'Get Started',
    documentTitle: { name: 'Get Started' },
  },
  {
    path: PATHS.LANDING_PAGE,
    component: XIDRLandingPage,
    exact: true,
    sideBar: 'Home',
    breadcrumbName: 'Home',
    documentTitle: { name: 'Dashboard', withSiteTypePrefix: true, withSiteTypeSuffix: false },
  },
  {
    path: PATHS.TRANSACTIONS,
    component: withStraitsXBreadcrumbBar(XIDRTransactions),
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    sideBar: 'Transaction History',
    breadcrumbName: 'Transaction History',
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.TRANSACTION_DETAILS,
    component: XIDRTransactionDetails,
    permission: PERMISSIONS.TRANSACTION,
    exact: true,
    documentTitle: { name: 'Transactions' },
  },
  {
    path: PATHS.DEPOSIT,
    component: withStraitsXBreadcrumbBar(XIDRDeposit),
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Transfer In',
    documentTitle: { name: 'Transfer In' },
  },
  {
    path: PATHS.TRANSFER_IN,
    component: withStraitsXBreadcrumbBar(XIDRDeposit),
    permission: PERMISSIONS.DEPOSIT,
    exact: true,
    breadcrumbName: 'Transfer In',
    documentTitle: { name: 'Transfer In' },
  },
  {
    path: PATHS.SIMULATE_DEPOSIT,
    component: XIDRSandbox,
    exact: true,
    documentTitle: { name: 'Simulate Transfer In' },
  },
  {
    path: PATHS.DEVELOPER_TOOLS,
    component: withStraitsXBreadcrumbBar(XIDRDeveloperTools),
    permission: PERMISSIONS.DEVELOPER_TOOLS,
    sideBar: 'Developer Tools',
    breadcrumbName: 'Developer Tools',
  },
  {
    path: PATHS.WITHDRAW,
    component: withStraitsXBreadcrumbBar(XIDRWithdraw),
    permission: PERMISSIONS.WITHDRAW,
    exact: true,
    breadcrumbName: 'Transfer Out',
    documentTitle: { name: 'Transfer Out' },
  },
  {
    path: PATHS.TRANSFER_OUT,
    component: withStraitsXBreadcrumbBar(XIDRWithdraw),
    permission: PERMISSIONS.WITHDRAW,
    exact: true,
    breadcrumbName: 'Transfer Out',
    documentTitle: { name: 'Transfer Out' },
  },
  {
    path: PATHS.MANAGE_ACCOUNT,
    component: withStraitsXBreadcrumbBar(XIDRMyAccount),
    exact: true,
    breadcrumbName: 'My Account',
    documentTitle: { name: 'Manage Account' },
  },
  {
    path: PATHS.VERIFICATION,
    component: withStraitsXBreadcrumbBar(XIDRVerification),
    exact: true,
    permission: PERMISSIONS.VERIFICATION,
    breadcrumbName: 'Verification',
    documentTitle: { name: 'Verification' },
  },
  {
    path: PATHS.TEAM,
    component: withStraitsXBreadcrumbBar(XIDRTeam),
    permission: PERMISSIONS.TEAM,
    exact: true,
    breadcrumbName: 'Team',
    sideBar: 'Team',
    documentTitle: { name: 'Team Management' },
  },
  {
    path: PATHS.ERROR_404,
    component: NotFound,
    documentTitle: { name: 'Page Not Found' },
  },
  {
    path: PATHS.ERROR,
    component: Error,
    documentTitle: { name: 'Error' },
  },
  {
    path: PATHS.BIZ_KYC_INFORMATION,
    component: withStraitsXBreadcrumbBar(BizKycInformation),
    breadcrumbName: 'Business Verification',
    permission: PERMISSIONS.VERIFICATION,
    documentTitle: { name: 'Verification Information' },
  },
  {
    path: PATHS.BIZ_KYC_INTENDED_RELATIONS,
    component: withStraitsXBreadcrumbBar(BizKycIntendedRelations),
    breadcrumbName: 'Business Verification',
    permission: PERMISSIONS.VERIFICATION,
    documentTitle: { name: 'Intended Relations' },
  },
  {
    path: PATHS.BIZ_KYC_BUSINESS_ACTIVITIES,
    component: withStraitsXBreadcrumbBar(BizKycBusinessActivities),
    breadcrumbName: 'Business Verification',
    permission: PERMISSIONS.VERIFICATION,
    documentTitle: { name: 'Business Activities' },
  },
  {
    path: PATHS.BIZ_KYC_UPLOAD_DOCUMENTS,
    component: withStraitsXBreadcrumbBar(BizKycUploadDocuments),
    breadcrumbName: 'Business Verification',
    permission: PERMISSIONS.VERIFICATION,
    documentTitle: { name: 'Verification - Upload Documents' },
  },
  {
    path: PATHS.BIZ_KYC_REVIEW,
    component: withStraitsXBreadcrumbBar(BizKycReview),
    breadcrumbName: 'Business Verification',
    permission: PERMISSIONS.VERIFICATION,
    documentTitle: { name: 'Verification Review' },
  },
  {
    path: PATHS.BIZ_KYC_STATUS,
    component: withStraitsXBreadcrumbBar(BizKycStatus),
    breadcrumbName: 'Business Verification',
    permission: PERMISSIONS.VERIFICATION,
    documentTitle: { name: 'Verification Status' },
  },
  {
    component: NotFound,
  },
]

export default XIDRRoutes

import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'

const parseArgs = (args: string | ArgsProps) => {
  let argObj: string | ArgsProps = args
  if (typeof args === 'string') {
    argObj = { message: args }
  }
  return argObj as ArgsProps
}

export const Notification = {
  success: (args: string | ArgsProps) => {
    notification.success(parseArgs(args))
  },

  info: (args: string | ArgsProps) => {
    notification.info(parseArgs(args))
  },

  error: (args: string | ArgsProps) => {
    notification.error(parseArgs(args))
  },

  warn: (args: string | ArgsProps) => {
    notification.warn(parseArgs(args))
  },
}

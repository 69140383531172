import { Reducer } from 'redux'
import {
  XIDRUserInfo,
  ActionTypes,
  BlockchainAddress,
  BankAccount,
  Bank,
  FinancialInstitution,
  UserProfile,
  SubmittingKYCData,
  VirtualNetwork,
} from './types'
import { XIDR_USER_INFO_ACTIONS } from './const'

type State = {
  isUserInfoInitiated: boolean
  isBlockchainAddressesInitiated: boolean
  isBankAccountsInitiated: boolean
  isBankListInitiated: boolean
  isUserProfileInitiated: boolean

  userInfo?: XIDRUserInfo
  blockchainAddresses: BlockchainAddress[]
  bankAccounts: BankAccount[]
  bankList: Bank[]
  virtualNetworks: VirtualNetwork[]
  fiList: FinancialInstitution[]
  maxAddressAmount: number
  addressQuota: number
  userProfile?: UserProfile

  submittingKYCData?: SubmittingKYCData
}

const initialState: State = {
  isUserInfoInitiated: false,
  isBlockchainAddressesInitiated: false,
  isBankAccountsInitiated: false,
  isBankListInitiated: false,
  isUserProfileInitiated: false,

  blockchainAddresses: [],
  bankAccounts: [],
  bankList: [],
  virtualNetworks: [],
  fiList: [],
  maxAddressAmount: 0,
  addressQuota: -1,
}

export const xidrUserInfo: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_USER_INFO_ACTIONS.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
        isUserInfoInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BLOCKCHAIN_ADDRESSES: {
      return {
        ...state,
        blockchainAddresses: action.blockchainAddresses,
        isBlockchainAddressesInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.bankAccounts,
        isBankAccountsInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_BANK_LIST: {
      return {
        ...state,
        bankList: action.bankList,
        isBankListInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_VIRTUAL_NETWORKS: {
      return {
        ...state,
        virtualNetworks: action.virtualNetworks,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_FI_LIST: {
      return {
        ...state,
        fiList: action.fiList,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_MAX_ADDRESS_AMOUNT: {
      return {
        ...state,
        maxAddressAmount: action.maxAddressAmount,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_ADDRESS_QUOTA: {
      return {
        ...state,
        addressQuota: action.addressQuota,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.userProfile,
        isUserProfileInitiated: true,
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_NEWLY_CREATED_BLOCKCHAIN_ADDRESS: {
      let addresses = state.blockchainAddresses
      if (!addresses) {
        addresses = []
      }
      addresses.push(action.blockchainAddress)

      return {
        ...state,
        blockchainAddresses: [...addresses],
      }
    }

    case XIDR_USER_INFO_ACTIONS.UPDATE_VERIFIED_BLOCKCHAIN_ADDRESS: {
      let addresses = state.blockchainAddresses
      if (!addresses) {
        addresses = []
      }

      const addressInd = addresses.findIndex(
        item => item.address === action.blockchainAddress.address
      )

      if (addressInd === -1) {
        return { ...state }
      }

      addresses[addressInd] = { ...action.blockchainAddress }

      return {
        ...state,
        blockchainAddresses: [...addresses],
      }
    }

    case XIDR_USER_INFO_ACTIONS.SET_SUBMITTING_KYC_DATA: {
      return {
        ...state,
        submittingKYCData: action.data,
      }
    }

    default:
      return state
  }
}

import camelCase from 'camel-case'
import { Dispatch } from '~/xidrBiz/store/types'
import { ActionTypes, ROUTE_ACTIONS, FeatureMatrix, Credentials } from './types'
import { createGaPageView, setGaAttribute } from '~/helpers/gaHelper'
import { PATHS } from '~/xidrBiz/routes/paths'
import { get, put, ENDPOINTS } from '~/api'
import { ConfigManager } from '~/managers/ConfigManager'
import { USER_ROLES } from '~/types'

export const redirect = (to: PATHS) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_REDIRECT,
    to,
  })
}

export const resetRedirect = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_REDIRECT,
    to: null,
  })
}

export const XIDRInit = () => (dispatch: Dispatch<ActionTypes>) => {
  Promise.all([get(ENDPOINTS.API_SSO_PROFILE), get(ENDPOINTS.API_V3_STABLECOIN_USER_PROFILE), get(ENDPOINTS.API_SSO_CREDENTIALS)]).then(
    ([ssoProfileResp, userProfileResp, ssoCredentials]) => {
      setGaAttribute({ userId: ssoProfileResp.kc_id }) // unsure if i should set it here
      createGaPageView(window.location.pathname + window.location.search)
      dispatch({
        type: ROUTE_ACTIONS.SET_USER_PROFILE,
        user: {
          kcId: ssoProfileResp.kc_id,
          fullName: userProfileResp.full_name,
          email: ssoProfileResp.email,
          mobileNumber: ssoProfileResp.mobile_number,
          countryCode: ssoProfileResp.country_code,
          avatarUrl: userProfileResp.avatar_url,
          displayname: userProfileResp.displayname,
          authServerUrl: ssoProfileResp.auth_server_url,
        },
      })

      dispatch({
        type: ROUTE_ACTIONS.SET_TRANSACTION_ACCOUNTS,
        /* eslint-disable @typescript-eslint/no-explicit-any */
        accounts: userProfileResp.accounts.map((acc: any) => {
          const newAcc: any = {}
          Object.keys(acc).forEach(key => {
            newAcc[camelCase(key)] = acc[key]
          })
          return newAcc
        }),
      })

      const featureMatrix = parseFeatureMatrixValues(ssoProfileResp)
      dispatch(setFeatureMatrix(featureMatrix))

      const credentialsPassword = ssoCredentials.find((credentials: Credentials) => credentials.type === "password")

      const { userCredentialMetadatas } = credentialsPassword
  
      if (userCredentialMetadatas) {
        dispatch({
          type: ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD,
          isUserHasPassword: userCredentialMetadatas.length > 0,
        })
      }
    }
  )
}

export const fetchAccounts = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_XFERS_ACCOUNTS).then(resp => {
    // [TODO] Duplicate action in init
    dispatch({
      type: ROUTE_ACTIONS.SET_TRANSACTION_ACCOUNTS,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      accounts: resp.map((account: any) => {
        const newAccount: any = {}
        Object.keys(account).forEach(key => {
          newAccount[camelCase(key)] = account[key]
        })
        return newAccount
      }),
    })
  })
}

export const updateUserProfile = ({
  deleteAvatar,
  displayImage,
}: {
  deleteAvatar?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  displayImage?: { fileData?: any; fileName?: any }
}) => (dispatch: Dispatch<ActionTypes>) => {
  put(ENDPOINTS.API_V3_UPDATE_USER_PROFILE, {
    delete_avatar: deleteAvatar,
    display_image: displayImage
      ? {
          file_data: displayImage.fileData,
          file_name: displayImage.fileName,
        }
      : null,
  })
    .then(resp => {
      dispatch({
        type: ROUTE_ACTIONS.UPDATE_USER_PROFILE,
        avatarUrl: resp.display_image.file_data,
      })
    })
    .catch(() => {
      // alert('error')
    })
}

export const setIsWelcomeModalOpened = (enabled: boolean) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED,
    enabled,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseFeatureMatrixValues = (ssoProfileResp: any) => {
  // Note: Setting this as 'invalid' because we need to be able to differentiate between
  // undefined that comes from API not being able to read the ssoProfileResp.userRole[0] value
  // or undefined because the API hasn't resolved yet. This is string not in the USER_ROLES enum
  // because USER_ROLES should only include values that is actually valid and can be handled
  // in rbacRules, as checked in rbacHoc checkAllowed function
  const ssoProfileUserRole =
    ssoProfileResp?.user_role && ssoProfileResp.user_role.length > 0
      ? // Find the element that match one of our USER_ROLES
        ssoProfileResp.user_role.find((role: USER_ROLES) =>
          Object.values(USER_ROLES).includes(role)
        )
      : 'invalid'

  return {
    userRole: ssoProfileUserRole,
    country: ConfigManager.getCountry(),
  } as FeatureMatrix
}

const setFeatureMatrix = (featureMatrix: FeatureMatrix) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: ROUTE_ACTIONS.SET_FEATURE_MATRIX,
    featureMatrix,
  })
}

import article1 from './article1.json'
import article2 from './article2.json'
import article3 from './article3.json'
import article4 from './article4.json'
import article5 from './article5.json'
import article6 from './article6.json'
import article7 from './article7.json'
import article8 from './article8.json'
import article9 from './article9.json'
import article10 from './article10.json'
import article11 from './article11.json'
import article12 from './article12.json'
import article13 from './article13.json'
import article14 from './article14.json'
import article15 from './article15.json'
import article16 from './article16.json'
import article17 from './article17.json'
import article18 from './article18.json'
import article19 from './article19.json'
import article20 from './article20.json'
import article21 from './article21.json'
import article22 from './article22.json'
import article23 from './article23.json'


import { Document } from '../../types'

export const GeneralTermsAndConditions: Document = {
  "sections": [
    article1,
    article2,
    article3,
    article4,
    article5,
    article6,
    article7,
    article8,
    article9,
    article10,
    article11,
    article12,
    article13,
    article14,
    article15,
    article16,
    article17,
    article18,
    article19,
    article20,
    article21,
    article22,
    article23
  ]
}

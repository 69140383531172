import React, { useContext } from 'react'

import { useModal } from '@xfers/design-system'

import { AddBankFlowContext } from '~/components/AddBankFlow'
import { STEPS } from '~/components/AddBankFlow/types'
import { setStep } from '~/components/AddBankFlow/actions'

import ConfirmBankAndAccount from './ConfirmBankAndAccount'
import ChooseBankAndAccount from './ChooseBankAndAccount'
import SuccessfullyAddedBankAccount from './SuccessfullyAddedBankAccount'
import ErrorAddingBankAccount from './ErrorAddingBankAccount'
import ChooseVerifyMethods from './ChooseVerifyMethods'
import UploadBankStatement from './UploadBankStatement'
import SuccessfullyUploadBankStatement from './SuccessfullyUploadBankStatement'
import { PATHS } from '~/app/routes/paths'

const AddBankFlowComponent = () => {
  const { state, dispatch } = useContext(AddBankFlowContext)
  const {
    step: activeStep,
    includeVerification,
    successCallback,
    onVerificationMethodNextButtonClick,
  } = state

  const { resetModal } = useModal()

  return (
    <>
      {activeStep === STEPS.CHOOSE_BANK_AND_ACCOUNT_NUMBER && (
        <ChooseBankAndAccount
          goNext={() => {
            dispatch(setStep(STEPS.CONFIRM_BANK_AND_ACCOUNT_NUMBER))
          }}
        />
      )}

      {activeStep === STEPS.CONFIRM_BANK_AND_ACCOUNT_NUMBER && (
        <ConfirmBankAndAccount
          goBack={() => dispatch(setStep(STEPS.CHOOSE_BANK_AND_ACCOUNT_NUMBER))}
          goNext={(success, bankAccount) => {
            if (success) {
              if (includeVerification) dispatch(setStep(STEPS.CHOOSE_VERIFY_METHODS))
              else dispatch(setStep(STEPS.SUCCESSFULLY_ADDED_BANK_ACCOUNT))

              if (bankAccount) {
                successCallback(bankAccount)
              }
            } else {
              dispatch(setStep(STEPS.ERROR_ADDING_BANK_ACCOUNT))
            }
          }}
        />
      )}

      {activeStep === STEPS.SUCCESSFULLY_ADDED_BANK_ACCOUNT && <SuccessfullyAddedBankAccount />}

      {activeStep === STEPS.ERROR_ADDING_BANK_ACCOUNT && <ErrorAddingBankAccount />}

      {activeStep === STEPS.CHOOSE_VERIFY_METHODS && (
        <ChooseVerifyMethods
          goNext={method => {
            if (method === 'deposit') {
              if (onVerificationMethodNextButtonClick) {
                onVerificationMethodNextButtonClick()
                resetModal()
              } else {
                window.location.href = PATHS.LANDING_PAGE
              }
            } else {
              dispatch(setStep(STEPS.VERIFY_WITH_BANK_STATEMENT))
            }
          }}
        />
      )}

      {activeStep === STEPS.VERIFY_WITH_BANK_STATEMENT && (
        <UploadBankStatement
          goBack={() => dispatch(setStep(STEPS.CHOOSE_VERIFY_METHODS))}
          goNext={(success, bankAccount) => {
            if (success) {
              dispatch(setStep(STEPS.SUCCESSFULLY_UPLOAD_BANK_STATEMENT))
            }

            if (bankAccount) {
              successCallback(bankAccount)
            }
          }}
        />
      )}

      {activeStep === STEPS.SUCCESSFULLY_UPLOAD_BANK_STATEMENT && (
        <SuccessfullyUploadBankStatement />
      )}
    </>
  )
}

export default AddBankFlowComponent

export enum XIDR_TOUR_ACTIONS {
  SET_CURRENT_STEP_INDEX = 'XIDR_TOUR_ACTIONS.SET_CURRENT_STEP_INDEX',
  SET_IS_TOUR_RUNNING = 'XIDR_TOUR_ACTIONS.SET_IS_TOUR_RUNNING',
}

type SetCurrentStepIndex = {
  type: typeof XIDR_TOUR_ACTIONS.SET_CURRENT_STEP_INDEX
  currentStepIndex: number
}

type SetIsTourRunning = {
  type: typeof XIDR_TOUR_ACTIONS.SET_IS_TOUR_RUNNING
  isTourRunning: boolean
}

export type ActionTypes = SetCurrentStepIndex | SetIsTourRunning

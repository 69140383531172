import { Reducer } from 'redux'
import { ActionTypes, ROUTE_ACTIONS, Account, User, Notification, FeatureMatrix } from './types'
import { PATHS } from '~/xidrBiz/routes/paths'
import { COUNTRY } from '~/managers/ConfigManager'

const initialState = {
  currentRedirect: null,
  user: {
    kcId: undefined,
    fullName: '',
    email: '',
    mobileNumber: '',
    countryCode: '',
    displayname: '',
    avatarUrl: '',
    authServerUrl: '',
  },
  accounts: [],
  notifications: {
    data: [],
    unreadCount: 0,
    isLoading: false,
  },
  dashboardRevampEnabled: true,
  mintDashboardEnabled: false,
  mintDashboardTestEnabled: false,
  isWelcomeModalOpened: null,
  featureMatrix: {
    userRole: null,
    country: COUNTRY.SINGAPORE,
  },
  isUserHasPassword: false,
}

type State = {
  currentRedirect: PATHS | null
  user: User
  accounts: Account[]
  notifications: {
    data: Notification[]
    unreadCount: number
    isLoading: boolean
  }
  dashboardRevampEnabled: boolean
  mintDashboardEnabled: boolean
  mintDashboardTestEnabled: boolean
  isWelcomeModalOpened: boolean | null
  featureMatrix: FeatureMatrix
  isUserHasPassword: boolean
}

export const route: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case ROUTE_ACTIONS.SET_REDIRECT: {
      return {
        ...state,
        currentRedirect: action.to,
      }
    }
    case ROUTE_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...action.user,
        },
      }
    }
    case ROUTE_ACTIONS.UPDATE_USER_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          avatarUrl: action.avatarUrl,
        },
      }
    }
    case ROUTE_ACTIONS.SET_TRANSACTION_ACCOUNTS: {
      return {
        ...state,
        accounts: action.accounts,
      }
    }
    case ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED: {
      return {
        ...state,
        isWelcomeModalOpened: action.enabled,
      }
    }
    case ROUTE_ACTIONS.SET_FEATURE_MATRIX: {
      return {
        ...state,
        featureMatrix: {
          ...action.featureMatrix,
        },
      }
    }
    case ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD: {
      return {
        ...state,
        isUserHasPassword: action.isUserHasPassword,
      }
    }
    default:
      return state
  }
}

import { TRANSACTION_TYPE_TAB } from './types'

export const FUNDING_TX_TYPES = [
  'deposit',
  'withdrawal',
  'stablecoin_deposit',
  'stablecoin_withdrawal',
  'mint',
  'payment/transfer',
]
export const SWAP_TX_TYPES = ['stablecoin_swap']
export const OTC_TX_TYPES = ['otc']

export const getTransactionTypes = (tab: TRANSACTION_TYPE_TAB, selectedTypes: string[]) => {
  if (tab === TRANSACTION_TYPE_TAB.SWAP) {
    return SWAP_TX_TYPES
  }

  if (tab === TRANSACTION_TYPE_TAB.OTC) {
    return OTC_TX_TYPES
  }

  if (selectedTypes.length !== 0) {
    return selectedTypes
  }

  return FUNDING_TX_TYPES
}

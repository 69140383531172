import { ConfigManager, COUNTRY } from '~/managers/ConfigManager'

const MAX_SUPPORTED_AMOUNT_ID = 100 * 1000 * 1000 // 100 million idr
const MAX_SUPPORTED_AMOUNT_SG = 20 * 1000 // 20,000 sgd

type Props = {
  balance: string | number | null | undefined
  country?: COUNTRY
  withCurrency?: boolean
}

/* eslint-disable no-lonely-if */
export function toLocaleBalance({
  balance,
  country = ConfigManager.getCountry(),
  withCurrency = true,
}: Props) {
  if (!(typeof balance === 'string' || typeof balance === 'number')) {
    return ''
  }

  const balanceNumber = typeof balance === 'string' ? parseFloat(balance) : balance
  // TODO: @henghong can help to move this maxSupportedAmountDash() logic out
  // as this will affect wallet balances that are above maxSupportedAmountDash
  // if (balanceNumber > maxSupportedAmountDash())
  //   throw new Error('Number is too large and not supported')

  let result

  if (withCurrency === false) {
    result = balanceNumber.toLocaleString(
      localeString(country),
      localeStringOptions(country, balanceNumber)
    )
  } else {
    result =
      localeCurrency(country) +
      balanceNumber.toLocaleString(
        localeString(country),
        localeStringOptions(country, balanceNumber)
      )
  }
  return result
}

// eslint-disable-next-line consistent-return
export const localeString = (country = ConfigManager.getCountry()) => {
  switch (country) {
    case COUNTRY.SINGAPORE:
      return 'en-SG'
    case COUNTRY.INDONESIA:
      return 'in-ID'
  }
}

// eslint-disable-next-line consistent-return
export const maxSupportedAmountDash = (country = ConfigManager.getCountry()) => {
  switch (country) {
    case COUNTRY.SINGAPORE:
      return MAX_SUPPORTED_AMOUNT_SG
    case COUNTRY.INDONESIA:
      return MAX_SUPPORTED_AMOUNT_ID
  }
}

// eslint-disable-next-line consistent-return
export const localeCurrency = (country = ConfigManager.getCountry()) => {
  switch (country) {
    case COUNTRY.SINGAPORE:
      return 'S$'
    case COUNTRY.INDONESIA:
      return 'Rp'
  }
}

export const localeStringOptions = (
  country = ConfigManager.getCountry(),
  balanceNumber: number
  // eslint-disable-next-line consistent-return
) => {
  switch (country) {
    case COUNTRY.SINGAPORE:
      // if balance is a whole number, do not show decimal and zeroes ".00"
      return balanceNumber % 1 === 0
        ? {}
        : {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
    case COUNTRY.INDONESIA:
      return {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
  }
}

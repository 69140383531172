import { Reducer } from 'redux'
import { UserInfo, ActionTypes, UserProfile, MerchantDetail } from './types'
import { USER_INFO_ACTIONS } from './const'

type State = {
  isUserInfoInitiated: boolean
  isUserProfileInitiated: boolean
  isMerchantDetailInitiated: boolean

  userInfo?: UserInfo
  userProfile?: UserProfile
  merchantDetail?: MerchantDetail
}

const initialState: State = {
  isUserInfoInitiated: false,
  isUserProfileInitiated: false,
  isMerchantDetailInitiated: false,
}

export const merchantInfo: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO_ACTIONS.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
        isUserInfoInitiated: true,
      }
    }

    case USER_INFO_ACTIONS.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.userProfile,
        isUserProfileInitiated: true,
      }
    }

    case USER_INFO_ACTIONS.SET_MERCHANT_DETAIL: {
      return {
        ...state,
        merchantDetail: action.merchantDetail,
        isMerchantDetailInitiated: true,
      }
    }

    default:
      return state
  }
}

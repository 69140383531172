/* eslint-disable max-lines */
import moment from 'moment'
import React from 'react'
import { BLOCKCHAIN_NETWORKS } from '~/common/constants'
import {
  BankAccount as USDBankAccount,
  BillingAddress as USDBillingAddress,
} from '~/components/AddUSDCBankFlow/types'

export { PERMISSIONS } from './permissions'
export * from './featureMatrix'
export * from './route'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FixMeLater = any

export type WalletIdType =
  | WALLET_TYPES.DIGITAL_GOODS
  | WALLET_TYPES.GENERAL
  | WALLET_TYPES.TEMPORARY_HOLDING_BALANCE
  | undefined

export type Wallet = {
  type: WalletIdType
  balance: string
  formattedBalance: string
  label: string
  accountId: number
  accountType: string
  fundPoolId: number
}

export enum WALLET_TYPES {
  GENERAL = 1,
  DIGITAL_GOODS = 2,
  XSGD = 7,
  TEMPORARY_HOLDING_BALANCE = 9,
  INDO_GENERAL_FUNDS = 2354,
}

export enum ACCOUNT_TYPES {
  PERSONAL = 'PersonalAccount',
  CORPORATE = 'CorporateAccount',
  BUSINESS = 'BusinessAccount',
  TEMPORARY_HOLDING_BALANCE = 'TemporaryHoldingBalanceAccount',
  BUSINESS_SETTLEMENT = 'BusinessSettlementAccount',
  MANAGED = 'ManagedAccount',
  SOBATKU_PERSONAL = 'SobatkuPersonalAccount',
}

export enum TRANSFER_TYPE {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  MINT = 'mint',
}

export type TransactionStatus =
  | 'completed'
  | 'processing'
  | 'failed'
  | 'cancelled'
  | 'refunded'
  | 'paid'
  | 'pending'

export type TransactionType =
  | 'Payment'
  | 'Withdrawal'
  | 'Deposit'
  | 'Stablecoin Withdrawal'
  | 'Stablecoin Deposit'
  | 'Disbursement'
  | 'Disbursement Dash'
  | 'Invoice'

export type BlockedReasons = 'Name mismatch' | 'Under review' | 'Exceed limit'

export type DepositRequestStatus = 'completed' | 'pending' | 'expired'

export type CreditCardsDisputeStatus =
  | 'Needs Response'
  | 'Under Review'
  | 'Resolved'
  | 'Won'
  | 'Lost'
  | 'Canceled'
  | 'Expired'
  | 'Accepted'

export enum CURRENCY {
  SGD = 'sgd',
  XSGD = 'xsgd',
  XUSD = 'xusd',
  IDR = 'idr',
  XIDR = 'xidr',
  USD = 'usd',
  USDC = 'usdc',
  USDT = 'usdt',
  USDP = 'usdp',
}

export type FIAT = CURRENCY.SGD | CURRENCY.USD
export type STABLECOIN = CURRENCY.XSGD | CURRENCY.USDC | CURRENCY.USDT | CURRENCY.XUSD
export type SWAP_CURRENCY = CURRENCY.XSGD | CURRENCY.USDC | CURRENCY.USDT | CURRENCY.XUSD

export type BlockchainNetwork = typeof BLOCKCHAIN_NETWORKS[keyof typeof BLOCKCHAIN_NETWORKS]
// It's a map for supported networks to switch between name and chainId
export enum BLOCKCHAIN_NETWORK_ID {
  ETHEREUM = 1,
  POLYGON = 137,
  TRON = 728126428,
  AVALANCHE = 43114,
}

export type FinancialInstitution = {
  id: number
  name: FinancialInstitutionName
  enabled: boolean
}

export type FinancialInstitutionName =
  | 'Personal Address (Non-Custodial)'
  | 'Personal Address'
  | 'Binance Singapore'
  | 'Coinhako'
  | 'Hodlnaut'
  | 'Independent Reserve'
  | 'Kryptos X'
  | 'Luno'
  | 'OnChain Custodian'
  | 'Sparrow'
  | 'Tokenize Exchange'
  | 'Other'
  | 'Bitgo'
  | 'Crypto.com'
  | 'Liquid'
  | 'QCP Capital'
  | 'Coinut'
  | 'Wirex'
  | 'Finblox'

export type BusinessVerificationStatus =
  | 'initial'
  | 'unverified'
  | 'verified'
  | 'pending'
  | 'rejected'
  | 'permanently rejected'
  | undefined

export type PersonalVerificationStatus =
  | 'initial'
  | 'verified'
  | 'pending'
  | 'rejected'
  | 'permanently rejected'
  | null

export enum BUSINESS_VERIFICATION_STATUS {
  INITIAL = 'initial',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified',
  PENDING = 'pending',
  REJECTED = 'rejected',
  PERMANENTLY_REJECTED = 'permanently rejected',
}

export enum PERSONAL_VERIFICATION_STATUS {
  INITIAL = 'initial',
  VERIFIED = 'verified',
  PENDING = 'pending',
  REJECTED = 'rejected',
  PERMANENTLY_REJECTED = 'permanently rejected',
}

export type FundPoolTypes = 1 | 2 | 3

export type AccountLimitation = {
  max: string
  remaining: string
  used: string
}

export type Account = {
  attributes: {
    type: ACCOUNT_TYPES
    walletId: WALLET_TYPES
    fundPoolId: number
    deletedAt: null | string
    limitations: {
      [key: string]: AccountLimitation
    }
    currency: CURRENCY
  }
}

export type DGWAccountLimit = {
  holdingRemaining: string
  holdingMax: string
  annualTransactionRemaining: string
  annualTransactionMax: string
  withdrawalDailyRemaining: string
  withdrawalDailyMax: string
  limitationIncreaseAmount: string
  limitationIncreaseDate: Moment | undefined
}

export enum USER_ROLES {
  OWNER = 'owner',
  ADMIN = 'admin',
  OPERATIONS = 'operations',
  DEVELOPER = 'developer',
  VIEWER = 'viewer',
  FINANCE = 'finance',
}

export type Moment = moment.Moment

export type LabelTypes = 'success' | 'error' | 'warning' | 'info' | 'neutral'

export enum LABEL_TYPES {
  SUCCESS = 'success',
  WARNING = 'warning',
  NEUTRAL = 'neutral',
  INFO = 'info',
  ERROR = 'error',
}

export type LabelEntity =
  | 'transaction'
  | 'verification'
  | 'invoice'
  | 'developerTools'
  | 'blockchainAddress'
  | 'bankAccount'
  | 'xfersDash'
  | 'creditCardsPayments'
  | 'creditCardsDisputes'
  | 'earn'

export type Map<T extends string | number | symbol> = {
  [key in T]: {
    [key: string]: string
  }
}

export type ErrorContent = {
  [key: string]: ErrorDetails
}

export type ErrorDetails = {
  errorTitle: string
  errorSubtitle?: string
  errorContent?: string | React.ReactNode
}

export type TransactionsTableColumnEntity =
  | 'settlementDate'
  | 'createdDate'
  | 'amount'
  | 'netAmount'
  | 'completedDate'
  | 'status'

export type EvidenceType =
  | 'proofOfDelivery'
  | 'invoiceOrReceipt'
  | 'distinctTxnInvoice'
  | 'customerCommutation'
  | 'refundPolicy'
  | 'recurringTxn'
  | 'additionalEvidence'

export type ManualAssignmentTableColumnEntity = 'assignedAmount' | 'completedDate'

export type RegionalAccountBalanceTableColumnEntity =
  | 'referenceId'
  | 'transactionId'
  | 'creationDate'
  | 'settlementDate'

export type CreditCardsDisputeReason =
  | 'credit_not_processed'
  | 'duplicate'
  | 'fraudulent'
  | 'general'
  | 'product_not_received'
  | 'product_unacceptable'
  | 'unrecognized'

export type ErrorResponse = {
  response?: {
    data?: {
      error?: string
    }
  }
}

export type VirtualAccount = {
  bankShortCode: string
  bankAccountNo: string
  bankNameFull: string
  bankPayeeName: string
  uniqueId: string
  bankNameAbbreviation?: string
  merchantRef?: string
  walletName?: string
  displayName?: string
}

export enum BANK_ACCOUNT_STATUS {
  VERIFIED = 'verified',
  PENDING = 'pending',
  REJECTED = 'rejected',
  UNVERIFIED = 'unverified',
}

export type BankAccount = {
  id: number
  bankId: number
  bankAbbrev: string
  accountNo: string
  accountHolderName: string
  updatedAt: string
  disabled: string // for BE returns 'true', 'false'
  verified: string // for BE returns 'true', 'false'
  verificationStatus: BANK_ACCOUNT_STATUS
  hasBankAccountProof: boolean
  detectedName: string
  hidden: boolean
  rejectReason: string
  usage: string
  isSGDBank: boolean
  isUSDBank: boolean
  swiftCode?: string
  intermediarySwiftCode?: string
  routingCode?: string
  bankAddress?: USDBankAccount
  billingDetails?: USDBillingAddress
  userProvidedBankName?: string
}

export type UsdVirtualAccount = {
  id: number
  accountNo: string
  enabled: boolean
  createdAt: string
  updatedAt: string
  bankName: string
  bankAddress: string
  bankCountry: string
  bankSwiftCode: string
  displayName: string
}

export const FINANCIAL_INSTITUTION_NAME = {
  OTHER: 'Other',
}

export const TX_DESCRIPTION_DATA = 'Digital Goods Wallet'

/* eslint-disable max-lines */
import moment from 'moment'
import { State, Pages, Role, AddFlowMeta } from './types'
import { countryOptions } from '~/common/selectOptions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateRequestBody = (state: any, page: Pages) => {
  let requestBody = state[page]

  if (page === 'businessInformation') {
    requestBody = {
      business_type: requestBody.businessType,
      business_name: requestBody.businessName,
      former_names: requestBody.merchantsFormerNames,
      business_display_name: requestBody.companyDisplayname,
      business_registration_number: requestBody.businessRegistrationNumber,
      business_license_expiry_date: requestBody.businessLicenseExpiryDate?.format('DD/MM/YYYY'),
      business_contact_no: requestBody.businessContactNo,
      business_date_of_incorporation: requestBody.businessDateOfIncorporation?.format('DD/MM/YYYY'),
      country_of_incorporation: requestBody.countryOfIncorporation,
      business_address_line_1: requestBody.businessAddressLine1,
      business_address_line_2: requestBody.businessAddressLine2,
      business_postal_code: requestBody.businessPostalCode,
      country: requestBody.country,
      city: requestBody.city,
    }
  } else if (page === 'businessRelation') {
    requestBody = {
      business_relations: JSON.stringify(requestBody.methods),
    }
  } else if (page === 'businessActivity') {
    requestBody = {
      countries_of_activities: JSON.stringify(requestBody.countriesOfActivities),
      business_website: requestBody.businessWebsite,
      business_category: requestBody.businessCategory,
      business_sub_category: requestBody.businessSubCategory,
      business_activity_summary: requestBody.businessActivitySummary,
      expected_transaction_volume: requestBody.expectedTransactionVolume,
      expected_value_per_transaction: requestBody.expectedValuePerTransaction,
      expected_gtv: requestBody.expectedGtv,
      source_of_wealth: requestBody.sourceOfWealth,
      source_of_funds: requestBody.sourceOfFunds,
      source_of_goods: requestBody.sourceOfGoods,
    }
  } else if (page === 'businessTaxResidency') {
    requestBody = {
      tax_amnesty_participation: requestBody.taxAmnestyParticipation,
      merchant_tax_residences: requestBody.merchantTaxResidences,
    }
  } else if (page === 'uploadDocument') {
    requestBody = {
      bizfile: requestBody.bizfileUrl,
      memorandum: requestBody.memorandumUrl,
    }
  } else if (page === 'addFlowMeta' && requestBody.type === 'individual') {
    requestBody = {
      business_position: parsePosition(requestBody.role),
      full_name: requestBody.fullName,
      date_of_birth: requestBody.dateOfBirth && requestBody.dateOfBirth.format('DD/MM/YYYY'),
      country_of_birth: requestBody.countryOfBirth,
      nationality: requestBody.nationality,
      nric_type: requestBody.nricType,
      NRIC: requestBody.NRIC,
      nric_issue_date: requestBody.nricIssueDate && requestBody.nricIssueDate.format('DD/MM/YYYY'),
      nric_expiry_date:
        requestBody.nricExpiryDate && requestBody.nricExpiryDate.format('DD/MM/YYYY'),
      address_line_1: requestBody.addressLine1,
      address_line_2: requestBody.addressLine2,
      village: requestBody.village,
      sub_district: requestBody.subDistrict,
      country: requestBody.country,
      city: requestBody.city,
      postal_code: requestBody.postalCode,
      rt_rw: requestBody.rtRw,
      ownership_percentage: requestBody.ownershipPercentage,
      id_front: requestBody.idFrontUrl,
      id_back: requestBody.idBackUrl,
      proof_of_address: requestBody.proofOfAddressUrl,
      deed_of_appointment: requestBody.deedOfAppointmentUrl,
    }
  } else if (page === 'addFlowMeta' && requestBody.type === 'company') {
    requestBody = {
      business_type: requestBody.businessType,
      business_name: requestBody.businessName,
      business_registration_number: requestBody.businessRegistrationNumber,
      business_license_expiry_date: requestBody.businessLicenseExpiryDate,
      date_of_incorporation:
        requestBody.dateOfIncorporation && requestBody.dateOfIncorporation.format('DD/MM/YYYY'),
      country_of_incorporation: requestBody.countryOfIncorporation,
      ownership_percentage: requestBody.ownershipPercentage,

      bizfile: requestBody.bizfileUrl,
      memorandum: requestBody.memorandumUrl,
      beneficial_owner_declaration: requestBody.beneficialOwnerDeclarationUrl,
    }
  }

  return requestBody
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseResponse = (res: any, currentState: State) => {
  if (Object.entries(res).length === 0) return {}

  // normalization
  Object.entries(res).forEach(([key, value]) => {
    if (typeof value === 'string' && value) {
      res[key] = value === '-' ? undefined : value
    } else if (value !== false && !value) {
      res[key] = undefined
    } else {
      res[key] = value
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const state: { [index: string]: any } = {
    businessInformation: {
      ...currentState.businessInformation,
      businessType: res.business_type,
      businessName: res.business_name,
      merchantsFormerNames: res.former_names,
      companyDisplayname: res.business_display_name,
      businessRegistrationNumber: res.business_registration_number,
      businessLicenseExpiryDate: res.business_license_expiry_date
        ? moment(res.business_license_expiry_date)
        : undefined,
      businessContactNo: res.business_contact_no,
      businessDateOfIncorporation: res.business_date_of_incorporation
        ? moment(res.business_date_of_incorporation, 'DD/MM/YYYY')
        : undefined,
      countryOfIncorporation: res.country_of_incorporation,
      businessAddressLine1: res.business_address_line_1,
      businessAddressLine2: res.business_address_line_2,
      businessPostalCode: res.business_postal_code,
      country: res.country,
      city: res.city,
      tinNpwp: res.tin_npwp,
    },
    businessRelation: {
      ...currentState.businessRelation,
      methods: res.business_relations ? JSON.parse(res.business_relations) : [],
    },
    businessActivity: {
      ...currentState.businessActivity,
      countriesOfActivities: res.countries_of_activities
        ? JSON.parse(res.countries_of_activities)
        : [],
      businessWebsite: res.business_website?.toString(),
      businessCategory: res.business_category,
      businessSubCategory: res.business_sub_category,
      businessActivitySummary: res.business_activity_summary?.toString(),
      expectedTransactionVolume: res.expected_transaction_volume,
      expectedValuePerTransaction: res.expected_value_per_transaction,
      expectedGtv: res.expected_gtv,
      sourceOfWealth: res.source_of_wealth,
      sourceOfFunds: res.source_of_funds,
      sourceOfGoods: res.source_of_goods,
    },
    businessTaxResidency: {
      taxAmnestyParticipation: res.tax_amnesty_participation,
      merchantTaxResidences: res.merchant_tax_residences,
    },
    uploadDocument: {
      ...currentState.uploadDocument,
      idFrontUrl: res.id_front?.url,
      isIdFrontPdf: res.id_front?.content_type?.includes('pdf'),
      bizfileUrl: res.bizfile?.url,
      isBizfilePdf: res.bizfile?.content_type?.includes('pdf'),
      memorandumUrl: res.memorandum?.url,
      isMemorandumPdf: res.memorandum?.content_type?.includes('pdf'),
      certificateOfIncorporationUrl: res.certificate_of_incorporation?.url,
      isCertificateOfIncorporationPdf: res.certificate_of_incorporation?.content_type?.includes(
        'pdf'
      ),
      registerOfMembersUrl: res.register_of_members?.url,
      isRegisterOfMembersPdf: res.register_of_members?.content_type?.includes('pdf'),
      registerOfDirectorsUrl: res.register_of_directors?.url,
      isRegisterOfDirectorsPdf: res.register_of_directors?.content_type?.includes('pdf'),
      molhrUrl: res.molhr?.url,
      isMolhrPdf: res.molhr?.content_type?.includes('pdf'),
    },
    accountManager: {
      ...currentState.accountManager,
      fullName: res.account_manager.full_name,
      dateOfBirth: res.account_manager?.date_of_birth
        ? moment(transformDateFormat(res.account_manager.date_of_birth))
        : undefined,
      countryOfBirth: res.account_manager.country_of_birth,
      nationality: res.account_manager.nationality,
      nricType: res.account_manager.nric_type,
      NRIC: res.account_manager.NRIC,
      nricIssueDate: res.account_manager?.nric_issue_date
        ? moment(transformDateFormat(res.account_manager?.nric_issue_date))
        : undefined,
      nricExpiryDate: res.account_manager?.nric_expiry_date
        ? moment(transformDateFormat(res.account_manager?.nric_expiry_date))
        : undefined,
      addressLine1: res.account_manager.address_line_1,
      addressLine2: res.account_manager.address_line_2,
      village: res.account_manager.village,
      subDistrict: res.account_manager.sub_district,
      country: res.account_manager.country,
      city: res.account_manager.city,
      postalCode: res.account_manager.postal_code,
      rtRw: res.account_manager.rt_rw,
      ownershipPercentage: res.account_manager.ownership_percentage,
      verificationStatus: res.account_manager.verification_status,
      idFrontUrl: res.account_manager.id_front?.url,
      isIdFrontPdf: res.account_manager.id_front?.content_type?.includes('pdf'),
      idBackUrl: res.account_manager.id_back?.url,
      isIdBackPdf: res.account_manager.id_back?.content_type?.includes('pdf'),
      proofOfAddressUrl: res.account_manager.proof_of_address?.url,
      isProofOfAddressPdf: res.account_manager.proof_of_address?.content_type?.includes('pdf'),
    },
    directorAndBoList: [
      {
        name: res.account_manager.full_name,
        type: 'individual',
        role: 'account_manager',
        ownershipPercentage: '-',
        is_shadow_account_manager: false,
      },
      ...parseBoAndCorporate(res),
    ],
    verificationStatus: res.verification_status,
  }
  if (res.categories_list) {
    state.businessCategoriesList = res.categories_list
  }
  if (res.high_risk_subcategories) {
    state.highRiskSubcategoriesList = res.high_risk_subcategories
  }

  return state
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseBoAndCorporate: (res: any) => AddFlowMeta[] = res => {
  let list: AddFlowMeta[] = []

  const roleMap: { [key: string]: Array<string> } = {
    director: ['director'],
    beneficial_owner: ['bo'],
    director_beneficial_owner: ['director', 'bo'],
  }

  list = [
    ...list,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...res.beneficial_owners.map((bo: any) => ({
      id: bo.id,
      isDirectorOrBo: bo.NRIC === res.account_manager?.NRIC,
      name: bo.full_name,
      fullName: bo.full_name,
      type: 'individual',
      role: roleMap[bo.business_position],
      dateOfBirth: bo.date_of_birth ? moment(transformDateFormat(bo.date_of_birth)) : undefined,
      countryOfBirth: bo.country_of_birth,
      nationality: bo.nationality,
      nricType: bo.nric_type,
      NRIC: bo.NRIC,
      nricIssueDate: bo.nric_issue_date
        ? moment(transformDateFormat(bo.nric_issue_date))
        : undefined,
      nricExpiryDate: bo.nric_expiry_date
        ? moment(transformDateFormat(bo.nric_expiry_date))
        : undefined,
      addressLine1: bo.address_line_1,
      addressLine2: bo.address_line_2,
      village: bo.village,
      subDistrict: bo.sub_district,
      country: bo.country,
      city: bo.city,
      postalCode: bo.postal_code,
      rtRw: bo.rt_rw,
      ownershipPercentage: bo.ownership_percentage,
      is_shadow_account_manager: res.account_manager.NRIC === bo.NRIC,
      idFrontUrl: bo.id_front?.url,
      isIdFrontPdf: bo.id_front?.content_type?.includes('pdf'),
      idBackUrl: bo.id_back?.url,
      isIdBackPdf: bo.id_back?.content_type?.includes('pdf'),
      proofOfAddressUrl: bo.proof_of_address?.url,
      isProofOfAddressPdf: bo.proof_of_address?.content_type?.includes('pdf'),
      deedOfAppointmentUrl: bo.deed_of_appointment?.url,
      isDeedOfAppointmentPdf: bo.deed_of_appointment?.content_type?.includes('pdf'),
      idFrontDisabled: bo.NRIC === res.account_manager?.NRIC,
      idBackDisabled: bo.NRIC === res.account_manager?.NRIC,
      proofOfAddressDisabled: bo.NRIC === res.account_manager?.NRIC,
    })),
  ]

  list = [
    ...list,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...res.corporates.map((co: any) => ({
      id: co.id,
      name: co.business_name,
      businessName: co.business_name,
      type: 'company',
      role: ['bo'],
      businessType: co.business_type,
      businessRegistrationNumber: co.business_registration_number,
      businessLicenseExpiryDate: co.business_license_expiry_date
        ? moment(transformDateFormat(co.business_license_expiry_date))
        : undefined,
      dateOfIncorporation: co.date_of_incorporation
        ? moment(transformDateFormat(co.date_of_incorporation))
        : undefined,
      countryOfIncorporation: co.country_of_incorporation,
      ownershipPercentage: co.ownership_percentage,
      bizfileUrl: co.acra_document?.url,
      isBizfilePdf: co.acra_document?.content_type?.includes('pdf'),
      memorandumUrl: co.memorandum?.url,
      isMemorandumPdf: co.memorandum?.content_type?.includes('pdf'),
      beneficialOwnerDeclarationUrl: co.beneficial_owner_declaration?.url,
      isBeneficialOwnerDeclarationPdf: co.beneficial_owner_declaration?.content_type?.includes(
        'pdf'
      ),
    })),
  ]

  return list
}

export const parsePosition = (positions: Array<Role> | 'account_manager' = []) => {
  if (positions === 'account_manager') {
    return 'Account Manager'
  } else if (positions.includes('director') && positions.includes('bo')) {
    return 'Director & Beneficial Owner'
  } else if (positions.includes('director')) {
    return 'Director'
  } else if (positions.includes('bo')) {
    return 'Beneficial Owner'
  } else {
    return ''
  }
}

export const parseCountry = (alpha2?: string) =>
  countryOptions.find(country => country.value.toUpperCase() === alpha2?.toUpperCase())?.label

export const transformDateFormat = (dateString: string): string => {
  // Moment cannot parse '15/12/1995' format, transform to '1995-12-15'
  if (/\d{1,2}\/\d{1,2}\/\d{4}/gm.test(dateString)) {
    const [date, month, year] = dateString.split('/')
    return `${year}-${month}-${date}`
  }

  return dateString
}

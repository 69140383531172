import React from 'react'
import styled from '@emotion/styled'

type Props = {
  variant?: 'regular' | 'mini'
}

const NewLabel = ({ variant }: Props) => {
  switch (variant) {
    case 'mini':
      return <MiniContainer>New</MiniContainer>
    default:
      return <Container>New</Container>
  }
}

export default NewLabel

const MiniContainer = styled.div`
  display: flex;
  height: 7px;
  padding: 1px 2px;
  font-size: 0.4em;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  font-family: inter;
  font-weight: bold;
  color: #ffffff;
  background-color: #00d37e;
`

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #ffffff;
  background-color: #00d37e;
  font-family: inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  padding: 3px 8px;
  margin-left: auto;
`

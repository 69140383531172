/* eslint max-lines: off */
import { Reducer } from 'redux'
import {
  ActionTypes,
  EarnAccount,
  PlatformInfo,
  EarnCurrencyBalance,
  EarnTransferRequestType,
  EarnTransaction,
  EarnTransactionDetails,
  EARN_CURRENCY,
  EARN_TRANSACTION_STATUS,
  EARN_TRANSACTION_TYPE,
  EARN_TRANSACTIONS_SORT_ORDER,
  EARN_ACTIONS,
} from './types'

const initialState: State = {
  earnAccount: null,
  isEarnEnabled: false,
  isLoading: false,
  platformInfo: null,
  currencyBalances: {},
  latestTransferRequest: undefined,
  earnTransactions: [],
  earnTransactionsType: EARN_TRANSACTION_TYPE.INTEREST,
  earnTransactionsCurrentPage: 1,
  earnTransactionsPerPage: 10,
  earnTransactionsTotalCount: 0,
  earnTransactionsTotalPages: 0,
  earnTransactionsSortOrder: null,
  earnTransactionsSortField: null,
  earnTransactionsFilterCurrency: null,
  earnTransactionsFilterStatus: null,
  earnTransactionsFilterStartDate: null,
  earnTransactionsFilterEndDate: null,
  earnTransactionsDetails: {},
}

type State = {
  earnAccount: EarnAccount
  isEarnEnabled: boolean
  isLoading: boolean
  platformInfo: PlatformInfo
  currencyBalances: Record<string, EarnCurrencyBalance>
  latestTransferRequest:
    | {
        amount: number
        currencyCode: string
        requestType: EarnTransferRequestType
      }
    | undefined
  earnTransactions: EarnTransaction[]
  earnTransactionsType: EARN_TRANSACTION_TYPE
  earnTransactionsCurrentPage: number
  earnTransactionsPerPage: number
  earnTransactionsTotalCount: number
  earnTransactionsTotalPages: number
  earnTransactionsSortOrder: EARN_TRANSACTIONS_SORT_ORDER | null
  earnTransactionsSortField: string | null
  earnTransactionsFilterCurrency: EARN_CURRENCY | null
  earnTransactionsFilterStatus: EARN_TRANSACTION_STATUS | null
  earnTransactionsFilterStartDate: string | null
  earnTransactionsFilterEndDate: string | null
  earnTransactionsDetails: Record<EarnTransactionDetails['referenceId'], EarnTransactionDetails>
}

export const earn: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case EARN_ACTIONS.SET_EARN_ACCOUNT: {
      return {
        ...state,
        earnAccount: action.earnAccount,
      }
    }
    case EARN_ACTIONS.SET_IS_EARN_ENABLED: {
      return {
        ...state,
        isEarnEnabled: action.isEnabled,
      }
    }
    case EARN_ACTIONS.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }
    case EARN_ACTIONS.SET_PLATFORM_INFO: {
      return {
        ...state,
        platformInfo: action.platformInfo,
      }
    }
    case EARN_ACTIONS.SET_EARN_CURRENCY_BALANCE: {
      const currencyKey = action.currency
      const newCurrencyBalances = {
        ...state.currencyBalances,
        [currencyKey]: action.earnCurrencyBalance,
      }
      return {
        ...state,
        currencyBalances: newCurrencyBalances,
      }
    }
    case EARN_ACTIONS.SET_LATEST_TRANSFER_REQUEST: {
      return {
        ...state,
        latestTransferRequest: {
          amount: action.amount,
          currencyCode: action.currencyCode,
          requestType: action.requestType,
        },
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS: {
      return {
        ...state,
        earnTransactions: action.transactions,
        earnTransactionsTotalCount: action.totalCount,
        earnTransactionsTotalPages: action.totalPages,
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS_TYPE: {
      return {
        ...state,
        earnTransactionsType: action.transactionType,
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS_SORT_ORDER: {
      return {
        ...state,
        earnTransactionsSortOrder: action.sortOrder,
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS_CURRENT_PAGE: {
      return {
        ...state,
        earnTransactionsCurrentPage: action.currentPage,
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_CURRENCY: {
      return {
        ...state,
        earnTransactionsFilterCurrency: action.currency,
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_DATE: {
      return {
        ...state,
        earnTransactionsFilterStartDate: action.startDate,
        earnTransactionsFilterEndDate: action.endDate,
      }
    }
    case EARN_ACTIONS.SET_EARN_TRANSACTIONS_FILTER_STATUS: {
      return {
        ...state,
        earnTransactionsFilterStatus: action.status,
      }
    }
    case EARN_ACTIONS.SET_TRANSACTION_DETAILS: {
      const { earnTransactionsDetails } = state
      const transactionRefId = action.transactionDetails.referenceId
      earnTransactionsDetails[transactionRefId] = action.transactionDetails

      return {
        ...state,
        earnTransactionsDetails,
      }
    }
    default:
      return state
  }
}

export enum API_VERSION {
  V3 = 'V3',
}

export enum TAB {
  API_KEY = 'apiKeys',
  CALLBACK_SETTINGS = 'callbackSettings',
}

export enum CALLBACK_TYPE {
  PAYMENT = 'PAYMENT',
  PAYOUT = 'PAYOUT',
  RETAIL_PAYMENT = 'RETAIL_PAYMENT',
}

export type V3KeyResponse = {
  api_key: string
  api_secret: string
  wallet_type: {
    id: number
    name: string
    contractual_model: boolean
    in_use: boolean
  }
  last_used: string
  created_at: string
  enabled: boolean
}

export type V3Key = {
  apiKey: string
  apiSecret: string
  walletType: string
  lastUsed: string
  createdAt: string
  enabled: boolean
}

export type ApiTokenInfo = {
  token: null | string
  secret: null | string
  lastUsed?: null | string
  createdAt?: null | string
}

export type DetailType = {
  api_version: string
  enabled_events: string[]
  signing_secret: string
  tag: string
  url: string
}

export type callbackFunctions = {
  successCallback: () => void
  errorCallback: () => void
}

export enum DEVELOPER_TOOLS_ACTIONS {
  SET_IS_LOADING = 'DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING',
  SET_API_KEY_VERSION_V3 = 'DEVELOPER_TOOLS_ACTIONS.SET_API_KEY_VERSION_V3',
  SET_IS_FLIPPER_DEVELOPER_TOOL_ENABLED = 'DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_DEVELOPER_TOOL_ENABLED',
  GET_INITIAL_URL_V3 = 'DEVELOPER_TOOLS_ACTIONS.GET_INITIAL_URL_V3',
  SET_CALLBACK_URL = 'DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URL',
  UPDATE_CALLBACK_URL = 'DEVELOPER_TOOLS_ACTIONS.UPDATE_CALLBACK_URL',
  RESET_API_TOKEN_INFO = 'DEVELOPER_TOOLS_ACTIONS.RESET_API_TOKEN_INFO',
  FETCH_API_KEY = 'DEVELOPER_TOOLS_ACTIONS.FETCH_API_KEY',
  GENERATE_API_KEY = 'DEVELOPER_TOOLS_ACTIONS.GENERATE_API_KEY',
  CLEAR_API_KEYS = 'DEVELOPER_TOOLS_ACTIONS.CLEAR_API_KEYS',
  SHOW_V3_SIGNATURE = 'DEVELOPER_TOOLS_ACTIONS.SHOW_V3_SIGNATURE',
  SET_V3_SIGNATURE = 'DEVELOPER_TOOLS_ACTIONS.SET_V3_SIGNATURE',
  GET_VERIFICATION_STATUS = 'DEVELOPER_TOOLS_ACTIONS.GET_VERIFICATION_STATUS',
  SET_API_TOKEN = 'DEVELOPER_TOOLS_ACTIONS.SET_API_TOKEN',
  SET_CALLBACK_URLS = 'DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URLS',
  SET_IS_FLIPPER_SANDBOX_ON = 'DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_SANDBOX_ON',
}

export type EventSetting = {
  event_name:
    | 'connect_token_disabled'
    | 'user_verification_status_updated'
    | 'withdrawal_completed'
    | 'payout_completed'
    | 'charge_completed'
    | 'virtual_account_topup_completed'
    | 'v3_payment_gateway_payment_expired'
    | 'v3_payment_gateway_payment_paid'
    | 'v3_payment_gateway_payment_completed'
  url: string
}

export type V3CallbackSettings = {
  connectTokenDisabled: string
  userVerificationStatusUpdated: string
  userBankAccountVerificationStatusUpdated: string
  withdrawalCompleted: string
  payoutCompleted: string
  chargeCompleted: string
  virtualAccountTopupCompleted: string
  v3PaymentGatewayPaymentExpired: string
  v3PaymentGatewayPaymentPaid: string
  v3PaymentGatewayPaymentCompleted: string
  signature: string
  showSig: boolean
}

export type CallbackURLs = {
  payment: string
  payout: string
  retailPayment: string
}

type GetInitialUrlV3 = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.GET_INITIAL_URL_V3
  v3CallbackSettings: V3CallbackSettings
}

type SetCallbackUrl = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URL
  apiVersion: API_VERSION.V3
  callbackType: string
  callbackUrl: string
}

type SetIsFlipperDeveloperToolEnabled = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_DEVELOPER_TOOL_ENABLED
  enabled: boolean
}

type SetIsFlipperSandboxEnabled = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_IS_FLIPPER_SANDBOX_ON
  enabled: boolean
}

type UpdateCallbackUrl = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.UPDATE_CALLBACK_URL
  callbackType: string
}

type SetIsLoading = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_IS_LOADING
  isLoading: boolean
}

type SetApiKeyVersionV3 = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_API_KEY_VERSION_V3
  isV3Merchant: boolean
}

type ResetApiTokenInfo = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.RESET_API_TOKEN_INFO
}

type SetApiToken = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_API_TOKEN
  apiTokenInfo: ApiTokenInfo
}

type SetCallbackUrls = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_CALLBACK_URLS
  callbackUrls: CallbackURLs
}

type FetchApiKey = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.FETCH_API_KEY
  apiVersion: API_VERSION.V3
  apiKeys: V3Key[]
}

type ClearApiKeys = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.CLEAR_API_KEYS
}

type ShowV3Signature = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SHOW_V3_SIGNATURE
  showSig: boolean
}

type SetV3Signature = {
  type: typeof DEVELOPER_TOOLS_ACTIONS.SET_V3_SIGNATURE
  signature: string
}

export type ActionTypes =
  | SetIsLoading
  | SetApiKeyVersionV3
  | SetApiToken
  | SetCallbackUrls
  | SetIsFlipperDeveloperToolEnabled
  | SetIsFlipperSandboxEnabled
  | GetInitialUrlV3
  | SetCallbackUrl
  | UpdateCallbackUrl
  | ResetApiTokenInfo
  | FetchApiKey
  | ClearApiKeys
  | ShowV3Signature
  | SetV3Signature

import { Reducer } from 'redux'
import {
  ActionTypes,
  VERIFICATION_ACTIONS,
  SCREENS,
  RESIDENCY_STATUS,
  ImmigrationStatus,
  ResidencyStatus,
  VerificationFlows,
  UserDetails,
  RetrievedDetails,
  MyInfoOnlyDetails,
  FormOptions,
} from './types'

const initialState = {
  userDetails: {
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    gender: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    idNumber: undefined,
    dateOfIssue: undefined,
    expiryDate: undefined,
    occupation: undefined,
    employmentSector: undefined,
    employer: undefined,
    expectedTransactionAmount: undefined,
    expectedTotalTransaction: undefined,
    expectedTransactionFrequency: undefined,
    sourceOfFunds: undefined,
  },
  myInfoOnlyDetails: {
    race: undefined,
    residentialStatus: undefined,
    aliasName: undefined,
    passStatusLastUpdated: undefined,
    error: undefined,
  },
  immigrationStatus: undefined,
  residencyStatus: undefined,
  isIdTypeSgPr: true,
  isResidencyNonSg: false,
  isInternationalOnboarding: false,
  flow: undefined,
  error: '',
  screen: undefined,
  kycRejectedReason: '',
  retrievedDetails: undefined,
  formOptions: {
    countries: [],
    nationalities: [],
    races: [],
    genders: [],
    occupations: [],
    employmentSectors: [],
    annualIncomes: [],
    expectedTransactionAmounts: [],
    expectedTotalTransactions: [],
    expectedTransactionFrequencies: [],
    sourcesOfFunds: [],
  },
}

type State = {
  userDetails: UserDetails
  immigrationStatus: ImmigrationStatus
  residencyStatus: ResidencyStatus
  isIdTypeSgPr: boolean
  isResidencyNonSg: boolean
  isInternationalOnboarding: boolean
  flow: VerificationFlows
  error: string
  screen?: SCREENS
  kycRejectedReason: string | { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  retrievedDetails?: RetrievedDetails
  myInfoOnlyDetails: MyInfoOnlyDetails
  formOptions: FormOptions
}

export const verification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_ACTIONS.SET_IMMIGRATION_STATUS: {
      return {
        ...state,
        immigrationStatus: action.status,
        isInternationalOnboarding: action.status !== 'sg_pr' && state.isResidencyNonSg,
      }
    }

    case VERIFICATION_ACTIONS.SET_RESIDENCY_STATUS: {
      const presetCountry =
        action.status === RESIDENCY_STATUS.LIVING_IN_SG
          ? {
              userDetails: {
                ...state.userDetails,
                country: 'SG',
              },
            }
          : {}
      return {
        ...state,
        residencyStatus: action.status,
        isResidencyNonSg: action.status === RESIDENCY_STATUS.NOT_LIVING_IN_SG,
        isInternationalOnboarding:
          state.immigrationStatus !== 'sg_pr' &&
          action.status === RESIDENCY_STATUS.NOT_LIVING_IN_SG,
        ...presetCountry,
      }
    }

    case VERIFICATION_ACTIONS.SET_FLOW: {
      return {
        ...state,
        flow: action.flow,
      }
    }

    case VERIFICATION_ACTIONS.SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.key]: action.value,
        },
      }
    }

    case VERIFICATION_ACTIONS.UPDATE_USER: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VERIFICATION_ACTIONS.SET_IS_ID_TYPE_SG_PR: {
      return {
        ...state,
        isIdTypeSgPr: action.isIdTypeSgPr,
      }
    }

    case VERIFICATION_ACTIONS.SET_SCREEN: {
      return {
        ...state,
        screen: action.screen,
      }
    }

    case VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.userDetails,
      }
    }

    case VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON: {
      return {
        ...state,
        kycRejectedReason: action.kycRejectedReason,
      }
    }

    case VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS: {
      return {
        ...state,
        retrievedDetails: action.retrievedDetails,
      }
    }

    case VERIFICATION_ACTIONS.SET_ERROR: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS: {
      return {
        ...state,
        myInfoOnlyDetails: action.myInfoOnlyDetails,
      }
    }

    case VERIFICATION_ACTIONS.SET_FORM_OPTIONS: {
      return {
        ...state,
        formOptions: action.formOptions,
      }
    }

    default:
      return state
  }
}

export enum FLIPPER_ACTIONS {
  FETCH_FLIPPER_ITEM_REQUESTED = 'FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_REQUESTED',
  FETCH_FLIPPER_ITEM_SUCCEEDED = 'FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_SUCCEEDED',
  FETCH_FLIPPER_ITEM_FAILED = 'FLIPPER_ACTIONS.FETCH_FLIPPER_ITEM_FAILED',
}

export const FLIPPER_KEYS = {
  USD_TRANSFER_IN: 'straitsx_usd_transfer_in_ui',
  USD_TRANSFER_OUT: 'straitsx_usd_transfer_out_ui',
  USD_ADD_BANK_ACCOUNT: 'straitsx_add_usd_bank_account_ui',

  USDC_ERC20_DEPOSIT: 'usdc_erc20_deposit',
  USDC_ERC20_WITHDRAWAL: 'usdc_erc20_withdrawal',
  USDT_ERC20_DEPOSIT: 'usdt_erc20_deposit',
  USDT_ERC20_WITHDRAWAL: 'usdt_erc20_withdrawal',

  USDC_SWAP: 'straitsx_swap_enable_usdc',

  APP_DASHBOARD_USDT: 'straitsx_usdt_app_dashboard',
  BIZ_DASHBOARD_USDT: 'straitsx_usdt_biz_dashboard',
  XUSD_DASHBOARD_ENABLE: 'straitsx_xusd_dashboard_enable',

  APP_DASHBOARD_SWAP: 'xsgd_swap_ui',
  BIZ_DASHBOARD_SWAP: 'straitsx_swap_biz_dashboard',
  SWAP_DISABLED_ACCURATE_FEE_CALCULATION: 'straitsx_swap_disabled_accurate_fee_calculation',
  USDT_USDC_SWAP_APP_DASHBOARD: 'straitsx_usdt_usdc_swap_app_dashboard',
  USDT_XUSD_SWAP_APP_DASHBOARD: 'straitsx_usdt_xusd_swap_app_dashboard',
  USDT_USDC_SWAP_BIZ_DASHBOARD: 'straitsx_usdt_usdc_swap_biz_dashboard',
  USDT_XUSD_SWAP_BIZ_DASHBOARD: 'straitsx_usdt_xusd_swap_biz_dashboard',

  XIDR_ZRC2_DEPOSIT: 'xidr_zrc2_deposit',
  XIDR_BANK_DELETION: 'xidr_bank_deletion',
  XIDR_BLOCKCHAIN_ADDRESS_DELETION: 'xidr_blockchain_address_deletion',

  // Verification
  SG_BIZ_MANUAL_ENHANCEMENT: 'straitsx_sg_biz_manual_enhancement',
  JUMIO_FLOW_FOR_SG_USER: 'straitsx_jumio_flow_for_sg_user',

  // Mint
  SG_MINT_DASHBOARD: 'straitsx_mint_dashboard',
  SG_TRANSACTION_TIMEZONE_CONTROL: 'straitsx_sg_transaction_timezone_control',
  ZRC_HTS_DIRECT_MINT_DISABLED: 'straitsx_zrc_hts_direct_mint_disabled',

  // OTC
  SG_OTC_FORM_APP_DASHBOARD: 'straitsx_otc_form_app_dashboard',
  SG_OTC_FORM_BIZ_DASHBOARD: 'straitsx_otc_form_biz_dashboard',
  SG_OTC_TRX_HISTORY_APP_DASHBOARD: 'straitsx_otc_trx_history_app_dashboard',
  SG_OTC_TRX_HISTORY_BIZ_DASHBOARD: 'straitsx_otc_trx_history_biz_dashboard',

  // MAS
  KILLSWITCH: 'straitsx_enable_killswitch',
  COOLING_DOWN: 'straitsx_enable_cooling_down',

  // MISC
  MANUAL_ADD_ADDRESS: 'straitsx_manual_add_address_ui',
  ASB_SANDBOX_UI: 'straitsx_asb_sandbox_ui',
  ASB_FE_TO_PASS_TOTAL_AMOUNT_FOR_STABLECOIN_WITHDRAWAL:
    'straitsx_asb_fe_to_pass_total_amount_for_stablecoin_withdrawal',
  WHITELISTING_IMPROVEMENT: 'whitelisting_improvement',
  SG_RECIPIENT_BANK_ACCOUNT_CHANGED_REMINDER:
    'straitsx_sg_recipient_bank_account_changed_reminder_ui',
  RENEW_DEPOSIT_ADDRESS: 'straitsx_renew_deposit_address',
  BITGO_DEPOSIT_ADDRESS_ROTATION_STAGING: 'straitsx_enable_new_bitgo_address_staging',
  BITGO_DEPOSIT_ADDRESS_ROTATION_PRODUCTION: 'straitsx_enable_new_bitgo_address_prod',
  DPT2_TNC: 'straitsx_dpt2_tnc',
}

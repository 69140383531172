import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { internalTools } from './internalTools/reducer'
import { route } from './route/reducer'
import { xidrTour } from './xidrTour/reducer'
import { xidrLandingPage } from './xidrLandingPage/reducer'
import { xidrDeposit } from './xidrDeposit/reducer'
import { xidrTransactionDetails } from './xidrTransactionDetails/reducer'
import { xidrUserInfo } from './xidrUserInfo/reducer'
import { xidrAccount } from './xidrAccount/reducer'
import { xidrTransactions } from './xidrTransactions/reducer'
import { xidrUserVerification } from './xidrUserVerification/reducer'
import { xidrMint } from './xidrMint/reducer'
import { flippers } from '~/store/flippers/reducer'

export const rootReducer = combineReducers({
  internalTools,
  route,
  xidrLandingPage,
  xidrDeposit,
  xidrTour,
  xidrTransactionDetails,
  xidrUserInfo,
  xidrAccount,
  xidrTransactions,
  flippers,
  xidrUserVerification,
  xidrMint,
})

const middlewares = [thunk]
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)))

export default store

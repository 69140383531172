import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import styled from '@emotion/styled'
import Spin from 'antd/es/spin'
import 'antd/es/spin/style/css'
import { Bell, BellFilled } from '@xfers/design-system/es/icons'
import { BadgeIcon, H3, H4, P1, P1Bold, SmallP, DESKTOP_BREAKPOINT } from '@xfers/design-system'
import { ELEVATIONS } from '~/common/elevation'
import { post, ENDPOINTS } from '~/api'
import StraitsXEmptyData from '~/components/StraitsXEmptyData'

type NotificationType = {
  notifications: {
    data: {
      id: number
      title: string
      message: string
      created_at: string
      is_read: number
      external_link: string
    }[]
    unreadCount: number
    isLoading: boolean
  }
}
export default function({ notifications }: NotificationType) {
  const { data, unreadCount, isLoading } = notifications

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
  const [isNotificationsCleared, setIsNotificationsCleared] = useState(false)

  const notificationMenu = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    if (notificationMenu.current && !notificationMenu.current.contains(event.target)) {
      setIsNotificationsOpen(false)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const notificationCards = data.map(notification => {
    const momentDate = notification.created_at
      ? moment(notification.created_at).format('D MMM YYYY, HH:mm')
      : '-'
    const readStatus = notification.is_read ? 'default' : 'primary'

    return (
      <NotificationBorder key={notification.id}>
        <NotificationBox
          href={notification.external_link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div style={{ paddingTop: '10px' }}>
            <BadgeIcon type={readStatus} iconWidth={15} icon={<Bell />} />
            <ReadCircle readStatus={notification.is_read} />
          </div>
          <div style={{ width: '355px', paddingLeft: '10px' }}>
            <NotificationHeader>{notification.title}</NotificationHeader>
            <NotificationBody>{notification.message}</NotificationBody>
            <NotificationTime>{momentDate}</NotificationTime>
          </div>
        </NotificationBox>
      </NotificationBorder>
    )
  })

  return (
    <Container>
      {!isNotificationsOpen && (
        <NotificationInfo
          onClick={() => {
            setIsNotificationsOpen(true)

            // check if notifications have been previously cleared for this cycle, then post directly
            if (data.length > 0 && !isNotificationsCleared) {
              post(ENDPOINTS.API_XFERS_USER_MESSAGE_ACKNOWLEDGMENTS, { message_id: data[0].id })
              setIsNotificationsCleared(true)
            }
          }}
        >
          <GreyCircle style={{ background: 'rgba(0, 92, 55, 0.5)' }}>
            <BellFilled color={'#FFFFFF'} style={{ width: '16px', height: '16px' }} />
          </GreyCircle>
          {unreadCount > 0 && <UnreadCount>{unreadCount}</UnreadCount>}
        </NotificationInfo>
      )}
      {isNotificationsOpen && (
        <>
          <NotificationInfo onClick={() => setIsNotificationsOpen(false)}>
            <GreyCircle style={{ background: 'rgba(0, 92, 55, 0.5)' }}>
              <BellFilled color={'#FFFFFF'} style={{ width: '16px', height: '16px' }} />
            </GreyCircle>
          </NotificationInfo>
          <NotificationMenu ref={notificationMenu}>
            <MenuHeader>Notifications</MenuHeader>
            <MenuBody>
              {isLoading && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                >
                  <Spin />
                </div>
              )}
              {!isLoading && data.length === 0 && (
                <StraitsXEmptyData title="No notifications found" />
              )}
              {!isLoading && data.length > 0 && notificationCards}
            </MenuBody>
            <MenuFooter />
          </NotificationMenu>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const NotificationInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 72px;
  padding-left: 15px;
  padding-right: 15px;
  &:hover {
    transition: 0.3s ease-out;
    background: rgba(0, 211, 126, 0.1);
  }
`

const GreyCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
`

const UnreadCount = styled(H4)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #00d37e;
  border: 2px solid #ffffff;
  border-radius: 50%;
  top: 12px;
  left: 32px;
  color: #ffffff;
`

const ReadCircle = styled.div<{ readStatus: number }>`
  position: relative;
  display: flex;
  z-index: 10;
  top: -25px;
  left: 25px;

  width: 10px;
  height: 10px;
  background: ${props => (props.readStatus ? 'transparent' : '#e22726')};
  border: ${props => (props.readStatus ? 'transparent' : '1px solid #ffffff')};
  border-radius: 50%;
`

const NotificationMenu = styled(P1)`
  padding-left: 5px;
  padding-right: 5px;
  z-index: ${ELEVATIONS.MENUS_AND_SUB_MENUS};
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(149, 149, 149, 0.25);
  border-radius: 5px;
  transition-timing-function: ease;
  font-weight: 500;

  position: absolute;
  width: 343px;
  top: 72px;
  right: -65px;
  max-height: 500px;

  ${DESKTOP_BREAKPOINT} {
    width: 450px;
    right: -10px;
    max-height: 650px;
  }
`

const MenuHeader = styled(H3)`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
  user-select: none;
`

const MenuBody = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 10px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  max-height: 400px;

  ${DESKTOP_BREAKPOINT} {
    max-height: 550px;
  }
`

const MenuFooter = styled.div`
  width: 100%;
  height: 45px;
  border-top: 1px solid #e8e8e8;
  user-select: none;
`

const NotificationBorder = styled.div`
  height: 145px;
  width: 100%;
  border-bottom: 0.5px solid #e8e8e8;
  padding-top: 10px;
  padding-bottom: 10px;
`

const NotificationBox = styled.a`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 211, 126, 0.1);
  }
`

const NotificationHeader = styled(P1Bold)`
  margin-bottom: 5px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const NotificationBody = styled(P1)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const NotificationTime = styled(SmallP)`
  margin-top: 5px;

  color: #777777;
`

import { Reducer } from 'redux'
import {
  ActionTypes,
  VERIFICATION_ACTIONS,
  KYC_TYPE,
  SCREENS,
  ResidentStatus,
  SgVerificationFlows,
  UserDetails,
  RetrievedDetails,
  MyInfoOnlyDetails,
  FormOptions,
  IkycRetrievedDetails,
  IkycVerificationOptions,
} from './types'

const initialState: State = {
  // shared data
  kycType: null,
  screen: undefined,
  userDetails: {
    fullName: undefined,
    dateOfBirth: undefined,
    countryOfBirth: undefined,
    nationality: undefined,
    gender: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    country: undefined,
    city: undefined,
    postalCode: undefined,
    idNumber: undefined,
    dateOfIssue: undefined,
    expiryDate: undefined,
    occupation: undefined,
    employmentSector: undefined,
    employer: undefined,
    expectedTransactionAmount: undefined,
    expectedTotalTransaction: undefined,
    expectedTransactionFrequency: undefined,
    purposeOfUsing: undefined,
    investmentTradingExperience: undefined,
    cryptoExperience: undefined,
    sourceOfWealth: undefined,
    sourceOfFunds: undefined,
  },
  formOptions: {
    countries: [],
    nationalities: [],
    races: [],
    genders: [],
    occupations: [],
    employmentSectors: [],
    annualIncomes: [],
    expectedTransactionAmounts: [],
    expectedTotalTransactions: [],
    expectedTransactionFrequencies: [],
    sourcesOfFunds: [],
  },
  error: '',
  kycRejectedReason: '',

  // International verification data
  ikycRetrievedDetails: undefined,
  ikycVerificationOptions: {
    isInitiated: false,
    error: undefined,
  },

  // SG verification data
  sgVerificationFlow: undefined,
  isResident: true,
  withAddress: false,
  myInfoOnlyDetails: {
    race: undefined,
    residentialStatus: undefined,
    aliasName: undefined,
    passStatusLastUpdated: undefined,
    error: undefined,
    hasValidExpiryDate: undefined,
  },
  residentStatus: undefined,
  retrievedDetails: undefined,

  // won't send to backend, just a normal state
  // to define the routing to SG flow or International flow
  countryOfResidence: undefined,
  sgVerificationMethod: undefined,
}

type State = {
  // shared data
  kycType: KYC_TYPE | null
  screen?: SCREENS
  userDetails: UserDetails
  formOptions: FormOptions
  kycRejectedReason: string | { [key: string]: any } | string[] // eslint-disable-line @typescript-eslint/no-explicit-any
  error: string

  // International verification data
  ikycRetrievedDetails?: IkycRetrievedDetails
  ikycVerificationOptions: {
    isInitiated: boolean
  } & IkycVerificationOptions

  // SG verification data
  sgVerificationFlow: SgVerificationFlows
  isResident: boolean
  withAddress: boolean
  myInfoOnlyDetails: MyInfoOnlyDetails
  residentStatus: ResidentStatus
  retrievedDetails?: RetrievedDetails

  // won't send to backend, just a normal state
  // to define the routing to SG flow or International flow
  countryOfResidence?: string
  sgVerificationMethod?: string
}

export const verification: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case VERIFICATION_ACTIONS.SET_RESIDENT_STATUS: {
      return {
        ...state,
        residentStatus: action.status,
      }
    }

    case VERIFICATION_ACTIONS.SET_FLOW: {
      return {
        ...state,
        sgVerificationFlow: action.sgVerificationFlow,
      }
    }

    case VERIFICATION_ACTIONS.SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.key]: action.value,
        },
      }
    }

    case VERIFICATION_ACTIONS.UPDATE_USER: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VERIFICATION_ACTIONS.SET_IS_RESIDENT: {
      let newRetrievedDetails

      if (state.retrievedDetails) {
        newRetrievedDetails = {
          ...state.retrievedDetails,
          nricType: action.isResident ? 'nric' : 'fin',
        } as RetrievedDetails
      }

      return {
        ...state,
        isResident: action.isResident,
        retrievedDetails: newRetrievedDetails,
      }
    }

    case VERIFICATION_ACTIONS.SET_WITH_ADDRESS: {
      return {
        ...state,
        withAddress: action.withAddress,
      }
    }

    case VERIFICATION_ACTIONS.SET_SCREEN: {
      return {
        ...state,
        screen: action.screen,
      }
    }

    case VERIFICATION_ACTIONS.SET_MULTIPLE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.userDetails,
      }
    }

    case VERIFICATION_ACTIONS.SET_KYC_REJECTED_REASON: {
      return {
        ...state,
        kycRejectedReason: action.kycRejectedReason,
      }
    }

    case VERIFICATION_ACTIONS.SET_RETRIEVED_DETAILS: {
      return {
        ...state,
        retrievedDetails: action.retrievedDetails,
      }
    }

    case VERIFICATION_ACTIONS.SET_ERROR: {
      const errorMsg =
        action.error === 'Same request is still processing'
          ? 'Please try again in a minute as we are still processing.'
          : action.error
      return {
        ...state,
        error: errorMsg,
      }
    }

    case VERIFICATION_ACTIONS.SET_MY_INFO_ONLY_DETAILS: {
      return {
        ...state,
        myInfoOnlyDetails: {
          ...state.myInfoOnlyDetails,
          ...action.myInfoOnlyDetails,
        },
      }
    }

    case VERIFICATION_ACTIONS.SET_FORM_OPTIONS: {
      return {
        ...state,
        formOptions: action.formOptions,
      }
    }

    case VERIFICATION_ACTIONS.SET_IKYC_VERIFICATION_OPTIONS: {
      return {
        ...state,
        ikycVerificationOptions: {
          isInitiated: true,
          ...action.ikycVerificationOptions,
        },
      }
    }

    case VERIFICATION_ACTIONS.SET_IKYC_RETRIEVED_DETAILS: {
      return {
        ...state,
        ikycRetrievedDetails: {
          ...action.ikycRetrievedDetails,
        },
      }
    }

    case VERIFICATION_ACTIONS.SET_KYC_TYPE: {
      return {
        ...state,
        kycType: action.kycType,
      }
    }

    case VERIFICATION_ACTIONS.SET_COUNTRY_OF_RESIDENCE: {
      return {
        ...state,
        countryOfResidence: action.countryOfResidence,
      }
    }

    case VERIFICATION_ACTIONS.SET_SG_VERIFICATION_METHOD: {
      return {
        ...state,
        sgVerificationMethod: action.sgVerificationMethod,
      }
    }

    default:
      return state
  }
}

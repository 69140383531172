/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { TooltipRenderProps } from 'react-joyride'

import { P1, FONT_WEIGHT, Button, DESKTOP_BREAKPOINT, straitsXTheme } from '@xfers/design-system'

import { RootState } from '~/app/store/types'
import { incrementStepIndex, endTour } from '~/app/store/tour/actions'
import { redirect } from '~/app/store/route/actions'
import { PATHS } from '~/app/routes/paths'
import { enableScroll } from '~/helpers/controlScroll'
import { setTab } from '~/app/store/landingPage/actions'

export default function({ index, step, tooltipProps, size, isLastStep }: TooltipRenderProps) {
  const { dashboardBanners, isDashboardBannerManagementEnabled } = useSelector(
    (state: RootState) => state.landingPage
  )
  const { isAccountDetailListInitiated } = useSelector((state: RootState) => state.accounts)
  const { latestPersonalTransactions } = useSelector((state: RootState) => state.transactions)

  const dispatch = useDispatch()

  const currentStepNum = index + 1

  const handleSkipButtonClick = () => {
    dispatch(endTour())
    enableScroll()
  }

  const handleNextButtonClick = () => {
    switch (currentStepNum) {
      case 3:
        dispatch(redirect(PATHS.LANDING_PAGE))
        dispatch(incrementStepIndex())
        break
      case 4:
        // the tour UI for step 5 will only render properly if the UI related to home page is loaded completely
        if (isAccountDetailListInitiated !== null && latestPersonalTransactions !== null) {
          // if dashboard banner is enabled, only increment step after dashboard banners are retrieved
          if (isDashboardBannerManagementEnabled && dashboardBanners !== null) {
            dispatch(incrementStepIndex())
          } else if (!isDashboardBannerManagementEnabled) {
            dispatch(incrementStepIndex())
          }
        }
        break
      case 5:
        dispatch(setTab('withdraw'))
        dispatch(incrementStepIndex())
        break
      case 6:
        dispatch(setTab('swap'))
        dispatch(incrementStepIndex())
        break
      case size:
        dispatch(endTour())
        enableScroll()
        break
      default:
        dispatch(incrementStepIndex())
    }
  }

  return (
    <Container {...tooltipProps}>
      <StepText>{`Step ${currentStepNum} of ${size}`}</StepText>
      <MainText>{step.content}</MainText>
      <Row>
        <SkipButton type="tertiary" onClick={handleSkipButtonClick} isLastStep={isLastStep}>
          Skip
        </SkipButton>
        <StyledButton
          type="tertiary"
          style={{
            marginLeft: '20px',
          }}
          onClick={handleNextButtonClick}
        >
          {isLastStep ? 'Finish' : 'Next'}
        </StyledButton>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${straitsXTheme.brand.action.hover};
  box-shadow: 0px 4px 15px rgba(149, 149, 149, 0.25);
  border-radius: 5px;
  max-width: 295px;

  ${DESKTOP_BREAKPOINT} {
    max-width: 260px;
  }
`

const StepText = styled(P1)`
  color: #d3d7de;
`
const MainText = styled(P1)`
  margin: 10px 0px;
  font-weight: ${FONT_WEIGHT.REGULAR};
  color: #ffffff;
`

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
`

const StyledButton = styled(Button)`
  &.ant-btn {
    font-size: 14px !important;
    color: #ffffff !important;
  }
`

const SkipButton = styled(Button)<{ isLastStep: boolean }>`
  &.ant-btn {
    font-size: 14px !important;
    color: #ffffff !important;
    display: ${props => props.isLastStep && 'none'};
  }
`

import { createSelector } from 'reselect'
import { RetrievedDetails, KYC_TYPE } from './types'
import { RootState } from '~/app/store/types'

const getIdNumberFromString = (idNumber: string) => idNumber
const getIsMyInfoFlow = (retrievedDetails: RetrievedDetails) => retrievedDetails.isMyInfoFlow
const getIsElderly = (retrievedDetails: RetrievedDetails) => retrievedDetails.isElderly

const getFullName = (retrievedDetails: RetrievedDetails) => retrievedDetails.fullName
const getDateOfBirth = (retrievedDetails: RetrievedDetails) => retrievedDetails.dateOfBirth
const getCountryOfBirth = (retrievedDetails: RetrievedDetails) => retrievedDetails.countryOfBirth
const getNationality = (retrievedDetails: RetrievedDetails) => retrievedDetails.nationality
const getGender = (retrievedDetails: RetrievedDetails) => retrievedDetails.gender
const getAddressLine1 = (retrievedDetails: RetrievedDetails) => retrievedDetails.addressLine1
const getCountry = (retrievedDetails: RetrievedDetails) => retrievedDetails.country
const getCity = (retrievedDetails: RetrievedDetails) => retrievedDetails.city
const getPostalCode = (retrievedDetails: RetrievedDetails) => retrievedDetails.postalCode
const getIdNumber = (retrievedDetails: RetrievedDetails) => retrievedDetails.idNumber
const getNricType = (retrievedDetails: RetrievedDetails) => retrievedDetails.nricType
const getExpiryDate = (retrievedDetails: RetrievedDetails) => retrievedDetails.expiryDate
const getProofOfAddressUrl = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.proofOfAddressUrl
const getProofOfAddressUploaded = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.proofOfAddressUploaded
const getSelfieUrl = (retrievedDetails: RetrievedDetails) => retrievedDetails.selfieUrl
const getSelfieUploaded = (retrievedDetails: RetrievedDetails) => retrievedDetails.selfieUploaded
const getIdFrontUrl = (retrievedDetails: RetrievedDetails) => retrievedDetails.idFrontUrl
const getIdFrontUploaded = (retrievedDetails: RetrievedDetails) => retrievedDetails.idFrontUploaded
const getIdBackUrl = (retrievedDetails: RetrievedDetails) => retrievedDetails.idBackUrl
const getIdBackUploaded = (retrievedDetails: RetrievedDetails) => retrievedDetails.idBackUploaded
const getBankAccountProofUrl = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.bankAccountProofUrl
const getBankAccountProofUploaded = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.bankAccountProofUploaded

const getOccupation = (retrievedDetails: RetrievedDetails) => retrievedDetails.occupation
const getEmploymentSector = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.employmentSector
const getEmployer = (retrievedDetails: RetrievedDetails) => retrievedDetails.employer
const getAnnualIncomeRange = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.annualIncomeRange

const getExpectedTransactionAmount = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.expectedTransactionAmount
const getExpectedTotalTransaction = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.expectedTotalTransaction
const getExpectedTransactionFrequency = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.expectedTransactionFrequency
const getPurposeOfUsing = (retrievedDetails: RetrievedDetails) => retrievedDetails.purposeOfUsing
const getInvestmentTradingExperience = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.investmentTradingExperience
const getCryptoExperience = (retrievedDetails: RetrievedDetails) =>
  retrievedDetails.cryptoExperience
const getSourceOfWealth = (retrievedDetails: RetrievedDetails) => retrievedDetails.sourceOfWealth
const getSourceOfFunds = (retrievedDetails: RetrievedDetails) => retrievedDetails.sourceOfFunds

export const getIsResident = createSelector([getIdNumberFromString], idNumber => {
  if (!idNumber) return true
  const firstLetter = idNumber
    .toString()
    .charAt(0)
    .toUpperCase()

  return firstLetter === 'S' || firstLetter === 'T'
})

export const getFlow = createSelector([getIsMyInfoFlow], isMyInfoFlow => {
  return isMyInfoFlow ? 'my_info' : 'manual'
})

export const getResidentStatus = createSelector([getNricType], nricType => {
  if (nricType === undefined || nricType === 'nric') {
    return 'sg_pr'
  } else {
    return 'pass_holder'
  }
})

export const fakeOccupations: Array<string> = ['student', 'housewife']

export const getPersonalDetailsCompleted = (retrievedDetails: RetrievedDetails) =>
  createSelector(
    [
      getFullName,
      getDateOfBirth,
      getCountryOfBirth,
      getNationality,
      getGender,
      getAddressLine1,
      getCountry,
      getCity,
      getPostalCode,
      getIdNumber,
      getNricType,
      getExpiryDate,
      getOccupation,
      getEmploymentSector,
      getEmployer,
      getAnnualIncomeRange,
    ],
    (
      fullName,
      dateOfBirth,
      countryOfBirth,
      nationality,
      gender,
      addressLine1,
      country,
      city,
      postalCode,
      idNumber,
      nricType,
      expiryDate,
      occupation,
      employmentSector,
      employer,
      annualIncomeRange
    ) => {
      const completed =
        !!fullName &&
        !!dateOfBirth &&
        !!countryOfBirth &&
        !!nationality &&
        !!gender &&
        !!addressLine1 &&
        !!country &&
        !!city &&
        !!postalCode &&
        !!idNumber &&
        (nricType === 'fin' ? !!expiryDate : true) &&
        !!occupation

      const hasFakeOccupation: boolean = fakeOccupations.includes(occupation || '')
      // if occupation selected is "STUDENT" or "HOUSEWIFE", allow them to proceed without "employer" etc
      const occupationDetails = hasFakeOccupation
        ? true
        : !!employmentSector && !!employer && !!annualIncomeRange

      return completed && occupationDetails
    }
  )(retrievedDetails)

export const getMyInfoPersonalDetailsCompletedNric = createSelector(
  [
    getFullName,
    getDateOfBirth,
    getCountryOfBirth,
    getNationality,
    getGender,
    getAddressLine1,
    getCountry,
    getCity,
    getPostalCode,
    getIdNumber,
  ],
  (
    fullName,
    dateOfBirth,
    countryOfBirth,
    nationality,
    gender,
    addressLine1,
    country,
    city,
    postalCode,
    idNumber
  ) => {
    return (
      !!fullName &&
      !!dateOfBirth &&
      !!countryOfBirth &&
      !!nationality &&
      !!gender &&
      !!addressLine1 &&
      !!country &&
      !!city &&
      !!postalCode &&
      !!idNumber
    )
  }
)

export const getMyInfoPersonalDetailsCompletedFin = createSelector(
  [getFullName, getDateOfBirth, getCountryOfBirth, getNationality, getGender, getIdNumber],
  (fullName, dateOfBirth, countryOfBirth, nationality, gender, idNumber) => {
    // for fin users, we do not check for address/postal code, country, and city since MyInfo may not return values for these. we will be asking users to input this later
    return (
      !!fullName && !!dateOfBirth && !!countryOfBirth && !!nationality && !!gender && !!idNumber
    )
  }
)

export const getDeclarationsCompleted = createSelector(
  [
    getExpectedTransactionAmount,
    getExpectedTotalTransaction,
    getExpectedTransactionFrequency,
    getIsElderly,
    getPurposeOfUsing,
    getInvestmentTradingExperience,
    getCryptoExperience,
    getSourceOfWealth,
    getBankAccountProofUrl,
    getBankAccountProofUploaded,
    getAnnualIncomeRange,
    getSourceOfFunds,
  ],
  (
    expectedTransactionAmount,
    expectedTotalTransaction,
    expectedTransactionFrequency,
    isElderly,
    purposeOfUsing,
    investmentTradingExperience,
    cryptoExperience,
    sourceOfWealth,
    bankAccountProofUrl,
    bankAccountProofUploaded,
    annualIncomeRange,
    sourceOfFunds
  ) => {
    return (
      !!expectedTransactionAmount &&
      !!expectedTotalTransaction &&
      !!expectedTransactionFrequency &&
      !!sourceOfFunds &&
      !!annualIncomeRange &&
      (isElderly
        ? !!purposeOfUsing &&
          !!investmentTradingExperience &&
          !!cryptoExperience &&
          !!sourceOfWealth &&
          !!bankAccountProofUrl &&
          !!bankAccountProofUploaded
        : true)
    )
  }
)

export const getProofOfAddressCompleted = createSelector(
  [getProofOfAddressUrl, getProofOfAddressUploaded],
  (proofOfAddressUrl, proofOfAddressUploaded) => {
    return !!proofOfAddressUrl && !!proofOfAddressUploaded
  }
)

export const getIdFrontAndBackCompleted = createSelector(
  [getIdFrontUrl, getIdFrontUploaded, getIdBackUrl, getIdBackUploaded],
  (idFrontUrl, idFrontUploaded, idBackUrl, idBackUploaded) => {
    return !!idFrontUrl && !!idFrontUploaded && !!idBackUrl && !!idBackUploaded
  }
)

export const getSelfieCompleted = createSelector(
  [getSelfieUrl, getSelfieUploaded],
  (selfieUrl, selfieUploaded) => {
    return !!selfieUrl && !!selfieUploaded
  }
)

export const selectKycType = (state: RootState) => state.verification.kycType
export const selectIsI18nUser = createSelector(
  [selectKycType],
  kycType => kycType === KYC_TYPE.INTERNATIONAL
)
export const selectIsSgUser = createSelector([selectKycType], kycType => kycType === KYC_TYPE.SG)

import React from 'react'
import { H3, H2 } from '@xfers/design-system'
import styled from '@emotion/styled'
import ReactHtmlParser from 'react-html-parser'; 
import { GeneralTermsAndConditions } from './Data/GeneralTermAndConditions/index'
import { RiskWarningsNoticeDisclosures } from './Data/RiskWarningsNoticeDisclosures/index'
import { StraitsXAccounts } from './Data/StraitsXAccounts/index'
import { StraitsXTokens } from './Data/StraitsXTokens/index'
import { PurchasingAndRedeemingTokens } from './Data/PurchasingAndRedeemingTokens/index'
import { StraitsXEMoney } from './Data/StraitsXEMoney/index'
import { MakingPaymentsTransfersOrWithdrawals } from './Data/MakingPaymentsTransfersOrWithdrawals/index'
import { OTCSwapsServices } from './Data/OTCSwapsServices/index'
import { StraitsXEarn } from './Data/StraitsXEarn/index'
import { StraitsXDataPrivacyPolicy } from './Data/StraitsXDataPrivacyPolicy/index'
import { Article, Notes, Section, SubArticle } from './types'

function TermsOfService() {
  return (
    <Container>
      <CustomH2>GENERAL TERMS AND CONDITIONS</CustomH2>
      {
        GeneralTermsAndConditions.notes &&
        <NotesSectionContainer>
          {
            Object.keys(GeneralTermsAndConditions.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(GeneralTermsAndConditions.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {GeneralTermsAndConditions.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>RISK WARNINGS, NOTICE & DISCLOSURES</CustomH2>
      {
        RiskWarningsNoticeDisclosures.notes &&
        <NotesSectionContainer>
          {
            Object.keys(RiskWarningsNoticeDisclosures.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(RiskWarningsNoticeDisclosures.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {RiskWarningsNoticeDisclosures.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>STRAITSX ACCOUNTS</CustomH2>
      {
        StraitsXAccounts.notes &&
        <NotesSectionContainer>
          {
            Object.keys(StraitsXAccounts.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(StraitsXAccounts.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {StraitsXAccounts.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>STRAITSX TOKENS</CustomH2>
      {
        StraitsXTokens.notes &&
        <NotesSectionContainer>
          {
            Object.keys(StraitsXTokens.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(StraitsXTokens.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {StraitsXTokens.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>PURCHASING AND REDEEMING STRAITSX TOKENS AND NON-STRAITSX TOKENS</CustomH2>
      {
        PurchasingAndRedeemingTokens.notes &&
        <NotesSectionContainer>
          {
            Object.keys(PurchasingAndRedeemingTokens.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(PurchasingAndRedeemingTokens.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {PurchasingAndRedeemingTokens.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}


      <CustomH2>STRAITSX E-MONEY</CustomH2>
      {
        StraitsXEMoney.notes &&
        <NotesSectionContainer>
          {
            Object.keys(StraitsXEMoney.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(StraitsXEMoney.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {StraitsXEMoney.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>MAKING PAYMENTS, TRANSFERS OR WITHDRAWALS</CustomH2>
      {
        MakingPaymentsTransfersOrWithdrawals.notes &&
        <NotesSectionContainer>
          {
            Object.keys(MakingPaymentsTransfersOrWithdrawals.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(MakingPaymentsTransfersOrWithdrawals.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {MakingPaymentsTransfersOrWithdrawals.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>OTC & SWAPS SERVICES</CustomH2>
      {
        OTCSwapsServices.notes &&
        <NotesSectionContainer>
          {
            Object.keys(OTCSwapsServices.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(OTCSwapsServices.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {OTCSwapsServices.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}

      <CustomH2>STRAITSX EARN</CustomH2>
      {
        StraitsXEarn.notes &&
        <NotesSectionContainer>
          {
            Object.keys(StraitsXEarn.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(StraitsXEarn.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {StraitsXEarn.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}


      <CustomH2>STRAITSX DATA PRIVACY POLICY</CustomH2>
      {
        StraitsXDataPrivacyPolicy.notes &&
        <NotesSectionContainer>
          {
            Object.keys(StraitsXDataPrivacyPolicy.notes ?? {}).map((key) => (
              <ArticleFormatting key={key}>{ReactHtmlParser(StraitsXDataPrivacyPolicy.notes?.[key as keyof Notes] || "")}</ArticleFormatting>
            ))
          }
        </NotesSectionContainer>
      }
      {StraitsXDataPrivacyPolicy.sections.map((section: Section) => (
        <SectionContainer key={section.title}>
          <H3>{ReactHtmlParser(section.title)}</H3>
          {section.articles.map((article: Article) => (
            <ArticleComponent key={article.article} article={article} />
          ))}
        </SectionContainer>
      ))}
    </Container>
  )
}

const ArticleComponent = ({ article }: { article: Article }) => (
  <ArticleContainer>
    {article.title && <SubTitle>{ReactHtmlParser(article.title)}</SubTitle>}
    <ArticleFormatting>{ReactHtmlParser(article.article)}</ArticleFormatting>
    {article.subArticles?.map((subArticle: SubArticle) => (
      <SubArticleComponent key={subArticle.article} subArticle={subArticle} />
    ))}
  </ArticleContainer>
)

const SubArticleComponent = ({ subArticle }: { subArticle: SubArticle }) => (
  <SubArticleContainer>
    {subArticle.title && <SubTitle>{ReactHtmlParser(subArticle.title)}</SubTitle>}
    <SubArticleFormatting>{ReactHtmlParser(subArticle.article)}</SubArticleFormatting>
    {subArticle.subArticles?.map((subSubArticle: SubArticle) => (
      <SubSubArticleComponent key={subSubArticle.article} subSubArticle={subSubArticle} />
    ))}
  </SubArticleContainer>
)

const SubSubArticleComponent = ({ subSubArticle }: { subSubArticle: SubArticle }) => (
  <SubSubArticleContainer>
    {subSubArticle.title && <SubTitle>{ReactHtmlParser(subSubArticle.title)}</SubTitle>}
    <SubSubArticleFormatting>{ReactHtmlParser(subSubArticle.article)}</SubSubArticleFormatting>
  </SubSubArticleContainer>
)

const Container = styled.div`
  min-height: 400px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 22px;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const SubArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 20px;
`

const SubSubArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 40px;
`

const ArticleFormatting = styled.p`
  font-weight: 400;
  line-height: 22px;
  font-size: 15px;
`

const SubArticleFormatting = styled.p`
  font-weight: 400;
  line-height: 22px;
  font-size: 15px;
`

const SubSubArticleFormatting = styled.p`
  font-weight: 400;
  line-height: 22px;
  font-size: 15px;
`

const SubTitle = styled.p`
  font-weight: 400;
  line-height: 22px;
  font-size: 15px;
  text-decoration: underline;
`

const CustomH2 = styled(H2)`
  text-align: center;
  text-decoration: underline;
`

const NotesSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(254, 243, 231, 1);
  padding: 10px;
  border-radius: 5px;
`

export default TermsOfService

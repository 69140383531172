import { Moment, CURRENCY, TransactionStatus } from '~/types'

export enum CSV_FORMAT_VERSION {
  V3 = 'V3',
  V4 = 'V4',
}

export enum TRANSACTIONS_TAB_KEY {
  XFERS_ACCOUNT = 'xfers_account',
  XFERS_WALLET = 'xfers_wallet',
}

export enum TRANSACTION_TYPE {
  BANK_TRANSFER_IN = 'Deposit',
  BANK_TRANSFER_OUT = 'Withdrawal',
  BLOCKCHAIN_TRANSFER_IN = 'Stablecoin Deposit',
  BLOCKCHAIN_TRANSFER_OUT = 'Stablecoin Withdrawal',
  MINT = 'Mint',
  STABLECOIN_SWAP = 'Stablecoin Swap',
  PAYMENT_TRANSFER = 'Payment/Transfer',
  EARN_ADD_FUNDS = 'Add funds',
  EARN_REMOVE_FUNDS = 'Remove funds',
  OTC = 'Otc',
}

export enum EXPORT_MODE {
  FUNDING,
  SWAP,
  ALL,
}

export const TRANSACTION_TYPE_TITLE: Record<string, string> = {
  [TRANSACTION_TYPE.BANK_TRANSFER_IN]: 'Bank Transfer In',
  [TRANSACTION_TYPE.BANK_TRANSFER_OUT]: 'Bank Transfer Out',
  [TRANSACTION_TYPE.BLOCKCHAIN_TRANSFER_IN]: 'Blockchain Transfer In',
  [TRANSACTION_TYPE.BLOCKCHAIN_TRANSFER_OUT]: 'Blockchain Transfer Out',
  [TRANSACTION_TYPE.MINT]: 'Mint',
  [TRANSACTION_TYPE.STABLECOIN_SWAP]: 'Swap',
  [TRANSACTION_TYPE.EARN_ADD_FUNDS]: 'Add funds',
  [TRANSACTION_TYPE.EARN_REMOVE_FUNDS]: 'Remove funds',
  [TRANSACTION_TYPE.OTC]: 'OTC Trade',
  [TRANSACTION_TYPE.PAYMENT_TRANSFER]: 'Payment/Transfer',
}

export enum TRANSACTION_TYPE_TAB {
  FUNDING = 'funding',
  SWAP = 'swap',
  OTC = 'otc',
}

export enum FUNDING_TRANSACTION_TYPE_TAB {
  ALL_FUNDING = 'all_funding',
  ACTION_NEEDED = 'action_needed',
}

export type TransactionAmount = {
  prefix: string
  postfix: string
}

export type TransactionDescription = Record<string, string>

export type Transaction = {
  key: number
  amount: TransactionAmount
  transactionId: string
  createdAt: string
  method: string
  transactionStatus: TransactionStatus
  transactionType: TRANSACTION_TYPE
  description: TransactionDescription
  fee: string
  netAmount: TransactionAmount
  currency: { source: string; destination: string }
  tax?: string
  accountHolderName?: string
  transactionHash?: string | null
  transactionHashUrl?: string | null
  blockedReasons: string | null
  network: string | null
  isDeclarationRequired: boolean | null
}

export type FilterOptions = {
  currencySymbols: Array<{ label: string; value: CURRENCY }>
  transactionTypes: Array<{ label: string; value: string }>
  transactionStatuses: Array<{ label: string; value: string }>
  sortTypes: Array<{ label: string; value: string }>
  accountTypes: Array<{ label: string; value: string }> | undefined
}

export type FilterValues = {
  currencySymbol: CURRENCY | null
  startDate: Moment | string
  endDate: Moment | string
  transactionStatuses: string[]
  transactionTypes: string[]
  sortType: string
  accountIds: number[]
  currentPage: number
  pageLimit: number
  totalCount: number
  searchString: string
  contractsExist: boolean
  transactions: Transaction[] | undefined
  isTxnLoading: boolean
  isPaginationLoading: boolean
  isTravelRule: boolean
  [key: string]: FilterValuesObject[keyof FilterValuesObject]
}

export type FilterValuesObject = {
  [filterKey: string]:
    | Moment
    | string[]
    | number[]
    | string
    | number
    | Transaction[]
    | boolean
    | undefined
    | CURRENCY
    | null
}

export enum STRAITSX_TRANSACTIONS_ACTIONS {
  SET_IS_TXN_LOADING = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING',
  SET_IS_FILTER_LOADING = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING',
  SET_IS_PAGINATION_LOADING = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING',
  SET_CSV_DOWNLOAD_COMPLETE = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_CSV_DOWNLOAD_COMPLETE',
  GET_TRANSACTIONS = 'STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS',
  GET_TRANSACTIONS_TOTAL_COUNT = 'STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT',
  GET_TRANSACTION_FILTER_OPTIONS = 'STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS',
  SET_FILTERS = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_FILTERS',
  RESET_CURRENT_PAGE = 'STRAITSX_TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE',
  SET_CSV_FORMAT_VERSION = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION',
  SWITCH_TRANSACTIONS_TAB = 'STRAITSX_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB',
  GET_TRANSACTION_TAB_OPTIONS = 'STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS',
  SET_FUNDING_TRANSACTION_TYPE_TAB = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_FUNDING_TRANSACTION_TYPE_TAB',
  SET_TRANSACTION_TYPE_TAB = 'TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE_TAB',
  GET_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT = 'TRANSACTIONS_ACTIONS.GET_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT',
  SET_IS_TRANSACTION_TIMEZONE_CONTROLLED = 'TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_TIMEZONE_CONTROLLED',
  SET_LATEST_TRANSACTIONS = 'STRAITSX_TRANSACTIONS_ACTIONS.SET_LATEST_TRANSACTIONS',
}

type SetIsTxnLoading = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING
  isTxnLoading: boolean
}

type SetIsFilterLoading = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_FILTER_LOADING
  isFilterLoading: boolean
}

type SetIsPaginationLoading = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING
  isPaginationLoading: boolean
}

type GetTransactions = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS
  transactions: Transaction[]
  contractsExist: boolean
}

type GetTransactionsTotalCount = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT
  totalCount: number
}

type GetTransactionFilterOptions = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS
  viewType: string
  filterOptions: {
    [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: FilterOptions
    [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: FilterOptions
  }
}

type SetFilters = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_FILTERS
  filterValuesObject: FilterValuesObject
}

type ResetCurrentPage = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE
}

type SetCsvFormatVersion = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION
  csvFormatVersion: CSV_FORMAT_VERSION
}

type SwitchTransactionsTab = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB
  transactionsTabKey: TRANSACTIONS_TAB_KEY
}

type GetTransactionTabOptions = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS
  transactionTabOptions: TRANSACTIONS_TAB_KEY[]
}

type SetTransactionTypeTab = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE_TAB
  transactionTypeTab: TRANSACTION_TYPE_TAB
}

type SetFundingTransactionTypeTab = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_FUNDING_TRANSACTION_TYPE_TAB
  fundingTransactionTypeTab: FUNDING_TRANSACTION_TYPE_TAB
}

type GetTravelRuleComplianceBlockerTotalCount = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.GET_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT
  travelRuleComplianceBlockerTotalCount: number
}

type SetIsTransactionTimezoneControlled = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_TIMEZONE_CONTROLLED
  isTransactionTimezoneControlled: boolean
}

type SetLatestTransactions = {
  type: typeof STRAITSX_TRANSACTIONS_ACTIONS.SET_LATEST_TRANSACTIONS
  latestTransactions: Transaction[]
}

export type ActionTypes =
  | SetIsTxnLoading
  | SetIsFilterLoading
  | SetIsPaginationLoading
  | GetTransactions
  | GetTransactionsTotalCount
  | GetTransactionFilterOptions
  | SetFilters
  | ResetCurrentPage
  | SetCsvFormatVersion
  | SwitchTransactionsTab
  | GetTransactionTabOptions
  | SetTransactionTypeTab
  | SetFundingTransactionTypeTab
  | GetTravelRuleComplianceBlockerTotalCount
  | SetIsTransactionTimezoneControlled
  | SetLatestTransactions

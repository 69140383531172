import { Reducer } from 'redux'
import {
  ActionTypes,
  XIDR_DEPOSIT_ACTIONS,
  BankTransferInfo,
  QrisTransferInfo,
  BlockchainAddress,
  RetailPaymentInfo,
} from './types'

export const defaultBankTransferInfo = {
  isInitiated: false,
  recipientName: '',
  recipientBankFullName: '',
  recipientBankShortName: '',
  recipientAccountNo: '',
  recipientBankCode: '',
}

export const defaultRetailPaymentInfo = {
  retailOutletName: '',
  expiredAt: '',
  amount: '',
  fees: '',
  paymentCode: '',
}

const initialState = {
  isLoading: false,
  bankTransferInfo: { ...defaultBankTransferInfo },
  qrisTransferInfo: {
    isInitiated: false,
    qrCodeImageUrl: '',
  },
  blockchainAddress: null,
  retailPaymentInfo: { ...defaultRetailPaymentInfo },
}

type State = {
  isLoading: boolean
  bankTransferInfo: BankTransferInfo
  qrisTransferInfo: QrisTransferInfo
  blockchainAddress: BlockchainAddress | null
  retailPaymentInfo: RetailPaymentInfo
}

export const xidrDeposit: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case XIDR_DEPOSIT_ACTIONS.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      }
    }

    case XIDR_DEPOSIT_ACTIONS.SET_BANK_TRANSFER_INFO: {
      return {
        ...state,
        bankTransferInfo: action.bankTransferInfo,
        isLoading: false,
      }
    }

    case XIDR_DEPOSIT_ACTIONS.SET_QRIS_TRANSFER_INFO: {
      return {
        ...state,
        qrisTransferInfo: action.qrisTransferInfo,
        isLoading: false,
      }
    }

    case XIDR_DEPOSIT_ACTIONS.SET_BLOCKCHAIN_ADDRESS: {
      return {
        ...state,
        blockchainAddress: action.blockchainAddress,
        isLoading: false,
      }
    }

    case XIDR_DEPOSIT_ACTIONS.SET_RETAIL_PAYMENT_INFO: {
      return {
        ...state,
        retailPaymentInfo: action.retailPaymentInfo,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

import React from 'react'
import styled from '@emotion/styled'
import { Card, Button, DESKTOP_BREAKPOINT, Link } from '@xfers/design-system'
import { WarningFilled } from '@xfers/design-system/es/icons'
import cookie from 'js-cookie'
import straitsXBackground from '~/assets/straitsXBackground.png'
import SSOManager from '~/managers/SSOManager'
import StraitsXFooter from '~/components/StraitsXFooter'
import { SUPPORT_LINKS } from '~/common/constants'

function KillSwitchLockedPage() {
  const signOut = () => {
    cookie.remove('SESSIONID')
    SSOManager.ssoLogout()
  }

  return (
    <Container>
      <Content>
        <CardContainer>
          <LockContainer>
            <WarningFilled style={{ color: '#E5C412' }} width="50" height="50" />
          </LockContainer>
          <Header>You have locked your account.</Header>
          <Body>
            Pending transactions have been failed for security reasons.
            <br />
            To unlock your account and restore access, please contact{' '}
            <Link href={SUPPORT_LINKS.NEW_REQUEST} target="_blank" rel="noreferrer noopener">
              customer support
            </Link>
            .
          </Body>
          <Button type="secondary" style={{ width: '100%' }} onClick={() => signOut()}>
            Proceed to Log Out
          </Button>
        </CardContainer>
        <StraitsXFooter />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  background-image: url(${straitsXBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  display: flex;
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  ${DESKTOP_BREAKPOINT} {
    padding-top: 0;
  }
`

const CardContainer = styled(Card)`
  max-width: 700px;
  margin-top: 60px;
  padding: 30px;
`

const LockContainer = styled.div`
  text-align: center;
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
`

const Body = styled.div`
  width: 100%;
  margin: 30px auto;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
`

export default KillSwitchLockedPage

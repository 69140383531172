import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  H2,
} from '@xfers/design-system'
import {   
  Column,
  ColumnTitle,
  ColumnText,
  CustomCard,
  Divider,
  Row, 
} from '~/styledComponent/styled'
import { selectMerchantDetail } from '~/biz/store/merchantInfo/selectors'
import { MerchantDetail } from '~/biz/store/merchantInfo/types'
import { parseCountry } from '~/biz/store/businessVerification/helpers'

export default function() {
  const { merchantDetail } = useSelector(selectMerchantDetail)

  const {
    countriesOfActivities,
    businessWebsite,
    businessCategory,
    businessSubCategory,
    businessActivitySummary,
    expectedTransactionVolume,
    expectedValuePerTransaction,
    expectedGtv,
    sourceOfWealth,
    sourceOfFunds,
    sourceOfGoods,
  } = merchantDetail as MerchantDetail

  const getCountriesOfActivities = useCallback((countriesOfActivities: string) => {
    if (countriesOfActivities && countriesOfActivities.length > 0) {
      let parsedCountries: string[] = JSON.parse(countriesOfActivities)
      return parsedCountries.map((country)=> parseCountry(country)).join(', ')
    }
    return '-'
  }, []);

  return (
    <>
      <CustomCard>
        <H2>Business Activities</H2>
        <Divider />
        <Row>
          <Column>
            <ColumnTitle>Country(ies) of Business Activities</ColumnTitle>
            <ColumnText>{getCountriesOfActivities(countriesOfActivities)}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Business Website / Social Media</ColumnTitle>
            <ColumnText>{businessWebsite || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Description / Line(s) of Business</ColumnTitle>
            <ColumnText>{businessCategory || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Description / Line of Business (Subcategory)</ColumnTitle>
            <ColumnText>{businessSubCategory || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Summary of Business Activity</ColumnTitle>
            <ColumnText>{businessActivitySummary || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Expected Number of Transactions per Month</ColumnTitle>
            <ColumnText>{expectedTransactionVolume || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Expected Value per Transaction per User (S$)</ColumnTitle>
            <ColumnText>{expectedValuePerTransaction || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Expected Gross Transaction Volume per Month (S$)</ColumnTitle>
            <ColumnText>{expectedGtv || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Source of Wealth - of Entity as a whole</ColumnTitle>
            <ColumnText>{sourceOfWealth || '-'}</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Source of Funds</ColumnTitle>
            <ColumnText>{sourceOfFunds || '-'}</ColumnText>
          </Column>
        </Row>
        <Row>
          <Column>
            <ColumnTitle>Source of Goods</ColumnTitle>
            <ColumnText>{sourceOfGoods || '-'}</ColumnText>
          </Column>
        </Row>
      </CustomCard>
    </>
  )
}
import { Reducer } from 'redux'
import {
  ActionTypes,
  MINT_ACTIONS,
  Mint,
  MintSetup,
  BankAccount,
  FinancialInstitution,
  SETUP_MODE,
} from './types'
import { BlockchainAddress } from '~/app/store/blockchainAddresses/types'

const initialState: State = {
  mint: null,
  mintSetupData: {
    bankAccounts: null,
  },
  setupMode: SETUP_MODE.CREATE,
  bankAccount: null,
  blockchain: null,
  blockchainAddress: null,
  is2FaOpen: false,
  financialInstitutions: {},
  error: null,
  isMintLimitIncreaseEnabled: false,
}

type State = {
  mint: Mint | null
  mintSetupData: MintSetup
  setupMode: SETUP_MODE
  bankAccount: BankAccount | null
  blockchainAddress: BlockchainAddress | null
  blockchain: string | null
  is2FaOpen: boolean
  financialInstitutions: { [key: number]: FinancialInstitution }
  error: string | null
  isMintLimitIncreaseEnabled: boolean
}

export const mint: Reducer<State, ActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case MINT_ACTIONS.SET_MINT:
      return {
        ...state,
        mint: action.mint || null,
      }
    case MINT_ACTIONS.SET_MINT_SETUP:
      return {
        ...state,
        mintSetupData: action.data,
      }
    case MINT_ACTIONS.SET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccount: action.bankAccount,
      }
    case MINT_ACTIONS.SET_BLOCKCHAIN:
      return {
        ...state,
        blockchain: action.blockchain,
      }
    case MINT_ACTIONS.SET_BLOCKCHAIN_ADDRESS:
      return {
        ...state,
        blockchainAddress: action.blockchainAddress,
      }
    case MINT_ACTIONS.SET_CONFIRM_MODAL_OPEN:
      return {
        ...state,
        is2FaOpen: action.is2FaOpen,
      }
    case MINT_ACTIONS.SET_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case MINT_ACTIONS.SET_SETUP_MODE:
      return {
        ...state,
        setupMode: action.setupMode,
      }
    case MINT_ACTIONS.SET_IS_MINT_LIMIT_INCREASE_ENABLED: {
      return {
        ...state,
        isMintLimitIncreaseEnabled: action.isMintLimitIncreaseEnabled,
      }
    }
    default:
      return state
  }
}

/* eslint-disable max-lines */
import { isMoment } from 'moment'
import { Dispatch, GetState } from '~/app/store/types'
import {
  ActionTypes,
  TRANSACTIONS_ACTIONS,
  CSV_FORMAT_VERSION,
  TRANSACTIONS_TAB_KEY,
  FilterValuesObject,
  Transaction,
  TRANSACTION_TYPE_TAB,
  FilterValues,
  FilterOptions,
  EXPORT_MODE,
  FUNDING_TRANSACTION_TYPE_TAB,
} from './types'
import { get, ENDPOINTS, post } from '~/api'
import transactionTypeFormatter from '~/helpers/transactionTypeHelper'
import { convertToSGTTime, keysToCamel } from '~/helpers/helpers'
import { FUNDING_TX_TYPES, getTransactionTypes, SWAP_TX_TYPES, OTC_TX_TYPES } from './helpers'
import { selectIsLinkedToAsb } from '~/app/store/accounts/selectors'
import { selectFlipperWithId } from '~/store/flippers/selectors'
import { FLIPPER_KEYS } from '~/store/flippers/const'
import { CURRENCY, Moment } from '~/types'

const setTransactions = (transactions: Transaction[], contractsExist: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.GET_TRANSACTIONS,
    transactions,
    contractsExist,
  })
}

const setTransactionCount = (totalCount: number) => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.GET_TRANSACTIONS_TOTAL_COUNT,
    totalCount,
  })
}

export const refreshTransactionsData = (resetPage: boolean = true) => (
  dispatch: Dispatch<ActionTypes>
) => {
  if (resetPage) {
    dispatch(resetCurrentPage())
  }
  dispatch(getTransactions())
  dispatch(getTransactionTotalCount())
}

export const getTransactionOptions = () => (dispatch: Dispatch<ActionTypes>) => {
  get(ENDPOINTS.API_XFERS_TRANSACTIONS_FILTER_OPTIONS).then(resp => {
    const transactionTabOptions = []
    if (
      Object.prototype.hasOwnProperty.call(
        resp.account_types_by_tabs,
        TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT
      )
    ) {
      transactionTabOptions.push(TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT)
    }
    if (
      Object.prototype.hasOwnProperty.call(
        resp.account_types_by_tabs,
        TRANSACTIONS_TAB_KEY.XFERS_WALLET
      )
    ) {
      transactionTabOptions.push(TRANSACTIONS_TAB_KEY.XFERS_WALLET)
    }

    dispatch({
      type: TRANSACTIONS_ACTIONS.GET_TRANSACTION_TAB_OPTIONS,
      transactionTabOptions,
    })

    dispatch({
      type: TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB,
      transactionsTabKey: transactionTabOptions[0],
    })

    const toStablecoinCurrencyMapper = (currency: { label: string; value: CURRENCY }) =>
      currency.value === CURRENCY.SGD
        ? { label: CURRENCY.XSGD.toUpperCase(), value: CURRENCY.XSGD }
        : currency

    dispatch({
      type: TRANSACTIONS_ACTIONS.GET_TRANSACTION_FILTER_OPTIONS,
      viewType: resp.view_type,
      filterOptions: {
        [TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT]: {
          currencySymbols: resp.currency_symbols.map(toStablecoinCurrencyMapper),
          transactionTypes: transactionTypeFormatter({
            initialTypes: resp.transaction_types,
          }),
          transactionStatuses: resp.transaction_statuses,
          sortTypes: resp.sort_types,
          accountTypes: resp.account_types_by_tabs[TRANSACTIONS_TAB_KEY.XFERS_ACCOUNT],
        },
        [TRANSACTIONS_TAB_KEY.XFERS_WALLET]: {
          currencySymbols: resp.currency_symbols.map(toStablecoinCurrencyMapper),
          transactionTypes: transactionTypeFormatter({
            initialTypes: resp.transaction_types,
          }),
          transactionStatuses: resp.transaction_statuses,
          sortTypes: resp.sort_types,
          accountTypes: resp.account_types_by_tabs[TRANSACTIONS_TAB_KEY.XFERS_WALLET],
        },
      },
    })
  })
}

const generateTransactionSubRoute = (params: {
  selectedAccountIds: string[] | number[] | undefined
  isoStartDate: string
  isoEndDate: string
  transactionStatuses: string[]
  transactionTypes: string[]
  sortType: string
  currentPage: number
  pageLimit: number
  searchString: string
  formattedCurrencySymbol: CURRENCY | null | string
}) => {
  return `?start_date=${params.isoStartDate}&end_date=${params.isoEndDate}&transaction_statuses=${
    params.transactionStatuses
  }&transaction_types=${params.transactionTypes}&sort_type=${params.sortType}&account_ids=${
    params.selectedAccountIds
  }&current_page=${params.currentPage}&page_limit=${params.pageLimit}&currency_symbols=${
    params.formattedCurrencySymbol
  }&search_string=${encodeURIComponent(params.searchString)}`
}

export const getTransactions = () => (dispatch: Dispatch<ActionTypes>, getState: GetState) => {
  const currentState = getState().transactions
  const {
    transactionsTabKey,
    filterOptions,
    filterValues,
    transactionTypeTab,
    isTransactionTimezoneControlled,
  } = currentState

  if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')
  if (filterOptions === null) throw new Error('filterOptions cannot be null!')

  const {
    currencySymbol,
    startDate,
    endDate,
    transactionStatuses,
    transactionTypes,
    sortType,
    accountIds,
    currentPage,
    pageLimit,
    searchString,
    isTravelRule,
  } = filterValues[transactionsTabKey]

  let formattedCurrencySymbol = ''
  if (currencySymbol) {
    formattedCurrencySymbol = currencySymbol === CURRENCY.XSGD ? CURRENCY.SGD : currencySymbol
  }

  const isoStartDate = isMoment(startDate)
    ? parseFilterTime(startDate, 'from', isTransactionTimezoneControlled)
    : ''
  const isoEndDate = isMoment(endDate)
    ? parseFilterTime(endDate, 'to', isTransactionTimezoneControlled)
    : ''

  const { accountTypes } = filterOptions[transactionsTabKey]
  const defaultAccountIds = accountTypes && accountTypes.map(element => element.value)
  // Note: If user haven't filtered by wallet type, use the account ids given by default for the particular tab
  const selectedAccountIds = accountIds.length <= 0 ? defaultAccountIds : accountIds

  const generatedSubRoute = generateTransactionSubRoute({
    currentPage,
    isoStartDate,
    isoEndDate,
    pageLimit,
    searchString,
    selectedAccountIds,
    sortType,
    transactionStatuses,
    transactionTypes: getTransactionTypes(transactionTypeTab, transactionTypes), // NOTE: add transaction type filter base on the transaction type tab (funding, swap)
    formattedCurrencySymbol,
  })

  const subRoute = generatedSubRoute + `&is_travel_rule=${isTravelRule}`

  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_IS_TXN_LOADING,
    isTxnLoading: true,
  })

  get(ENDPOINTS.API_XFERS_TRANSACTIONS, subRoute).then(resp => {
    const transactions = (resp.transactions as unknown[]).map(i => keysToCamel(i) as Transaction)
    dispatch(setTransactions(transactions, resp.contracts_exist))
  })
}

export const getTransactionTotalCount = () => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  const {
    transactionsTabKey,
    filterOptions,
    transactionTypeTab,
    isTransactionTimezoneControlled,
  } = getState().transactions

  if (transactionsTabKey === null) throw new Error('transactionsTabKey cannot be null!')
  if (filterOptions === null) throw new Error('filterOptions cannot be null!')

  const {
    currencySymbol,
    startDate,
    endDate,
    transactionStatuses,
    transactionTypes,
    sortType,
    accountIds,
    currentPage,
    pageLimit,
    searchString,
  } = getState().transactions.filterValues[transactionsTabKey]

  let formattedCurrencySymbol = ''
  if (currencySymbol) {
    formattedCurrencySymbol = currencySymbol === CURRENCY.XSGD ? CURRENCY.SGD : currencySymbol
  }

  const isoStartDate = isMoment(startDate)
    ? parseFilterTime(startDate, 'from', isTransactionTimezoneControlled)
    : ''
  const isoEndDate = isMoment(endDate)
    ? parseFilterTime(endDate, 'to', isTransactionTimezoneControlled)
    : ''

  const { accountTypes } = filterOptions[transactionsTabKey]
  const defaultAccountIds = accountTypes && accountTypes.map(element => element.value)
  // Note: If user haven't filtered by wallet type, use the account ids given by default for the particular tab
  const selectedAccountIds = accountIds.length <= 0 ? defaultAccountIds : accountIds

  const subRoute = generateTransactionSubRoute({
    currentPage,
    isoStartDate,
    isoEndDate,
    pageLimit,
    searchString,
    selectedAccountIds,
    sortType,
    transactionStatuses,
    transactionTypes: getTransactionTypes(transactionTypeTab, transactionTypes), // NOTE: add transaction type filter base on the transaction type tab (funding, swap)
    formattedCurrencySymbol,
  })

  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_IS_PAGINATION_LOADING,
    isPaginationLoading: true,
  })

  get(ENDPOINTS.API_XFERS_TRANSACTIONS_TOTAL_COUNT, subRoute).then(resp => {
    dispatch(setTransactionCount(resp.total_count))
  })
}

export const setFilters = (filterValuesObject: FilterValuesObject) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_FILTERS,
    filterValuesObject,
  })

  let resetCurrentPage = true
  if ('currentPage' in filterValuesObject) {
    resetCurrentPage = false
  }
  dispatch(refreshTransactionsData(resetCurrentPage))
}

export const resetCurrentPage = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.RESET_CURRENT_PAGE,
  })
}

export const setFiltersWithoutRefreshTransactions = (filterValuesObject: FilterValuesObject) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_FILTERS,
    filterValuesObject,
  })
}

export const getCsvFormatVersion = () => (dispatch: Dispatch<ActionTypes>) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_CSV_FORMAT_VERSION,
    csvFormatVersion: CSV_FORMAT_VERSION.V3,
  })
}

export const switchTransactionsTab = (transactionsTabKey: TRANSACTIONS_TAB_KEY) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SWITCH_TRANSACTIONS_TAB,
    transactionsTabKey,
  })
  dispatch(refreshTransactionsData())
}

export const getLatestTransactions = () => (
  dispatch: Dispatch<ActionTypes>,
  getState: GetState
) => {
  let latestTxTypes = [...FUNDING_TX_TYPES, ...SWAP_TX_TYPES]

  const { email } = getState().userInfo.userInfo
  const isLinkedToAsb = selectIsLinkedToAsb(getState())
  const flipperOtcTrxUI = selectFlipperWithId(
    FLIPPER_KEYS.SG_OTC_TRX_HISTORY_APP_DASHBOARD,
    email
  )(getState())
  if (isLinkedToAsb && flipperOtcTrxUI?.enabled) latestTxTypes = [...latestTxTypes, ...OTC_TX_TYPES]

  get(
    ENDPOINTS.API_XFERS_TRANSACTIONS,
    `?transaction_types=${latestTxTypes}&current_page=1&page_limit=5`
  ).then(txnResp => {
    const transactions = (txnResp.transactions as unknown[]).map(i => keysToCamel(i) as Transaction)

    dispatch({
      type: TRANSACTIONS_ACTIONS.SET_LATEST_PERSONAL_TRANSACTIONS,
      latestPersonalTransactions: transactions,
    })
  })
}

export const setTransactionTypeTab = (tab: TRANSACTION_TYPE_TAB) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_TRANSACTION_TYPE_TAB,
    transactionTypeTab: tab,
  })
}

export const onSwitchingTransactionTypeTab = (tab: TRANSACTION_TYPE_TAB) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setTransactionTypeTab(tab))

  dispatch(setFundingTransactionTypeTab(FUNDING_TRANSACTION_TYPE_TAB.ALL_FUNDING))
  dispatch(setFiltersWithoutRefreshTransactions({ transactionTypes: [] }))
  dispatch(setFiltersWithoutRefreshTransactions({ transactionStatuses: [] }))
  dispatch(setFiltersWithoutRefreshTransactions({ isTravelRule: false }))

  // reset data before refreshing
  dispatch(setTransactions([], false))
  dispatch(setTransactionCount(0))

  dispatch(refreshTransactionsData())
}

export const setFundingTransactionTypeTab = (tab: FUNDING_TRANSACTION_TYPE_TAB) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_FUNDING_TRANSACTION_TYPE_TAB,
    fundingTransactionTypeTab: tab,
  })
}

export const onSwitchingFundingTransactionTypeTab = (tab: FUNDING_TRANSACTION_TYPE_TAB) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch(setFundingTransactionTypeTab(tab))

  if (tab === FUNDING_TRANSACTION_TYPE_TAB.ACTION_NEEDED) {
    dispatch(setFiltersWithoutRefreshTransactions({ transactionTypes: 'stablecoin_deposit' }))
    dispatch(setFiltersWithoutRefreshTransactions({ transactionStatuses: 'pending' }))
    dispatch(setFiltersWithoutRefreshTransactions({ isTravelRule: true }))
  } else {
    dispatch(setFiltersWithoutRefreshTransactions({ transactionTypes: [] }))
    dispatch(setFiltersWithoutRefreshTransactions({ transactionStatuses: [] }))
    dispatch(setFiltersWithoutRefreshTransactions({ isTravelRule: false }))
  }

  // reset data before refreshing
  dispatch(setTransactions([], false))
  dispatch(setTransactionCount(0))

  dispatch(refreshTransactionsData())
}

export const exportCSV = (
  exportMode: EXPORT_MODE | undefined,
  filterOptions: FilterOptions,
  filterValues: FilterValues,
  isTimezoneControlled: boolean,
  onSuccess?: () => void
) => {
  const { accountTypes } = filterOptions
  const {
    startDate,
    endDate,
    accountIds,
    transactionStatuses,
    transactionTypes,
    searchString,
    sortType,
    currencySymbol,
  } = filterValues

  // get tx types base on export mode
  let finalTxTypes = transactionTypes
  if (exportMode === EXPORT_MODE.ALL) {
    finalTxTypes = []
  } else if (exportMode === EXPORT_MODE.SWAP) {
    finalTxTypes = getTransactionTypes(TRANSACTION_TYPE_TAB.SWAP, transactionTypes)
  } else if (exportMode === EXPORT_MODE.FUNDING) {
    finalTxTypes = getTransactionTypes(TRANSACTION_TYPE_TAB.FUNDING, transactionTypes)
  }

  const isoStartDate = isMoment(startDate)
    ? parseFilterTime(startDate, 'from', isTimezoneControlled)
    : ''
  const isoEndDate = isMoment(endDate) ? parseFilterTime(endDate, 'to', isTimezoneControlled) : ''

  const defaultAccountIds = accountTypes && accountTypes.map(element => element.value)
  // Note: If user haven't filtered by wallet type, use the account ids given by default for the particular tab
  const selectedAccountIds = accountIds.length <= 0 ? defaultAccountIds : accountIds

  let formattedCurrencySymbol = ''
  if (currencySymbol) {
    formattedCurrencySymbol = currencySymbol === CURRENCY.XSGD ? CURRENCY.SGD : currencySymbol
  }

  const requestBody = {
    currency_symbols: formattedCurrencySymbol,
    start_date: isoStartDate,
    end_date: isoEndDate,
    transaction_statuses: transactionStatuses.toString(),
    transaction_types: finalTxTypes.toString(),
    sort_type: sortType.toString(),
    account_ids: selectedAccountIds ? selectedAccountIds.toString() : '',
    search_string: searchString,
    csv_type: 'only_completed',
    // Note: v4 format not finalized yet, we use v3 for now
    csv_format_version: 'V3',
  }

  post(ENDPOINTS.API_XFERS_TRANSACTIONS_EXPORT_CSV, requestBody).then(() => {
    if (onSuccess) {
      onSuccess()
    }
  })
}

export const parseFilterTime = (
  input: Moment,
  type: 'from' | 'to',
  isTimezoneControlEnabled: boolean
) => {
  if (isTimezoneControlEnabled) {
    return convertToSGTTime(input, type)
  }

  if (type === 'from') {
    return input.startOf('day').toISOString()
  } else {
    return input.endOf('day').toISOString()
  }
}

export const getTotalTravelRuleComplianceBlockerCount = () => (dispatch: Dispatch<ActionTypes>) => {
  const subRoute = `?transaction_types=stablecoin_deposit`
  get(ENDPOINTS.API_XFERS_TRANSACTIONS_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT, subRoute).then(
    resp => {
      dispatch({
        type: TRANSACTIONS_ACTIONS.GET_TRAVEL_RULE_COMPLIANCE_BLOCKER_TOTAL_COUNT,
        travelRuleComplianceBlockerTotalCount: resp.total_count,
      })
    }
  )
}

export const setIsTransactionTimezoneControlled = (isEnabled: boolean) => (
  dispatch: Dispatch<ActionTypes>
) => {
  dispatch({
    type: TRANSACTIONS_ACTIONS.SET_IS_TRANSACTION_TIMEZONE_CONTROLLED,
    isTransactionTimezoneControlled: isEnabled,
  })
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Joyride, { Step } from 'react-joyride'
import { css } from 'emotion'
import { DESKTOP_BREAKPOINT, straitsXTheme } from '@xfers/design-system'
import { selectFlipperWithId } from '~/store/flippers/selectors'
import { selectUserEmail } from '~/app/store/userInfo/selectors'
import { FLIPPER_KEYS } from '~/store/flippers/const'
import { RootState } from '~/app/store/types'

import CustomTooltip from './Tooltip'

export default function() {
  const { currentStepIndex } = useSelector((state: RootState) => state.tour)

  const email = useSelector(selectUserEmail)
  const flipperSwapUI = useSelector(selectFlipperWithId(FLIPPER_KEYS.APP_DASHBOARD_SWAP, email))

  const spotlightEl = document.querySelector('.react-joyride__spotlight')
  const navbarSpotlightStyle = css`
    top: 0 !important;
    left: 0 !important;
    height: 100vh !important;
  `
  if (spotlightEl && currentStepIndex === 3) {
    spotlightEl.classList.add(navbarSpotlightStyle)
  }

  const spotlightStyle: React.CSSProperties = useMemo(() => {
    const spotlightSteps = flipperSwapUI?.enabled ? [4, 5, 6] : [4, 5]
    return spotlightSteps.includes(currentStepIndex) ? { borderRadius: '100px' } : {}
  }, [currentStepIndex, flipperSwapUI?.status])

  const threeConsecutiveDigits = /\d{3}/
  const desktopBreakpointWidth = DESKTOP_BREAKPOINT.match(threeConsecutiveDigits)[0]
  const isMobileView = document.documentElement.clientWidth <= desktopBreakpointWidth

  const mobileViewPlacements: string[] = useMemo(
    () => ['top', 'top', 'top', 'bottom-start', 'top', 'top', 'top'],
    []
  )
  const desktopViewPlacements: string[] = useMemo(
    () => ['right-start', 'right-start', 'left-start', 'right-start', 'top', 'top', 'top'],
    []
  )
  const tooltipPlacements = (isMobileView
    ? mobileViewPlacements
    : desktopViewPlacements) as Step['placement'][]
  const tourStepsContent: string[] = useMemo(
    () => [
      'Submit your personal identification documents to start your account verification.',
      'Link your bank account to enable transfers to and from your StraitsX account.',
      'Add your blockchain address to send and receive XSGD.',
      'This is the homepage where you can manage your StraitsX account.',
      'Fund your account by making a bank transfer in or via StraitsX stablecoins on the blockchain.',
      'Redeem StraitsX stablecoins back to your bank account or transfer out stablecoins to the blockchain.',
      'Swap between stablecoin assets that you have.',
    ],
    []
  )

  const tourSteps: Step[] = useMemo(() => {
    const contents = [
      {
        content: tourStepsContent[0],
        target: '.tour-step-1',
        placement: tooltipPlacements[0],
        disableBeacon: true,
        offset: 0,
      },
      {
        content: tourStepsContent[1],
        target: '.tour-step-2',
        placement: tooltipPlacements[1],
        disableBeacon: true,
        offset: 0,
      },
      {
        content: tourStepsContent[2],
        target: '.tour-step-3',
        placement: tooltipPlacements[2],
        disableBeacon: true,
        offset: 0,
      },
      {
        content: tourStepsContent[3],
        target: '.tour-step-4',
        placement: tooltipPlacements[3],
        disableBeacon: true,
        offset: 0,
      },
      {
        content: tourStepsContent[4],
        target: '.tour-step-5',
        placement: tooltipPlacements[4],
        disableBeacon: true,
        offset: 0,
      },
      {
        content: tourStepsContent[5],
        target: '.tour-step-6',
        placement: tooltipPlacements[5],
        disableBeacon: true,
        offset: 0,
      },
    ]

    // TODO: move these into the above object after removing the swap flipper
    if (flipperSwapUI?.enabled) {
      contents.push({
        content: tourStepsContent[6],
        target: '.tour-step-7',
        placement: tooltipPlacements[6],
        disableBeacon: true,
        offset: 0,
      })
    }

    return contents
  }, [flipperSwapUI?.status])

  type JoyrideSettings = {
    disableScroll: boolean
    scrollOffset?: number
  }
  const joyrideSettingsMobile: JoyrideSettings[] = useMemo(
    () => [
      {
        disableScroll: false,
        scrollOffset: 200,
      },
      {
        disableScroll: false,
        scrollOffset: 100,
      },
      {
        disableScroll: false,
        scrollOffset: 100,
      },
      {
        disableScroll: true,
      },
      {
        disableScroll: false,
        scrollOffset: 70,
      },
      {
        disableScroll: true,
      },
      {
        disableScroll: true,
      },
    ],
    []
  )
  const joyrideSettingsDesktop: JoyrideSettings[] = useMemo(
    () => [
      {
        disableScroll: true,
      },
      {
        disableScroll: true,
      },
      {
        disableScroll: true,
      },
      {
        disableScroll: true,
      },
      {
        disableScroll: false,
        scrollOffset: 70,
      },
      {
        disableScroll: true,
      },
      {
        disableScroll: true,
      },
    ],
    []
  )
  const joyrideSettings = isMobileView ? joyrideSettingsMobile : joyrideSettingsDesktop

  return (
    <Joyride
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      disableScrolling={joyrideSettings[currentStepIndex].disableScroll}
      scrollOffset={joyrideSettings[currentStepIndex].scrollOffset}
      run={true}
      steps={tourSteps}
      stepIndex={currentStepIndex}
      spotlightPadding={0}
      tooltipComponent={CustomTooltip}
      styles={{
        options: {
          zIndex: 10000,
          arrowColor: straitsXTheme.brand.action.hover,
          overlayColor: 'rgba(5, 21, 19, 0.7)',
        },
        buttonClose: {
          display: 'none',
        },
        spotlight: spotlightStyle,
      }}
      floaterProps={{
        styles: {
          arrow: {
            length: 12,
            spread: 16,
          },
        },
      }}
    />
  )
}

/* eslint-disable max-lines */
export const countryOptions = [
  { label: 'Indonesia', value: 'ID' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Afghanistan', value: 'AF' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Andorra', value: 'AD' },
  { label: 'Angola', value: 'AO' },
  { label: 'Anguilla', value: 'AI' },
  { label: 'Antarctica', value: 'AQ' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belarus', value: 'BY' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bermuda', value: 'BM' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia (Plurinational State of)', value: 'BO' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Bouvet Island', value: 'BV' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Indian Ocean Territory', value: 'IO' },
  { label: 'Brunei Darussalam', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Burundi', value: 'BI' },
  { label: 'Cabo Verde', value: 'CV' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Central African Republic', value: 'CF' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Christmas Island', value: 'CX' },
  { label: 'Cocos (Keeling) Islands', value: 'CC' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Comoros', value: 'KM' },
  { label: 'Congo', value: 'CG' },
  { label: 'Congo (Democratic Republic of the)', value: 'CD' },
  { label: 'Cook Islands', value: 'CK' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curaçao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czechia', value: 'CZ' },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominica', value: 'DM' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Falkland Islands (Malvinas)', value: 'FK' },
  { label: 'Faroe Islands', value: 'FO' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'French Guiana', value: 'GF' },
  { label: 'French Polynesia', value: 'PF' },
  { label: 'French Southern Territories', value: 'TF' },
  { label: 'Gabon', value: 'GA' },
  { label: 'Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guadeloupe', value: 'GP' },
  { label: 'Guam', value: 'GU' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guernsey', value: 'GG' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guinea-Bissau', value: 'GW' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Haiti', value: 'HT' },
  { label: 'Heard Island and McDonald Islands', value: 'HM' },
  { label: 'Holy See', value: 'VA' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Iran (Islamic Republic of)', value: 'IR' },
  { label: 'Iraq', value: 'IQ' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jersey', value: 'JE' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kiribati', value: 'KI' },
  { label: "Korea (Democratic People's Republic of)", value: 'KP' },
  { label: 'Korea (Republic of)', value: 'KR' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: "Lao People's Democratic Republic", value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Libya', value: 'LY' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macao', value: 'MO' },
  { label: 'Macedonia (the former Yugoslav Republic of)', value: 'MK' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Martinique', value: 'MQ' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mayotte', value: 'YT' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Micronesia (Federated States of)', value: 'FM' },
  { label: 'Moldova (Republic of)', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Montserrat', value: 'MS' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nauru', value: 'NR' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Caledonia', value: 'NC' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Nicaragua', value: 'NI' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Niue', value: 'NU' },
  { label: 'Norfolk Island', value: 'NF' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Palau', value: 'PW' },
  { label: 'Palestine, State of', value: 'PS' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Pitcairn', value: 'PN' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Russian Federation', value: 'RU' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Réunion', value: 'RE' },
  { label: 'Saint Barthélemy', value: 'BL' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Saint Martin (French part)', value: 'MF' },
  { label: 'Saint Pierre and Miquelon', value: 'PM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC' },
  { label: 'Samoa', value: 'WS' },
  { label: 'San Marino', value: 'SM' },
  { label: 'Sao Tome and Principe', value: 'ST' },
  { label: 'Saudi Arabia', value: 'SA' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Seychelles', value: 'SC' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Sint Maarten (Dutch part)', value: 'SX' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { label: 'South Sudan', value: 'SS' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Sudan', value: 'SD' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ' },
  { label: 'Swaziland', value: 'SZ' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Syrian Arab Republic', value: 'SY' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania, United Republic of', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Timor-Leste', value: 'TL' },
  { label: 'Togo', value: 'TG' },
  { label: 'Tokelau', value: 'TK' },
  { label: 'Tonga', value: 'TO' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'Tuvalu', value: 'TV' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'United Arab Emirates', value: 'AE' },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GB',
  },
  { label: 'United States Minor Outlying Islands', value: 'UM' },
  { label: 'United States of America', value: 'US' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vanuatu', value: 'VU' },
  { label: 'Venezuela (Bolivarian Republic of)', value: 'VE' },
  { label: 'Viet Nam', value: 'VN' },
  { label: 'Virgin Islands (British)', value: 'VG' },
  { label: 'Virgin Islands (U.S.)', value: 'VI' },
  { label: 'Wallis and Futuna', value: 'WF' },
  { label: 'Western Sahara', value: 'EH' },
  { label: 'Yemen', value: 'YE' },
  { label: 'Zambia', value: 'ZM' },
  { label: 'Zimbabwe', value: 'ZW' },
  { label: 'Åland Islands', value: 'AX' },
]

export const nationalityOptions = [
  {
    value: 'Indonesian',
    label: 'Indonesian',
  },
  {
    value: 'Singaporean',
    label: 'Singaporean',
  },
  {
    value: 'Malaysian',
    label: 'Malaysian',
  },
  {
    value: 'Afghan',
    label: 'Afghan',
  },
  {
    value: 'Albanian',
    label: 'Albanian',
  },
  {
    value: 'Algerian',
    label: 'Algerian',
  },
  {
    value: 'American',
    label: 'American',
  },
  {
    value: 'American Samoan',
    label: 'American Samoan',
  },
  {
    value: 'Andorran',
    label: 'Andorran',
  },
  {
    value: 'Angolan',
    label: 'Angolan',
  },
  {
    value: 'Anguillian',
    label: 'Anguillian',
  },
  {
    value: 'Antiguan, Barbudan',
    label: 'Antiguan, Barbudan',
  },
  {
    value: 'Argentinean',
    label: 'Argentinean',
  },
  {
    value: 'Armenian',
    label: 'Armenian',
  },
  {
    value: 'Aruban',
    label: 'Aruban',
  },
  {
    value: 'Australian',
    label: 'Australian',
  },
  {
    value: 'Austrian',
    label: 'Austrian',
  },
  {
    value: 'Azerbaijani',
    label: 'Azerbaijani',
  },
  {
    value: 'Bahamian',
    label: 'Bahamian',
  },
  {
    value: 'Bahraini',
    label: 'Bahraini',
  },
  {
    value: 'Bangladeshi',
    label: 'Bangladeshi',
  },
  {
    value: 'Barbadian',
    label: 'Barbadian',
  },
  {
    value: 'Belarusian',
    label: 'Belarusian',
  },
  {
    value: 'Belgian',
    label: 'Belgian',
  },
  {
    value: 'Belizean',
    label: 'Belizean',
  },
  {
    value: 'Beninese',
    label: 'Beninese',
  },
  {
    value: 'Bermudian',
    label: 'Bermudian',
  },
  {
    value: 'Bhutanese',
    label: 'Bhutanese',
  },
  {
    value: 'Bolivian',
    label: 'Bolivian',
  },
  {
    value: 'Bosnian, Herzegovinian',
    label: 'Bosnian, Herzegovinian',
  },
  {
    value: 'Brazilian',
    label: 'Brazilian',
  },
  {
    value: 'British',
    label: 'British',
  },
  {
    value: 'Bruneian',
    label: 'Bruneian',
  },
  {
    value: 'Bulgarian',
    label: 'Bulgarian',
  },
  {
    value: 'Burkinabe',
    label: 'Burkinabe',
  },
  {
    value: 'Burundian',
    label: 'Burundian',
  },
  {
    value: 'Cambodian',
    label: 'Cambodian',
  },
  {
    value: 'Cameroonian',
    label: 'Cameroonian',
  },
  {
    value: 'Canadian',
    label: 'Canadian',
  },
  {
    value: 'Cape Verdian',
    label: 'Cape Verdian',
  },
  {
    value: 'Caymanian',
    label: 'Caymanian',
  },
  {
    value: 'Central African',
    label: 'Central African',
  },
  {
    value: 'Chadian',
    label: 'Chadian',
  },
  {
    value: 'Channel Islander',
    label: 'Channel Islander',
  },
  {
    value: 'Chilean',
    label: 'Chilean',
  },
  {
    value: 'Chinese',
    label: 'Chinese',
  },
  {
    value: 'Christmas Island',
    label: 'Christmas Island',
  },
  {
    value: 'Cocos Islander',
    label: 'Cocos Islander',
  },
  {
    value: 'Colombian',
    label: 'Colombian',
  },
  {
    value: 'Comoran',
    label: 'Comoran',
  },
  {
    value: 'Congolese',
    label: 'Congolese',
  },
  {
    value: 'Cook Islander',
    label: 'Cook Islander',
  },
  {
    value: 'Costa Rican',
    label: 'Costa Rican',
  },
  {
    value: 'Croatian',
    label: 'Croatian',
  },
  {
    value: 'Cuban',
    label: 'Cuban',
  },
  {
    value: 'Cypriot',
    label: 'Cypriot',
  },
  {
    value: 'Czech',
    label: 'Czech',
  },
  {
    value: 'Danish',
    label: 'Danish',
  },
  {
    value: 'Djibouti',
    label: 'Djibouti',
  },
  {
    value: 'Dominican',
    label: 'Dominican',
  },
  {
    value: 'Dutch',
    label: 'Dutch',
  },
  {
    value: 'East Timorese',
    label: 'East Timorese',
  },
  {
    value: 'Ecuadorean',
    label: 'Ecuadorean',
  },
  {
    value: 'Egyptian',
    label: 'Egyptian',
  },
  {
    value: 'Emirian',
    label: 'Emirian',
  },
  {
    value: 'Equatorial Guinean',
    label: 'Equatorial Guinean',
  },
  {
    value: 'Eritrean',
    label: 'Eritrean',
  },
  {
    value: 'Estonian',
    label: 'Estonian',
  },
  {
    value: 'Ethiopian',
    label: 'Ethiopian',
  },
  {
    value: 'Falkland Islander',
    label: 'Falkland Islander',
  },
  {
    value: 'Faroese',
    label: 'Faroese',
  },
  {
    value: 'Fijian',
    label: 'Fijian',
  },
  {
    value: 'Filipino',
    label: 'Filipino',
  },
  {
    value: 'Finnish',
    label: 'Finnish',
  },
  {
    value: 'French',
    label: 'French',
  },
  {
    value: 'French Guianan',
    label: 'French Guianan',
  },
  {
    value: 'French Polynesian',
    label: 'French Polynesian',
  },
  {
    value: 'Gabonese',
    label: 'Gabonese',
  },
  {
    value: 'Gambian',
    label: 'Gambian',
  },
  {
    value: 'Georgian',
    label: 'Georgian',
  },
  {
    value: 'German',
    label: 'German',
  },
  {
    value: 'Ghanaian',
    label: 'Ghanaian',
  },
  {
    value: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    value: 'Greek',
    label: 'Greek',
  },
  {
    value: 'Greenlandic',
    label: 'Greenlandic',
  },
  {
    value: 'Grenadian',
    label: 'Grenadian',
  },
  {
    value: 'Guadeloupian',
    label: 'Guadeloupian',
  },
  {
    value: 'Guamanian',
    label: 'Guamanian',
  },
  {
    value: 'Guatemalan',
    label: 'Guatemalan',
  },
  {
    value: 'Guinea-Bissauan',
    label: 'Guinea-Bissauan',
  },
  {
    value: 'Guinean',
    label: 'Guinean',
  },
  {
    value: 'Guyanese',
    label: 'Guyanese',
  },
  {
    value: 'Haitian',
    label: 'Haitian',
  },
  {
    value: 'Heard and McDonald Islander',
    label: 'Heard and McDonald Islander',
  },
  {
    value: 'Honduran',
    label: 'Honduran',
  },
  {
    value: 'Hungarian',
    label: 'Hungarian',
  },
  {
    value: 'I-Kiribati',
    label: 'I-Kiribati',
  },
  {
    value: 'Icelander',
    label: 'Icelander',
  },
  {
    value: 'Indian',
    label: 'Indian',
  },
  {
    value: 'Iranian',
    label: 'Iranian',
  },
  {
    value: 'Iraqi',
    label: 'Iraqi',
  },
  {
    value: 'Irish',
    label: 'Irish',
  },
  {
    value: 'Israeli',
    label: 'Israeli',
  },
  {
    value: 'Italian',
    label: 'Italian',
  },
  {
    value: 'Ivorian',
    label: 'Ivorian',
  },
  {
    value: 'Jamaican',
    label: 'Jamaican',
  },
  {
    value: 'Japanese',
    label: 'Japanese',
  },
  {
    value: 'Jordanian',
    label: 'Jordanian',
  },
  {
    value: 'Kazakhstani',
    label: 'Kazakhstani',
  },
  {
    value: 'Kenyan',
    label: 'Kenyan',
  },
  {
    value: 'Kirghiz',
    label: 'Kirghiz',
  },
  {
    value: 'Kittian and Nevisian',
    label: 'Kittian and Nevisian',
  },
  {
    value: 'Kuwaiti',
    label: 'Kuwaiti',
  },
  {
    value: 'Laotian',
    label: 'Laotian',
  },
  {
    value: 'Latvian',
    label: 'Latvian',
  },
  {
    value: 'Lebanese',
    label: 'Lebanese',
  },
  {
    value: 'Liberian',
    label: 'Liberian',
  },
  {
    value: 'Libyan',
    label: 'Libyan',
  },
  {
    value: 'Liechtensteiner',
    label: 'Liechtensteiner',
  },
  {
    value: 'Lithuanian',
    label: 'Lithuanian',
  },
  {
    value: 'Luxembourger',
    label: 'Luxembourger',
  },
  {
    value: 'Macedonian',
    label: 'Macedonian',
  },
  {
    value: 'Malagasy',
    label: 'Malagasy',
  },
  {
    value: 'Malawian',
    label: 'Malawian',
  },
  {
    value: 'Maldivan',
    label: 'Maldivan',
  },
  {
    value: 'Malian',
    label: 'Malian',
  },
  {
    value: 'Maltese',
    label: 'Maltese',
  },
  {
    value: 'Manx',
    label: 'Manx',
  },
  {
    value: 'Marshallese',
    label: 'Marshallese',
  },
  {
    value: 'Mauritanian',
    label: 'Mauritanian',
  },
  {
    value: 'Mauritian',
    label: 'Mauritian',
  },
  {
    value: 'Mexican',
    label: 'Mexican',
  },
  {
    value: 'Micronesian',
    label: 'Micronesian',
  },
  {
    value: 'Moldovan',
    label: 'Moldovan',
  },
  {
    value: 'Monegasque',
    label: 'Monegasque',
  },
  {
    value: 'Mongolian',
    label: 'Mongolian',
  },
  {
    value: 'Montenegrin',
    label: 'Montenegrin',
  },
  {
    value: 'Montserratian',
    label: 'Montserratian',
  },
  {
    value: 'Moroccan',
    label: 'Moroccan',
  },
  {
    value: 'Mosotho',
    label: 'Mosotho',
  },
  {
    value: 'Motswana',
    label: 'Motswana',
  },
  {
    value: 'Mozambican',
    label: 'Mozambican',
  },
  {
    value: 'Myanmarian',
    label: 'Myanmarian',
  },
  {
    value: 'Namibian',
    label: 'Namibian',
  },
  {
    value: 'Nauruan',
    label: 'Nauruan',
  },
  {
    value: 'Nepalese',
    label: 'Nepalese',
  },
  {
    value: 'New Caledonian',
    label: 'New Caledonian',
  },
  {
    value: 'New Zealander',
    label: 'New Zealander',
  },
  {
    value: 'Ni-Vanuatu',
    label: 'Ni-Vanuatu',
  },
  {
    value: 'Nicaraguan',
    label: 'Nicaraguan',
  },
  {
    value: 'Nigerian',
    label: 'Nigerian',
  },
  {
    value: 'Niuean',
    label: 'Niuean',
  },
  {
    value: 'Norfolk Islander',
    label: 'Norfolk Islander',
  },
  {
    value: 'North Korean',
    label: 'North Korean',
  },
  {
    value: 'Norwegian',
    label: 'Norwegian',
  },
  {
    value: 'Omani',
    label: 'Omani',
  },
  {
    value: 'Pakistani',
    label: 'Pakistani',
  },
  {
    value: 'Palauan',
    label: 'Palauan',
  },
  {
    value: 'Palestinian',
    label: 'Palestinian',
  },
  {
    value: 'Panamanian',
    label: 'Panamanian',
  },
  {
    value: 'Papua New Guinean',
    label: 'Papua New Guinean',
  },
  {
    value: 'Paraguayan',
    label: 'Paraguayan',
  },
  {
    value: 'Peruvian',
    label: 'Peruvian',
  },
  {
    value: 'Pitcairn Islander',
    label: 'Pitcairn Islander',
  },
  {
    value: 'Polish',
    label: 'Polish',
  },
  {
    value: 'Portuguese',
    label: 'Portuguese',
  },
  {
    value: 'Puerto Rican',
    label: 'Puerto Rican',
  },
  {
    value: 'Qatari',
    label: 'Qatari',
  },
  {
    value: 'Romanian',
    label: 'Romanian',
  },
  {
    value: 'Russian',
    label: 'Russian',
  },
  {
    value: 'Rwandan',
    label: 'Rwandan',
  },
  {
    value: 'Sahrawi',
    label: 'Sahrawi',
  },
  {
    value: 'Saint Barthélemy Islander',
    label: 'Saint Barthélemy Islander',
  },
  {
    value: 'Saint Helenian',
    label: 'Saint Helenian',
  },
  {
    value: 'Saint Lucian',
    label: 'Saint Lucian',
  },
  {
    value: 'Saint Martin Islander',
    label: 'Saint Martin Islander',
  },
  {
    value: 'Saint Vincentian',
    label: 'Saint Vincentian',
  },
  {
    value: 'Salvadoran',
    label: 'Salvadoran',
  },
  {
    value: 'Sammarinese',
    label: 'Sammarinese',
  },
  {
    value: 'Samoan',
    label: 'Samoan',
  },
  {
    value: 'Sao Tomean',
    label: 'Sao Tomean',
  },
  {
    value: 'Saudi Arabian',
    label: 'Saudi Arabian',
  },
  {
    value: 'Senegalese',
    label: 'Senegalese',
  },
  {
    value: 'Serbian',
    label: 'Serbian',
  },
  {
    value: 'Seychellois',
    label: 'Seychellois',
  },
  {
    value: 'Sierra Leonean',
    label: 'Sierra Leonean',
  },
  {
    value: 'Slovak',
    label: 'Slovak',
  },
  {
    value: 'Slovene',
    label: 'Slovene',
  },
  {
    value: 'Solomon Islander',
    label: 'Solomon Islander',
  },
  {
    value: 'Somali',
    label: 'Somali',
  },
  {
    value: 'South African',
    label: 'South African',
  },
  {
    value: 'South Georgia and the South Sandwich Islander',
    label: 'South Georgia and the South Sandwich Islander',
  },
  {
    value: 'South Korean',
    label: 'South Korean',
  },
  {
    value: 'South Sudanese',
    label: 'South Sudanese',
  },
  {
    value: 'Spanish',
    label: 'Spanish',
  },
  {
    value: 'Sri Lankan',
    label: 'Sri Lankan',
  },
  {
    value: 'Sudanese',
    label: 'Sudanese',
  },
  {
    value: 'Surinamer',
    label: 'Surinamer',
  },
  {
    value: 'Swazi',
    label: 'Swazi',
  },
  {
    value: 'Swedish',
    label: 'Swedish',
  },
  {
    value: 'Swiss',
    label: 'Swiss',
  },
  {
    value: 'Syrian',
    label: 'Syrian',
  },
  {
    value: 'Tadzhik',
    label: 'Tadzhik',
  },
  {
    value: 'Taiwanese',
    label: 'Taiwanese',
  },
  {
    value: 'Tanzanian',
    label: 'Tanzanian',
  },
  {
    value: 'Thai',
    label: 'Thai',
  },
  {
    value: 'Togolese',
    label: 'Togolese',
  },
  {
    value: 'Tokelauan',
    label: 'Tokelauan',
  },
  {
    value: 'Tongan',
    label: 'Tongan',
  },
  {
    value: 'Trinidadian',
    label: 'Trinidadian',
  },
  {
    value: 'Tunisian',
    label: 'Tunisian',
  },
  {
    value: 'Turkish',
    label: 'Turkish',
  },
  {
    value: 'Turkmen',
    label: 'Turkmen',
  },
  {
    value: 'Turks and Caicos Islander',
    label: 'Turks and Caicos Islander',
  },
  {
    value: 'Tuvaluan',
    label: 'Tuvaluan',
  },
  {
    value: 'Ugandan',
    label: 'Ugandan',
  },
  {
    value: 'Ukrainian',
    label: 'Ukrainian',
  },
  {
    value: 'Uruguayan',
    label: 'Uruguayan',
  },
  {
    value: 'Uzbekistani',
    label: 'Uzbekistani',
  },
  {
    value: 'Venezuelan',
    label: 'Venezuelan',
  },
  {
    value: 'Vietnamese',
    label: 'Vietnamese',
  },
  {
    value: 'Virgin Islander',
    label: 'Virgin Islander',
  },
  {
    value: 'Wallis and Futuna Islander',
    label: 'Wallis and Futuna Islander',
  },
  {
    value: 'Yemeni',
    label: 'Yemeni',
  },
  {
    value: 'Zambian',
    label: 'Zambian',
  },
  {
    value: 'Zimbabwean',
    label: 'Zimbabwean',
  },
]

export const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
]

export const raceOptions = [
  { value: 'AB', label: 'Anglo Burmese' },
  { value: 'AC', label: 'Anglo Chinese' },
  { value: 'AD', label: 'Amerindian' },
  { value: 'AF', label: 'African' },
  { value: 'AG', label: 'Afghan' },
  { value: 'AH', label: 'Anglo Thai' },
  { value: 'AI', label: 'Anglo Indian' },
  { value: 'AJ', label: 'Achehnese' },
  { value: 'AL', label: 'Albanian' },
  { value: 'AM', label: 'Armenian' },
  { value: 'AN', label: 'Annamite' },
  { value: 'AO', label: 'Ambonese' },
  { value: 'AP', label: 'Anglo Filipino' },
  { value: 'AR', label: 'Arab' },
  { value: 'AS', label: 'Assami' },
  { value: 'AT', label: 'Austrian' },
  { value: 'AU', label: 'Australian' },
  { value: 'AX', label: 'Anglo Saxon' },
  { value: 'AY', label: 'Aryan' },
  { value: 'AZ', label: 'Azeri' },
  { value: 'BA', label: 'Batak' },
  { value: 'BB', label: 'Bulgarian' },
  { value: 'BC', label: 'Butonese' },
  { value: 'BD', label: 'Bangladeshi' },
  { value: 'BE', label: 'Belgian' },
  { value: 'BF', label: 'Bajau' },
  { value: 'BG', label: 'Bugis' },
  { value: 'BH', label: 'Burgher' },
  { value: 'BI', label: 'Bengali' },
  { value: 'BJ', label: 'Banjarese' },
  { value: 'BK', label: 'Bamar' },
  { value: 'BL', label: 'Bangala' },
  { value: 'BM', label: 'Balinese' },
  { value: 'BN', label: 'Bhutanese' },
  { value: 'BO', label: 'Banten' },
  { value: 'BQ', label: 'Basque' },
  { value: 'BR', label: 'Brahmin' },
  { value: 'BS', label: 'Bisaya' },
  { value: 'BT', label: 'British' },
  { value: 'BU', label: 'Burmese' },
  { value: 'BV', label: 'Bosniak' },
  { value: 'BW', label: 'Betawi' },
  { value: 'BY', label: 'Boyanese' },
  { value: 'BZ', label: 'Brazilian' },
  { value: 'CA', label: 'Caucasian' },
  { value: 'CB', label: 'Canadian' },
  { value: 'CC', label: 'Cape Coloured' },
  { value: 'CD', label: 'Cambodian' },
  { value: 'CE', label: 'Ceylonese' },
  { value: 'CF', label: 'Cornish' },
  { value: 'CG', label: 'Creole' },
  { value: 'CH', label: 'Swiss' },
  { value: 'CI', label: 'Croat' },
  { value: 'CJ', label: 'Chamorro' },
  { value: 'CM', label: 'Ceylon Moor' },
  { value: 'CN', label: 'Chinese' },
  { value: 'CO', label: 'Cocos' },
  { value: 'CR', label: 'Caribbean' },
  { value: 'CS', label: 'Czechoslovak' },
  { value: 'CZ', label: 'Czech' },
  { value: 'DA', label: 'Dane' },
  { value: 'DB', label: 'Dutch Burgher' },
  { value: 'DH', label: 'Bidayuh' },
  { value: 'DS', label: 'Dusun' },
  { value: 'DU', label: 'Dutch' },
  { value: 'DY', label: 'Dayak' },
  { value: 'EL', label: 'English' },
  { value: 'ER', label: 'European' },
  { value: 'ES', label: 'Spanish' },
  { value: 'ET', label: 'Ethiopian' },
  { value: 'EU', label: 'Eurasian' },
  { value: 'EY', label: 'Egyptian' },
  { value: 'FI', label: 'Finn' },
  { value: 'FJ', label: 'Fijian' },
  { value: 'FM', label: 'Flemish' },
  { value: 'FR', label: 'French' },
  { value: 'GA', label: 'Goan' },
  { value: 'GE', label: 'Gujarati' },
  { value: 'GH', label: 'Ghanaian' },
  { value: 'GK', label: 'Gurkha' },
  { value: 'GM', label: 'German' },
  { value: 'GO', label: 'Goanese' },
  { value: 'GR', label: 'Greek' },
  { value: 'HA', label: 'Haitian' },
  { value: 'HI', label: 'Hispanic' },
  { value: 'HO', label: 'Hollander' },
  { value: 'HT', label: 'Hindustani' },
  { value: 'HU', label: 'Hungarian' },
  { value: 'HW', label: 'Hawaiian' },
  { value: 'IA', label: 'Iranian' },
  { value: 'IB', label: 'Iban' },
  { value: 'ID', label: 'Indonesian' },
  { value: 'IK', label: 'Isoko' },
  { value: 'IL', label: 'Israeli' },
  { value: 'IN', label: 'Indian' },
  { value: 'IQ', label: 'Iraqi' },
  { value: 'IR', label: 'Irish' },
  { value: 'IS', label: 'Icelander' },
  { value: 'IT', label: 'Italian' },
  { value: 'IU', label: 'Inuit' },
  { value: 'JA', label: 'Javanese' },
  { value: 'JK', label: 'Jakun' },
  { value: 'JM', label: 'Jamaican' },
  { value: 'JO', label: 'Jordanian' },
  { value: 'JP', label: 'Japanese' },
  { value: 'JW', label: 'Jew' },
  { value: 'KA', label: 'Kachin' },
  { value: 'KB', label: 'Khasi' },
  { value: 'KC', label: 'Kayah' },
  { value: 'KD', label: 'Kayin' },
  { value: 'KE', label: 'Kenyan' },
  { value: 'KF', label: 'Kinh' },
  { value: 'KG', label: 'Kyrgyz' },
  { value: 'KH', label: 'Khmer' },
  { value: 'KI', label: 'Kenyah' },
  { value: 'KK', label: 'Kazakh' },
  { value: 'KL', label: 'Kelabit' },
  { value: 'KM', label: 'Kampuchean' },
  { value: 'KN', label: 'Karen' },
  { value: 'KR', label: 'Korean' },
  { value: 'KY', label: 'Kayan' },
  { value: 'KZ', label: 'Kadazan' },
  { value: 'LA', label: 'Lao' },
  { value: 'LB', label: 'Lebanese' },
  { value: 'LI', label: 'Lithuanian' },
  { value: 'LK', label: 'Sri Lankan' },
  { value: 'LT', label: 'Latin' },
  { value: 'LV', label: 'Latvian' },
  { value: 'LX', label: 'Luxembourger' },
  { value: 'LY', label: 'Libyan' },
  { value: 'MA', label: 'Madurese' },
  { value: 'MB', label: 'Malabari' },
  { value: 'MC', label: 'Magyars' },
  { value: 'MD', label: 'Moldavian' },
  { value: 'ME', label: 'Minangkabau' },
  { value: 'MF', label: 'Manx' },
  { value: 'MG', label: 'Malagasy' },
  { value: 'MH', label: 'Mahratta' },
  { value: 'MI', label: 'Maori' },
  { value: 'MJ', label: 'Murut' },
  { value: 'MK', label: 'Makasarese' },
  { value: 'ML', label: 'Maldivian' },
  { value: 'MM', label: 'Malayalee' },
  { value: 'MN', label: 'Melanesian' },
  { value: 'MO', label: 'Mongolian' },
  { value: 'MP', label: 'Manipuri' },
  { value: 'MQ', label: 'Mestizo' },
  { value: 'MR', label: 'Marathi' },
  { value: 'MS', label: 'Metis' },
  { value: 'MT', label: 'Maltese' },
  { value: 'MU', label: 'Mauritian' },
  { value: 'MV', label: 'Mon' },
  { value: 'MW', label: 'Moroccan' },
  { value: 'MX', label: 'Mexican' },
  { value: 'MY', label: 'Malay' },
  { value: 'MZ', label: 'Melanau' },
  { value: 'NA', label: 'Naga' },
  { value: 'NG', label: 'Negro' },
  { value: 'NI', label: 'Nigerian' },
  { value: 'NL', label: 'Netherlander' },
  { value: 'NO', label: 'Norwegian' },
  { value: 'NP', label: 'Nepalese' },
  { value: 'NR', label: 'Nauruan' },
  { value: 'NW', label: 'Newar' },
  { value: 'NZ', label: 'New Zealander' },
  { value: 'PA', label: 'Penan' },
  { value: 'PB', label: 'Palestine' },
  { value: 'PC', label: 'Polish' },
  { value: 'PE', label: 'Persian' },
  { value: 'PH', label: 'Filipino' },
  { value: 'PJ', label: 'Punjabi' },
  { value: 'PK', label: 'Pakistani' },
  { value: 'PL', label: 'Pole' },
  { value: 'PN', label: 'Pathan' },
  { value: 'PO', label: 'Portuguese' },
  { value: 'PR', label: 'Peruvian' },
  { value: 'PS', label: 'Parsee' },
  { value: 'PY', label: 'Polynesian' },
  { value: 'RJ', label: 'Rajput' },
  { value: 'RK', label: 'Rakhine' },
  { value: 'RO', label: 'Romanian' },
  { value: 'RU', label: 'Russian' },
  { value: 'SA', label: 'Sino Kadazan' },
  { value: 'SB', label: 'Sammarinese' },
  { value: 'SC', label: 'Scot' },
  { value: 'SD', label: 'Sindhi' },
  { value: 'SE', label: 'Swede' },
  { value: 'SF', label: 'Serbia/Montengerin' },
  { value: 'SG', label: 'Samoan' },
  { value: 'SH', label: 'Shan' },
  { value: 'SI', label: 'Sinhalese' },
  { value: 'SJ', label: 'Sino Japanese' },
  { value: 'SK', label: 'Sikh' },
  { value: 'SL', label: 'Slovak' },
  { value: 'SM', label: 'Sumatran' },
  { value: 'SN', label: 'Sino Indian' },
  { value: 'SO', label: 'Somali' },
  { value: 'SQ', label: 'Slavic' },
  { value: 'SR', label: 'Serani' },
  { value: 'SS', label: 'Sundanese' },
  { value: 'SU', label: 'Sudanese' },
  { value: 'SW', label: 'Swedish' },
  { value: 'SY', label: 'Seychellois' },
  { value: 'TE', label: 'Telugu' },
  { value: 'TH', label: 'Thai' },
  { value: 'TI', label: 'Tibetan' },
  { value: 'TJ', label: 'Tajik' },
  { value: 'TM', label: 'Tamil' },
  { value: 'TN', label: 'Turkmen' },
  { value: 'TO', label: 'Tongan' },
  { value: 'TP', label: 'Timor' },
  { value: 'TR', label: 'Turk' },
  { value: 'UN', label: 'Unknown' },
  { value: 'UR', label: 'Ukrainian' },
  { value: 'US', label: 'American' },
  { value: 'UY', label: 'Uyghur' },
  { value: 'UZ', label: 'Uzbek' },
  { value: 'VE', label: 'Venezuelan' },
  { value: 'VN', label: 'Vietnamese' },
  { value: 'WE', label: 'Welsh' },
  { value: 'XD', label: 'Other Indonesian' },
  { value: 'XE', label: 'Other Eurasian' },
  { value: 'XI', label: 'Other Indian' },
  { value: 'XX', label: 'Others' },
  { value: 'YU', label: 'Yugoslav' },
  { value: 'ZW', label: 'Zimbabwean' },
]

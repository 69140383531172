import { PATHS } from '~/app/routes/paths'

export type User = {
  kcId: string | undefined
  fullName: string
  email: string
  mobileNumber: string
  countryCode: string
  avatarUrl: string
  displayname: string
  authServerUrl: string
  twoFa: {
    isOnCoolingDownPeriod: boolean
    twoFaSetupTime: string
    twoFaList: {
      credential: {
        id: string
        type: string
        userLabel: string
        createdDate: number
        credentialData: Record<string, unknown>
      }
    }[]
  }
  isBitgoAddressAcknowledged?: boolean
}

export type Notification = {
  id: number
  title: string
  message: string
  created_at: string
  is_read: number
  external_link: string
}

export type Credential = {
  id: string
  type: string
  createdDate: number
  credentialData: string;
}

export type UserCredentialMetadata = {
  credential: Credential
}

export type Credentials = {
  type: string
  category: string
  displayName: string
  helptext: string
  iconCssClass: string
  updateAction?: string
  createAction?: string
  removeable: boolean
  userCredentialMetadatas: UserCredentialMetadata[]
}

export enum ROUTE_ACTIONS {
  SET_REDIRECT = 'ROUTE_ACTIONS.SET_REDIRECT',
  SET_USER_PROFILE = 'ROUTE_ACTIONS.SET_USER_PROFILE',
  UPDATE_USER_PROFILE = 'ROUTE_ACTIONS.UPDATE_USER_PROFILE',
  SET_NOTIFICATIONS = 'ROUTE_ACTIONS.SET_NOTIFICATIONS',
  TOGGLE_NOTIFICATIONS_LOADING = 'ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING',
  SET_DASHBOARD_REVAMP_ENABLED = 'ROUTE_ACTIONS.SET_DASHBOARD_REVAMP_ENABLED',
  SET_MINT_DASHBOARD_ENABLED = 'SET_MINT_DASHBOARD_ENABLED',
  SET_MINT_DASHBOARD_TEST_ENABLED = 'SET_MINT_DASHBOARD_TEST_ENABLED',
  SET_IS_WELCOME_MODAL_OPENED = 'SET_IS_WELCOME_MODAL_OPENED',
  SET_IS_TYPEFORM_SURVEY_VISIBLE = 'ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_VISIBLE',
  SET_IS_TYPEFORM_SURVEY_REQUIRED = 'ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_REQUIRED',
  SET_IS_LOCKED_FEATURE_ON = 'ROUTE_ACTIONS.SET_IS_LOCKED_FEATURE_ON',
  SET_IS_LINKED_TO_ASB = 'ROUTE_ACTIONS.SET_IS_LINKED_TO_ASB',
  SET_IS_USER_INFO_INITIALISED = 'ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED',
  SET_IS_USER_HAS_PASSWORD = 'ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD',
}

type SetRedirect = {
  type: typeof ROUTE_ACTIONS.SET_REDIRECT
  to: PATHS | null
}

type SetUserProfile = {
  type: typeof ROUTE_ACTIONS.SET_USER_PROFILE
  user: User
}

type UpdateUserProfile = {
  type: typeof ROUTE_ACTIONS.UPDATE_USER_PROFILE
  avatarUrl: string
}

type SetNotifications = {
  type: typeof ROUTE_ACTIONS.SET_NOTIFICATIONS
  data: Notification[]
  unreadCount: number
  isLoading: boolean
}

type ToggleNotificationsLoading = {
  type: typeof ROUTE_ACTIONS.TOGGLE_NOTIFICATIONS_LOADING
  isLoading: boolean
}

type SetIsWelcomeModalOpened = {
  type: typeof ROUTE_ACTIONS.SET_IS_WELCOME_MODAL_OPENED
  enabled: boolean
}

type SetIsTypeformSurveyVisible = {
  type: typeof ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_VISIBLE
  isTypeformSurveyVisible: boolean
}

type SetIsTypeformSurveyRequired = {
  type: typeof ROUTE_ACTIONS.SET_IS_TYPEFORM_SURVEY_REQUIRED
  isTypeformSurveyRequired: boolean
}

type SetIsLockedFeatureOn = {
  type: typeof ROUTE_ACTIONS.SET_IS_LOCKED_FEATURE_ON
  isLockedFeatureOn: boolean
}

type SetIsLinkedToAsb = {
  type: typeof ROUTE_ACTIONS.SET_IS_LINKED_TO_ASB
  isLinkedToAsb: boolean
}

type SetIsUserInfoInitialised = {
  type: typeof ROUTE_ACTIONS.SET_IS_USER_INFO_INITIALISED
  isUserInfoInitialised: boolean
}

type SetIsUserHasPassword = {
  type: typeof ROUTE_ACTIONS.SET_IS_USER_HAS_PASSWORD
  isUserHasPassword: boolean
}

export type ActionTypes =
  | SetRedirect
  | SetUserProfile
  | UpdateUserProfile
  | SetNotifications
  | ToggleNotificationsLoading
  | SetIsWelcomeModalOpened
  | SetIsTypeformSurveyVisible
  | SetIsTypeformSurveyRequired
  | SetIsLockedFeatureOn
  | SetIsLinkedToAsb
  | SetIsUserInfoInitialised
  | SetIsUserHasPassword

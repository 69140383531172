/* eslint-disable max-lines, react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from 'emotion'
import { matchPath } from 'react-router'
import { Link, withRouter, useLocation } from 'react-router-dom'
import { Location, History } from 'history'
import Menu from 'antd/es/menu'
import 'antd/es/menu/style/css'
import {
  MAIN_FONT,
  FONT_SIZE,
  FONT_WEIGHT,
  NEUTRAL_COLORS,
  Link as CustomLink,
  straitsXTheme,
} from '@xfers/design-system'
import {
  Flag as FlagIcon,
  NavDashboard as NavDashboardIcon,
  NavTransactions as NavTransactionsIcon,
  User as UserIcon,
  DeveloperTool as DeveloperToolIcon,
  Employee as TeamIcon,
  Invoice as InvoiceIcon,
  Home as HomeIcon,
  Transaction as TransactionIcon,
  AccountStatement as AccountStatementIcon,
  FaqDialog as FaqDialogIcon,
  CustomerSupport as CustomerSupportIcon,
  MintWallet as MintWalletIcon,
  PiggyBank as EarnIcon,
} from '@xfers/design-system/es/icons'
import sendSolidSrc from '~/assets/sendSolid.svg'
import NewLabel from '~/components/NewLabel'
import { createGaEvent } from '~/helpers/gaHelper'

import { PATHS as APP_PATHS } from '~/app/routes/paths'
import { PATHS as BIZ_PATHS } from '~/biz/routes/paths'
import { PATHS as XIDR_APP_PATHS } from '~/xidrApp/routes/paths'
import { PATHS as XIDR_BIZ_PATHS } from '~/xidrBiz/routes/paths'

import { PERMISSIONS, Route, SubRoute } from '~/types'
import { withPermission } from '~/permission'
import '~/helpers/String'

import { importStraitsXRoutes } from '~/helpers/dynamicImport'
import { ConfigManager } from '~/managers/ConfigManager'

const { brand } = straitsXTheme

type PathsOfEnum = APP_PATHS | BIZ_PATHS | XIDR_APP_PATHS | XIDR_BIZ_PATHS

const newPaths: PathsOfEnum[] = [APP_PATHS.MINT]
const checkIsNewPath = (pathToCheck: PathsOfEnum | undefined, paths: PathsOfEnum[]) => {
  if (!pathToCheck) return false
  return paths.includes(pathToCheck)
}

const getTabIcon = (isSandbox: boolean, tabName?: string) => {
  const tabIconStyle = {
    marginRight: 10,
    width: 18,
    height: 18,
    color: isSandbox ? '#051513' : '#00d37e',
  }
  switch (tabName) {
    case 'Get Started':
      return <FlagIcon style={tabIconStyle} />
    case 'Dashboard':
      return <NavDashboardIcon style={tabIconStyle} />
    case 'Home':
      return <HomeIcon style={tabIconStyle} className="home-icon" />
    case 'Transaction History':
      return <TransactionIcon style={tabIconStyle} />
    case 'Account Statement':
      return <AccountStatementIcon style={tabIconStyle} />
    case 'Credit Cards':
      return <NavTransactionsIcon style={tabIconStyle} />
    case 'Users':
      return <UserIcon style={tabIconStyle} />
    case 'Developer Tools':
      return <DeveloperToolIcon style={tabIconStyle} />
    case 'Team':
      return <TeamIcon style={tabIconStyle} />
    case 'Payment Link':
      return <InvoiceIcon style={tabIconStyle} />
    case 'FAQ':
      return <FaqDialogIcon style={tabIconStyle} />
    case 'Support':
      return <CustomerSupportIcon style={tabIconStyle} />
    case 'Mint':
      return <MintWalletIcon style={tabIconStyle} />
    case 'Earn':
      return <EarnIcon style={tabIconStyle} className="earn-icon" />
    case 'Send':
      return <img src={sendSolidSrc} style={tabIconStyle} />
    default:
      return null
  }
}

const CustomMenuItem: React.FC<{
  tab: {
    tabName?: string
    path?: PathsOfEnum
    permission?: PERMISSIONS
    subTabNames?: SubRoute[]
  }
  key: string
  currentPath: PathsOfEnum
  isSandbox: boolean
  onClickCallback?: Function
}> = ({ tab, key, currentPath, isSandbox, children, onClickCallback, ...props }) => {
  const matchConfig =
    tab.path === ('/' as PathsOfEnum) ? { path: tab.path, exact: true } : { path: tab.path }
  const match = matchPath(currentPath, matchConfig)

  const WithSubMenu = () => {
    if (tab.subTabNames) {
      const checkPath = (pathNames: SubRoute[], isSandboxProp: boolean) => {
        const paths = pathNames.map(pathName => pathName.path as PathsOfEnum)
        const matchPathName = matchPath(currentPath, paths)
        return matchPathName ? activeSubMenu(isSandboxProp) : ''
      }
      return (
        <Menu.SubMenu
          title={tab.tabName}
          icon={getTabIcon(isSandbox, tab.tabName)}
          className={checkPath(tab.subTabNames, isSandbox) || SubMenuStyle}
          {...props}
        >
          {tab.subTabNames.map((subMenu, index) => SubMenuItem(subMenu, `key-${index}`))}
        </Menu.SubMenu>
      )
    }
    return MenuItem()
  }

  const MenuItem = () => {
    const linkStyleClass = isSandbox ? sandBoxActiveLinkClass : activeLinkClass
    const location = useLocation()
    const currentPath = location.pathname
    const linkClass = match ? linkStyleClass : ''
    const tourClass =
      tab.path === APP_PATHS.LANDING_PAGE ? 'tour-step-4 tour-target-homepage-nav-item' : ''
    const showNewLabel = checkIsNewPath(tab.path, newPaths)

    const SubMenuTitleComponent = (
      <SubMenuTitle className="no-bg-change-on-select" isSandbox={isSandbox}>
        {getTabIcon(isSandbox, tab.tabName)}
        {tab.tabName}
        {showNewLabel && <NewLabel />}
      </SubMenuTitle>
    )

    return (
      <Menu.Item className={`${linkClass} ${tourClass}`} key={key} {...props}>
        {onClickCallback ? (
          <button type="button" className={StyleResetButton} onClick={() => onClickCallback()}>
            {SubMenuTitleComponent}
          </button>
        ) : (
          <Link
            data-cy={`link-${tab.tabName?.removeSpace().toCamelCase()}`}
            to={tab.path || '/'}
            onClick={() => {
              createGaEvent(
                'General',
                `click${tab.tabName?.removeSpace()}Link`,
                `Clicked ${tab.tabName} in Left Menu`
              )

              if (tab.path === currentPath && tab.path === '/') {
                window.location.href = tab.path || '/'
              }
            }}
          >
            {SubMenuTitleComponent}
          </Link>
        )}
        {children}
      </Menu.Item>
    )
  }

  const SubMenuItem = (subRoute: SubRoute, indexKey: string) => {
    const subRouteConfig = subRoute.path as PathsOfEnum
    const matchSubRoute = matchPath(currentPath, subRouteConfig)
    const subMenulinkStyleClass = isSandbox ? sandBoxSubMenuLinkClass : subMenuLinkClass
    return (
      <Menu.Item
        className={matchSubRoute ? subMenulinkStyleClass : ''}
        key={indexKey || key}
        {...props}
      >
        <Link to={subRoute.path || '/'}>
          <CustomSubMenuItem>{subRoute.sideBar}</CustomSubMenuItem>
        </Link>
      </Menu.Item>
    )
  }

  const EnhancedCustomMenu = tab.permission
    ? withPermission(WithSubMenu, tab.permission)
    : WithSubMenu
  return <EnhancedCustomMenu />
}

type NavigationMenuProps = {
  location: Location
  history: History
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  match: any
  displayEarnTab: boolean
  displayGetStartedTab: boolean
  displayMintTab: boolean
  displayAccountStatementTab?: boolean
  displayDevToolTab?: boolean
  onClickCallbacks: { [k: string]: Function } | undefined
  isSandbox: boolean
}

const NavigationMenu = (props: NavigationMenuProps) => {
  const { pathname } = props.location

  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [routes, setRoutes] = useState<Route[]>([])

  useEffect(() => {
    setUpRoutes()
    async function setUpRoutes() {
      let straitsXRoutes = await importStraitsXRoutes()
      if (!props.displayGetStartedTab) {
        // user has completed get started so don't include get started tab
        straitsXRoutes = straitsXRoutes.filter(route => route.path !== APP_PATHS.GET_STARTED)
      }
      if (!props.displayMintTab) {
        straitsXRoutes = straitsXRoutes.filter(route => route.path !== APP_PATHS.MINT)
      }
      if (!props.displayDevToolTab) {
        straitsXRoutes = straitsXRoutes.filter(route => route.path !== BIZ_PATHS.DEVELOPER_TOOLS)
      }
      if (!props.displayEarnTab) {
        straitsXRoutes = straitsXRoutes.filter(route => route.path !== BIZ_PATHS.EARN)
      }
      if (!props.displayAccountStatementTab) {
        straitsXRoutes = straitsXRoutes.filter(route => route.path !== BIZ_PATHS.ACCOUNT_STATEMENT)
      }
      setRoutes(straitsXRoutes)
    }
  }, [
    props.displayGetStartedTab,
    props.displayMintTab,
    props.displayEarnTab,
    props.displayDevToolTab,
    props.displayAccountStatementTab,
  ])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onOpenChange = (openKey: any[]) => setOpenKeys(openKey)

  const routesHasTab = routes
    .filter(route => route.sideBar)
    .map(({ path, sideBar, permission, subRoutes }) => {
      return {
        path,
        tabName: sideBar,
        permission,
        subTabNames: subRoutes,
        onClickCallback: path ? props.onClickCallbacks?.[path] : undefined,
      }
    })

  const isXIDRSite = ConfigManager.isXIDRSite()

  const faqLink = isXIDRSite
    ? 'https://support.id.straitsx.com/hc/en-us'
    : 'https://support.straitsx.com/hc/en-us'

  const supportLink = isXIDRSite
    ? 'https://support.id.straitsx.com/hc/en-us/requests/new'
    : 'https://support.straitsx.com/hc/en-us/requests/new'

  return (
    <Container isSandbox={props.isSandbox}>
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
        {routesHasTab.map((route, idx) => (
          <CustomMenuItem
            isSandbox={props.isSandbox}
            tab={route}
            key={idx.toString()}
            currentPath={pathname as PathsOfEnum}
            onClickCallback={route.onClickCallback}
          />
        ))}
        <Menu.Item>
          <CustomLink
            href={faqLink}
            target="_blank"
            onClick={() => createGaEvent('Landing', 'clickFAQLink', 'Clicked FAQ on Useful Links')}
            className={noUnderline}
          >
            <SubMenuTitle className="no-bg-change-on-select" isSandbox={props.isSandbox}>
              {getTabIcon(props.isSandbox, 'FAQ')}
              FAQ
            </SubMenuTitle>
          </CustomLink>
        </Menu.Item>
        <Menu.Item>
          <CustomLink
            href={supportLink}
            target="_blank"
            onClick={() =>
              createGaEvent(
                'Landing',
                'clickCustomerSupportLink',
                'Clicked Create Customer Support Link on Useful Links'
              )
            }
            className={noUnderline}
          >
            <SubMenuTitle className="no-bg-change-on-select" isSandbox={props.isSandbox}>
              {getTabIcon(props.isSandbox, 'Support')}
              Support
            </SubMenuTitle>
          </CustomLink>
        </Menu.Item>
      </Menu>
    </Container>
  )
}

export default withRouter(NavigationMenu)

const sandBoxOrMainColor = (props: { isSandbox: boolean; isSubMenu?: boolean }) =>
  props.isSandbox ? '#051513' : 'white'

const SubMenuTitle = styled.div`
  transition: 0.2s all ease !important;
  font-family: ${MAIN_FONT};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: ${FONT_SIZE.P1};
  color: ${sandBoxOrMainColor};
  padding-left: ${(props: { isSubMenu?: boolean }) => (props.isSubMenu ? '5px' : '15px')};
  padding-right: ${(props: { isSubMenu?: boolean }) => (props.isSubMenu ? 0 : '15px')};
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  .ant-menu-item &:hover {
    background-color: ${(props: { isSandbox: boolean }) =>
      props.isSandbox ? brand.primary.default : 'white'};
    color: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')};
  }
  .ant-menu-item &:hover svg:not(.earn-icon) path {
    fill: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#00d37e')} !important;
  }
  &:hover .home-icon path {
    stroke: ${(props: { isSandbox: boolean }) =>
      props.isSandbox ? 'white' : '#00d37e'} !important;
  }
`

const CustomSubMenuItem = styled.div`
  font-family: ${MAIN_FONT};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: ${FONT_SIZE.P1};
  color: white;
  padding-left: 5px;
  border-radius: 5px;
  .ant-menu-item &:hover {
    background-color: white;
    color: #051513;
  }
  .ant-menu-item &:hover path {
    fill: #00d37e !important;
  }
`

const SubMenuStyle = css`
  transition: 0.3s all ease !important;
  font-family: ${MAIN_FONT};
  font-weight: ${FONT_WEIGHT.MEDIUM};
  font-size: ${FONT_SIZE.P1};
  color: ${NEUTRAL_COLORS.G400};
  border-radius: 5px;
`

const Container = styled.div`
  padding-bottom: 45px;
  & .ant-menu-inline {
    border: 0;
    background: ${(props: { isSandbox: boolean }) => (props.isSandbox ? '#FFFFFF' : '#051513')};
  }
  & .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 50px;
    margin: 0;
    padding-left: 14px !important;
  }
  & .ant-menu-inline > .ant-menu-submenu {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  & .ant-menu-inline .ant-menu-item {
    height: unset;
    margin: 0;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  & .ant-menu-inline .ant-menu-submenu {
    height: unset;
    margin: 0 0 8px 0;
    padding-bottom: 7px;
    padding-top: 7px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: calc(100% + 1px);
  }

  & .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: ${sandBoxOrMainColor};
    :hover {
      color: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')};
      background-color: ${(props: { isSandbox: boolean }) =>
        props.isSandbox ? brand.action.default : 'white'};
    }
  }

  & .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
    background: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')};
  }
  & .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')};
  }
  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background: ${sandBoxOrMainColor};
  }
  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: ${sandBoxOrMainColor};
  }
  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: transparent;
  }
  & .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }
  &
    .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected
    ${SubMenuTitle}:not(.no-bg-change-on-select) {
    background-color: ${(props: { isSandbox: boolean }) =>
      props.isSandbox ? brand.primary.default : 'white'};
    color: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')};
  }
  & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected ${CustomSubMenuItem} {
    background-color: ${(props: { isSandbox: boolean }) =>
      props.isSandbox ? brand.primary.default : 'white'};
    color: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')};
  }
  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: 30px;
    line-height: 50px;
  }
  & .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 54px;
    padding: 2.5px 0 2.5px 40px !important;
    margin: 0;
  }
  & .ant-menu-submenu > .ant-menu {
    background: ${sandBoxOrMainColor};
  }
  & .ant-menu-item > a::before {
    display: none;
  }
  & .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 8px;
  }
  & .ant-menu-submenu-selected > .ant-menu-submenu-title {
    font-family: ${MAIN_FONT};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    font-size: ${FONT_SIZE.P1};
    color: ${(props: { isSandbox: boolean }) => (props.isSandbox ? 'white' : '#051513')} !important;
    background-color: ${sandBoxOrMainColor};
  }
  & .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background: ${(props: { isSandbox: boolean }) =>
      props.isSandbox ? 'white' : '#051513'} !important;
  }
  & .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: ${(props: { isSandbox: boolean }) =>
      props.isSandbox ? 'white' : '#051513'} !important;
  }
  @media (max-width: 768px) {
    & .ant-menu-submenu-open > .ant-menu-submenu-title {
      font-family: ${MAIN_FONT};
      font-weight: ${FONT_WEIGHT.MEDIUM};
      font-size: ${FONT_SIZE.P1};
      color: ${(props: { isSandbox: boolean }) =>
        props.isSandbox ? 'white' : '#051513'} !important;
      background-color: ${(props: { isSandbox: boolean }) =>
        props.isSandbox ? brand.primary.default : 'white'};
      .ant-menu-submenu-arrow::after,
      .ant-menu-submenu-arrow::before {
        background: ${(props: { isSandbox: boolean }) =>
          props.isSandbox ? 'white' : '#051513'} !important;
      }
    }
  }
`

const activeLinkClass = css`
  &.ant-menu-item ${SubMenuTitle} {
    background-color: white;
    color: #051513;
  }
  &.ant-menu-item ${SubMenuTitle} svg:not(.earn-icon) path {
    fill: #00d37e !important;
  }
`
const sandBoxActiveLinkClass = css`
  &.ant-menu-item ${SubMenuTitle} {
    background-color: ${brand.primary.default};
    color: white;
  }
  &.ant-menu-item ${SubMenuTitle} path {
    fill: white !important;
  }
  &.ant-menu-item ${SubMenuTitle} .home-icon path {
    stroke: white !important;
  }
`

const subMenuLinkClass = css`
  &.ant-menu-item ${CustomSubMenuItem} {
    background-color: white;
    color: #00d37e;
  }
  &.ant-menu-item ${CustomSubMenuItem} path {
    fill: #00d37e !important;
  }
`
const sandBoxSubMenuLinkClass = css`
  &.ant-menu-item ${CustomSubMenuItem} {
    background-color: ${brand.primary.default};
    color: white;
  }
  &.ant-menu-item ${CustomSubMenuItem} path {
    fill: white !important;
  }
`

const activeSubMenu = (isSandbox: boolean) => css`
  & .ant-menu-submenu-title {
    font-family: ${MAIN_FONT};
    font-weight: ${FONT_WEIGHT.MEDIUM};
    font-size: ${FONT_SIZE.P1};
    color: ${isSandbox ? 'white' : '#051513'} !important;
    background-color: ${isSandbox ? brand.primary.default : 'white'};
    .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-arrow::before {
      background: ${isSandbox ? 'white' : '#051513'} !important;
    }
  }
`
const noUnderline = css`
  text-decoration: none !important;
`

const StyleResetButton = css`
  width: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
`
